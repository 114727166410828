import React from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";

const loggedInFooter = ( props ) => (

    <footer className="pt-4 my-md-5 pt-md-5 border-top no-print">
      <div className="row">
        <div className="col-12 col-md">
          <img className="mb-2" src="/images/relate-sm.png" alt="Relate" width="24" height="24"/>
          <small className="d-block mb-3 text-muted">&copy; 2017-2019 IN</small>
          <small className="d-block mb-3 text-muted">Logged in as: {props.userData.username}</small>
        </div>
        <div className="col-6 col-md">
          <h5><i className="fal fa-user-circle"/> Account</h5>
          <ul className="list-unstyled text-small" style={{lineHeight: '190%'}}>
            <li><NavLink className="p-2 text-muted" exact={true} to='/orders'><i className="fal fa-tags"/> Orders</NavLink></li>
            <li><NavLink className="p-2 text-muted" exact={true} to='/users/account'><i className="fal fa-key"/> Update Password</NavLink></li>
            {/*<li><NavLink className="p-2 text-muted" exact={true} to='/account'><i className="fal fa-unlock"></i> Change Password</NavLink></li>*/}
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5><i className="fal fa-book"/> Resources</h5>
          <ul className="list-unstyled text-small" style={{lineHeight: '190%'}}>
            <li><a className="text-muted"  target='_blank' href="https://relatefoundation.com/" rel="noopener noreferrer" title="Relate Blog">Relate Blog</a></li>
          </ul>
        </div>


        <div className="col-6 col-md">
          <h5><i className="fal fa-info-circle"/> About</h5>
          <ul className="list-unstyled text-small" style={{lineHeight: '190%'}}>
            {/*<li><a className="text-muted" href="#"><i className="fal fa-user-friends"></i> Team</a></li>*/}
            <li><a className="text-muted" target='_blank' href="/privacy.html">Privacy</a></li>
            <li><a className="text-muted" target='_blank' href="/terms.html">Terms</a></li>
          </ul>
        </div>
      </div>
    </footer>


);

const mapReduxStateToProps = (reduxState) => {
  return {
    userData: reduxState.oldReducer.userData,
  };
};

export default connect(mapReduxStateToProps) (loggedInFooter);

//export default loggedInFooter;