import React from 'react';

const error = (props) => (
    <div className="pricing-header mx-auto text-center">
      <h5 className="display-4  mb-4" style={{fontSize: '2.3em'}}>{props.heading}</h5>
      <div className="alert alert-danger" role="alert">
        {props.children}
      </div>
    </div>
);

export default error;