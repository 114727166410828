import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

function StrongerMarriage({match, location, cartContents}) {

    const [view, setView] = useState([]);
    const {params: {slug}} = match;

    let cart = {};

    let history = useHistory();

    useEffect(() => {

        function populateCart() {

            let newCart = {};

            localStorage.removeItem('cart');

            if (match.params['assessment'] === 'relate') {
                newCart = {
                    "originalPrice": 40,
                    "coupon": "STRONGER",
                    "totalCost": 0,
                    "totalQty": 1,
                    "discount": 40,
                    "cartContents": [{"productName": "Couples Package", "qty": 1, "price": 40, "sku": "MC-OTN-141"}]
                };
            } else {
                newCart = {
                    "originalPrice": 25,
                    "coupon": "STRONGER",
                    "totalCost": 0,
                    "totalQty": 1,
                    "discount": 40,
                    "cartContents": [{"productName": "Ready", "qty": 1, "price": 25, "sku": "MC-GTV-227"}]
                };
            }


            localStorage.setItem('cart', JSON.stringify(newCart));

            window.location = '/payment';

        }

        populateCart();

    }, []);

    return (

        <>

            <div className="fa-5x" style={{textAlign: "center", margin: 'auto', color: "#4374A2"}}>
                <span style={{}}><i className="fas fa-spinner fa-spin"></i></span>
                <h2>Processing request...</h2>
            </div>


            {/*<p>*/}
            {/*    <strong>Cart: </strong>*/}
            {/*    {JSON.stringify(cart)}*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*    <strong>slug: </strong>*/}
            {/*    {slug}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    <strong>Match Props: </strong>*/}
            {/*    <code>{JSON.stringify(match, null, 2)}</code>*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    <strong>Location Props: </strong>*/}
            {/*    <code>{JSON.stringify(location, null, 2)}</code>*/}
            {/*</p>*/}
        </>

    );
}

export default StrongerMarriage;