import React, {Component} from 'react';
import SkeletonLine from "../layout/SkeletonLine";

class LinkSkeleton extends Component {

    render() {

        return (

            <div className="card mt-4">
                <div className="card-header" style={{fontSize: '.9em', color: '#646464'}}>
                    <SkeletonLine />
                </div>
                <div className="card-body scroll">
                    <div className="container">

                        <table className="table table-fixed">
                            <tbody>
                            <tr className="text-center">
                                <th><SkeletonLine /></th>
                                <th><SkeletonLine /></th>
                                <th><SkeletonLine /></th>
                            </tr>
                            <tr className="text-center">
                                <th><SkeletonLine /></th>
                                <th><SkeletonLine /></th>
                                <th><SkeletonLine /></th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );
    }

}

export default LinkSkeleton;