import React from 'react';
//import React, {useState} from 'react';
//import axios from "axios";
import {withRouter} from 'react-router-dom';

function AvailableAssessmentItem(props) {


    function shareAssessmentCode(assessmentCode) {

        props.history.push({
            pathname: '/assessment/code/share',
            state: {code: assessmentCode} // your data array of objects
        });

    }

  function nickNameAssessment() {
    props.setRedemptionCode(props.redemptionCode);

    props.nickNameAssessment();

  }

  // let used = props.usedByUserEmail !== null && props.usedByUserEmail.length > 0;

  let button =
      <button type="submit" className="btn btn-sm btn-outline-success ml-2 justify-content-center d-md-table mx-auto" onClick={() => nickNameAssessment()}>
        <i className="fal fa-hourglass-start"/> Begin
      </button>;

    let shareButton =
        <button type="submit" className="btn btn-sm btn-outline-primary ml-2 justify-content-center d-md-table mx-auto" onClick={() => shareAssessmentCode(props.redemptionCode)}>
            <i className="fal fa-share"/> Share
        </button>;

     //  let usedBy = used ? <div className='used-code'>Used by: <span className='text-info'>{props.usedByUserEmail}</span></div> : '';



  return (

      <>
          <tr className=' table-middle'>
              <td className="w-auto">
                  <img className='img-re-50' title={props.productName} alt={props.productName} src={`/images/${props.sku}.jpg`}/>
              </td>
              <td className="w-auto" style={{verticalAlign: "middle"}}>
                      <h4><strong>{props.productName} Assessment</strong></h4>
                         <h5><small>{props.redemptionCode}</small></h5>
                         {/*{usedBy}*/}
              </td>
              <td  style={{verticalAlign: "middle"}}>
                  {button}
              </td>
              <td style={{verticalAlign: "middle"}}>
                  {(props.sku === '2' || props.sku === '3' ) && shareButton}
              </td>
          </tr>
      </>

      // <>
      //
      //   <div className="d-flex justify-content-around align-items-center w-75">
      //     <div className="p-2">
      //       <img className={used ? 'grayscale' : 'img-re-50'} title={props.productName} alt={props.productName} src={`/images/${props.sku}.jpg`}/>
      //     </div>
      //     <div className="mr-auto p-2">
      //       <h4 className={used ? 'text-muted' : ''}><strong>{props.productName} Assessment</strong></h4>
      //       <h5><small className={used ? 'used-code' : ''}>{props.redemptionCode}</small></h5>
      //       {usedBy}
      //     </div>
      //     <div className="p-2">
      //       {button}
      //     </div>
      //   </div>
      //
      //   <hr/>
      //
      //
      // </>

  )

}

export default withRouter(AvailableAssessmentItem);