import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavigationItem from "./navigationItems/NavigationItem";

class CartDisplay extends Component {

  render () {

    return (

        <NavigationItem link="/cart" exact >
      <span style={{fontSize: '28px', color: '#b4c0d0'}}>
        <i className="fal fa-shopping-cart"></i>
      </span>
          <span className="badge badge-pill badge-success badge-custom">{this.props.totalQty}</span>
        </NavigationItem>



    )
  }

}

const mapReduxStateToProps = (reduxState) => {
  return {
    totalQty: reduxState.checkOut.cart.totalQty,
  };
};

export default connect(mapReduxStateToProps) (CartDisplay);