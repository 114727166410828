import React, {Component} from 'react';

import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';
import Radial from "./Radial";

class MarriageAdvantage extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-chart-line fa-2x" style={{color: "#c3b2b5"}}/></div>
                        <div>
                            MARRIAGE OUTLOOK
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    While not everyone wants to marry or will marry, marriage is an important life goal for most of us. We often
                    have very different beliefs about marriage, such as what a marriage should look like, or how important marriage
                    is compared to other life goals. The goal of this section is to provide some feedback on how your particular
                    approach to marriage could potentially influence your chances of forming a happy, successful marriage in the
                    future.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="pt-4">
                    <b>MARRIAGE ADVANTAGE: <span className='text-black-70'>{this.props.advantage.primarySelf[0] < 50 && 'Single'}
                    {this.props.advantage.primarySelf[0] >= 50 && 'Married'}</span></b>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    Many of us either want to be married or stay single, and where we fall often depends on how advantageous we think one is compared to the other.
                    In other words, which one will give us the best chances at having the life we want?  In this section we have assessed if you believe being single
                    or married gives you the advantage in providing the space and resources you need to reach your life goals.
                </div>

                {this.props.windowSize.windowWidth >= 710 &&

                <div className="d-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill align-items-center text-center"
                         style={{fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>Single
                    </div>
                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.advantage.primarySelf[0]} cId='bxlu'/>
                    </div>
                    <div className="p-2 flex-fill gauge">Married</div>
                </div>
                }

                {this.props.windowSize.windowWidth < 710 &&

                <div className="d-flex justify-content-around align-items-center">

                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.advantage.primarySelf[0]} cId='bxlu'/>
                    </div>

                </div>
                }

                <div style={{borderBottom: '2px #4374a3 solid', marginTop: '-8em', zIndex: 9999}} />

                <div className="d-flex justify-content-around align-items-start">
                    <div className={'mt-2 p-2 flex-even ' + (this.props.advantage.primarySelf[0] < 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge" >Single
                            </div>
                            }
                            <p>
                            Low: If you scored low in Advantage it means you generally see being single as giving you more chances at reaching your life goals.
                            You probably see the demands of marriage as conflicting with the space and resources you feel you need in reaching your goals.
                            </p>
                            <p>
                            Pros: Some things are easier to accomplish while being single, such as valuable volunteer 	experiences and internships that require relocation.
                            Research shows that important 	developmental processes are also enhanced while single, allowing for greater maturity
                            and focus in accomplishing your life goals.
                            </p>
                            <p>
                            Cons: Hesitation to commit to relationships because you do not feel done being single 	can inhibit social experiences that may contribute to
                            greater marriage stability and 	satisfaction later in life.  If your current life goals do not allow for the presence of another
                            in your life, this may also carry over into later relationships which will make it more 	difficult to adjust.  If marriage is a future
                            goal for you, consider how flexible your life 	goals would be if another person became a significant part of your life.
                            Research also 	shows that those who remain single for an extended period of time become less satisfied
                            with life than those who pursue meaningful relationships.
                            </p>
                        </div>
                    </div>
                    <div className={'mt-2 p-2 flex-even ' + (this.props.advantage.primarySelf[0] >= 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill text-center gauge">Married</div>
                            }
                            <p>
                                High: If you scored high in Advantage it means you generally see marriage as giving you a leg up in life.
                                You see marriage as being helpful in accomplishing your life goals.  You believe that marriage will open up
                                new opportunities rather than close existing ones.
                            </p>
                            <p>
                                Pros: Because your life goals are consistent with marriage, you will be better able to 	include your future
                                relationships and spouse in reaching your goals rather than having to 	choose between them.  It will be easier to
                                place marriage as a top priority, which has been 	shown to be an essential part of creating stable and satisfying marriages.
                                Research also 	shows that those who marry are often better off in finances, physical health, and life 	satisfaction.
                            </p>

                            <p>
                                Cons: You may be required to sacrifice, or at least adjust, some of your life goals if they
                                do not match those of your future spouse.  Bringing another into your life also means 	events in their life will place
                                some limitations on your flexibility in achieving your goals.  	When looking for potential partners,
                                it is helpful to find someone with whom you share 	compatible goals and dreams.
                            </p>
                        </div>
                    </div>
                </div>

                <div style={{borderBottom: '2px #4374a3 solid'}} className='mb-5'/>

            </>

        )

    }

}

export default withWindowSizeListener(MarriageAdvantage);