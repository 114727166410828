import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class RelationshipEffort extends Component {


    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;

    }


    render() {

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-hand-holding-heart fa-2x" style={{color: "#c3b2b5"}}/></div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            RELATIONSHIP EFFORT
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                {this.props.windowSize.windowWidth > 710 &&

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td align="center"><DonutChart elId='effort1' percent={this.props.relEffort.primarySelf[0]}
                                                           color={this.props.relEffort.primarySelf[1]}/>
                            </td>
                            <td className="border-right" align="center"><DonutChart elId='effort2'
                                                                                    percent={this.props.relEffort.partnerPrimary[0]}
                                                                                    color={this.props.relEffort.partnerPrimary[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='effort3'
                                                           percent={this.props.relEffort.primaryPartner[0]}
                                                           color={this.props.relEffort.primaryPartner[1]}/></td>
                            {/*<td align="center"><DonutChart elId='kind4' percent='0' color='#eee' /></td>*/}
                            <td align="center"><DonutChart elId='effort4'
                                                           percent={this.props.relEffort.partnerSelf[0] !== '' ? this.props.relEffort.partnerSelf[0] : '0'}
                                                           color={this.props.relEffort.partnerSelf[1]}/></td>

                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                            <td className="border-right" align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                            <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                            <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                            {/*NO PARTNER <br/>ANSWER RECORDED*/}
                        </tr>
                        </tbody>
                    </table>
                </div>
                }

                {this.props.windowSize.windowWidth <= 710 &&
                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <td align="center"><DonutChart elId='effort1' percent={this.props.relEffort.primarySelf[0]}
                                                       color={this.props.relEffort.primarySelf[1]}/>
                        </td>
                        <td align="center"><DonutChart elId='effort2' percent={this.props.relEffort.partnerPrimary[0]}
                                                       color={this.props.relEffort.partnerPrimary[1]}/>
                        </td>

                    </tr>
                    <tr className="border-bottom" style={{fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                        <td align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>

                    </tr>
                    <tr>
                        <td align="center"><DonutChart elId='effort3' percent={this.props.relEffort.primaryPartner[0]}
                                                       color={this.props.relEffort.primaryPartner[1]}/></td>
                        <td align="center"><DonutChart elId='effort4'
                                                       percent={this.props.relEffort.partnerSelf[0] !== '' ? this.props.relEffort.partnerSelf[0] : '0'}
                                                       color={this.props.relEffort.partnerSelf[1]}/></td>
                    </tr>
                    <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                        <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                    </tr>
                    </tbody>
                </table>
                }


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    The <b>Relationship Effort</b> scale measures yours and your partner's persistence in use of ideas
                    and skills to change
                    your own behaviour and enhance your relationship. Effort is especially important when initial
                    attempts to
                    improve the relationship do not work well.
                </div>

                <div className='mt-3 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            In research with couples, the higher the relationship strategies and effort the better
                            couples sustain high relationship satisfaction. Use of strategies and effort is particularly
                            important when couples are experiencing times of rapid change or stress.
                        </div>
                    </div>
                </div>

                <StrengthLegendDetail id="re-strength">
                    People who score in the strength areas of these scales see themselves as using a lot of different
                    ideas and approaches to being a more loving partner.
                    They persist in trying to be constructive even when their initial attempts are unsuccessful. Their
                    relationship is something they
                    think about regularly, and they make strengthening the relationship a life priority.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="re-needs-impr">
                    Scoring in this area is a good indication that you want to make your relationship successful and you
                    put in a lot of effort.
                    However, you may be trying to use the same tactics all the time when other tactics may actually be
                    more effective.
                    You may also find change difficult and unappreciated, so you may also not try as hard as you have in
                    the past.
                    Don’t give up though, because even consistent effort is better than giving up and if you try making
                    small changes at first,
                    you may find that your efforts start to produce better results.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="re-challenge">
                    People who have Relationship Effort scores in the challenge area are likely to have their
                    relationships become less satisfying over time.
                    People rating themselves in the challenge area on these scales may want to increase the focus and
                    priority they attach to their relationship.
                </ChallengeLegendDetail>

                <button className="btn btn-outline-dark no-print" type="button" data-toggle="collapse"
                        data-target="#reffort"
                        aria-expanded="false" aria-controls="emotion">
                    Show Section Questions
                </button>

                <div className="collapse" id="reffort">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-hand-holding-heart"/> Relationship Effort
                            </h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col" className="align-middle">Please answer the following questions about
                                        YOURSELF
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">If things go wrong in the relationship I tend to feel
                                        powerless.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I tend to fall back on what is comfortable for me in
                                        relationships, rather than trying new ways of relating.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Even when I know what I could do differently to improve
                                        things in the relationship, I cannot seem to change my behavior.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">If my partner doesn't appreciate the change efforts I
                                        am making, I tend to give up.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>What are some of your strategies for resolving relationship issues? How well have
                                they worked in the past? How well are they working now?
                            </li>
                            <li>How could you help each other step outside of your comfort zone to try new ways
                                of improving your relationship?
                            </li>
                            <li>How are you prioritizing your relationship? Try imagining what would happen if you
                                changed your priorities.
                            </li>
                            <li>How do you show appreciation for the efforts your partner makes? How do they
                                show appreciation for your efforts? How are you different?
                            </li>
                            <li>Do you tend to focus on those parts of your relationship which are out of your
                                control or in your control? How might this change your strategies for improving
                                your relationship?
                            </li>
                        </ul>
                    </div>
                </div>

            </>

        )

    }

}

export default withWindowSizeListener(RelationshipEffort);