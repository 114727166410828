import {connect} from "react-redux";
//import axios from 'axios';
import axios from "../axios/axios-custom";
import React, {Component} from "react";
import NavButton from "../layout/navbar/navigationItems/NavButton";
import CartItem from './CartItem';
import * as actionCreators from "../store/action/checkOut";
import LoadingIcon from "./LoadingIcon";

class Cart extends Component {

  state = {
    couponCode: '',
    couponError: '',
    invalidClass: 'form-control is-invalid',
    validClass: 'form-control',
    loading: false,
  };

  componentDidMount() {

    this.setState({cart: this.props.cartContents});
  }

  handleCouponCode = (e) => {
    this.setState({couponCode: e.target.value.toUpperCase(), couponError: ''});
  };

  applyCoupon = () => {

    const enteredCode = this.state.couponCode.trim();

    if (enteredCode !== '') {

      this.setState({loading: true});

      let url = `${process.env.REACT_APP_API_URL}/api/coupons/${enteredCode}/validate`;

      axios.get(url)
      .then(response => {

        if(response.data.amountDiscount) {
          this.props.onApplyDiscount(response.data.couponCode, response.data.percent, response.data.amount);

        } else {
          this.props.onApplyDiscount(response.data.couponCode, response.data.percent);
        }


        this.setState({couponCode: '', loading: false});
       // this.setState({loading: false});

      })
      .catch(error => {

        this.setState({couponError: 'Invalid Coupon', loading: false});
        //this.setState({loading: false});

      });

    }

  };

  handleQtyChange = (event, item) => {

    const qty = event.target.type === 'number' ? parseInt(event.target.value) : event.target.value;

    const cart = {...item, qty: qty};

    if (qty === 0) {

      this.props.onRemoveFromCart(cart.sku);

    } else {
      this.props.onUpdateCart(cart);
    }

    this.setState({cart: cart});

  };


  handleQtyChange2 = (qty, item) => {

    const cart = {...item, qty: qty};

    if (qty === 0) {

      this.props.onRemoveFromCart(cart.sku);

    } else {
      this.props.onUpdateCart(cart);
    }

    this.setState({cart: cart});

  };

  removeCartItem = (item) => {

    const cart = {...item};

    this.props.onRemoveFromCart(cart.sku);

  };

  removeAppliedCoupon = () => {

    this.props.onRemoveCoupon();

  };

  render() {

    let checkOutButton = <NavButton className="btn btn-success pull-right" link="/payment">Checkout</NavButton>;


    let cartContents = this.props.cart.cartContents.map((item, idx) => {
      return (



          <CartItem key={idx} sku={item.sku} name={item.productName} price={item.price} qty={item.qty}
                    value={item.qty}
                    changed={(qty) => this.handleQtyChange2(qty, item)}
                    removeItem={() => this.removeCartItem(item)}
          />

      )
    });

    if (this.props.cart.cartContents.length === 0) {
      cartContents = <div>Your Cart is Empty. <NavButton className="btn btn-outline-primary btn-sm ml-3" link="/pricing"><i className="fal fa-cash-register"></i> Let's Go Shopping</NavButton></div>;
      checkOutButton = <button className="btn btn-success pull-right" disabled={true}>Checkout</button>;
    }

    let discountDiv = '';

    if (this.props.cart.coupon.trim() !== '' && this.props.cart.discount > 0) {

      discountDiv = <div className="alert alert-success" role="alert">
        A discount code <strong>{this.props.cart.coupon}</strong> was applied to this purchase.
        <button className="btn btn-outline-danger btn-sm ml-4" onClick={this.removeAppliedCoupon}><i className="fal fa-trash"></i> Remove Coupon</button>
      </div>;
    }

    return (

        <>


          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Shopping Cart</h5>
            <p className="lead">
              Please review your purchase. If you would like to add additional assessments to your cart, you can simply go back to the pricing page.
            </p>
          </div>

          <div className="card mb-3">
            <div className="card-header">
              <span style={{fontSize: '15px', color: '#b4c0d0', marginRight: '10px'}}>
                <i className="fal fa-shopping-cart"></i>
              </span>
              Shopping Cart
            </div>
            <div className="card-body">

              {cartContents}

              {/*{this.props.cartContents.map((item, idx) => {*/}
              {/*  return (*/}

              {/*      <CartItem key={idx} sku={item.sku} name={item.productName} price={item.price} qty={item.qty}*/}
              {/*                value={item.qty}*/}
              {/*                changed = {(event) => this.handleQtyChange(event, item)}*/}
              {/*                removeItem = {() => this.removeCartItem(item)}*/}
              {/*      />*/}

              {/*  )*/}
              {/*})}*/}

              {discountDiv}

            </div>

            <div className="card-footer text-muted">
              <div className="row">
                <div className="col-sm-3">
                  <label>
                    <input type="text" className={this.state.couponError === '' ? this.state.validClass : this.state.invalidClass} placeholder="Coupon code"
                           onChange={this.handleCouponCode} value={this.state.couponCode}
                           onKeyPress={(ev) => {
                            // console.log(`Pressed keyCode ${ev.key}`);
                             if (ev.key === 'Enter') {
                               // Do code here
                               //ev.preventDefault();
                               this.applyCoupon();
                             }
                           }}

                           disabled={this.props.cart.totalQty === 0}
                    />
                    <div className="invalid-feedback">
                      {this.state.couponError}
                    </div>
                  </label>
                </div>
                <div className="col-sm-5" style={{paddingTop: '5px', paddingLeft: '0'}}>


                  <button type="button" className="btn btn-outline-success btn-sm"
                          onClick={this.applyCoupon} disabled={this.state.loading}>
                    <LoadingIcon show={this.state.loading} className='mr-1'/> {this.props.loading ? 'Applying' : 'Apply'} Coupon

                  </button>

                </div>
                <div className="col-sm-4">
                  <div className="row ">
                    <div className="col-sm-7 text-md-right" style={{paddingTop: '5px'}}>
                      Total price: <b>${Number.parseFloat(this.props.cart.totalCost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
                    </div>
                    <div className="col-sm-5">
                      {checkOutButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </>

    )
  }

}

const mapReduxStateToProps = (reduxState) => {
  return {
    cart: reduxState.checkOut.cart,
    cartQty: reduxState.checkOut.totalQty,
    //loading: reduxState.checkOut.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateCart: (cart) => dispatch(actionCreators.updateCart(cart)),
    //onSetTotalQty: (totalQty) => dispatch(actionCreators.setTotalQty(totalQty)),
    onRemoveFromCart: (sku) => dispatch(actionCreators.removeFromCart(sku)),
    onApplyDiscount: (coupon, percent, amount) => dispatch(actionCreators.applyDiscount(coupon, percent, amount)),
    onRemoveCoupon: () => dispatch(actionCreators.removeCoupon()),
  };
};

export default connect(mapReduxStateToProps, mapDispatchToProps)(Cart);