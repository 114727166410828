import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from '../../constants/Colors'

class Conscientiousness extends Component {

    componentDidMount() {
    }

    render() {

        let cons = Math.round((Number(this.props.flex.primarySelf[0]) + Number(this.props.organization.primarySelf[0])) / 2);
        let color = Color.CHALLENGE;

        if (cons >= 33.33 && cons < 66.66) {
            color = Color.IMPROVE;
        } else if (cons >= 66.66) {
            color = Color.STRENGTH;
        }

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-book-heart fa-2x" style={{color: "#c3b2b5"}}/></div>
                        <div>
                            CONSCIENTIOUSNESS
                        </div>
                    </div>
                </div>

                <div className="mt-2 mb-5 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    This section measures your overall ability to function in your environment. Generally, conscientious
                    people are aware of their surroundings and able to orient themselves towards accomplishing desired
                    goals within their environment. They act in ways consistent with reaching the goals they set, while
                    also being able to adjust their behavior when new information or obstacles show up.
                    Conscientiousness is helpful in forming healthy relationships as individuals are able to adjust to
                    the preferences and motivations of new partners while also keeping sight of their own relationship
                    goals. If you score low in conscientiousness, it does not indicate severe weakness in relationships,
                    but does suggest that finding a partner who challenges you to better balance organization and
                    flexibility would be beneficial.
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='aiyn' percent={cons} color={color}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1">
                        {color === Color.CHALLENGE &&
                        <ChallengeLegendDetail id="alei">
                            If you score in the red, you are likely to struggle with new relationships. Your lack of
                            both flexibility and organization inhibit adjusting to new relationship patterns and
                            behaviors. Being able to make sense of new situations by organizing thoughts and experiences
                            is actually an important part of being able to make adjustments effectively. The following
                            scales represent the sub-dimensions used to calculate the conscientious scores.
                        </ChallengeLegendDetail>
                        }

                        {color === Color.IMPROVE &&
                        <ImproveLegendDetail id="alnf">
                            A score in this section likely indicates a tendency towards either being flexible or
                            organized, but suggests a lack of balance. You are either able to go with the flow of
                            things, or can be depended on to get the job done. However, this imbalance may mean that you
                            end up working harder in life to accomplish the same amount as those with a better balance.
                            In relationships, such an imbalance may hold you back from being able to adjust to new
                            partners or move on from previous partners. Don’t be afraid to let someone else help you
                            improve in your specific area of weakness.
                        </ImproveLegendDetail>
                        }

                        {color === Color.STRENGTH &&
                        <StrengthLegendDetail id="apts">
                            You have a good sense of your environment and are able to function well. You tend to be
                            responsible and trusted by others. In relationships, you are able to adapt well to the needs
                            of your partners and remain consistent in your efforts to meet those needs.
                        </StrengthLegendDetail>
                        }
                    </div>
                </div>

                <div className="mt-2 mb-5 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    This section assesses your overall emotional tendencies in relationships, such as how you react to
                    difficulties that arise in your life. Emotional reactivity, or neuroticism, is generally considered
                    harmful to relationships because it reflects difficulty controlling emotions and strong reactions to
                    emotionally charged events. Neuroticism also means you put emotional meaning into events that do not
                    require an emotional response. A lack of emotional control can lead to taking things personally,
                    irrational responses to challenges, and a tendency to misinterpret the behavior of others. On
                    emotional readiness it has been found that being with someone high in this trait is better for
                    relationships, no matter how you score.
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>FLEXIBILITY</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='blvf' percent={this.props.flex.primarySelf[0]}
                                    color={this.props.flex.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Flexibility refers to how you respond to change. Flexible does not necessarily mean someone will
                        just “go with the flow,” but rather the ability to accept new perspectives or beliefs and adopt
                        more effective patterns of behavior. Flexibility in relationships is important because no two
                        individuals are exactly alike and research suggests that being able to listen and respect the
                        differences in relationships, as well as adapt to a partner’s unique needs is associated with
                        higher stability and satisfaction. A high score in this section indicates you are able to accept
                        and try new things, even allowing yourself to change as a result.

                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>ORGANIZATION</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='bmxm' percent={this.props.organization.primarySelf[0]}
                                    color={this.props.organization.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Organization reflects how well you keep track of your life. Organized individuals are able to
                        remember and carry out important responsibilities and are often trusted to be self-guided in
                        accomplishing tasks. In relationships, organized individuals are usually more aware of what
                        their partner needs from them, and their partner can depend on them to meet those needs.
                        Organization also offers stability in relationships through predictability. A high score in
                        organization means you keep track of things effectively.

                    </div>
                </div>


            </>

        )

    }

}

export default Conscientiousness;