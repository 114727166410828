import React, {Component} from 'react';


class ReadyReportMenu extends Component {


    showComponent = (componentToShow) => {

        this.props.onClick(componentToShow);

    };

    componentDidMount() {
    }

    render() {


        return (

            <div className="btn-group">
                <button type="button" className="btn btn-outline-info dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i className="fal fa-chart-bar"/> Report Sections
                </button>
                <div className="dropdown-menu">
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('cover')}>
                        <i className="fal fa-info-square text-muted mr-1"/> Report Summary
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('futureRisks')}>
                        <i className="fal fa-thermometer-three-quarters text-muted mr-1"/> Future Relationship Risk
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('famBackgroundChecklist')}>
                        <i className="fal fa-tasks text-muted mr-1"/> Family Background Checklist
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('readyFamilyBackground')}>
                        <i className="fal fa-home-heart text-muted mr-1"/> Family Background
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('relationshipInfluence')}>
                        <i className="fal fa-signal-stream text-muted mr-1"/> Relationship Influence
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('myths')}>
                        <i className="fal fa-transporter-empty text-muted mr-1"/> Dating Myths
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('mentalHealth')}>
                        <i className="fal fa-head-side-brain text-muted mr-1"/> Mental Health
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('riskBehaviorsChecklist')}>
                        <i className="fal fa-ballot-check text-muted mr-1"/> Risk Behaviors Checklist
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('relationshipOutlook')}>
                        <i className="fal fa-rings-wedding text-muted mr-1"/> Relationship Outlook
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('marriageAdvantage')}>
                        <i className="fal fa-chart-line text-muted mr-1"/> Marriage Advantage
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('timingOfMarriage')}>
                        <i className="fal fa-clock text-muted mr-1"/>
                        Timing Of Marriage
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('marriageOptimism')}>
                        <i className="fal fa-house-day text-muted mr-1"/>
                        Marriage Optimism
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('maritalSalience')}>
                        <i className="fal fa-house-night text-muted mr-1"/>
                        Marital Salience
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('maritalPermanence')}>
                        <i className="fal fa-telescope text-muted mr-1"/>
                        Marital Permanence
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('readyAttachment')}>
                        <i className="fal fa-leaf-heart text-muted mr-1"/>
                        Attachment
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('readyCommunication')}>
                        <div className="fa-stack text-muted mr-1" style={{fontSize: '.7rem'}}>
                            <i className="fal fa-comment fa-stack-2x"/>
                            <i className="fal fa-heart fa-stack-1x"/>
                        </div>
                        Effective Communication
                    </div>
                    <div className="dropdown-item pointer-cursor"
                         onClick={() => this.showComponent('readyConflictResolve')}>
                        <i className="fal fa-handshake text-muted mr-1"/>
                        Conflict Resolution
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('readyAggression')}>
                        <i className="fal fa-exclamation-circle text-muted mr-1"/>
                        Relational Aggression
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('personalTraits')}>
                        <i className="fal fa-hand-holding-seedling text-muted mr-1"/>
                        Trait - Emotional Readiness
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('warmth')}>
                        <i className="fal fa-scarf text-muted mr-1"/>
                        Trait - Warmth
                    </div>
                    <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('conscience')}>
                        <i className="fal fa-book-heart text-muted mr-1"/>
                        Trait - Conscientiousness
                    </div>
                </div>
            </div>

        )

    }

}

export default ReadyReportMenu;