import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router";
import jQuery from "jquery";

function UpdateNicknameModal(props) {

    const [nickName, setNickName] = useState(props.currentNickName);
    const [error, setError] = useState(false);
    const [errorDetail, setErrorDetail] = useState("You need to enter a name for your assessment.");

    useEffect(() => {
        setNickName(props.currentNickName);
    }, [props.currentNickName]);

    const saveNickname = (e) => {

        e.preventDefault();

        if (nickName.length > 0) {

            setError(false);

            props.saveUpdatedNickname(nickName.trim())
                .then((response) => {

                    if (response.status >= 200 && response.status < 300) {

                        props.reloadActivated();

                        jQuery('#upNickModal').modal('hide');

                    }

                })
                .catch(error => {
                    //console.log(error);
                    //setError(true);
                    setErrorDetail(error.response.data.detail);
                });

        } else {
            setError(true);
        }
    };

    const cleanUp = () => {

        setNickName("");
        setError(false);

    };

    let errorDiv = <div className="alert alert-danger text-center" role="alert">
        <i className="fal fa-exclamation-triangle"/> {errorDetail}
    </div>;


    return (

        <>
            <div className="modal fade in" id="upNickModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="upNickModalTitle"><i className="fal fa-exclamation-circle"/> Update Assessment's Name</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={cleanUp}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {error && errorDiv}
                            <form onSubmit={saveNickname}>
                                <div className="form-group">
                                    <label htmlFor="inputPassword5">Enter the nickname (25 characters
                                        max) <small>(Required)</small></label>
                                    <input type="text" id="upnickname" value={nickName}
                                           onChange={e => setNickName(e.target.value)}
                                           maxLength='25' className="form-control"
                                           aria-describedby="nickNameHelpBlock"/>
                                    <small id="nickNameHelpBlock" className="form-text text-muted">
                                        Give Your Assessment a nickname (ex. Mary's or John's). This will help you
                                        identify this
                                        specific assessment in the future.
                                    </small>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={cleanUp}>Close
                            </button>
                            <button type="submit" className="btn btn-primary" onClick={saveNickname}
                                    disabled={nickName.trim().length === 0}>Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default withRouter(UpdateNicknameModal);