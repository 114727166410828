//import axios from 'axios';

import loginAxios from 'axios';
import axios from "../axios/axios-custom";
import {  extractUserData} from '../layout/utility';

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const ADD = 'ADD';
export const SUBTRACT = 'SUBTRACT';
export const STORE_RESULT = 'STORE_RESULT';
export const DELETE_RESULT = 'DELETE_RESULT';


export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH = 'AUTH_REFRESH';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const LOAD_CART = 'LOAD_CART';
export const SET_TOTAL_QTY = 'SET_TOTAL_QTY';
export const APPLY_DISCOUNT = 'APPLY_DISCOUNT';
export const REMOVE_COUPON = 'REMOVE_COUPON';

export const REFRESH_LOGIN = 'REFRESH_LOGIN';
//////////////////// AUTH //////////////////////////////////////////////////////

export const authStart = () => {
  return {
    type: AUTH_START
  };
};

export const authSuccess = (token, userId, refreshToken) => {

  // let base64Url = localStorage.getItem('token').split('.')[1]; // token you get
  // let base64 = base64Url.replace('-', '+').replace('_', '/');
  // let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
  // // console.log(decodedData);
  // // console.log(decodedData.sub);
  //
  // let properties = decodedData.sub.split(', ');
  // let userData = {};
  // properties.forEach(function(property) {
  //   let tup = property.split('=');
  //   userData[tup[0]] = tup[1];
  // });
  // userData['role'] = decodedData.auth;

  return {
    type: AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    refreshToken: refreshToken,
    userData: extractUserData()
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error
  };
};

export const logout = () => {

  let user =  localStorage.getItem('userId');

  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('token2');

  if(user !== null) {

    let url = `${process.env.REACT_APP_API_URL}/api/authenticate/${user}/logout`;

    loginAxios.get(url)
        .then(response => {

        })
        .catch(error => {
        })
        .finally(function () {
          window.location = '/auth';
        });

  }
  return {
    type: AUTH_LOGOUT
  };
};

export const refresh = () => {

  return (dispatch, getState) => {

    const expirationDate = new Date(localStorage.getItem('expirationDate'));

    const now = new Date();

    let diff = Math.round(((expirationDate - now) / 1000) / 60);

    if (expirationDate > new Date() && diff <= 15) {

      const refreshData = {
        refreshToken: localStorage.getItem('token2')
      };

      let url = `${process.env.REACT_APP_API_URL}/api/authenticate/refresh`;

      loginAxios.post(url, refreshData, {withCredentials: true})
          .then(response => {

            localStorage.setItem('token', response.data.idToken);
            localStorage.setItem('token2', response.data.refreshToken);
            localStorage.setItem('expirationDate', new Date(response.data.expiresIn));
            localStorage.setItem('userId', response.data.userId);

            //localStorage.setItem('token', response.data.idToken);
            //localStorage.setItem('expirationDate', response.data.expiresIn);

            //localStorage.setItem('userId', response.data.userId);
            //localStorage.setItem('userId', response.data.localId);
            dispatch(authSuccess(response.data.token, response.data.userId, response.data.refreshToken));
            //UNCOMMENT WHEN READY
            // dispatch(checkAuthTimeout(Date.parse(response.data.expiresIn)));
            // dispatch(checkAuthTimeout(60000));
            // dispatch(checkRefresh(30000));

            // dispatch(authSuccess(response.data.idToken, response.data.localId));

            //document.getElementById("ftr").style.display = "block";

          })
          .catch(error => {
           // console.log(error);
            dispatch(logout());
            dispatch(authFail('Authentication Error'));
          });



      let newExpTime = new Date(now.getTime() + 60000);

      localStorage.setItem('expirationDate', newExpTime);

      dispatch({type: AUTH_REFRESH});
    }

  };

  // return {
  //   type: AUTH_REFRESH
  // };
};

export const checkRefresh = (refreshRate) => {

  return dispatch => {
    setInterval(() => {
      dispatch(refresh());
    }, refreshRate);
  };
};

export const checkAuthTimeout = (expirationTime) => {

  //expirationTime = 15000;

  return dispatch => {
    setInterval(() => {

      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(logout());
      } else {
        const expirationDate = new Date(localStorage.getItem('expirationDate'));
        if (expirationDate <= new Date()) {
          dispatch(logout());
        }
      }

    }, expirationTime);
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      //const now = new Date();
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {



        dispatch(refresh());

        const userId = localStorage.getItem('userId');
        dispatch(authSuccess(token, userId));
        dispatch(checkAuthTimeout(1800000)); // 30 minutes
        dispatch(checkRefresh(900000)); // 15 minutes
      }
    }
  };
};




export const auth = (email, password) => {
  return dispatch => {

    dispatch(authStart());

    // const authData = {
    //   email: email,
    //   password: password,
    //   returnSecureToken: true
    // };

    const authData = {
      username: email,
      password: password,
      //returnSecureToken: true
    };

    let url = `${process.env.REACT_APP_API_URL}/api/authenticate`;

    loginAxios.post(url, authData, { withCredentials: true })
        .then(response => {
          //const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);

          //const now = new Date();

          //const expirationDate = new Date(now.getTime() + 60000); //300000

          //const diff = expirationDate - now;

          //localStorage.setItem('expirationDate', Date.parse(response.data.expiresIn));
          localStorage.setItem('expirationDate', new Date(response.data.expiresIn));


          localStorage.setItem('token', response.data.idToken);
          localStorage.setItem('token2', response.data.refreshToken);

          //localStorage.setItem('token', response.data.idToken);
          //localStorage.setItem('expirationDate', response.data.expiresIn);

          localStorage.setItem('userId', response.data.userId);
          //localStorage.setItem('userId', response.data.localId);
          dispatch(authSuccess(response.data.token, response.data.userId, response.data.refreshToken));
          //UNCOMMENT WHEN READY
          // dispatch(checkAuthTimeout(Date.parse(response.data.expiresIn)));
          dispatch(checkAuthTimeout(1800000)); // 30 minutes
          dispatch(checkRefresh(900000)); // 15 minutes
          // dispatch(checkRefresh());

          // dispatch(authSuccess(response.data.idToken, response.data.localId));


          //document.getElementById("ftr").style.display = "block";

        })
        .catch(error => {
         // console.log(error);
          //dispatch(authFail(error.response.data.error));
          dispatch(authFail('Some error'));
        });
  };
};

export const postRegistrationAuth = (loginData) => {
  return dispatch => {

    //console.log("AFTER SIGN UP", loginData);

    localStorage.removeItem('tempUserId');

    //localStorage.setItem('expirationDate', Date.parse(response.data.expiresIn));
    localStorage.setItem('expirationDate', new Date(loginData.expiresIn));


    localStorage.setItem('token', loginData.idToken);
    localStorage.setItem('token2', loginData.refreshToken);

    localStorage.setItem('userId', loginData.userId);
    dispatch(authSuccess(loginData.token, loginData.userId, loginData.refreshToken));
    dispatch(checkAuthTimeout(1800000)); // 30 minutes
    dispatch(checkRefresh(900000)); // 15 minutes
  };
};


export const postPurchaseAuth = (password, passwordConfirmation, userId) => {
  return dispatch => {

    dispatch(authStart());

    const authData = {
      password: password,
      passwordConfirmation: passwordConfirmation,
      userId: userId,
    };

    let url = `${process.env.REACT_APP_API_URL}/api/register-purchase`;


    axios.post(url, authData, { withCredentials: true })
        .then(response => {

          localStorage.removeItem('tempUserId');

          //localStorage.setItem('expirationDate', Date.parse(response.data.expiresIn));
          localStorage.setItem('expirationDate', new Date(response.data.expiresIn));


          localStorage.setItem('token', response.data.idToken);
          localStorage.setItem('token2', response.data.refreshToken);

          //localStorage.setItem('token', response.data.idToken);
          //localStorage.setItem('expirationDate', response.data.expiresIn);

          localStorage.setItem('userId', response.data.userId);
          //localStorage.setItem('userId', response.data.localId);
          dispatch(authSuccess(response.data.token, response.data.userId, response.data.refreshToken));
          //UNCOMMENT WHEN READY
          // dispatch(checkAuthTimeout(Date.parse(response.data.expiresIn)));
          dispatch(checkAuthTimeout(1800000)); // 30 minutes
          dispatch(checkRefresh(900000)); // 15 minutes


        })
        .catch(error => {
         // console.log(error);
          //dispatch(authFail(error.response.data.error));
          dispatch(authFail('Some error'));
        });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: SET_AUTH_REDIRECT_PATH,
    path: path
  };
};




////////////////////////////////////////////////////////////////////////////////


export const increment = () => {
  return {
    type: INCREMENT
  };
};

export const add = ( value ) => {
  return {
    type: ADD,
    val: value
  };
};

export const saveResult = ( res ) => {
  // const updatedResult = res * 2;
  return {
    type: STORE_RESULT,
    result: res
  };
};

export const storeResult = ( res ) => {
  return (dispatch) => {
    setTimeout( () => {
      // const oldCounter = getState().ctr.counter;
      // console.log(oldCounter);
      dispatch(saveResult(res));
    }, 2000 );
  }
};