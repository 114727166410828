import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";

class RelationshipQuality extends Component {


    componentDidMount() {
    }

    render() {

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-medal fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            RELATIONSHIP QUALITY
                        </div>
                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>Overview</b>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='mt-2 text-justify'>
                    The <strong>Relationship Quality</strong> scale assesses two main measures of your overall
                    relationship well-being:
                    satisfaction and stability. Both of these measures help assess the perceptions of the overall health
                    of your
                    relationship and are among the best predictors of long-term relationship success. <strong>Relationship
                    satisfaction</strong>
                    takes into account aspects such as your satisfaction with the level of intimacy and sense of
                    fulfillment you gain
                    through your relationship. <strong>Relationship stability</strong> refers to how much you believe
                    the relationship will last over
                    the long term. Although satisfaction and stability are not the same thing, they often go
                    hand-in-hand with more
                    satisfying relationships also being more likely to remain intact over time.
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>OVERALL RELATIONSHIP SATISFACTION</b>
                </div>

                <GraphLegend/>

                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='mt-2 text-justify'>
                    This graph shows how each of you rated your relationship satisfaction. These overall evaluations of
                    your relationship
                    generally reflect the relative total of strengths and challenges in specific parts of your
                    relationship. Research has found that
                    the more satisfying a relationship is, the more likely it will be stable. Research also shows that
                    even the best of relationships
                    experience some fluctuation in satisfaction over the course of the relationship.
                </div>

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td className="border-right" align="center"><DonutChart elId='satisfaction1'
                                                                                    percent={this.props.overallRelSatisfaction.primarySelf[0]}
                                                                                    color={this.props.overallRelSatisfaction.primarySelf[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='satisfaction2'
                                                           percent={this.props.overallRelSatisfaction.partnerSelf[0]}
                                                           color={this.props.overallRelSatisfaction.partnerSelf[1]}/>
                            </td>
                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td className="border-right" align="center"><b>YOUR</b> RATING</td>
                            <td align="center"><b>YOUR PARTNER'S</b> RATING</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>PROBLEM AREAS SCALE</b>
                </div>

                <GraphLegend/>

                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='mt-2 text-justify'>
                    The <b>Problem Areas</b> scale is a measure of how often certain key areas have been a problem in
                    your relationship.
                    The areas reported on include: Financial matters, communication, having and rearing children,
                    sexuality,
                    parents, roles (who does what), weight, who's in charge, time spent together, and substance abuse.
                </div>

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td className="border-right" align="center"><DonutChart elId='problem1'
                                                                                    percent={this.props.probArea.primarySelf[0]}
                                                                                    color={this.props.probArea.primarySelf[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='problem2' percent={this.props.probArea.partnerSelf[0]}
                                                           color={this.props.probArea.partnerSelf[1]}/></td>
                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td className="border-right" align="center"><b>YOUR</b> RATING</td>
                            <td align="center">HOW <b>YOUR PARTNER'S</b> RATING</td>
                        </tr>
                        </tbody>
                    </table>
                </div>


                <StrengthLegendDetail id="r-strength">
                    People who score in this area of the scale see relatively low levels of problems in the
                    relationship.
                    They tend to feel that they are able to deal with differences in the relationship without resulting
                    in numerous unresolved issues.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="r-needs-impr">
                    Scoring in this section is a good indicator that you may wish to start evaluating your relationship
                    and to begin working on
                    problem areas that are affecting its overall quality. A score in this section generally means that
                    relationship quality is
                    adequate despite moments of instability and conflict. A couple scoring in this area has things to
                    work on, but both people tend
                    to still be committed through the ups and downs.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="r-challenge">
                    People who score in this area on the problem checklist report high levels of problems across a
                    number of areas in their relationship.
                    If problems are occurring frequently in areas that are personally important, a score in the red area
                    may indicate areas that need
                    change and improvement. List the top 2-3 problems you are currently struggling with and start to
                    develop a plan for resolving them.
                </ChallengeLegendDetail>

                <div className='mt-3 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            Research has shown that the overall problem checklist rating is strongly related to marital
                            satisfaction and marital commitment. Some couples may have a low overall score, but still
                            have one or two areas of frequent problems. It is important to note that couples with
                            relatively low problem ratings do not necessarily have less troubles or difficulties than
                            other couples; rather they are able to manage the differences in their relationship in ways
                            that maintain a sense of partnership and unity.
                        </div>
                    </div>
                </div>


                <div className="page-break"></div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>RELATIONSHIP STABILITY</b>
                </div>

                <GraphLegend/>


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='mt-2'>
                    This graph shows how each of you rated your relationship stability
                </div>

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td className="border-right" align="center"><DonutChart elId='stability1'
                                                                                    percent={this.props.stability.primarySelf[0]}
                                                                                    color={this.props.stability.primarySelf[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='stability2'
                                                           percent={this.props.stability.partnerSelf[0]}
                                                           color={this.props.stability.partnerSelf[1]}/></td>
                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td className="border-right" align="center"><b>YOUR</b> RATING</td>
                            <td align="center"><b>YOUR PARTNER'S</b> RATING</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <StrengthLegendDetail id="rs-strength">
                    People scoring in this section are generally fulfilled and satisfied with their relationship.
                    They tend to be positive about their relationship, even when troubles arise. This may also indicate
                    that the expectations
                    of being in a relationship are being met. People who score in this section see more of the good in
                    their relationship than the bad.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="rs-needs-impr">
                    If you scored in this section, you probably have a few areas of your relationship where you are
                    feeling dissatisfied with how things are going.
                    This is not uncommon and you may currently just be in a rough patch. If you start to address some of
                    the less fulfilling aspects of the
                    relationship, you will probably find yourself moving out of this area and into the strength section.
                    If not, you may just as quickly
                    find yourself in the challenge area.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="rs-challenge">
                    Scoring in this section is an indicator that you do not feel that your relationship is fulfilling.
                    You may not be very satisfied with a number of
                    aspects of your relationship, such as the amount of intimacy, or feelings of inequality. Below you
                    will find those specific aspects
                    of the relationship you are least satisfied with. Use the discussion questions below to discuss or
                    think about how to improve these areas.
                </ChallengeLegendDetail>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>RELATIONSHIP SATISFACTION</b>
                </div>

                <GraphLegend/>

                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='mt-2'>
                    This graph shows how each of you rated your relationship satisfaction
                </div>

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td className="border-right" align="center"><DonutChart elId='overall1'
                                                                                    percent={this.props.satisfaction.primarySelf[0]}
                                                                                    color={this.props.satisfaction.primarySelf[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='overall2'
                                                           percent={this.props.satisfaction.partnerSelf[0]}
                                                           color={this.props.satisfaction.partnerSelf[1]}/></td>
                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td className="border-right" align="center"><b>YOUR</b> RATING</td>
                            <td align="center">HOW <b>YOUR PARTNER'S</b> RATING</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <StrengthLegendDetail id="ors-strength">
                    Those who score in this section probably feel very good about the future of the relationship. You
                    feel it will last a long time and do not
                    question your commitment to the relationship. Your relationship probably helps you feel secure about
                    life in general. You do not question
                    that being in this relationship is the right thing for you.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="ors-needs-impr">
                    If you score in this section, you may have experienced periods of questioning the relationship or
                    hit a few rough spots in your relationship lately.
                    You might have felt that other relationships or alternatives would be better for you. You may or may
                    not have discussed how you feel
                    with your partner, and you may not be on the same page. Scoring in this section suggests that you
                    may need to work on either couple
                    dynamics or lingering areas of conflict to avoid having your relationship continue to become less
                    stable in the future.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="ors-challenge">
                    Scoring in this section is a good indicator that your relationship might be in trouble. You may be
                    thinking the relationship is
                    not going anywhere or unresolved issues in your relationship might be causing a lack of stability.
                    At this point, it may be time
                    to consider why you are staying together and what you should do in the near future. Couples in the
                    challenge section need to reevaluate with their
                    partners their commitment and dedication to each other. For married couples, can you revive a sense
                    of dedication to the
                    relationship before deciding to just end it?
                </ChallengeLegendDetail>


                <button className="btn btn-outline-dark no-print" type="button" data-toggle="collapse"
                        data-target="#quality"
                        aria-expanded="false" aria-controls="emotion">
                    Show Section Questions
                </button>

                <div className="collapse" id="quality">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-medal"/> Relationship Quality</h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col" className="align-middle">How often have the following areas been a
                                        problem in your relationship?
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">
                                        Financial Matters
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Communication
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Substance/chemical use
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Religion/Spirituality
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        My weight
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        My partner's weight
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Who's in charge</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Time spent together</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Time spent using media such as video games, the
                                        internet, or television.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Types of media used such as violent or
                                        Sexually-Oriented media
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Having Children</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Rearing Children</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Intimacy/Sexuality</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Your Parents</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Your Partner's Parents</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Roles (Who Does What)</td>
                                </tr>
                                <tr>
                                    <th className="align-middle">In your relationship, how satisfied are you with the
                                        following?
                                    </th>
                                </tr>
                                <tr>
                                    <td className="align-middle">The physical intimacy you experience.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">The love you experience.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">How conflicts are resolved.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">The amount of relationship equality you experience.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">The amount of time you have together.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">The quality of your communication.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Your overall relationship with your partner.</td>
                                </tr>
                                <tr>
                                    <th className="align-middle">Please answer the following questions about YOURSELF
                                    </th>
                                </tr>
                                <tr>
                                    <td className="align-middle">How often have you thought your relationship (or
                                        marriage) might be in trouble?
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">How often have you and your partner discussed ending
                                        your relationship (or marriage)?
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">How often have you broken up or separated and then
                                        gotten back together?
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div className='mt-5 pb-4 pl-4 pr-4 pt-3  no-break' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>What are the biggest stressors on the relationship? Are there any compromises that
                                can be made that will strengthen the relationship?
                            </li>
                            <li>When conflicts occur in the relationship, are they usually resolved?</li>
                            <li>How do you and your partner view intimacy? How can that be better implemented
                                in the relationship to satisfy both you and your partner?
                            </li>
                            <li>In moments of instability, for what reasons do you and your partner stay committed
                                to the relationship?
                            </li>
                            <li>How much quality time do you and your partner set aside for one another? What
                                activities do you both enjoy that can be made routine to strengthen the
                                relationship?
                            </li>
                        </ul>
                    </div>
                </div>

            </>

        )

    }

}

export default RelationshipQuality;