import React, { Component } from 'react';
import {
  withRouter
} from 'react-router-dom';
import Header from "./Header";
import LoggedInFooter from "./footer/LoggedInFooter";
import LoggedOutFooter from "./footer/LoggedOutFooter";



class Layout extends Component {

  render () {

    let footer = <LoggedOutFooter />;

    if(this.props.isAuthnticated) {

      footer = <LoggedInFooter />;

    }

    return (

        <>
          <Header />

            <div className="container">
                {this.props.children}

              {footer}
            </div>
        </>
    )

  }

}

export default withRouter(Layout);