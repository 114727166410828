import React, {Component} from 'react';
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';


class ReportNavigation extends Component {


    showComponent = (componentToShow) => {

        this.props.onClick(componentToShow);

    };

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {


        return (
            <>

            {this.props.windowSize.windowWidth > 710 &&
                    <>
                        {this.props.current === 0 &&

                        <>
                            <button type="button" className="btn btn-outline-primary ml-4"
                                    onClick={() => this.showComponent(this.props.next)}>
                                <i className="fal fa-chevron-right"/> {this.props.nextValue}
                            </button>
                        </>
                        }

                        {this.props.current === this.props.componentsLength - 1 &&
                        <button type="button" className="btn btn-outline-primary ml-4"
                                onClick={() => this.showComponent(this.props.prev)}>
                            <i className="fal fa-chevron-left"/> {this.props.prevValue}
                        </button>
                        }

                        {this.props.current > 0 && this.props.current < this.props.componentsLength - 1 &&

                        <>
                            <button type="button" className="btn btn-outline-primary ml-4"
                                    onClick={() => this.showComponent(this.props.prev)}>
                                <i className="fal fa-chevron-left"/> {this.props.prevValue}
                            </button>
                            {/*{this.getCurrentIndex()} {this.state.componentKeys[this.getCurrentIndex() - 1]}*/}
                            <button type="button" className="btn btn-outline-primary ml-4"
                                    onClick={() => this.showComponent(this.props.next)}>
                                {this.props.nextValue} <i className="fal fa-chevron-right"/>
                            </button>
                        </>
                        }
                    </>
                }

                {this.props.windowSize.windowWidth <= 710 &&
                <div className='mt-2'>
                    {this.props.current === 0 &&

                    <>
                        <button type="button" className="btn btn-sm btn-outline-primary"
                                onClick={() => this.showComponent(this.props.next)}>
                            <i className="fal fa-chevron-right"/> {this.props.nextValue}
                        </button>
                    </>
                    }

                    {this.props.current === this.props.componentsLength - 1 &&
                    <button type="button" className="btn btn-sm btn-outline-primary"
                            onClick={() => this.showComponent(this.props.prev)}>
                        <i className="fal fa-chevron-left"/> {this.props.prevValue}
                    </button>
                    }

                    {this.props.current > 0 && this.props.current < this.props.componentsLength - 1 &&

                    <>

                        <div className="d-flex align-content-end">
                            <div className='flex-even text-left'>
                                <button type="button" className="btn btn-sm btn-outline-primary"
                                        onClick={() => this.showComponent(this.props.prev)}>
                                    <i className="fal fa-chevron-left"/> {this.props.prevValue}
                                </button>
                            </div>
                            <div className='flex-even text-right'>
                                <button type="button" className="btn btn-sm btn-outline-primary"
                                        onClick={() => this.showComponent(this.props.next)}>
                                    {this.props.nextValue} <i className="fal fa-chevron-right"/>
                                </button>
                            </div>
                        </div>




                    </>
                    }
                </div>
                }
            </>


        )

    }

}

// export default ReportNavigation;
export default withWindowSizeListener(ReportNavigation);