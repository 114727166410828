import React, {Component} from 'react';
import NavButton from "../layout/navbar/navigationItems/NavButton";

class AdminDashboard extends Component {

    render() {
        return (
            <div>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Admin Dashboard</h5>
                </div>

                <div className="mb-4">
                    <NavButton className="btn  btn-outline-success" link="/add/counselor"><i className="fal fa-smile-plus" /> Create Counselor Account</NavButton>
                    <span className='mr-3'></span>
                    <NavButton className="btn  btn-outline-info" link="/generate/codes"><i className="fal fa-barcode" /> Generate Assessment Codes</NavButton>
                    <span className='mr-3'></span>
                    <NavButton className="btn  btn-outline-info" link="/user/details"><i className="fal fa-file-user" /> User Details</NavButton>
                    <span className='mr-3'></span>
                    <NavButton className="btn  btn-outline-success" link="/admin/coupons"><i className="fal fa-list" /> List Coupons</NavButton>
                </div>

            </div>
        );
    }
}

export default AdminDashboard;