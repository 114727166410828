import React, {Component} from 'react';

class FamBackgroundChecklist extends Component {

    render() {

        let divorced = <>
            <td className={this.props.divorcedParents.primarySelf[0] === 'true' ? 'table-danger text-center' : ''}>
                {this.props.divorcedParents.primarySelf[0] === 'true' ? <i className="fal fa-check"></i> : ''}
            </td>
            <td className={this.props.divorcedParents.primarySelf[0] === 'false' ? 'table-success text-center' : ''}>
                {this.props.divorcedParents.primarySelf[0] === 'false' ? <i className="fal fa-check"></i> : ''}
            </td>
        </>;

        let unmarried = <>
            <td className={this.props.unmarriedParents.primarySelf[0] === 'false' ? 'table-danger text-center' : ''}>
                {this.props.unmarriedParents.primarySelf[0] === 'false' ? <i className="fal fa-check"></i> : ''}
            </td>
            <td className={this.props.unmarriedParents.primarySelf[0] === 'true' ? 'table-success text-center' : ''}>
                {this.props.unmarriedParents.primarySelf[0] === 'true' ? <i className="fal fa-check"></i> : ''}
            </td>
        </>;

        if(this.props.divorcedParents.primarySelf[0] === 'NA') {
            divorced = <>
                <td className='text-center'>N/A</td>
                <td className='text-center'>N/A</td>
            </>;
        }

        if(this.props.unmarriedParents.primarySelf[0] === 'NA') {
            unmarried = <>
                <td className='text-center'>N/A</td>
                <td className='text-center'>N/A</td>
            </>;
        }


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fal fa-tasks fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div className='pl-6'>
                            FAMILY BACKGROUND CHECKLIST
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    The previous sections have focused on how your family experiences shape your perceptions of relationships.
                    There are also elements of how your family was structured or specific behaviors that took place in your family
                    that we know are related to relationship success.  Many of these factors are what we call static or demographic.
                    That means they are factors from your past that we know for one reason or another increases your risk of unsuccessful relationships.
                    Below is a list of some of these factors and which factors may change your probability of successful or unsuccessful
                    relationships in the future.  They cannot be changed and simply do or do not exist.  Keep in mind that having one risk is
                    not as problematic as a pile-up of risks.  The following is a list of possible risk factors from your family:
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>CHECKLIST</b>
                </div>

                <table className="table table-striped table-bordered col-sm-8 mt-4" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Challenge</th>
                        <th scope="col">Strength</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Divorced biological parents</th>
                        {divorced}
                    </tr>
                    <tr>
                        <th scope="row">Unmarried biological parents</th>
                        {unmarried}
                    </tr>
                    <tr>
                        <th scope="row">Physical abuse from a family member</th>
                        <td className={this.props.physAbuse.primarySelf[0] < 100 ? 'table-danger text-center' : ''}>
                            {this.props.physAbuse.primarySelf[0] < 100 ? <i className="fal fa-check"></i> : ''}
                        </td>
                        <td className={this.props.physAbuse.primarySelf[0] === '100' ? 'table-success text-center' : ''}>
                            {this.props.physAbuse.primarySelf[0] === '100' ? <i className="fal fa-check"></i> : ''}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Sexual abuse from a family member</th>
                        <td className={this.props.sexAbuse.primarySelf[0] < 100 ? 'table-danger text-center' : ''}>
                            {this.props.sexAbuse.primarySelf[0] < 100 ? <i className="fal fa-check"></i> : ''}
                        </td>
                        <td className={this.props.sexAbuse.primarySelf[0] === '100' ? 'table-success text-center' : ''}>
                            {this.props.sexAbuse.primarySelf[0] === '100' ? <i className="fal fa-check"></i> : ''}
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>DIVORCED PARENTS</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Research has shown that individuals with divorced parents are more likely to experience instability in their own relationships.
                    This may be because those individuals have fewer models of lasting relationships to learn from, see divorce as more acceptable,
                    instability as unavoidable, or have more difficulty in navigating romantic attachments. If your parents divorced, consider if you
                    have more positive feelings toward divorce or are untrusting of others in relationships.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>UNMARRIED PARENTS</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Research has shown that the children of parents who never married are at greater risk for experiencing instability in home life,
                    which may then translate to their adult relationships. Some research suggests this may be because growing up with unmarried parents
                    sends messages to children that long-term committed relationships are less important. Other research suggests that living with a
                    single parent likely comes with an increased risk of other family stressors which may impact healthy relationships formation.
                    If you have this risk, consider if you truly have trust in the importance of long-term relationships.  Consider if you believe
                    in the principle of commitment.  If not, it may be difficult to maintain a relationship in the future.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>PHYSICAL ABUSE</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Research suggests that victims of childhood physical abuse are both more likely to find themselves in abusive
                    relationships as adults, either as a victim or offender.  Physical abuse is also associated with increased risk of
                    depression and other mental health issues which affect relationship quality.  If you experienced physical abuse, we
                    recommend seeking professional counseling if you have not done so already to help you understand the experience and
                    learn more about its impact on your life.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}
                     className="mt-4">
                    <b>SEXUAL ABUSE</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4  mb-5 text-justify'>
                    Research has found that childhood victims of sexual abuse are more prone to sexual difficulties in adult relationships,
                    whether it be giving in to sexual pressure or finding it more difficult to separate sex and other forms of physical
                    intimacy from feelings of guilt, shame, and powerlessness.  These difficulties can affect your ability to feel close
                    to your partner or demonstrate trust and commitment.  If you experienced sexual abuse, we recommend seeking professional
                    counseling to help you understand the experience and learn more about its impact on your life.
                </div>


            </>

        )

    }

}

export default FamBackgroundChecklist;