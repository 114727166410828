import React, {Component} from "react";
import ReactDOM from 'react-dom';
import NavButton from "../layout/navbar/navigationItems/NavButton";
import AlertError from "../Error/AlertError";
import axios from "../axios/axios-custom";
import Spinner from "../Spinner/Spinner";
import {connect} from "react-redux";
import GrantAccess from "./GrantAccess";

class GrantReadyAccess extends React.Component {

    state = {
        firstName1: '',
        lastName1: '',
        email1: '',
        error: false,
        errorDetails: "Make sure you filled out all the fields correctly",
        spinner: false,
        hidden: false,
    };

    componentDidMount() {

    }


    handleInput = (event) => {

        this.setState({ [event.target.name]: event.target.value });

    };

    handleSubmit = (event) => {

        this.setState({error: false});

        event.preventDefault();

        // console.log((this.state.firstName2.length > 0 && this.state.lastName2.length > 0 && this.isValidEmail(this.state.email2)));
        // console.log(((this.state.firstName2.length > 0 && this.state.lastName2.length > 0 && this.isValidEmail(this.state.email2)) ||
        //     (this.state.firstName2.length === 0 && this.state.lastName2.length === 0 && this.state.email2 === 0)));

        if(this.state.firstName1.length > 0 && this.state.lastName1.length > 0 && this.isValidEmail(this.state.email1)) {

            if(this.state.email1 === this.props.userData.username) {
                this.setState({error: true, errorDetails: "You cannot assign access codes to a professional's account"});
            } else {
                this.grantAccess();
            }



        } else {

            let error = "Make sure you filled out all the fields correctly. You cannot leave this form only partially filled out.";
            this.setState({error: true, errorDetails: error});
        }

    };

    isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    };

    handleCheckboxChange = (changeEvent) => {

        this.setState({hidden: !this.state.hidden});

    };

    grantAccess = () => {

        this.setState({spinner: true});

        let data = {
            firstName1: this.state.firstName1,
            lastName1: this.state.lastName1,
            email1: this.state.email1,
            firstName2: "",
            lastName2: "",
            email2: "",
            link: false,
            hidden: this.state.hidden,
            assessmentType : "ready"
        };

        let url = `${process.env.REACT_APP_API_URL}/api/pro/grant-access`;


        axios.post(url, data, {withCredentials: true})
            .then(response => {

                console.log(response);

                this.props.history.push(`/`);

            })
            .catch(error => {
                // console.log('ERROR',  error.response.data.detail);
                // console.log(JSON.stringify(error));
                //alert('inside error!' + JSON.stringify(error));
                this.setState({error: error.response.data.detail});
                this.setState({spinner: false});

            });
    };


    render() {

        return (
            <>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Send READY Access</h5>
                </div>

                <div className="mb-4">
                    <NavButton className="btn  btn-outline-success" link="/pro/dashboard"><i className="fal fa-arrow-alt-left"/> Back To Dashboard</NavButton>
                </div>

                <div className="bd-callout bd-callout-warning" style={{borderLeftColor: "#f0ad4e"}}>
                    <h5 id="conveying-meaning-to-assistive-technologies">Granting client access</h5>

                    <p>
                        Use the form below to automatically create assessments for an individual.
                    </p>

                </div>

                <div className="card">
                    <div>
                        <h5 className="card-header"><i className="fal fa-user"/> INDIVIDUAL'S INFORMATION
                        </h5>
                    </div>

                    {this.state.error && <div className='mt-3 ml-5 mr-5'><AlertError>{this.state.errorDetails}</AlertError></div>}
                    {this.state.spinner && <Spinner/>}

                    <div className="card-body">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="col-sm-3 mb-3">
                                    <input type="text" className="form-control" id="firstName1" name="firstName1" placeholder="First Name"
                                           onChange={this.handleInput}
                                           value={this.state.firstName1.length > 0 ? this.state.firstName1 : ''}/>
                                </div>
                                <div className="col-sm-3 mb-3">
                                    <input type="text" className="form-control" id="lastName1" name="lastName1" placeholder="Last name"
                                           onChange={this.handleInput}
                                           value={this.state.lastName1.length > 0 ? this.state.lastName1 : ''}/>
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <input type="text" className="form-control" id="email1" name="email1" placeholder="Email"
                                           onChange={this.handleInput}
                                           value={this.state.email1.length > 0 ? this.state.email1 : ''} />
                                </div>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                        checked={this.state.hidden}
                                        onChange={this.handleCheckboxChange}
                                       //disabled={this.state.disabled}
                                       className="custom-control-input" id={"hideres"}/>
                                <label className="custom-control-label" htmlFor={"hideres"}>Hide results from client</label>
                            </div>

                            <hr className="mb-4"/>

                            <div className="form-row">
                                <div className="col mb-3">
                                    <button type="button" className="btn  btn-outline-dark" onClick={this.handleSubmit}>SEND CLIENT ACCESS</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>



            </>
        );
    }
}

const mapReduxStateToProps = (reduxState) => {
    return {
        userData: reduxState.oldReducer.userData,
    };
};

//export default GrantReadyAccess;
export default connect(mapReduxStateToProps) (GrantReadyAccess);
