import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from '../../constants/Colors'

class Warmth extends Component {

    componentDidMount() {
    }

    render() {

        let warmth = Math.round((Number(this.props.kind.primarySelf[0]) + Number(this.props.sociability.primarySelf[0])) / 2);
        let color = Color.CHALLENGE;

        if (warmth >= 33.33 && warmth < 66.66) {
            color = Color.IMPROVE;
        } else if (warmth >= 66.66) {
            color = Color.STRENGTH;
        }

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-scarf fa-2x" style={{color: "#c3b2b5"}}/></div>
                        <div>
                            WARMTH
                        </div>
                    </div>
                </div>

                <div className="mt-2 mb-5 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    This section assesses your warmth, which is a measure of the respect and acceptance you show towards
                    others. When we think of “cold-hearted” for example, we think of someone who doesn’t have much
                    concern over how they treat others and are generally closed in social interactions. Warm people,
                    however, listen well to others, help and support others, and look for the good in people. In
                    general, warm people are the most agreeable to be around in any social relationships, and we are
                    more likely to form fulfilling and mutually beneficial relationships with warm people.
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='iant' percent={warmth} color={color}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1">
                        {color === Color.CHALLENGE &&
                        <ChallengeLegendDetail id="ikqh">
                            You tend to come across as “cold” to others, meaning that you act in ways that suggest you
                            are mostly concerned with your own comfort and not necessarily considering the needs of
                            others. You tend to remain quiet and aloof. This could represent significant weakness in
                            your ability to attract people to you. This negatively impacts your opportunities to meet
                            new people as well as your likability. Try to think about your body language and the
                            messages it sends to others. When someone does approach you, do not be too quick to judge
                            and show interest in what they have to say, even if just for a short time. The following are
                            the subdimensions that make up the warmth scale.
                        </ChallengeLegendDetail>
                        }

                        {color === Color.IMPROVE &&
                        <ImproveLegendDetail id="ilsr">
                            Unlike many other categories, scoring in the white section does not necessarily indicate
                            potential relationship weaknesses. It may indicate trouble being around others, or a
                            tendency to avoid those different than yourself. While not necessarily harmful to others,
                            think about how you come across to people and how that might affect your abilities to
                            connect or form new relationships.
                        </ImproveLegendDetail>
                        }

                        {color === Color.STRENGTH &&
                        <StrengthLegendDetail id="iltu">
                            You tend to get along well with others. You are accepting of differences and treat others
                            with respect. You are able to connect in positive ways and pleasant to be around. This is a
                            great asset for you in relationships and will contribute to both your chances of forming new
                            relationships as well as maintaining them over time.
                        </StrengthLegendDetail>
                        }
                    </div>
                </div>

                <div className="mt-2 mb-5 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    This section assesses your overall emotional tendencies in relationships, such as how you react to
                    difficulties that arise in your life. Emotional reactivity, or neuroticism, is generally considered
                    harmful to relationships because it reflects difficulty controlling emotions and strong reactions to
                    emotionally charged events. Neuroticism also means you put emotional meaning into events that do not
                    require an emotional response. A lack of emotional control can lead to taking things personally,
                    irrational responses to challenges, and a tendency to misinterpret the behavior of others. On
                    emotional readiness it has been found that being with someone high in this trait is better for
                    relationships, no matter how you score.
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>KINDNESS</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='iohy' percent={this.props.kind.primarySelf[0]}
                                    color={this.props.kind.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Kindness is a measure of your tendency to treat others with respect and helping behavior,
                        whether they are friends or strangers. Research suggests that the kindness we display towards
                        others has a major influence on their perceptions of us and desire to be around us. A high
                        kindness score indicates your focus on helping and understanding others.

                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>SOCIABILITY</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='irdf' percent={this.props.sociability.primarySelf[0]}
                                    color={this.props.sociability.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Sociability is a measure of how much you open up in social settings. This is very similar to the
                        idea of “introvert” and “extrovert.” Research suggests that sociability is not a strong
                        indicator of relationship success, but people who are more open are more likely to meet new
                        people and be optimistic for new relationships. A high sociability score means you are able to
                        get involved in and contribute to conversations with others.

                    </div>
                </div>


            </>

        )

    }

}

export default Warmth;