import React, {Component} from 'react';
import axios from "axios";
import * as actions from "../store/actions";
import {connect} from "react-redux";
import ReceiptItem from "../Checkout/ReceiptItem";
import {checkValidity, updateObject} from "../layout/utility";
import Spinner from "../Spinner/Spinner";
import {Redirect} from "react-router";

class PurchaseConfirmation extends Component {

    state = {

        passwordForm: {
            password: {
                value: '',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 8,
                    maxLength: 30,
                },
            },
            passwordConfirmation: {
                value: '',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 8,
                    maxLength: 30,
                },
            },
        },
        formIsValid: false,
        invalidClass: 'form-control is-invalid',
        validClass: 'form-control',
        errorDetails: '',
        resetId: this.props.match.params.resetId,
        showSpinner: false,
        success: false,

        userId: '',
        orderId: this.props.match.params.orderId,
        finalCost: 0,
        active: false,
        orderInformation: [],
    };

    componentDidMount() {

        let tempUserId = localStorage.getItem('tempUserId');

        if (!this.props.isAuthenticated && tempUserId != null && !this.state.active
            && this.state.userId === tempUserId) {

            this.passwordInput.focus();

        }

        const userId = this.props.match.params.userId;
        const orderId = this.props.match.params.orderId;

        this.setState({userId: userId ? userId : ''});


        let url = `${process.env.REACT_APP_API_URL}/api/users/${userId}/order/${orderId}`;

        axios.get(url, {
            withCredentials: true,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        },)
            .then(response => {
                this.setState({
                    orderInformation: response.data.orderDetails, finalCost: response.data.finalCost,
                    active: response.data.active, userId: response.data.userId
                });


                if (typeof window !== "undefined") {

                    // Facebook ads
                    if (window.fbq != null) {
                        //window.fbq('init', '319156652008771');
                        window.fbq('track', 'PageView');
                        window.fbq('track', 'Purchase', {value: response.data.finalCost, currency: 'USD'});
                    }
                    // Facebook ads

                    // Google Ad Event

                    window.gtmDataLayer.push({
                        'event': 'pageview',
                        'page': {
                            'category': 'purchaseConfirmation',
                            'path': `/receipt/${orderId}/${userId}`,
                            'title': 'Purchase Confirmation'
                        }
                    });

                    // if (window.gtag != null) {
                    //     window.gtag('event', 'conversion', {
                    //         'send_to': 'AW-736034926/2yevCOKp5r8BEO6A_N4C',
                    //         'value': response.data.finalCost,
                    //         'currency': 'USD',
                    //         'transaction_id': ''
                    //     });
                    // }
                    // Google Ad Event

                    // Bing
                    window.uetq = window.uetq || [];
                    window.uetq.push('event', {'revenue_value': response.data.finalCost, 'currency': 'USD'});
                    // Bing
                }


            })
            .catch(error => {

            });

    };

    handleUserInput = (event) => {
        //event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        // this.setState({[name]: value});

        const updatedPasswordForm = {
            ...this.state.passwordForm
        };

        const updatedFormElement = {
            ...updatedPasswordForm[name]
        };

        updatedFormElement.value = value;

        updatedFormElement.valid = true;

        // if(value.length > 5) {
        //   updatedFormElement.valid = true;
        // }

        //updatedFormElement.valid = this.validateForm(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedPasswordForm[name] = updatedFormElement;
        this.setState({passwordForm: updatedPasswordForm});

    };


    validateElementInput = (event) => {

        event.preventDefault();

        // const name = event.target.name;
        // const value = event.target.value;

        const inputIdentifier = event.target.name;

        if (!this.state.passwordForm[inputIdentifier].validation) {

            return true;

        }

        const updatedFormElement = updateObject(this.state.passwordForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.passwordForm[inputIdentifier].validation),
            touched: true
        });

        const updatedResetForm = updateObject(this.state.passwordForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedResetForm) {
            formIsValid = updatedResetForm[inputIdentifier].valid && formIsValid;
        }

        this.setState({passwordForm: updatedResetForm, formIsValid: formIsValid});

    };


    handleSubmit = (ev) => {


        ev.preventDefault();

        // console.log(this.state.passwordForm.password.value.trim().length);
        // console.log(this.state.passwordForm.password.value.trim());
        // console.log(this.state.passwordForm.passwordConfirmation.value.trim());

        // if(this.state.passwordForm.password.value.trim().length >= 8 &&
        //     this.state.passwordForm.password.value.trim() === this.state.passwordForm.passwordConfirmation.value.trim()) {
        //
        //
        //   // const orderData = {
        //   //   password: this.state.passwordForm.password.value.trim(),
        //   //   passwordConfirmation: this.state.passwordForm.passwordConfirmation.value.trim(),
        //   //   userId: this.state.userId,
        //   // };
        //
        //   this.props.onPostPurchaseAuth( this.state.passwordForm.password.value.trim(), this.state.passwordForm.passwordConfirmation.value.trim(),
        //       this.state.userId );
        //
        // }


        if (this.state.passwordForm.password.value.trim().length >= 8 &&
            this.state.passwordForm.password.value.trim() === this.state.passwordForm.passwordConfirmation.value.trim()) {

            this.setState({showSpinner: true});

            // this.props.onPostPurchaseAuth( this.state.passwordForm.password.value.trim(), this.state.passwordForm.passwordConfirmation.value.trim(),
            //     this.state.userId );


            const authData = {
                password: this.state.passwordForm.password.value.trim(),
                passwordConfirmation: this.state.passwordForm.passwordConfirmation.value.trim(),
                userId: localStorage.getItem('tempUserId'),
            };

            let url = `${process.env.REACT_APP_API_URL}/api/register-purchase`;

            // const resetData = {
            //   password: this.state.passwordForm.password.value.trim(),
            //   passwordConfirmation: this.state.passwordForm.passwordConfirmation.value.trim(),
            // };
            //
            //
            // let url = `${process.env.REACT_APP_API_URL}/api/password/${localStorage.getItem('tempUserId')}/update`;

            axios.post(url, authData)
                .then(response => {

                    let defaultValue = {
                        password: {
                            value: '',
                            valid: true,
                            touched: false,
                            validation: {
                                required: true,
                                minLength: 8,
                                maxLength: 30,
                            },
                        },
                        passwordConfirmation: {
                            value: '',
                            valid: true,
                            touched: false,
                            validation: {
                                required: true,
                                minLength: 8,
                                maxLength: 30,
                            },
                        },
                    };

                    this.props.onPostPurchaseAuth(this.state.passwordForm.password.value.trim(), this.state.passwordForm.passwordConfirmation.value.trim(),
                        this.state.userId);

                    this.setState({passwordForm: defaultValue, formIsValid: false, showSpinner: false, success: true});

                })
                .catch(error => {

                    this.setState({showSpinner: false});

                    // Error 😨
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        this.setState({errorDetails: error.response.data.detail});

                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        //console.log(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        //console.log('Error', error.message);
                    }
                    //console.log(error);

                });

        } else {

            this.setState({showSpinner: false});

            const updatedFormElement = updateObject(this.state.passwordForm['passwordConfirmation'], {
                value: this.state.passwordForm.passwordConfirmation.value.trim(),
                valid: false,
                touched: true
            });

            const updatedResetForm = updateObject(this.state.passwordForm, {
                'passwordConfirmation': updatedFormElement
            });

            let formIsValid = false;
            this.setState({passwordForm: updatedResetForm, formIsValid: formIsValid});

        }

    };

    render() {

        let signUpForm;

        let tempUserId = localStorage.getItem('tempUserId');

        let redirect;

        if (this.props.isAuthenticated) {
            redirect = <Redirect to="/"/>;
        } else {
            redirect = <></>;
        }

        if (!this.props.isAuthenticated && tempUserId != null && !this.state.active
            && this.state.userId === tempUserId) {

            signUpForm =
                <form className="form-signin" onSubmit={this.handleSubmit}>
                    <h5 className="mb-3 text-center"><span className="text-muted">Create password to manage the codes in your account</span>
                    </h5>
                    <div className="col-md-12 order-md-1">
                        <div className="mb-3">
                            <label htmlFor="password">New Password</label>
                            <input type="password"
                                   className={this.state.passwordForm.password.valid ? this.state.validClass : this.state.invalidClass}
                                   id="password" name="password"
                                   ref={(input) => {
                                       this.passwordInput = input;
                                   }}
                                   value={this.state.passwordForm.password.value}
                                   onChange={(event) => this.handleUserInput(event)}
                                   onBlur={(event) => this.validateElementInput(event)}/>
                            <div className="invalid-feedback">
                                Password must be at least 8 characters long
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="confirmPassword">Confirm New Password</label>
                            <input type="password"
                                   className={this.state.passwordForm.passwordConfirmation.valid ? this.state.validClass : this.state.invalidClass}
                                   id="passwordConfirmation" name="passwordConfirmation"
                                   value={this.state.passwordForm.passwordConfirmation.value}
                                   onChange={(event) => this.handleUserInput(event)}/>
                            <div className="invalid-feedback">
                                Passwords do not match
                            </div>
                        </div>
                    </div>

                    <hr className="mb-4"/>
                    <button className="btn btn-info btn-block" type="submit"><i className="fal fa-key"></i> Update
                        Password
                    </button>


                </form>;

        } else {
            signUpForm = <></>;

        }


        let orderInfo = this.state.orderInformation.map((item, idx) => {
            return (

                <ReceiptItem key={idx} sku={item.sku} productName={item.productName}
                             redemptionCode={item.redemptionCode}
                />

            )
        });

        return (

            <>
                {redirect}

                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4" style={{fontSize: '2.3em'}}>Purchase Confirmation</h5>
                    <p className="lead">
                        This is your purchase confirmation.
                    </p>

                </div>

                {(this.state.showSpinner || this.props.loading) && <Spinner/>}
                {(!this.state.showSpinner && !this.props.loading) && signUpForm}

                <div className="card mb-3">
                    <div className="card-header">
              <span style={{fontSize: '15px', color: '#b4c0d0', marginRight: '10px'}}>
                <i className="fas fa-receipt"></i>
              </span>
                        Purchase Confirmation - Order ID: <strong>{this.state.orderId}</strong>
                    </div>
                    <div className="card-body">

                        {orderInfo}


                    </div>

                    <div className="card-footer text-muted">
                        <div className="row">
                            <div className="col-sm-9">
                            </div>
                            <div className="col-sm-3">
                                <div className="text-md-right pull-right" style={{paddingTop: '5px'}}>Total
                                    price: <b>${Number.parseFloat(this.state.finalCost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>


        )
    }

}

const mapStateToProps = (reduxState) => {
    return {
        loading: reduxState.oldReducer.loading,
        error: reduxState.oldReducer.error,
        isAuthenticated: reduxState.oldReducer.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onIncrementCounter: () => dispatch({type: 'INCREMENT'}),
        onPostPurchaseAuth: (email, password, isSignup) => dispatch(actions.postPurchaseAuth(email, password, isSignup)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseConfirmation);