import React, {Component} from 'react';
import Spinner from "../Spinner/Spinner";
import axios from "../axios/axios-custom";
import CartWidgetItem from "../Checkout/CartWidgetItem";
import NavButton from "../layout/navbar/navigationItems/NavButton";

class GenerateCodes extends Component {

    state = {
        errorDetails: '',
        showSpinner: false,
        relate: true,
        ready: false,
        assessment: 'relate',
        asmtCount: '',
        generatedCodes: [

        ]
    }

    handleToggle = (e) => {

        if (e.target.value.trim() === 'relate') {
            this.setState({assessment: 'relate', relate: true, ready: false});
        } else {
            this.setState({assessment: 'ready', relate: false, ready: true});
        }

    };

    handleUserInput = (event) => {

        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({asmtCount: event.target.value});
        }
    };

    handleSubmit = async (event) => {

        event.preventDefault();

        this.setState({errorDetails: ''});

        if(parseInt(this.state.asmtCount) > 0) {

            this.setState({showSpinner: true});


            const codeData = {

                assessmentType: this.state.assessment,
                qty: this.state.asmtCount
            };

            let url = `${process.env.REACT_APP_API_URL}/api/admin/redemption-codes`;

            try {
                let response = await axios.post(url, codeData, {withCredentials: true});

                this.setState({showSpinner: false, generatedCodes: response.data, asmtCount: '', relate: true, ready: false, assessment: 'relate'});

                //window.location.reload(false);

            } catch (error) {

                this.setState({showSpinner: false});

                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail});

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }


            }

        } else {
            this.setState({errorDetails: "Number of Assessments must be greater than zero"});
        }

    };


    getGeneratedCodes = () => {

        if(this.state.generatedCodes.length > 0) {


            const codes = this.state.generatedCodes.map((item, idx) => {

                console.log(item);

                return <tr key={idx}><td>{item.redemptionCode} - {item.assessmentId === 1 ? 'RELATE' : 'READY'}</td></tr>;

            });

            return codes;


        }

    };


    render() {

        let errorDiv = <div className="alert alert-danger text-center" role="alert">
            <i className="fal fa-exclamation-triangle"/> {this.state.errorDetails}
        </div>;

        let generatedCodes = <div className="col-md-6 mx-auto">
            <table className="table table-bordered table-hover">
                <tbody>
                <tr>
                    <th>Generated Codes</th>
                </tr>
                {this.getGeneratedCodes()}
                </tbody>
        </table></div>;

        let codeForm = (

            <>
                {this.state.errorDetails && errorDiv}
                {!this.state.showSpinner && <form className="form-signin"  onSubmit={this.handleSubmit}>
                    <div className="col-md-12 order-md-1">


                        <div className="mb-2">
                            <div className="mb-3">Assessment Type</div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="relate" className="custom-control-input" value="relate" onChange={(v) => this.handleToggle(v)} checked={this.state.relate}/>
                                <label className="custom-control-label" htmlFor="relate">Relate
                                </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="ready" className="custom-control-input" value="ready" onChange={(v) => this.handleToggle(v)} checked={!this.state.relate}/>
                                <label className="custom-control-label" htmlFor="ready">Ready</label>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="asmtCount">Number of Assessments</label>
                            <input type="text" className="form-control"
                                   id="asmtCount" name="asmtCount" value={this.state.asmtCount}
                                   onChange={(event) => this.handleUserInput(event)}
                            />
                        </div>

                    </div>
                    <hr className="mb-4"/>
                    <button className="btn btn-outline-info btn-block" type="submit"><i className="fal fa-barcode" /> Generate Assessment Codes</button>
                </form> }

                {this.state.showSpinner && <Spinner />}




            </>

        );

        return (
            <div>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Generate Assessment Codes</h5>
                </div>

                {codeForm}
                {this.state.generatedCodes.length > 0 && generatedCodes}
            </div>


        );
    }
}

export default GenerateCodes;