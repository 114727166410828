import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from "../axios/axios-custom";
import * as actionCreators from "../store/action/checkOut";
import LoadingIcon from "./LoadingIcon";

class CouponWidget extends Component {

    state = {
        couponCode: '',
        couponError: '',
        invalidClass: 'form-control is-invalid',
        validClass: 'form-control',
        loading: false,
    };

    handleCouponCode = (e) => {
        this.setState({couponCode: e.target.value.toUpperCase(), couponError: ''});
    };

    applyCoupon = () => {

        const enteredCode = this.state.couponCode.trim();

        if (enteredCode !== '') {

            this.setState({loading: true});

            let url = `${process.env.REACT_APP_API_URL}/api/coupons/${enteredCode}/validate`;

            axios.get(url)
                .then(response => {

                    if(response.data.amountDiscount) {
                        this.props.onApplyDiscount(response.data.couponCode, response.data.percent, response.data.amount);

                    } else {
                        this.props.onApplyDiscount(response.data.couponCode, response.data.percent);
                    }


                    this.setState({couponCode: '', loading: false});
                    // this.setState({loading: false});

                })
                .catch(error => {

                    this.setState({couponError: 'Invalid Coupon', loading: false});
                    //this.setState({loading: false});

                });

        }

    };

    render() {
        return (
            <li className="list-group-item d-flex justify-content-between">
                    <div>
                        <label>
                            <input type="text" className={this.state.couponError === '' ? this.state.validClass : this.state.invalidClass} placeholder="Coupon code"
                                   onChange={this.handleCouponCode} value={this.state.couponCode}
                                   onKeyPress={(ev) => {
                                       // console.log(`Pressed keyCode ${ev.key}`);
                                       if (ev.key === 'Enter') {
                                           // Do code here
                                           //ev.preventDefault();
                                           this.applyCoupon();
                                       }
                                   }}

                                   disabled={this.props.cart.totalQty === 0}
                            />
                            <div className="invalid-feedback">
                                {this.state.couponError}
                            </div>
                        </label>
                    </div>
                <div>
                    <button type="button" className="ml-4 btn btn-outline-success"
                            onClick={this.applyCoupon} disabled={this.state.loading}>
                        <LoadingIcon show={this.state.loading} className='mr-1'/> {this.props.loading ? 'Applying' : 'Apply'}

                    </button>
                </div>
            </li>
        );
    }
}

function mapStateToProps(reduxState) {
    return {
        cart: reduxState.checkOut.cart,
        cartQty: reduxState.checkOut.totalQty,
        //loading: reduxState.checkOut.isLoading,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onApplyDiscount: (coupon, percent, amount) => dispatch(actionCreators.applyDiscount(coupon, percent, amount)),
    };
};

export default connect(
    mapStateToProps, mapDispatchToProps
)(CouponWidget);

