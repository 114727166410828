import React from 'react';
import {Route} from 'react-router';
import {Redirect, Switch} from "react-router-dom";
import FocusForm from "./color/FocusForm";
import Survey from "./Survey/Survey";
import Logout from "./Auth/Logout";
import Pricing from "./layout/Pricing";
import CheckOut from "./Checkout/Cart";
import UpdatePassword from "./layout/users/UpdatePassword";
import PaymentRoute from "./Stripe/PaymentRoute";
import Stripe from "./Stripe/Stripe";
import PurchaseConfirmation from "./Stripe/PurchaseConfirmation";
import Report from "./Report/Report";
import Cover from "./Report/relate/Cover";
import ShareCode from "./Survey/ShareCode";
import ReadyCover from "./Report/ready/ReadyCover";
import PrintReport from "./Report/PrintReport";
import ReadyReport from "./Report/ReadyReport";
import ReadyPrint from "./Report/ReadyPrint";
import Orders from "./Orders/Orders";
import LinkAssessments from "./Survey/LinkAssessments";
import Auth from "./Auth/Auth";
import BulkOrder from "./Professionals/BulkOrder";
import Dashboard from "./Professionals/Dashboard/Dashboard";
import GrantAccess from "./Professionals/GrantAccess";
import Codes from "./Professionals/Codes";
import GrantReadyAccess from "./Professionals/GrantReadyAccess";
import AddToCart from "./layout/AddToCart";
import MyAssessment from "./Survey/MyAssessment";
import ProfessionalReview from "./Survey/ProfessionalReview";
import Archived from "./Professionals/Archived";

const professionalRoutes = () => (
    <Switch>
        {/*<Route path="/" exact render={() => <h1>Home</h1>}/>*/}
        <Route path="/" exact component={Dashboard}/>
        <Route path="/pro/dashboard" exact component={Dashboard}/>
        <Route path="/pro/bulk-orders" exact component={BulkOrder}/>
        <Route path="/pro/relate-access" exact component={GrantAccess}/>
        <Route path="/pro/ready-access" exact component={GrantReadyAccess}/>
        <Route path="/pro/codes" exact component={Codes}/>
        <Route path="/pro/archived" exact component={Archived}/>
        <Route path="/checkout/product/:type" exact component={AddToCart}/>
        <Route path="/professional/review/:assessmentId" exact component={ProfessionalReview}/>
        {/*{this.props.userData.role === 'ROLE_DEVELOPER' &&  <Route path="/test/:id" exact component={Details}/> }*/}
        {/*{this.props.userData.role === 'ROLE_DEVELOPER' && <Route path="/test" exact component={Test}/>}*/}

        <Route path="/focus" exact component={FocusForm}/>
        <Route path="/survey/:assessmentId" exact component={Survey}/>
        <Route path="/logout" exact component={Logout}/>
        <Route path="/pricing" exact component={BulkOrder}/>
        <Route path="/cart" exact component={CheckOut}/>
        <Route path="/users/account" exact component={UpdatePassword}/>

        {/*<Route path="/payment" exact component={Stripe}/>*/}
        <PaymentRoute path="/payment" exact component={Stripe}/>
        <Route path="/receipt/:orderId/:userId" exact component={PurchaseConfirmation}/>
        <Route path="/assessments" exact component={MyAssessment}/>
        <Route path="/report/:assessmentId" exact component={Report}/>
        <Route path="/report/:assessmentId/:section" exact component={Report}/>
        <Route path="/report/:assessmentId/relate/cover" exact component={Cover}/>
        <Route path="/assessment/code/share" exact component={ShareCode}/>
        <Route path="/report-ready/:assessmentId/ready/cover" exact component={ReadyCover}/>
        <Route path="/report/action/print/:assessmentId" exact component={PrintReport}/>
        <Route path="/report-ready/:assessmentId" exact component={ReadyReport}/>
        <Route path="/report-ready/:assessmentId/:section" exact component={ReadyReport}/>
        <Route path="/report-ready/action/print/:assessmentId" exact component={ReadyPrint}/>
        <Route path="/orders" exact component={Orders}/>
        <Route path="/link-assessments/:assessmentId" exact component={LinkAssessments}/>
        <Route path="/auth" exact component={Auth}/>


        <Redirect to="/"/>
        {/*<Route component={NoMatch} />*/}
    </Switch>
);

export default professionalRoutes;