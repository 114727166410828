import React from 'react';

const noAnswer = (props) => {


    return (

        <div className="bd-callout bd-callout-warning" style={{borderLeftColor: "#cccccc"}}>

            <h5 id={props.id} style={{color: "#cccccc"}}>Not Applicable</h5>
            <div style={{height: '4px', width: '140px', backgroundColor: '#cccccc'}} className='mb-2'></div>

            <p>
                Your Response Was: <b>Does Not Apply</b>
            </p>
        </div>

    )};

export default noAnswer;