import React from 'react';

const cartWidgetItem = (props) => {

  return (
      <li className="list-group-item d-flex justify-content-between lh-condensed">
        <div>
          <h6 className="my-0">{props.productName}</h6>
          <small className="text-muted">{props.sku} {props.productName} ({props.qty} x ${props.price})</small>
        </div>
        <span className="text-muted">${props.price * props.qty}</span>
      </li>

  )
};

export default cartWidgetItem;