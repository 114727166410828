import React, {Component} from 'react';
import './App.css';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Layout from "./layout/Layout";
import Stripe from "./Stripe/Stripe";
import Auth from './Auth/Auth';
import * as actions from './store/actions';
import Pricing from "./layout/Pricing";
import CheckOut from "./Checkout/Cart";
import PurchaseConfirmation from "./Stripe/PurchaseConfirmation";
import SignUp from "./Auth/SignUp";
import PaymentRoute from "./Stripe/PaymentRoute";
import ResetPassword from "./layout/users/ResetPassword";
import RequestPasswordReset from "./layout/users/RequestPasswordReset";
import Email from "./Checkout/Email";
import userRoutes from "./UserRoutes";
import professionalRoutes from "./ProfessionalRoutes";
import adminRoutes from "./AdminRoutes";
import AddToCart from "./layout/AddToCart";
import axios from "./axios/axios-custom";
import StrongerMarriage from "./layout/StrongerMarriage";

class App extends Component {


   async componentDidMount() {

       //await axios.get("/api/csrf");


        //WindowSizeListener.DEBOUNCE_TIME = 1000;

        this.props.authCheckState();

        //https://medium.com/front-end-weekly/multi-tab-logout-in-react-redux-4715f071c7fa
        // window.addEventListener('storage', e => {
        //   if (e.key === 'token' && e.oldValue && !e.newValue) {
        //     this.props.logOutEvent();
        //   }
        // });

        window.addEventListener('storage', this.localStorageUpdated);

        // window.addEventListener("beforeunload", this.tabClosed);


    }

    localStorageUpdated = (e) => {

        if (e.key === 'token' && e.oldValue && !e.newValue) {
            this.props.logOutEvent();
        }

    };

    // tabClosed = (ev) => {
    //
    //   ev.preventDefault();
    //   return ev.returnValue = 'Are you sure you want to close?';
    //
    // };

    componentWillUnmount() {
        window.removeEventListener('storage', this.localStorageUpdated);
        // window.removeEventListener("beforeunload", this.tabClosed);
    }

    render() {

        let routes = (
            <Switch>
                <Route path="/" exact component={Auth}/>
                {/*<Route path="/ready" exact component={Ready}/>*/}
                {/*<Route path="/relate" exact component={Relate}/>*/}
                <Route path="/checkout/product/:type" exact component={AddToCart}/>
                <Route path="/pricing" exact component={Pricing}/>
                <Route path="/cart" exact component={CheckOut}/>
                <Route path="/signup" exact component={SignUp}/>
                <Route path="/auth" exact component={Auth}/>
                {/*<Route path="/payment" exact component={Stripe}/>*/}
                <Route path="/customer/email" exact component={Email}/>
                <PaymentRoute path="/payment" exact component={Stripe}/>
                <Route path="/account/reset/:resetId" exact component={ResetPassword}/>
                <Route path="/account/reset" exact component={RequestPasswordReset}/>
                <Route path="/stronger-marriage/:assessment" exact component={StrongerMarriage}/>


                <Route path="/receipt/:orderId/:userId" exact component={PurchaseConfirmation}/>
                <Redirect
                    to={{
                        pathname: "/auth",
                        state: {from: this.props.location}
                    }}
                />
                <Route path="/auth" exact component={Auth}/>
            </Switch>
        );

        //console.log("ROLE", this.props.userData.role);

        if (this.props.isAuthenticated) {

            if (this.props.userData.role === 'ROLE_USER') {

                routes = userRoutes();

            } else if (this.props.userData.role === 'ROLE_COUNSELOR') {

                routes = professionalRoutes();

            } else {

                routes = adminRoutes();

            }

        }

        // if (this.props.isAuthenticated && this.props.userData.role === 'ROLE_COUNSELOR') {
        //     routes = (
        //         <Switch>
        //             {/*<Route path="/" exact render={() => <h1>Home</h1>}/>*/}
        //             <Route path="/" exact component={MyAssessment}/>
        //             {/*{this.props.userData.role === 'ROLE_DEVELOPER' &&  <Route path="/test/:id" exact component={Details}/> }*/}
        //             {/*{this.props.userData.role === 'ROLE_DEVELOPER' && <Route path="/test" exact component={Test}/>}*/}
        //
        //             <Route path="/focus" exact component={FocusForm}/>
        //             <Route path="/survey/:assessmentId" exact component={Survey}/>
        //             <Route path="/logout" exact component={Logout}/>
        //             <Route path="/pricing" exact component={Pricing}/>
        //             <Route path="/cart" exact component={CheckOut}/>
        //             <Route path="/users/account" exact component={UpdatePassword}/>
        //
        //             {/*<Route path="/payment" exact component={Stripe}/>*/}
        //             <PaymentRoute path="/payment" exact component={Stripe}/>
        //             <Route path="/receipt/:orderId/:userId" exact component={PurchaseConfirmation}/>
        //             <Route path="/assessments" exact component={MyAssessment}/>
        //             <Route path="/report/:assessmentId" exact component={Report}/>
        //             <Route path="/report/:assessmentId/:section" exact component={Report}/>
        //             <Route path="/report/:assessmentId/relate/cover" exact component={Cover}/>
        //             <Route path="/assessment/code/share" exact component={ShareCode}/>
        //             <Route path="/report-ready/:assessmentId/ready/cover" exact component={ReadyCover}/>
        //             <Route path="/report/action/print/:assessmentId" exact component={PrintReport}/>
        //             <Route path="/report-ready/:assessmentId" exact component={ReadyReport}/>
        //             <Route path="/report-ready/:assessmentId/:section" exact component={ReadyReport}/>
        //             <Route path="/report-ready/action/print/:assessmentId" exact component={ReadyPrint}/>
        //             <Route path="/orders" exact component={Orders}/>
        //             <Route path="/link-assessments/:assessmentId" exact component={LinkAssessments}/>
        //             <Route path="/auth" exact component={Auth}/>
        //
        //
        //             <Redirect to="/"/>
        //             {/*<Route component={NoMatch} />*/}
        //         </Switch>
        //     );
        // }

        return (

            <Layout isAuthnticated={this.props.isAuthenticated}>{routes}</Layout>

        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        authCheckState: () => dispatch(actions.authCheckState()),
        logOutEvent: () => dispatch(actions.logout())
        //onTryLoadCart: () => dispatch(actions.loadCart()),
    };
};

const mapStateToProps = reduxState => {

    return {
        isAuthenticated: reduxState.oldReducer.token !== null,
        userData: reduxState.oldReducer.userData,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

// Use this if need to add more HOCs
// import { withWindowSizeListener } from 'react-window-size-listener';
// import {compose} from 'redux';
// export default compose(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)), withWindowSizeListener);
