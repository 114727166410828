import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import NavigationItem from "./navigationItems/NavigationItem";
import NavButton from "./navigationItems/NavButton";
import CartDisplay from "./CartDisplay";
import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';
import {connect} from "react-redux";
import * as actions from "../../store/actions";

class LoggedInNavBar extends Component {

  // _isMounted = false;

  state = {
    // screenSize: window.innerWidth,
  };

  componentDidMount() {
    WindowSizeListener.DEBOUNCE_TIME = 1000;

    // this._isMounted = true;
    // window.addEventListener("resize", this.resize);

  }

  componentWillUnmount() {
    // this._isMounted = false;
    // window.removeEventListener("resize", this.resize);
    //this.source.cancel("Operation canceled by the user.");
  };

  // resize = () => {
  //   if (this._isMounted) {
  //     this.setState({screenSize: window.innerWidth});
  //   }
  // };


  render() {

    let link = '';
    let pricingLink = '';
    let linkMobile = '';
    let pricingLinkMobile = '';

    if (this.props.userData.role === 'ROLE_COUNSELOR') {
      link = <NavigationItem link="/pro/dashboard"><i className="fal fa-th-list"></i> Dashboard</NavigationItem>;
      pricingLink = <NavigationItem link="/pro/bulk-orders"><i className="fal fa-dollar-sign"></i> Pricing</NavigationItem>;

      linkMobile = <NavLink className="dropdown-item" activeClassName='' exact to="/pro/dashboard"><i className="fal fa-th-list"></i> Dashboard</NavLink>;
      pricingLinkMobile = <NavLink className="dropdown-item" activeClassName=''  exact to="/pro/bulk-orders"><i className="fal fa-dollar-sign"></i> Pricing</NavLink>;
    } else {
    //  link = <NavigationItem link="/assessments"><i className="fal fa-pen-nib"></i> Assessments</NavigationItem>;
      pricingLink = <NavigationItem link="/pricing"><i className="fal fa-dollar-sign"></i> Pricing</NavigationItem>;

     // linkMobile = <NavLink className="dropdown-item" activeClassName='' exact to="/assessments"><i className="fal fa-pen-nib"></i> Assessments</NavLink>;
      pricingLinkMobile = <NavLink className="dropdown-item" activeClassName='' exact to="/pricing"><i className="fal fa-dollar-sign"></i> Pricing</NavLink>;
    }


    return (

        <>

          <div className="d-flex align-items-center justify-content-around p-3 px-md-4 mb-3  bg-white border-bottom shadow-sm">
            <h5 className="my-0 mr-md-auto font-weight-normal">
              {/*<Link to='/' className="p-2 text-dark"><img className="mb-2" src="/relate.png" alt="" height="30"/></Link>*/}
              <a href="https://www.relatefoundation.com" className="p-2 text-dark" ><img className="mb-2" src="/images/header-logo.png" alt="" height="40"/></a>
              {process.env.REACT_APP_ENV}
            </h5>

      {/*      <span>*/}
      {/*  {this.props.windowSize.windowWidth},*/}
      {/*        {this.props.windowSize.windowHeight}*/}
      {/*</span>*/}

            {this.props.windowSize.windowWidth > 751 &&

            <>
              <nav className="my-2 my-md-0 mr-md-3">

                {/*<NavigationItem link="/assessments"><i className="fal fa-pen-nib"></i> Assessments</NavigationItem>*/}
                <NavigationItem link="/assessments"><i className="fal fa-pen-nib"></i> Assessments</NavigationItem>
                {link}
                {pricingLink}
                <NavigationItem link="/orders"><i className="fal fa-tags"></i> Orders</NavigationItem>
                <NavButton className="btn btn-outline-danger btn-sm" link="/logout"><i className="fal fa-sign-out-alt"></i> Sign Out</NavButton>
              </nav>
              <CartDisplay/>
            </>

            }

            {this.props.windowSize.windowWidth <= 751 &&

            <nav>
              <div className="btn-group mr-3">
                <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fal fa-bars"></i>
                </button>
                <div className="dropdown-menu">
                  {linkMobile}
                  <NavLink className="dropdown-item" activeClassName='' exact to="/assessments"><i className="fal fa-pen-nib"></i> Assessments</NavLink>
                  {pricingLinkMobile}
                  <NavLink className="dropdown-item" activeClassName='' exact to="/orders"><i className="fal fa-tags"></i> Orders</NavLink>
                  <div className="dropdown-divider"></div>
                  <NavLink className="dropdown-item text-danger" activeClassName='' exact to="/logout"><i className="fal fa-sign-out-alt"></i> Sign Out</NavLink>
                </div>
              </div>
              <div className='float-right'>
                <CartDisplay/>
              </div>
            </nav>

            }
          </div>

          {/*<div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">*/}
          {/*  <h5 className="my-0 mr-md-auto font-weight-normal">*/}
          {/*    <Link to='/' className="p-2 text-dark"><img className="mb-2" src="/relate.png" alt="" height="30"/></Link>*/}
          {/*    {process.env.REACT_APP_ENV}*/}
          {/*  </h5>*/}

          {/*  {this.state.screenSize > 800 &&*/}

          {/*      <>*/}

          {/*  <nav className="my-2 my-md-0 mr-md-3">*/}
          {/*    <NavigationItem link="/assessments"><i className="fal fa-pen-nib"></i> Assessments</NavigationItem>*/}
          {/*    <NavigationItem link="/pricing"><i className="fal fa-dollar-sign"></i> Pricing</NavigationItem>*/}
          {/*    <NavigationItem link="/orders"><i className="fal fa-tags"></i> Orders</NavigationItem>*/}
          {/*    <NavButton className="btn btn-outline-danger btn-sm" link="/logout"><i className="fal fa-sign-out-alt"></i> Sign Out</NavButton>*/}
          {/*  </nav>*/}
          {/*  <CartDisplay/>*/}
          {/*  </>*/}

          {/*  }*/}

          {/*  {this.state.screenSize <= 800 &&*/}

          {/*  <nav>*/}
          {/*    <div className="btn-group mr-3">*/}
          {/*      <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
          {/*        <i className="fal fa-bars"></i>*/}
          {/*      </button>*/}
          {/*      <div className="dropdown-menu">*/}
          {/*        <NavLink className="dropdown-item" activeClassName='' exact to="/assessments"><i className="fal fa-pen-nib"></i> Assessments</NavLink>*/}
          {/*        <NavLink className="dropdown-item" activeClassName='' exact to="/pricing"><i className="fal fa-dollar-sign"></i> Pricing</NavLink>*/}
          {/*        <NavLink className="dropdown-item" activeClassName='' exact to="/orders"><i className="fal fa-tags"></i> Orders</NavLink>*/}
          {/*        <div className="dropdown-divider"></div>*/}
          {/*        <NavLink className="dropdown-item text-danger" activeClassName='' exact to="/logout"><i className="fal fa-sign-out-alt"></i> Sign Out</NavLink>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='float-right'>*/}
          {/*      <CartDisplay/>*/}
          {/*    </div>*/}
          {/*  </nav>*/}

          {/*  }*/}
          {/*</div>*/}
        </>

    )

  }
}


const mapStateToProps = reduxState => {

  return {
    userData: reduxState.oldReducer.userData,
  };
};

export default withWindowSizeListener(connect(mapStateToProps)(LoggedInNavBar));