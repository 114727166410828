import React, {Component} from 'react';
import {checkValidity, updateObject} from "../utility";
import axios from "axios";
import Spinner from "../../Spinner/Spinner";
import {Link} from "react-router-dom";

class ResetPassword extends Component {


  state = {

    resetForm: {
      password: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 8,
          maxLength: 30,
        },
      },
      passwordConfirmation: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 8,
          maxLength: 30,
        },
      },
    },
    formIsValid: false,
    validClass: 'form-control',
    errorDetails: '',
    resetId: this.props.match.params.resetId,
    showSpinner: false,
    success: false,
  };

  componentDidMount() {


      let url = `${process.env.REACT_APP_API_URL}/api/password/${this.state.resetId}/validate`;

      axios.get(url)
      // .then(response => {
      //
      //
      // })
      .catch(error => {

        this.props.history.push(`/`);


      });
  }

  handleUserInput = (event) => {
    //event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;

    const updatedResetForm = {
      ...this.state.resetForm
    };

    const updatedFormElement = {
      ...updatedResetForm[name]
    };

    updatedFormElement.value = value;

    updatedFormElement.valid = true;

    updatedFormElement.touched = true;
    updatedResetForm[name] = updatedFormElement;
    this.setState({resetForm: updatedResetForm});

  };

  validateElementInput = (event) => {

    event.preventDefault();

    // const name = event.target.name;
    // const value = event.target.value;

    const inputIdentifier = event.target.name;

    if (!this.state.resetForm[inputIdentifier].validation) {

      return true;

    }

    const updatedFormElement = updateObject(this.state.resetForm[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.resetForm[inputIdentifier].validation),
      touched: true
    });

    const updatedResetForm = updateObject(this.state.resetForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedResetForm) {
      formIsValid = updatedResetForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({resetForm: updatedResetForm, formIsValid: formIsValid});

  };

  handleSubmit = (ev) => {

    this.setState({showSpinner: true});

    ev.preventDefault();

    if(this.state.resetForm.password.value.trim().length >= 8 &&
        this.state.resetForm.password.value.trim() === this.state.resetForm.passwordConfirmation.value.trim()) {

        const resetData = {
          password: this.state.resetForm.password.value.trim(),
          passwordConfirmation: this.state.resetForm.passwordConfirmation.value.trim(),
        };


        let url = `${process.env.REACT_APP_API_URL}/api/password/${this.state.resetId}/reset`;

        axios.post(url, resetData)
        .then(response => {

         let defaultValue =  {
            password: {
              value: '',
                  valid: true,
                  touched: false,
                  validation: {
                required: true,
                    minLength: 8,
                    maxLength: 30,
              },
            },
            passwordConfirmation: {
              value: '',
                  valid: true,
                  touched: false,
                  validation: {
                required: true,
                    minLength: 8,
                    maxLength: 30,
              },
            },
          };

          this.setState({resetForm: defaultValue, formIsValid: false, showSpinner: false, success: true});

         // this.setState({showSpinner: false, success: true});


          //this.props.history.push(`/auth`);




        })
        .catch(error => {

          this.setState({showSpinner: false});

          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            this.setState({errorDetails: error.response.data.detail});

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            //console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            //console.log('Error', error.message);
          }
          //console.log(error);

        });

    } else {

      this.setState({showSpinner: false});

      const updatedFormElement = updateObject(this.state.resetForm['passwordConfirmation'], {
        value: this.state.resetForm.passwordConfirmation.value.trim(),
        valid: false,
        touched: true
      });

      const updatedResetForm = updateObject(this.state.resetForm, {
        'passwordConfirmation': updatedFormElement
      });

      let formIsValid = false;
      this.setState({resetForm: updatedResetForm, formIsValid: formIsValid});

    }

  };

  render () {

    let confirmDiv = <div className="alert alert-warning text-center" role="alert">
      You password has been successfully reset.<br/>
      <Link to='/auth'>Click Here to Sign in</Link>
    </div>;

    return (

        <React.Fragment>

          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Reset Password</h5>
            {this.state.showSpinner && <Spinner />}
          </div>


          <form className="form-signin" onSubmit={this.handleSubmit}>
            {this.state.success && confirmDiv}

            {!this.state.success &&
            <>
              <div className="col-md-12 order-md-1">
                <div className="mb-3">
                  <label htmlFor="password">Password</label>
                  <input type="password" className={this.state.resetForm.password.valid ? this.state.validClass : this.state.invalidClass}
                         id="password" name="password"
                         value={this.state.resetForm.password.value}
                         onChange={(event) => this.handleUserInput(event)}
                         onBlur={(event) => this.validateElementInput(event)}/>
                  <div className="invalid-feedback">
                    Password must be at least 8 characters long
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input type="password" className={this.state.resetForm.passwordConfirmation.valid ? this.state.validClass : this.state.invalidClass}
                         id="passwordConfirmation" name="passwordConfirmation"
                         value={this.state.resetForm.passwordConfirmation.value}
                         onChange={(event) => this.handleUserInput(event)}/>
                  <div className="invalid-feedback">
                    Passwords do not match
                  </div>
                </div>
              </div>

              <hr className="mb-4"/>
              <button className="btn btn-info btn-block" type="submit"><i className="fal fa-key"></i> Save New Password</button>
            </>
            }
          </form>

        </React.Fragment>

    )
  }

}

export default ResetPassword;