import React, {Component} from "react";
import { connect } from 'react-redux';
import * as actionCreators from "../store/action/checkOut";
import NavButton from "./navbar/navigationItems/NavButton";

class Pricing extends Component {

  state = {
    totalQty: this.props.cartQty,
    showCheckOut: this.props.cartQty > 0,
    readyQty: 0,
    readyTouched: false,
    relateQty: 0,
    relateTouched: false,
    relateCoupleQty: 0,
    relateCouplTouched: false,
    products: [
      {
        sku: "MC-GTV-227",
        price: 25,
        name: "Ready",
        image: 'ready.png',
        descriptions: [
          "1 Ready Assessment",
          "Assesses relationship readiness"
        ]
      },
      {
        sku: "MC-OTN-802",
        price: 25,
        name: "Relate",
        image: 'relate.png',
        descriptions: [
          "1 Relate Assessment",
          "Assesses Couple's Relationship.",
          "Includes 1 assessments."
        ]
      },
      {
        sku: "MC-OTN-141",
        price: 40,
        name: "Couples Package",
        image: 'relate.png',
        descriptions: [
          "Includes 2 assessments.",
          "Assesses Couple's Relationship.  ",
          "Includes 2 assessments."
        ]
      }
    ]
  };

  componentDidMount() {

    this.props.cartContents.forEach(item => {

      if(item.sku === 'MC-GTV-227') {
        this.setState({readyQty: item.qty, readyTouched: (item.qty > 0)});
      }
      if(item.sku === 'MC-OTN-802') {
        this.setState({relateQty: item.qty, relateTouched: (item.qty > 0)});
      }
      if(item.sku === 'MC-OTN-141') {
        this.setState({relateCoupleQty: item.qty, relateCoupleTouched: (item.qty > 0)});
      }
    });

    // this.props.cartContents.map(item => {
    //
    //   if(item.sku === 'MC-GTV-227') {
    //     this.setState({readyQty: item.qty, readyTouched: (item.qty > 0)});
    //   }
    //   if(item.sku === 'MC-OTN-802') {
    //     this.setState({relateQty: item.qty, relateTouched: (item.qty > 0)});
    //   }
    //   if(item.sku === 'MC-OTN-141') {
    //     this.setState({relateCoupleQty: item.qty, relateCoupleTouched: (item.qty > 0)});
    //   }
    // });



    // const totalQty = this.props.cartContents.reduce((a, {qty}) => a + qty, 0);
    //
    // this.props.onSetTotalQty(totalQty);
  }

  removeItem = (sku) => {

    this.props.onRemoveFromCart(sku);

    let cartContents = localStorage.getItem('cart');

    if(sku === 'MC-GTV-227') {
      this.setState({readyTouched: false, readyQty: 0, showCheckOut: cartContents !== null});
    }
    if(sku === 'MC-OTN-802') {
      this.setState({relateTouched: false, relateQty: 0, showCheckOut: cartContents !== null});
    }
    if(sku === 'MC-OTN-141') {
      this.setState({relateCoupleTouched: false, relateCoupleQty: 0, showCheckOut: cartContents !== null});
    }

  };

  updateTouched = (sku, qty, inCart) => {

    if (inCart && qty === 0) {

      if(sku === 'MC-GTV-227') {
        this.setState({readyTouched: false});
      }
      if(sku === 'MC-OTN-802') {
        this.setState({relateTouched: false});
      }
      if(sku === 'MC-OTN-141') {
        this.setState({relateCoupleTouched: false});
      }

    } else  {

      if(sku === 'MC-GTV-227') {
        this.setState({readyTouched: true});
      }
      if(sku === 'MC-OTN-802') {
        this.setState({relateTouched: true});
      }
      if(sku === 'MC-OTN-141') {
        this.setState({relateCoupleTouched: true});
      }

    }

  };

  addToCart = (sku, qty) => {

    if(qty > 0) {

      const products = [...this.state.products];

      const product = products.filter(product => product.sku === sku);

      const cart = {
        productName: product[0].name,
        qty: qty,
        price: product[0].price,
        sku: product[0].sku,
      };

      const inCart = this.props.cartContents.some(item => item.sku === sku);

      this.updateTouched(sku, qty, inCart);


      if(inCart && qty > 0) {
        this.props.onUpdateCart(cart);
      } else if (inCart && qty === 0) {
        this.props.onRemoveFromCart(sku);
      } else if (!inCart && qty > 0) {
        this.props.onAddToCart(cart);
      }

      const currentCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {coupon: '', discount: 0,
        totalCost: 0,
        totalQty: 0,
        cartContents: []};

      let totalInCart = currentCart.cartContents.reduce(function(prev, cur) {
        return prev + cur.qty;
      }, 0);

      // this.props.onSetTotalQty(totalInCart);

      totalInCart > 0 ? this.setState({showCheckOut: true}) : this.setState({showCheckOut: false});
    }




  };

  changeQty = (event) => {

    // console.log(type);
    //
    //   const { target: { name, value } } = event;
    //
    //   this.setState({ [name]: value });

    this.setState({
      [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value
    });

  };


  incrementCount = (sku) => {

    if(sku === 'MC-GTV-227') {
      this.setState({readyQty: ++this.state.readyQty});
      this.addToCart(sku, this.state.readyQty);
    }
    if(sku === 'MC-OTN-802') {
      this.setState({relateQty: ++this.state.relateQty});
      this.addToCart(sku, this.state.relateQty);
    }
    if(sku === 'MC-OTN-141') {
      this.setState({relateCoupleQty: ++this.state.relateCoupleQty});
      this.addToCart(sku, this.state.relateCoupleQty);
    }


  };

  decrementCount = (sku) => {

    if(sku === 'MC-GTV-227') {
      this.setState({readyQty: this.state.readyQty > 0 ? --this.state.readyQty : 0});
      if(this.state.readyQty === 0) {
        this.removeItem(sku);
      } else {
        this.addToCart(sku, this.state.readyQty);
      }
    }
    if(sku === 'MC-OTN-802') {

      this.setState({relateQty: this.state.relateQty > 0 ? --this.state.relateQty : 0});
      if(this.state.relateQty === 0) {
        this.removeItem(sku);
      } else {
        this.addToCart(sku, this.state.relateQty);
      }
    }
    if(sku === 'MC-OTN-141') {

      this.setState({relateCoupleQty: this.state.relateCoupleQty > 0 ? --this.state.relateCoupleQty : 0});
      if(this.state.relateCoupleQty === 0) {
        this.removeItem(sku);
      } else {
        this.addToCart(sku, this.state.relateCoupleQty);
      }
    }





  };

  render() {
    return (

        <>
          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Pricing</h5>
            <p className="lead text-left">
              <i className="fal fa-check text-primary"/> Find the assessment that's right for you, whether you are single or in a committed relationship.
              You can also add assessments as gifts for family or friends!
            </p>
          </div>


          {/*style={{pointerEvents: 'none', opacity: '0.4'}}*/}
          <div className="card-deck mb-3 text-center mt-5">
            <div className="card mb-4 shadow-lg" >
              {/*<div className="ribbon ribbon-top-left"><span>Coming Soon</span></div>*/}
              <div className="card-header" style={{backgroundColor: '#a7bcce'}}>
                <h4 className="my-2 font-weight-normal">READY</h4>
                {/*<small>&nbsp;</small>*/}
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$25 <small className="text-muted"></small></h1>
                <div className="pricing-card-feature-section text-left mt-3 mb-4">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item"><i className="fal fa-check text-primary"></i> <span>1 READY Assessment</span></li>
                    <li className="list-group-item">Perfect for singles preparing for their next relationship<br/><br/><br/></li>
                  </ul>
                </div>


                <div className="d-flex justify-content-around align-items-center mb-3">
                  <div className="flex-fill">
                    <div className='text-danger hand' onClick={() => this.decrementCount('MC-GTV-227')}><i className="fal fa-minus-circle fa-2x"></i></div>
                  </div>
                  <div className="flex-fill text-black-50"><h2>{this.state.readyQty}</h2></div>
                  <div className="flex-fill">
                    <div className='text-success hand' onClick={() => this.incrementCount('MC-GTV-227')}><i className="fal fa-plus-circle fa-2x"></i></div>


                  </div>

                </div>



                {this.state.showCheckOut ?  <NavButton type="button" className="btn btn-lg btn-sm btn-outline-info" link="/cart">
                  <i className="fal fa-shopping-cart"></i> Review Order</NavButton> : <div></div>}

              </div>
            </div>
            <div className="card mb-4 shadow-lg">
              <div className="card-header" style={{backgroundColor: '#a7a9ac'}}>
                <h4 className="my-2  font-weight-normal">RELATE</h4>

              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$25 <small className="text-muted"></small></h1>


                <div className="pricing-card-feature-section text-left mt-3 mb-4">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item"><i className="fal fa-check text-primary"></i> <span>1 RELATE Assessment</span></li>
                    <li className="list-group-item">Perfect for someone in a committed relationship<br/><br/><br/></li>
                  </ul>
                </div>

                <div className="d-flex justify-content-around align-items-center mb-3">
                  <div className="flex-fill">
                    <div className='text-danger hand' onClick={() => this.decrementCount('MC-OTN-802')}><i className="fal fa-minus-circle fa-2x"></i></div>
                  </div>
                  <div className="flex-fill text-black-50"><h2>{this.state.relateQty}</h2></div>
                  <div className="flex-fill">
                    <div className='text-success hand' onClick={() => this.incrementCount('MC-OTN-802')}><i className="fal fa-plus-circle fa-2x"></i></div>
                  </div>

                </div>



                {this.state.showCheckOut ?  <NavButton type="button" className="btn btn-lg btn-sm btn-outline-info" link="/cart">
                  <i className="fal fa-shopping-cart"></i> Review Order</NavButton> : <div></div>}

                {/*<button type="button" className="btn btn-lg btn-outline-success btn-sm" disabled={this.state.relateQty === 0 || this.state.relateQty.length === 0}*/}
                {/*        onClick={() => this.addToCart('MC-OTN-802', this.state.relateQty)}>*/}
                {/*  {this.state.relateTouched ?  <><i className="fal fa-edit"></i> Update Cart</> : <><i className="fal fa-plus"></i> Add To Cart</>}*/}
                {/*</button>*/}
              </div>
            </div>


            <div className="card mb-4 shadow-lg">
              {/*<div className="ribbon ribbon-top-right"><span>Best Value</span></div>*/}
              <div className="card-header" style={{backgroundColor: '#4374a2'}}>
                <h4 className="my-n1 font-weight-normal">COUPLES PACKAGE</h4>
                <small style={{fontWeight: 'bold', color: 'white'}}>BEST VALUE</small>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$40 <small className="text-muted"></small></h1>
                {/*<ul className="list-unstyled mt-3 mb-4">*/}
                {/*  <li>2 Relate Assessments</li>*/}
                {/*</ul>*/}
                <div className="pricing-card-feature-section text-left mt-3 mb-4">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item"><i className="fal fa-check text-primary"></i> 2 RELATE Assessments</li>
                    <li className="list-group-item">2 Relate assessments at a discounted price. 1 for you, and 1 to send to your partner.</li>
                  </ul>
                </div>


                <div className="d-flex justify-content-around align-items-center mb-3">
                  <div className="flex-fill">
                    <div className='text-danger hand' onClick={() => this.decrementCount('MC-OTN-141')}><i className="fal fa-minus-circle fa-2x"></i></div>
                  </div>
                  <div className="flex-fill text-black-50"><h2>{this.state.relateCoupleQty}</h2></div>
                  <div className="flex-fill">
                    <div className='text-success hand disable-select' onClick={() => this.incrementCount('MC-OTN-141')}><i className="fal fa-plus-circle fa-2x"></i></div>
                    {/*<button type="button" className="btn btn-outline-success btn-sm"*/}
                    {/*        onClick={() => this.incrementCount('MC-OTN-141')}>*/}
                    {/*  <i className="fal fa-plus-circle fa-2x"></i>*/}
                    {/*</button>*/}
                  </div>
                  {/*<div className="flex-fill">*/}
                  {/*  <button type="button" className="btn btn-outline-danger btn-sm"  disabled={this.state.readyQty === 0 || this.state.readyQty.length === 0}*/}
                  {/*          onClick={() => this.removeItem('MC-GTV-227')}>*/}
                  {/*    <i className="fal fa-trash"></i>*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                </div>

                {/*<div className="d-flex justify-content-around align-items-center mb-3">*/}
                {/*  <div className="flex-fill">*/}
                {/*    Qty:*/}
                {/*  </div>*/}
                {/*  <div className="flex-fill">*/}
                {/*    <input className="form-control" id="relateCouple" name='relateCoupleQty' type='number' min="1" max="99"*/}
                {/*           value={this.state.relateCoupleQty}*/}
                {/*           onChange={(event) => this.changeQty(event)}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <div className="flex-fill">*/}
                {/*    <button type="button" className="btn btn-outline-danger btn-sm" disabled={this.state.relateCoupleQty === 0 || this.state.relateCoupleQty.length === 0}*/}
                {/*            onClick={() => this.removeItem('MC-OTN-141')}>*/}
                {/*      <i className="fal fa-trash"></i>*/}
                {/*    </button>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="form-group row">*/}
                {/*  <label htmlFor="ready" className="col-sm-5 col-form-label">Qty:</label>*/}
                {/*  <div className="col-sm-6 center-block">*/}
                {/*    <input className="form-control" id="relateCouple" name='relateCoupleQty' type='number' min="0" max="99"*/}
                {/*           value={this.state.relateCoupleQty}*/}
                {/*           onChange={(event) => this.changeQty(event)}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}


                {this.state.showCheckOut ?  <NavButton type="button" className="btn btn-lg btn-sm btn-outline-info" link="/cart">
                  <i className="fal fa-shopping-cart"></i> Review Order</NavButton> : <div></div>}

                {/*<button type="button" className="btn btn-lg btn-outline-success btn-sm" disabled={this.state.relateCoupleQty === 0 || this.state.relateCoupleQty.length === 0}*/}
                {/*        onClick={() => this.addToCart('MC-OTN-141', this.state.relateCoupleQty)}>*/}
                {/*  {this.state.relateCoupleTouched ?  <><i className="fal fa-edit"></i> Update Cart</> : <><i className="fal fa-plus"></i> Add To Cart</>}*/}
                {/*</button>*/}
              </div>
            </div>
          </div>


          {/*<div className="row">*/}
          {/*  <div className="col-lg-12">*/}
          {/*    {this.state.showCheckOut ?  <NavButton className="btn btn-outline-success float-right" link="/cart">*/}
          {/*      <i className="fal fa-shopping-cart"></i> Review Order and Checkout</NavButton> : <div></div>}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </>
    )
  }
}

const mapReduxStateToProps = (reduxState) => {
  return {
    cartContents: reduxState.checkOut.cart.cartContents,
    cartQty: reduxState.checkOut.cart.totalQty,
    isAuthenticated: reduxState.oldReducer.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementCounter: () => dispatch({type: 'INCREMENT'}),
    // onAddToCart: (cart) => dispatch({type:'ADD_TO_CART', cart: cart}),
    onAddToCart: (cart) => dispatch(actionCreators.addToCart(cart)),
    onUpdateCart: (cart) => dispatch(actionCreators.updateCart(cart)),
    onRemoveFromCart: (sku) => dispatch(actionCreators.removeFromCart(sku)),
    // onSetTotalQty: (totalQty) => dispatch(actionCreators.setTotalQty(totalQty)),

    //onUpdateCart: (cart) => dispatch({type:'UPDATE_CART', cart: cart})
  };
};

export default connect(mapReduxStateToProps, mapDispatchToProps) (Pricing);
