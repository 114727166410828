import React, {Component} from 'react';
import {checkValidity, updateObject} from "../layout/utility";
import axios from "../axios/axios-custom";
import {Link} from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import NavButton from "../layout/navbar/navigationItems/NavButton";

class CreateCounselorAccount extends Component {

    state = {

        registrationForm: {
            firstName: {
                value: '',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 1,
                    maxLength: 30
                },
            },
            lastName: {
                value: '',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 1,
                    maxLength: 30
                },
            },
            email: {
                value: '',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 50,
                    isEmail: true,
                },
            },
            password: {
                value: Math.random().toString(36).substring(2) +  Math.random().toString(36).substring(2, 6),
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 8,
                    maxLength: 30,
                },
            },
            assessment: {
                value: 'relate',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                },
            },
            asmtCount: {
                value: '2',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 1,
                    maxLength: 30
                },
            },
        },
        formIsValid: false,
        invalidClass: 'form-control is-invalid',
        validClass: 'form-control',
        errorDetails: '',
        showSpinner: false,
        relate: true,
        ready: false,
        assessment: '',
        created: false
    };

    handleUserInput = (event) => {

        const name = event.target.name;
        const value = event.target.value;

        const updatedRegistrationForm = {
            ...this.state.registrationForm
        };

        const updatedFormElement = {
            ...updatedRegistrationForm[name]
        };

        updatedFormElement.value = value;

        updatedFormElement.valid = true;

        updatedFormElement.touched = true;
        updatedRegistrationForm[name] = updatedFormElement;
        this.setState({registrationForm: updatedRegistrationForm});

    };

    validateElementInput = (event) => {

        event.preventDefault();

        const inputIdentifier = event.target.name;

        if (!this.state.registrationForm[inputIdentifier].validation) {

            return true;

        }

        const updatedFormElement = updateObject(this.state.registrationForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.registrationForm[inputIdentifier].validation),
            touched: true
        });

        const updatedRegistrationForm = updateObject(this.state.registrationForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedRegistrationForm) {
            formIsValid = updatedRegistrationForm[inputIdentifier].valid && formIsValid;
        }

        this.setState({registrationForm: updatedRegistrationForm, formIsValid: formIsValid});

    };


    handleSubmit = async (ev) => {

        ev.preventDefault();

        if(this.state.registrationForm.password.value.trim().length >= 8) {

            this.setState({showSpinner: true});


            const registrationData = {
                firstName: this.state.registrationForm.firstName.value.trim(),
                lastName: this.state.registrationForm.lastName.value.trim(),
                email: this.state.registrationForm.email.value.trim(),
                password: this.state.registrationForm.password.value.trim(),
                assessmentType: this.state.registrationForm.assessment.value.trim(),
                qty: this.state.registrationForm.asmtCount.value.trim()
            };


            let url = `${process.env.REACT_APP_API_URL}/api/admin/account/counselor`;

            try {
                let response = await axios.post(url, registrationData, {withCredentials: true});

                this.setState({showSpinner: false, created: true});

                //window.location.reload(false);

            } catch (error) {

                this.setState({showSpinner: false});

                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail});

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }


            }

        }
    };

    handleToggle = (e) => {

        const updatedRegistrationForm= {
            ...this.state.registrationForm
        };

        if(e.target.value.trim() === 'relate') {
            updatedRegistrationForm.assessment.value = 'relate';
        } else {
            updatedRegistrationForm.assessment.value = 'ready';
        }

        this.setState({relate: !this.state.relate, registrationForm: updatedRegistrationForm});

    };

    render() {

        let errorDiv = <div className="alert alert-danger text-center" role="alert">
            <i className="fal fa-exclamation-triangle"/> {this.state.errorDetails}
        </div>;

        let counselorDetails = <div className="alert alert-warning text-center" role="alert">
            <h4><i className="fal fa-award" /> A new counselor account was successfully created</h4>
            <div className="text-left">
                <ul>
                    <li>{this.state.registrationForm.firstName.value}</li>
                    <li>{this.state.registrationForm.lastName.value}</li>
                    <li>{this.state.registrationForm.email.value}</li>
                    <li>{this.state.registrationForm.password.value}</li>
                </ul>
                <button className="btn  btn-outline-success" onClick={() => window.location.reload(false)}><i className="fal fa-eraser"></i> Add Another</button>

            </div>
        </div>;


        let signUpForm = (

            <>
                {this.state.errorDetails && errorDiv}
                {this.state.created && counselorDetails}

                {(!this.state.showSpinner && !this.state.created) && <form className="form-signin"  onSubmit={this.handleSubmit}>
                    <div className="col-md-12 order-md-1">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="firstName">First name</label>
                                <input type="text" className={this.state.registrationForm.firstName.valid ? this.state.validClass : this.state.invalidClass}
                                       id="firstName" name="firstName"
                                       ref={(input) => { this.nameInput = input; }}
                                       value={this.state.registrationForm.firstName.value}
                                       onChange={(event) => this.handleUserInput(event)}
                                       onBlur={(event) => this.validateElementInput(event)}
                                />
                                <div className="invalid-feedback">
                                    Valid first name is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="lastName">Last name</label>
                                <input type="text" className={this.state.registrationForm.lastName.valid ? this.state.validClass : this.state.invalidClass}
                                       id="lastName" name="lastName"
                                       value={this.state.registrationForm.lastName.value}
                                       onChange={(event) => this.handleUserInput(event)}
                                       onBlur={(event) => this.validateElementInput(event)}
                                />
                                <div className="invalid-feedback">
                                    Valid last name is required.
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email">Email</label>
                            <input type="email" className={this.state.registrationForm.email.valid ? this.state.validClass : this.state.invalidClass}
                                   id="email" name="email" value={this.state.registrationForm.email.value} placeholder="you@example.com"
                                   onChange={(event) => this.handleUserInput(event)}
                                   onBlur={(event) => this.validateElementInput(event)}
                            />
                            <div className="invalid-feedback">
                                Please enter a valid email address.
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="password">Password</label>
                            <input type="text" className={this.state.registrationForm.password.valid ? this.state.validClass : this.state.invalidClass}
                                   id="password" name="password"
                                   value={this.state.registrationForm.password.value}
                                   onChange={(event) => this.handleUserInput(event)}
                                   onBlur={(event) => this.validateElementInput(event)}/>
                            <div className="invalid-feedback">
                                Password must be at least 8 characters long
                            </div>
                        </div>

                        <div className="mb-2">
                            <div className="mb-3">Assessment Type</div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="relate" className="custom-control-input" value="relate" onChange={(v) => this.handleToggle(v)} checked={this.state.relate}/>
                                <label className="custom-control-label" htmlFor="relate">Relate
                                </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="ready" className="custom-control-input" value="ready" onChange={(v) => this.handleToggle(v)} checked={!this.state.relate}/>
                                <label className="custom-control-label" htmlFor="ready">Ready</label>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="asmtCount">Number of Assessments</label>
                            <input type="text" className={this.state.registrationForm.asmtCount.valid ? this.state.validClass : this.state.invalidClass}
                                   id="asmtCount" name="asmtCount" value={this.state.registrationForm.asmtCount.value}
                                   onChange={(event) => this.handleUserInput(event)}
                                   onBlur={(event) => this.validateElementInput(event)}
                            />
                        </div>

                    </div>
                    <hr className="mb-4"/>
                    <button className="btn btn-outline-success btn-block" type="submit"><i className="fal fa-smile-plus" /> Create Counselor Account</button>
                </form> }

                {this.state.showSpinner && <Spinner />}



            </>

        );


        return (
            <div>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Create Counselor Account</h5>
                </div>

                {signUpForm}
                
            </div>
        );
    }
}

export default CreateCounselorAccount;