import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from '../../constants/Colors'

class ReadyAttachment extends Component {

    componentDidMount() {
    }

    render() {

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="fa-stack" style={{color: "#c3b2b5"}}>
                            <i className="fal fa-comment fa-stack-2x"></i>
                            <i className="fal fa-heart fa-stack-1x"></i>
                        </div>
                        <div>
                            EFFECTIVE COMMUNICATION
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='cfht' percent={this.props.overallEffComm.primarySelf[0]}
                                    color={this.props.overallEffComm.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1">
                        {this.props.overallEffComm.primarySelf[1] === Color.CHALLENGE &&
                        <ChallengeLegendDetail id="cghn">
                            Scoring in the red means that you are very anxious about your relationships. Your behavior
                            in relationships is often fueled by fear.
                            Instead of trust, you often look for threats and find them, even when they may not exist.
                            You like to keep others close, or
                            in constant contact when apart, and are likely to experience jealousy when a partner pays
                            attention to others.
                            To potential partners, you may come across as controlling and overbearing, which can create
                            real threats to relationships.
                            Overcoming severe anxiety requires a conscious effort to replace negative memories with
                            positive relationship experiences in
                            the present that contradict those memories, and this can be very difficult to do without
                            professional counseling.
                        </ChallengeLegendDetail>
                        }

                        {this.props.overallEffComm.primarySelf[1] === Color.IMPROVE &&
                        <ImproveLegendDetail id="cjka">
                            Scoring in the red means that you are very anxious about your relationships. Your behavior
                            in relationships is often fueled by fear.
                            Instead of trust, you often look for threats and find them, even when they may not exist.
                            You like to keep others close, or
                            in constant contact when apart, and are likely to experience jealousy when a partner pays
                            attention to others.
                            To potential partners, you may come across as controlling and overbearing, which can create
                            real threats to relationships.
                            Overcoming severe anxiety requires a conscious effort to replace negative memories with
                            positive relationship experiences in
                            the present that contradict those memories, and this can be very difficult to do without
                            professional counseling.
                        </ImproveLegendDetail>
                        }

                        {this.props.overallEffComm.primarySelf[1] === Color.STRENGTH &&
                        <StrengthLegendDetail id="clle">
                            Scoring in the red means that you are very anxious about your relationships. Your behavior
                            in relationships is often fueled by fear.
                            Instead of trust, you often look for threats and find them, even when they may not exist.
                            You like to keep others close, or
                            in constant contact when apart, and are likely to experience jealousy when a partner pays
                            attention to others.
                            To potential partners, you may come across as controlling and overbearing, which can create
                            real threats to relationships.
                            Overcoming severe anxiety requires a conscious effort to replace negative memories with
                            positive relationship experiences in
                            the present that contradict those memories, and this can be very difficult to do without
                            professional counseling.
                        </StrengthLegendDetail>
                        }
                    </div>
                </div>

                <div className="mt-2 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    It’s no secret that how we communicate plays a significant role in the success of our relationships.
                    However, research suggests that just knowing good communication skills is not enough to make us
                    competent communicators.
                    Communicating with a motive to maintain connection with others may be as equally important when
                    striving for clear communication
                    as the skills we use. In this section, we have given you an overall communication competency score,
                    determined by your
                    score in two important aspects of communication: effective communication and conflict resolution.
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>EMPATHY</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='cmww' percent={this.props.empathy.primarySelf[0]}
                                    color={this.props.empathy.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Empathy is often understood as being able to understand someone else’s point of view. In
                        communication, we also like to think of
                        empathy as the desire to connect with the other person. This requires listening both to the
                        words being spoken as well as the emotions
                        behind the words. A high score in this scale suggests you are generally able to understand the
                        inner thoughts and feelings of others.

                    </div>
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>LOVE</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                                <DonutChart elId='crrv' percent={this.props.love.primarySelf[0]}
                                    color={this.props.love.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        Love is important for communication because it represents a desire to connect with another.
                        When we have a desire to connect, we are more likely to take the time needed to truly listen and
                        seek understanding,
                        as well as a desire to speak and share our feelings. Notice, love is not a skill, but a
                        motivation to communicate clearly.
                        A high score in this scale means you tend to value connection with others.
                    </div>
                </div>

                <div className="page-break" />
                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>CLEAR SENDING</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='cvtd' percent={this.props.clearSending.primarySelf[0]}
                                    color={this.props.clearSending.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        Think about a time when you had trouble understanding someone else. Was it because they were
                        speaking in a different language? Not speaking loud enough or slow enough? Chances are, you
                        missed something important or thought they were talking about something completely different.
                        Clear sending in relationships refers to how well we use words and body language to make sure
                        our partner understands our inner thoughts and feelings. A high score in this scale indicates
                        you are able to “speak the same language” as others.
                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>SOOTHING</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-5">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='dili' percent={this.props.sooth.primarySelf[0]}
                                    color={this.props.sooth.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        Another part of effective communication is the ability to control our emotions. It is difficult
                        to clearly express our feelings when our feelings become overwhelming. Soothing refers to the
                        ability to calm ourselves and others down when emotions are high. This requires both recognition
                        of the emotions and tactics for regaining control. A high score in this section suggests you
                        have good control over your emotions and can help others stay or get calm.
                    </div>
                </div>


            </>

        )

    }

}

export default ReadyAttachment;