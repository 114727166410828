import React, {Component} from 'react';
import Spinner from "../Spinner/Spinner";
import axios from "../axios/axios-custom";

class AdminCoupons extends Component {

    state = {
        errorDetails: '',
        coupons: []
    }

    componentDidMount() {

        this.loadCoupons();

    }

    loadCoupons = async () => {

        let url = `${process.env.REACT_APP_API_URL}/api/admin/coupons`;

        let response = await axios.get(url, {withCredentials: true});

        this.setState({showSpinner: false, coupons: response.data});

    };

    populateCoupons = () => {

        return this.state.coupons.map((item, idx) => {

            console.log(item);

            return <tr key={idx}>
                <td>{item.couponCode}</td>
                <td>{item.validStartDate}</td>
                <td>{item.discountAmount}</td>
                <td>{item.discountPercentage}</td>
                <td>{item.maxRedemptions}</td>
                <td>{item.active ? 'True' : 'False'}</td>
                <td>{item.timesRedeemed}</td>
            </tr>;

        });

    };



    render() {

        return (
            <div>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Coupons</h5>

                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Coupon</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">Discount Amount</th>
                            <th scope="col">Discount Percentage</th>
                            <th scope="col">Max Redemptions</th>
                            <th scope="col">Active</th>
                            <th scope="col">Times Redeemed</th>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                        {this.populateCoupons()}
                    </table>
                </div>
            </div>


        );
    }
}

export default AdminCoupons;