import React from 'react';

const loadingIcon = ( props ) => {

  let spinningIcon = props.show ? <i className="fas fa-spinner fa-spin"></i> : null;



  return (
     <>{spinningIcon}</>
  );
};

export default loadingIcon;