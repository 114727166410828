import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class RelationalAggression extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }


    render() {


        return (

            <>
                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-exclamation-circle fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            RELATIONAL AGGRESSION
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                {this.props.windowSize.windowWidth > 710 &&

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td align="center"><DonutChart elId='aggr1'
                                                           percent={this.props.relAggression.primarySelf[0]}
                                                           color={this.props.relAggression.primarySelf[1]}/>
                            </td>
                            <td className="border-right" align="center"><DonutChart elId='aggr2'
                                                                                    percent={this.props.relAggression.partnerPrimary[0]}
                                                                                    color={this.props.relAggression.partnerPrimary[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='aggr3'
                                                           percent={this.props.relAggression.primaryPartner[0]}
                                                           color={this.props.relAggression.primaryPartner[1]}/></td>
                            {/*<td align="center"><DonutChart elId='kind4' percent='0' color='#eee' /></td>*/}
                            <td align="center"><DonutChart elId='aggr4'
                                                           percent={this.props.relAggression.partnerSelf[0] !== '' ? this.props.relAggression.partnerSelf[0] : '0'}
                                                           color={this.props.relAggression.partnerSelf[1]}/></td>

                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                            <td className="border-right" align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                            <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                            <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                            {/*NO PARTNER <br/>ANSWER RECORDED*/}
                        </tr>
                        </tbody>
                    </table>
                </div>

                }

                {this.props.windowSize.windowWidth <= 710 &&
                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <td align="center"><DonutChart elId='aggr1' percent={this.props.relAggression.primarySelf[0]}
                                                       color={this.props.relAggression.primarySelf[1]}/>
                        </td>
                        <td align="center"><DonutChart elId='aggr2' percent={this.props.relAggression.partnerPrimary[0]}
                                                       color={this.props.relAggression.partnerPrimary[1]}/>
                        </td>
                    </tr>
                    <tr className="border-bottom" style={{fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                        <td align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                    </tr>

                    <tr>
                        <td align="center">
                            <DonutChart elId='aggr3' percent={this.props.relAggression.primaryPartner[0]}
                                        color={this.props.relAggression.primaryPartner[1]}/>
                        </td>
                        <td align="center">
                            <DonutChart elId='aggr4'
                                        percent={this.props.relAggression.partnerSelf[0] !== '' ? this.props.relAggression.partnerSelf[0] : '0'}
                                        color={this.props.relAggression.partnerSelf[1]}/>
                        </td>
                    </tr>
                    <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                        <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                    </tr>
                    </tbody>
                </table>
                }


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    The <b>Relational Aggression</b> scale measures your use of social sabotage strategies (i.e.,
                    gossip, spreading
                    rumors, getting people to take your side against others) and love withdrawal strategies (i.e.,
                    pouting, silent
                    treatment, withholding affection) when trying to resolve conflict. Many people have used, or seen
                    others use,
                    tactics or strategies of manipulation in order to gain power or some other valued resources within
                    the
                    relationship.
                </div>

                <StrengthLegendDetail id="agr-strength">
                    If you scored in this area it means that during conflicts you don’t try to manipulate others by
                    harming their relationships with you or other people.
                    You also avoid withdrawing from others to display your displeasure with people you are upset with.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="agr-needs-impr">
                    If you scored in the Needs Improvement area on this scale it means that you sometimes use relational
                    aggression strategies during conflicts.
                    Even occasionally being relationally aggressive can harm relationships.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="agr-challenge">
                    People who have relational aggression scores in this area are much more likely to struggle to form
                    and stay in a lasting relationship.
                    High levels of social sabotage and love withdrawal are usually indicative of serious relationship
                    problems that are not likely to be resolved on their own.
                </ChallengeLegendDetail>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            <p>Research shows that relationally aggressive tactics are present in most marriages, at
                                least to some degree; and in some marriages these tactics are utilized quite frequently.
                                Both social sabotage and love withdrawal patterns have been found to be linked to lower
                                satisfaction and higher levels of break-up in couple relationships.</p>
                            <p>
                                It is common for partners to have some grievances with each other through the daily
                                process of living and, although they may not deal with these grievances with overt
                                fighting, they nevertheless want their partner to pay for infractions in some way. By
                                withholding love, partners can make each other ‘suffer’ or ‘take notice,’ and in some
                                way restore the balance or ledger in the relationship. It may be that in small amounts,
                                these manipulative behaviors have little effect on relationships, but that the more
                                frequent use of these strategies leads to poorer relationship quality and eventually
                                increased likelihood of breakup or divorce.
                            </p>
                        </div>
                    </div>
                </div>

                <button className="btn btn-outline-dark no-print mt-3" type="button" data-toggle="collapse"
                        data-target="#ragr"
                        aria-expanded="false" aria-controls="emotion">
                    Show Section Questions
                </button>

                <div className="collapse" id="ragr">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-exclamation-circle"/> Relational Aggression
                            </h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th className="align-middle">How often have you and your partner been engaged in the
                                        following behaviors in your relationship IN THE LAST YEAR?
                                    </th>
                                </tr>
                                <tr>
                                    <td className="align-middle">I have intentionally ignored my partner until he/she
                                        gives in to my way about something.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I have withheld physical affection from my partner when
                                        I was angry with him/her.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I have spread rumors or negative information about my
                                        partner to be mean.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I have gone 'behind my partner's back' and shared
                                        private information about him/her with other people.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I have given my partner the silent treatment or 'cold
                                        shoulder' when he/she has hurt my feelings or made me angry in some way.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">When I have been mad at my partner, I have recruited
                                        other people to 'take sides' with me and get
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I have threatened to end my relationship with my
                                        romantic partner in order to get him/her to do what I wanted
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">My partner has intentionally ignored me until I give in
                                        to his/her way about something.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">My partner has withheld physical affection from me when
                                        he/she was angry with me.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">My partner has spread rumors or negative information
                                        about me to be mean.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">My partner has gone 'behind my back' and shared private
                                        information about me with other people.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">My partner has given me the silent treatment or 'cold
                                        shoulder' when I have hurt his/her feelings or made him/her angry in some way.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">When I have been mad at my partner, I have recruited
                                        other people to 'take sides' with me and get them upset with him/her too.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">My partner has threatened to end our relationship in
                                        order to get me to do what he/she wanted.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>Have I tried to get something from my partner by the use of any means other than clear
                                verbal communication?
                            </li>
                            <li>Do I try and make my partner "pay" or "suffer" for what he or she does or doesn't do for
                                me?
                            </li>
                            <li>When I feel negatively towards my partner, do I communicate my feelings through clear
                                words? (Such as, when you do this... it makes me feel this way…)
                            </li>
                            <li>Do I withhold love or affection from my partner and/or others when I feel offended?</li>
                            <li>Do I ever use words or feelings of my partner, given in confidence, against them in a
                                disagreement or argument?
                            </li>

                        </ul>
                    </div>
                </div>

            </>

        )

    }

}


export default withWindowSizeListener(RelationalAggression);