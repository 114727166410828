import React, {Component} from 'react';

class RelationshipOutlook extends Component {

    componentDidMount() {
    }

    render() {

        let pragmatist = false;
        let realist = false;
        let idealist = false;

        let soulSeeker = false;
        let creator = false;
        let chooser = false;

        if(this.props.romanticism.primarySelf[0] <= 33) {
            pragmatist = true;
        } else if (this.props.romanticism.primarySelf[0] > 33 && this.props.romanticism.primarySelf[0] <= 66) {
            realist = true;
        } else {
            idealist = true;
        }

        if(this.props.searchLove.primarySelf[0] <= 33) {
            soulSeeker = true;
        } else if (this.props.searchLove.primarySelf[0] > 33 && this.props.searchLove.primarySelf[0] <= 66) {
            creator = true;
        } else {
            chooser = true;
        }





        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-rings-wedding fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            RELATIONSHIP OUTLOOK
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    <p>
                        <strong>Research suggests that the way we date and search for a potential relationship partner depends in part on the beliefs we hold about relationships.</strong>
                        While no belief is necessarily good or bad, they still have the potential to either limit or strengthen our relationships and search for love.
                        In this section we have assessed your beliefs regarding relationships and marriage and how they might impact your future relationship and marital success.
                        Based on your answers to assessment questions, we provide some feedback on how certain romantic beliefs may influence your dating and relationships, with the
                        primary goal being to help you identify how some beliefs may be holding you back from the type of relationships you want while other beliefs may be point you
                        in the direction of healthy relationship outcomes.
                    </p>
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>ROMANTIC BELIEFS</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    Our beliefs about romance and falling in love play a significant role in how we search for love and what sorts of
                    efforts we put into maintaining it. The primary goal of this section is to provide some feedback on the
                    challenges you may face in finding a potential dating or marriage partner based on your romantic beliefs.
                </div>

                <div style={{borderBottom: '1px #eee solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.1em', color: '#4374a3'}} className="mt-4">
                    <b>ROMANTICISM: <span className='text-black-70'>{pragmatist && 'Pragmatist'} {idealist && 'Idealist'} {realist && 'Realist'}</span></b>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    Romanticism is a term that reflects how much we believe romantic love contributes to the success of a
                    relationship. While most of us would consider a broad sense of “love” to be important for relationship success, romantic love
                    specifically refers to love characterized by strong passion, obsession, and attraction. While romance can contribute to feelings
                    of happiness in relationships, some of us may make romantic love too important while others reject it completely. Research
                    has found that either extreme can make relationships difficult by creating unrealistic expectations or unmet needs. In this
                    section we have assessed your level of romanticism and provided some feedback on what it means for your future
                    relationships.
                </div>

                <div className="d-flex justify-content-around align-items-start flex-custom-column">
                    <div className="p-2 flex-fill align-items-start text-center">
                        {idealist && <img src={`/images/idealist.png`}  height='211' alt="Idealist" />}
                        {!idealist && <img src={`/images/idealist-gray.png`}  height='211' alt="Idealist" />}
                        <div className={'romanticism ' + (idealist ? 'romanticism-gray font-weight-bolder ' : 'text-muted') +  ' pl-4 pr-4 pb-4 text-justify'}>
                            <span className='text-custom-blue'>IDEALIST:</span> A high score in this section suggests you emphasize romance as a highly important part of relationships.
                            You envision a relationship characterized by high passion, intense loyalty, and pure bliss.  You trust love to make everything
                            in your relationship right.  Love can be a powerful motivator to make whatever efforts are required to make a relationship work,
                            but is it enough?  Research shows that putting too much emphasis on romantic love leads to unrealistic expectations for ourselves
                            and our partners.  Such expectations can often lead us to delay or reject a relationship with another person simply because they
                            are not “romantic” enough.  One of the greatest dangers of high romanticism is becoming blind to other important relationship factors,
                            such as ignoring abuse or a lack of attention to financial insecurity.
                        </div>
                    </div>
                    <div className="p-2 flex-fill align-items-start text-center">
                        {realist && <img src={`/images/realist.png`} height='211'  alt="Realist" /> }
                        {!realist && <img src={`/images/realist-gray.png`} height='211' alt="Realist" /> }
                        <div className={'romanticism ' + (realist ? 'romanticism-gray font-weight-bolder ' : 'text-muted') +  ' pl-4 pr-4 pb-4 text-justify'}>
                            <span className='text-custom-blue'>REALIST:</span> If you scored in this section you appreciate the feeling of love in relationships, but also keep a straight head when you meet new people or potential partners.
                            Your ideal relationship is characterized by love, but a love that emphasizes togetherness, trust, and security over passion and idealization.
                            This does not mean you do not enjoy a romantic evening, but you value working together to resolve issues just as much.
                            This is a good foundation for relationships as it promotes loyalty and desire balanced with a sense of reality and purpose.
                        </div>
                    </div>
                    <div className="p-2 flex-fill align-items-start text-center">
                        {pragmatist && <img src={`/images/pragmatist.png`}  height='211' alt="Pragmatist" />}
                        {!pragmatist && <img src={`/images/pragmatist-gray.png`}  height='211' alt="Pragmatist" />}
                        <div className={'romanticism ' + (pragmatist ? 'romanticism-gray font-weight-bolder ' : 'text-muted') +  ' pl-4 pr-4 pb-4 text-justify'}>
                            <span className='text-custom-blue'>PRAGMATIST:</span> You take a more practical approach to relationships.  Love comes from a sense of equality and similarity in goals
                            and values more than a sense of attraction or passion.  While these characteristics may exist, you do not tend to put a lot of value on them or see them as
                            necessary for a successful relationship.  While such an approach to love can help you make good decisions about potential partners, you may also miss out on
                            opportunities by being too calculating or too cautious.  Make sure you remain flexible and give relationships a chance to prove themselves.
                        </div>
                    </div>
                </div>

                <div className="page-break" />

                <div style={{borderBottom: '1px #eee solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.1em', color: '#4374a3'}} className="mt-4">
                    <b>SEARCH FOR LOVE: <span className='text-black-70'>{soulSeeker && 'Soul Seeker'} {creator && 'Creator'} {chooser && 'Chooser'}</span></b>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    When it comes to finding a
                    relationship partner, we all have certain beliefs we use to help us decide if we should pursue a relationship with someone.
                    One of the most common beliefs deals with how much of a role our own choice plays in selecting a mate.  On one side are those
                    who believe that their future spouse has been determined by fate and they just need to find that person, while others believe
                    that the choice is strictly ours and we just need to find someone we like and then decide if we want to be with them.  Many of us fall
                    somewhere in between.  In this section we have assessed where you fall on the spectrum and what it means for you in your search for love.
                </div>

                <div className="d-flex justify-content-around align-items-start flex-custom-column">
                    <div className="p-2 flex-fill align-items-start text-center">
                        {soulSeeker && <img src={`/images/soul-seeker.png`}  height='211' alt="Soul Seeker" />}
                        {!soulSeeker && <img src={`/images/soul-seeker-gray.png`}  height='211' alt="Soul Seeker" />}
                        <div className={'romanticism ' + (soulSeeker ? 'romanticism-gray font-weight-bolder ' : 'text-muted') +  ' pl-4 pr-4 pb-4 text-justify'}>
                            <span className='text-custom-blue'>SOUL SEEKER:</span> Your scores indicate that you tend to believe in fate as a major player in your future marriage.
                            You believe that there is a soulmate out there who will make you happy in marriage, and you are just trying to find them.
                            While the thought of someday meeting the “perfect” partner and finding absolute joy in your relationship sounds nice, this belief has some important limitations.
                            The greatest limitation to such a belief is a lack of control in the happiness in relationships.  Instead, if something goes wrong or you don’t feel a "spark" with
                            someone you will reject a relationship on the grounds that it “wasn’t meant to be.”  Remember that successful relationships are built on potential and effort, not perfection.
                        </div>
                    </div>
                    <div className="p-2 flex-fill align-items-start text-center">
                        {creator && <img src={`/images/creator.png`} height='211' alt="Creator" /> }
                        {!creator && <img src={`/images/creator-gray.png`} height='211' alt="Creator" /> }
                        <div className={'romanticism ' + (creator ? 'romanticism-gray font-weight-bolder ' : 'text-muted') +  ' pl-4 pr-4 pb-4 text-justify'}>
                            <span className='text-custom-blue'>CREATOR:</span> Your score indicates that you emphasize your own ability to create a strong relationship with someone.
                            You emphasize a balance between forming relationships with potential and then building on that potential to create the success you want. Sharing some similarities
                            and being attracted to one another has been shown to be an important part of relationships, but beyond these basic compatibilities your own efforts will make
                            the relationship happy.  Those who fall in this category often have the happiest and longest lasting relationships.
                        </div>
                    </div>
                    <div className="p-2 flex-fill align-items-start text-center">
                        {chooser && <img src={`/images/chooser.png`}  height='211' alt="Chooser" />}
                        {!chooser && <img src={`/images/chooser-gray.png`}  height='211' alt="Chooser" />}
                        <div className={'romanticism ' + (chooser ? 'romanticism-gray font-weight-bolder ' : 'text-muted') +  ' pl-4 pr-4 pb-4 text-justify'}>
                            <span className='text-custom-blue'>CHOOSER:</span> Your scores indicate that you tend to believe that success in your future relationships
                            is strictly up to the choices you make.  You believe that you can be happy with many people as long as you just work at it.  Recognizing our own power
                            in making relationships work is very important and encourages real effort to overcome challenges.  However, there are still things outside of your control.
                            There is a certain level of compatibility that makes relationships easier and more fulfilling, so when you are looking for a potential future partner try
                            to find someone who shares some similarities with you.  Some things, like attraction, should happen naturally or they are easy to lose.
                        </div>
                    </div>
                </div>



            </>

        )

    }

}

export default RelationshipOutlook;