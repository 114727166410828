import React from 'react';
import {StripeProvider} from 'react-stripe-elements';
import Checkout from "./Checkout";

const stripe = ( props ) => {
  return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Checkout />
      </StripeProvider>
  );

};

export default stripe;