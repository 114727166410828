import * as actionTypes from '../action/checkOut';

const initialState = {
  isLoading: false,
  cart: {
    originalPrice: 0,
    coupon: '',
    discount: 0,
    totalCost: 0,
    totalQty: 0,
    p: false,
    cartContents: [],
  },
};

initialState.cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {
  originalPrice: 0,
  coupon: '',
  totalCost: 0,
  totalQty: 0,
  discount: 0,
  p: false,
  cartContents: []};

const calculateOriginalPrice = (currentCart) => {

  const totalCost = currentCart.reduce(function(prev, cur) {
    return prev + cur.price * cur.qty;
  }, 0);

  return totalCost;
};

const calculateTotalCost = (originalPrice, discount, discountPercent, discountAmount) => {

  // originalPrice, action.discount, action.percent, action.amount

  if(discountPercent > 0) {
    return originalPrice - originalPrice * (discountPercent / 100);
  } else if (discountAmount > 0) {
    return (originalPrice - discountAmount) > 0 ? (originalPrice - discountAmount) : 0;
    //return originalPrice - originalPrice * (discount / 100);
  } else {
    return originalPrice;
  }


};


const getTotalItems = (currentCart) => {

  return currentCart.reduce(function(prev, cur) {
    return prev + cur.qty;
  }, 0);
};

const initNewCartObject = () => {

  return {
    originalPrice: 0,
    coupon: '',
    totalCost: 0,
    totalQty: 0,
    discount: 0,
    cartContents: []};

};

const reducer = ( state = initialState, action ) => {

  const currentCart = [...state.cart.cartContents];

  let originalPrice = 0;
  let discountPercent = 0;
  let discountAmount = 0;


  switch ( action.type ) {

    case actionTypes.ADD_TO_CART:

      let newCart = {...state.cart};

     // console.log(newCart);

      const newCartContents = newCart.cartContents.concat({productName: action.cart.productName, qty: action.cart.qty, price: action.cart.price, sku: action.cart.sku});

      originalPrice = calculateOriginalPrice(newCartContents);

      newCart = {...state.cart, cartContents: newCartContents, originalPrice: originalPrice,
        totalCost: calculateTotalCost(originalPrice, state.cart.discount ), totalQty: getTotalItems(newCartContents)};
      // originalPrice, discount, discountPercent, discountAmount
      localStorage.setItem('cart', JSON.stringify(newCart));

      return {
        ...state,
        cart: newCart

      };

    case actionTypes.UPDATE_CART:

      const updatedCartContents = state.cart.cartContents.map((item) => (item.sku === action.cart.sku) ?
          {...item, productName: action.cart.productName, qty: action.cart.qty, price: action.cart.price, sku: action.cart.sku} : item);

      originalPrice = calculateOriginalPrice(updatedCartContents);

      discountPercent = 0;
      discountAmount = 0;
      if(state.cart.p) {
        discountPercent = state.cart.discount;
      } else {
        discountAmount = state.cart.discount;
      }


      const updatedCart = {...state.cart, cartContents: updatedCartContents, originalPrice: originalPrice,
        totalCost: calculateTotalCost(originalPrice, state.cart.discount, discountPercent, discountAmount), totalQty: getTotalItems(updatedCartContents)};

     // console.log(updatedCart);

      localStorage.setItem('cart', JSON.stringify(updatedCart));

      return {
        ...state,
        cart: updatedCart
        // results: state.results.concat({id: new Date(), value: state.counter})
      };

    case actionTypes.REMOVE_FROM_CART:

      const remainingCartContents = state.cart.cartContents.filter(item => item.sku !== action.sku).sort();

      originalPrice = calculateOriginalPrice(remainingCartContents);

      discountPercent = 0;
      discountAmount = 0;
      if(state.cart.p) {
        discountPercent = state.cart.discount;
      } else {
        discountAmount = state.cart.discount;
      }

      const cartAfterRemove = {...state.cart, cartContents: remainingCartContents, originalPrice: originalPrice,
        totalCost: calculateTotalCost(originalPrice, state.cart.discount, discountPercent, discountAmount), totalQty: getTotalItems(remainingCartContents)};

      if(remainingCartContents.length === 0) {

        localStorage.removeItem('cart');
        return {
          ...state,
          cart: initNewCartObject(),
          totalQty: 0,
        };

      } else {
        localStorage.setItem('cart', JSON.stringify(cartAfterRemove));
        return {
          ...state,
          cart: cartAfterRemove
        };
      }



    case actionTypes.LOAD_CART:

      return {
        ...state,
        cart: action.cart
      };

    case actionTypes.SET_TOTAL_QTY:

      return {
        ...state,
        totalQty: action.qty
      };


    case actionTypes.APPLY_DISCOUNT:

      if(action.coupon.trim() !== '') {

        originalPrice = calculateOriginalPrice(currentCart);

        const cartWithDiscount = {...state.cart, coupon: action.coupon, discount: action.discount, originalPrice: originalPrice,
          totalCost: calculateTotalCost(originalPrice, action.discount, action.percent, action.amount), p:  action.percent > 0};

        localStorage.setItem('cart', JSON.stringify(cartWithDiscount));

        return {
          ...state,
          cart: cartWithDiscount,
          isLoading: false
        };
      } else {
        return {
          ...state,
        };
      }



    case actionTypes.REMOVE_COUPON:

      originalPrice = calculateOriginalPrice(currentCart);

      const cartWithCouponRemoved = {...state.cart, coupon: '', discount: 0, originalPrice: originalPrice,
        totalCost: calculateTotalCost(originalPrice, 0, 0, 0)};

      localStorage.setItem('cart', JSON.stringify(cartWithCouponRemoved));

      return {
        ...state,
        cart: cartWithCouponRemoved
      };


    case actionTypes.EMPTY_CART:

      initialState.cart = {
        originalPrice: 0,
        coupon: '',
        totalCost: 0,
        totalQty: 0,
        discount: 0,
        cartContents: []};

      localStorage.removeItem('cart');

      return {
        ...state,
        cart: initialState.cart,
      };

    case 'START_LOAD':

      return {
        ...state,
        isLoading: true
      };

    case 'STOP_LOAD':

      return {
        ...state,
        isLoading: false
      };



    default: return state;
  }
};

export default reducer;