import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Main.css'
import 'jquery';
import 'popper.js';
import {StripeProvider} from 'react-stripe-elements';

//bootstrap/dist/css/bootstrap.css
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import orderReducer from './store/reducers/checkOut';
import reducer from './store/reducer';

import App from './App';
import ScrollToTop from './ScrollToTop';
import * as serviceWorker from './serviceWorker';


const logger = store => {
  return next => {
    return action => {
      const result = next(action);
      return result;
    }
  }
};

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// Look into for LIVE

// const composeEnhancers = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
//
// if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
//   if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') {
//     window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function() {};
//   }
// }

const rootReducer = combineReducers({
  oldReducer: reducer,
  checkOut: orderReducer
});

// const store = composeEnhancers === null ? createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk))) : createStore(rootReducer, applyMiddleware(logger, thunk));

//const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));
const store = createStore(rootReducer, applyMiddleware(logger, thunk));

const app = (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
            {/*<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY} >*/}
          <App />
            {/*</StripeProvider>*/}
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
);

// const footer =  <div className="container">
//   <span className="text-muted">Place sticky footer content here.</span>
// </div>;

ReactDOM.render(app, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
