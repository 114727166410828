import React from "react";
import axios from "../axios/axios-custom";
import {NavLink, withRouter} from "react-router-dom";
import NavButton from "../layout/navbar/navigationItems/NavButton";

class Archived extends React.Component {

    initialState = {
        counts: [],
        relate: {
            qty: 0,
            assessmentId: 0,
            productName: ''
        },
        ready: {
            qty: 0,
            assessmentId: 0,
            productName: ''
        },
        showSpinner: false,
        finishedRelate: [],
        finishedReady: [],
        inProgress: [],
        showRelateSpinner: false,
        showReadySpinner: false,
        showInProgress: false,
        disabled: false,
    };

    state = {
        ...this.initialState
    };

    componentDidMount() {

        this.getArchivedAssessments('relate');
        this.getArchivedAssessments('ready');
    }


    getArchivedAssessments = (assessment) => {

        this.setState({showRelateSpinner: true, showReadySpinner: true, finishedRelate: [], finishedReady: [],});

        axios.get(`${process.env.REACT_APP_API_URL}/api/pro/assessments/${assessment}/archived`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {



                if(response.data !== null && response.data.length > 0) {

                    if(assessment == 'relate') {
                        this.setState({
                            finishedRelate: response.data,
                            showRelateSpinner: false
                        });
                    } else {
                        this.setState({
                            finishedReady: response.data,
                            showReadySpinner: false
                        });
                    }
                }

                this.setState({
                    showRelateSpinner: false,  showReadySpinner: false
                });

            })
            .catch(error => {

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail, showSpinner: false, showRelateSpinner: false, showReadySpinner: false});

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

                this.setState({
                    error: true,
                    showSpinner: false,
                    showRelateSpinner: false, showReadySpinner: false
                });

            });

    };


    resetState = () => {

        this.setState({...this.initialState});

    };

    unarchiveAssessment = async (surveyId) => {

        this.setState({disabled: true});

        await axios.post(`${process.env.REACT_APP_API_URL}/api/pro/assessments/unarchive/${surveyId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        this.setState({disabled: false});

        this.getArchivedAssessments('relate');
        this.getArchivedAssessments('ready');

    };


    showArchivedRelateAssessments = () => {

        return this.state.finishedRelate.map((item, idx) => {

            return <tr key={item.extSurveyId}>
                <td scope="row" className="align-middle">{item.firstName} {item.lastName} </td>
                <td scope="row" className="align-middle">
                    {item.nickname}<br/>
                    <NavButton className="btn btn-outline-success btn-sm" link={`/report/${item.extSurveyId}`}>
                        VIEW RESULTS
                    </NavButton>
                </td>
                <td className="align-middle">{item.connectedNickname} <br/>
                    {item.connectedCompletedAt !== null ?
                        <NavButton className="btn btn-outline-success btn-sm" link={`/report/${item.connectedExtSurveyId}`}>
                            VIEW RESULTS
                        </NavButton> : (item.connectedExtSurveyId === null ? '' : <button className="btn btn-sm btn-outline-danger" disabled='disabled'>INCOMPLETE</button>) }
                </td>
                <td scope="row" className="align-middle">

                    <button type="button" title="Archive" className="btn btn-sm btn-outline-info"
                            onClick={() => this.unarchiveAssessment(item.extSurveyId)} disabled={this.state.disabled}>
                        Restore
                    </button>

                        {/*<div className="custom-control custom-checkbox">*/}
                        {/*    <input type="checkbox"*/}
                        {/*           onChange={() => this.unarchiveAssessment(item.extSurveyId)}*/}
                        {/*           disabled={this.state.disabled}*/}
                        {/*           className="custom-control-input" id={"arc1" + idx}/>*/}
                        {/*    <label className="custom-control-label" htmlFor={"arc1" + idx}></label>*/}
                        {/*</div>*/}
                </td>
            </tr>;
        });
    };

    showArchivedReadyAssessments = () => {

        return this.state.finishedReady.map((item, idx) => {

            return <tr key={item.extSurveyId}>
                <td scope="row" className="align-middle">{item.firstName} {item.lastName}</td>
                <td scope="row" className="align-middle">
                    {item.nickname}<br/>

                </td>
                <td className="align-middle">
                    <NavButton className="btn btn-outline-success btn-sm" link={`/report-ready/${item.extSurveyId}`}>
                        VIEW RESULTS
                    </NavButton>
                </td>
                <td scope="row" className="align-middle">

                    <button type="button" title="Archive" className="btn btn-sm btn-outline-info"
                            onClick={() => this.unarchiveAssessment(item.extSurveyId)} disabled={this.state.disabled}>
                        Restore
                    </button>

                        {/*<div className="custom-control custom-checkbox">*/}
                        {/*    <input type="checkbox"*/}
                        {/*           onChange={() => this.unarchiveAssessment(item.extSurveyId)}*/}
                        {/*           disabled={this.state.disabled}*/}
                        {/*           className="custom-control-input" id={"arcr" + idx}/>*/}
                        {/*    <label className="custom-control-label" htmlFor={"arcr" + idx}></label>*/}
                        {/*</div>*/}
                </td>
            </tr>;
        });
    };


    render() {

        let finishedRelateAssessments = this.showArchivedRelateAssessments();
        let finishedReadyAssessments = this.showArchivedReadyAssessments();



        const {elementFontSize} = this.state;
        const spinner = <tr><td><i className="fas fa-spinner fa-spin"></i></td></tr>;
        const spinner2 = <i className="fas fa-spinner fa-spin"></i>;

        let relateClientAccess = <NavButton className="btn btn-block btn-outline-primary"
                                            link={`/pro/relate-access`} disabled={this.state.relate.qty === 0}>
            SEND CLIENT ACCESS
        </NavButton>;

        if(this.state.relate.qty === 0) {
            relateClientAccess = <button type="button" title="Purchase more assessments in order to share codes with your clients"
                                         className="btn btn-block btn-outline-primary" disabled={this.state.relate.qty === 0} >
                SEND CLIENT ACCESS
            </button>
        }

        let readyClientAccess = <NavButton className="btn btn-block btn-outline-success"
                                           link={`/pro/ready-access`} disabled={this.state.ready.qty === 0}>
            SEND CLIENT ACCESS
        </NavButton>;

        if(this.state.ready.qty === 0) {
            readyClientAccess = <button type="button" title="Purchase more assessments in order to share codes with your clients"
                                        className="btn btn-block btn-outline-success" disabled={this.state.ready.qty === 0}>
                SEND CLIENT ACCESS
            </button>
        }




        return (

            <>

                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Archived Assessments</h5>
                </div>

                <div className="mb-4">
                    <NavButton className="btn  btn-outline-success" link="/pro/bulk-orders"><i className="fal fa-cart-plus"/> Purchase More Assessments</NavButton>
                </div>



                <div className="mt-5 mb-5"/>

                <div className="card">
                    <div>
                        <h5 className="card-header text-white bg-danger"><i className="fal fa-user-friends"/> ARCHIVED RELATE ASSESSMENTS</h5>
                    </div>
                    <div className="card-body scroll">
                        <table className="table table-hover text-center">
                            <thead>
                            <tr>
                                <th scope="col" className="align-middle">Client</th>
                                <th scope="col" className="align-middle">Client Results</th>
                                <th scope="col" className="align-middle">Partner Results</th>
                                <th scope="col" className="align-middle">Restore</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.showRelateSpinner ? spinner : finishedRelateAssessments}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-5 mb-5"/>

                <div className="card">
                    <div>
                        <h5 className="card-header text-white bg-danger"><i className="fal fa-user"/> ARCHIVED READY ASSESSMENTS</h5>
                    </div>
                    <div className="card-body scroll">
                        <table className="table table-hover text-center">
                            <thead>
                            <tr>
                                <th scope="col" className="align-middle">Client</th>
                                <th scope="col" className="align-middle">Assessment Nickname</th>
                                <th scope="col" className="align-middle">Results</th>
                                <th scope="col" className="align-middle">Restore</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.showReadySpinner ? spinner : finishedReadyAssessments}
                            </tbody>
                        </table>
                    </div>
                </div>

            </>

        );
    }
}

export default withRouter(Archived);
