import React, { Component } from 'react';
import {checkValidity, updateObject} from "../utility";
import axios from "axios";
import Spinner from '../../Spinner/Spinner';


class RequestPasswordReset extends Component {


  state = {

    resetRequestForm: {
      email: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 5,
          maxLength: 30,
          isEmail: true,
        },
      },
    },
    formIsValid: false,
    invalidClass: 'form-control is-invalid',
    validClass: 'form-control',
    errorDetails: '',
    confirmation: false,
    showSpinner: false,
  };

  handleUserInput = (event) => {
    //event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;

    const updatedResetRequestForm = {
      ...this.state.resetRequestForm
    };

    const updatedFormElement = {
      ...updatedResetRequestForm[name]
    };

    updatedFormElement.value = value;

    updatedFormElement.valid = true;

    updatedFormElement.touched = true;
    updatedResetRequestForm[name] = updatedFormElement;
    this.setState({resetRequestForm: updatedResetRequestForm});

  };

  validateElementInput = (event) => {

    event.preventDefault();

    // const name = event.target.name;
    // const value = event.target.value;

    const inputIdentifier = event.target.name;

    if (!this.state.resetRequestForm[inputIdentifier].validation) {

      return true;

    }

    const updatedFormElement = updateObject(this.state.resetRequestForm[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.resetRequestForm[inputIdentifier].validation),
      touched: true
    });

    const updatedResetRequestForm = updateObject(this.state.resetRequestForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedResetRequestForm) {
      formIsValid = updatedResetRequestForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({resetRequestForm: updatedResetRequestForm, formIsValid: formIsValid});

  };

  handleSubmit = (ev) => {

    ev.preventDefault();

    if(this.state.resetRequestForm.email.value.trim().length >= 5) {

      this.setState({showSpinner: true});

      const resetData = {
        email: this.state.resetRequestForm.email.value.trim(),
      };

      let url = `${process.env.REACT_APP_API_URL}/api/password/reset`;

      axios.post(url, resetData)
      .then(response => {

        const updatedFormElement = updateObject(this.state.resetRequestForm['email'], {
          value: '',
          valid: true,
          touched: false
        });

        const updatedResetRequestForm = updateObject(this.state.resetRequestForm, {
          'email': updatedFormElement
        });

        this.setState({confirmation: true, showSpinner: false, resetRequestForm: updatedResetRequestForm, formIsValid: true});

      })
      .catch(error => {
        this.setState({confirmation: true, showSpinner: false});
      });

    }

  };

  render () {

    let confirmDiv = <div className="alert alert-warning text-center" role="alert">
      If your email exists in our system, you will  receive instructions
      <br/>on how to reset your password.
    </div>;

    return (

        <React.Fragment>

          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Reset Password</h5>

          </div>

          {this.state.showSpinner && <Spinner />}



          <form className="form-signin"  onSubmit={this.handleSubmit}>
            {this.state.confirmation && confirmDiv}
            <div className="col-md-12 order-md-1">
              <div className="mb-3">
                <label htmlFor="email">Please, provide the email you used to register</label>

                <input type="email" className={this.state.resetRequestForm.email.valid ? this.state.validClass : this.state.invalidClass}
                       id="email" name="email" value={this.state.resetRequestForm.email.value} placeholder="you@example.com"
                       onChange={(event) => this.handleUserInput(event)}
                       onBlur={(event) => this.validateElementInput(event)}
                />
                <div className="invalid-feedback">
                  Please enter a valid email address.
                </div>
              </div>
              <hr className="mb-4"/>
              <button className="btn btn-info " type="submit"><i className="fal fa-key"></i> Reset Password</button>
            </div>

          </form>

        </React.Fragment>

    )
  }

}

export default RequestPasswordReset;