import React, {Component} from 'react';
import AvailableAssessmentItem from "./AvailableAssessmentItem";
//import axios from 'axios';
import axios from '../axios/axios-custom.js'
import ActivatedAssessmentItem from "./ActivatedAssessmentItem";
import NavButton from "../layout/navbar/navigationItems/NavButton";
import NicknameModal from "./NicknameModal";
import UpdateNicknameModal from "./UpdateNicknameModal";
import jQuery from "jquery";
import AlertError from "../Error/AlertError";
import FinishedAssessmentItem from "./FinishedAssessmentItem";

class MyAssessment extends Component {

    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component
    _isMounted = false;

    state = {

        availableAssessments: [],
        activatedAssessments: [],
        finishedAssessments: [],
        redemptionCode: '',
        enteredRedemptionCode: '',
        codeError: '',
        loading: false,
        modalVisible: false,
        error: false,
        errorDetails: '',
        currentNickName: '',
        externalAssessmentId: '',
    };

    // CancelToken = axios.CancelToken;
    // source = this.CancelToken.source();


    componentDidMount() {

        this._isMounted = true;

        this.loadAvailableAssessments();
        this.loadActivatedAssessments();
        this.loadFinishedAssessments();

    };

    handleCheckboxChange = () => {
      alert("A");
    };

    componentWillUnmount() {
        this._isMounted = false;
        //this.source.cancel("Operation canceled by the user.");
    };

    loadAvailableAssessments = () => {

        window.scrollTo(0, 0);

        axios.get('/api/survey/available',
            //     {
            //   headers: {
            //     Authorization: 'Bearer ' + localStorage.getItem('token')
            //   }
            // }
            //     {
            //       cancelToken: this.source.token
            //     }

        )
            .then((response) => {

                if (this._isMounted) {

                    this.setState({
                        availableAssessments: response.data,
                    });
                }

            })
            .catch(error => {
            })
        ;

    };

    loadActivatedAssessments = () => {

        window.scrollTo(0, 0);

        axios.get('/api/survey/activated')
            .then((response) => {

                if (this._isMounted) {

                    this.setState({
                        activatedAssessments: response.data,
                    });
                }

            })
            .catch(error => {
            });

    };


    loadFinishedAssessments = () => {

        window.scrollTo(0, 0);

        axios.get('/api/survey/finished')
            .then((response) => {

                if (this._isMounted) {

                    this.setState({
                        finishedAssessments: response.data,
                    });
                }

            })
            .catch(error => {
            });

    };

    handleRedemptionCode = (e) => {

        this.setState({enteredRedemptionCode: e.target.value.toUpperCase(), codeError: ''});
    };

    setRedemptionCode = (redemptionCode) => {

        this.setState({redemptionCode: redemptionCode});

    };


    setCurrentNickname = (currentNickName, externalAssessmentId) => {

        this.setState({currentNickName: currentNickName, externalAssessmentId: externalAssessmentId});

    };

    beginAssessment = (termsAccepted, nickName) => {

        const enteredCode = this.state.redemptionCode.trim();

        if (enteredCode !== '') {

            this.setState({loading: true});

            let termsData = {
                termsAccepted: termsAccepted,
                nickName: nickName
            };

            return axios.post(`${process.env.REACT_APP_API_URL}/api/survey/${enteredCode}`, termsData, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
        }

    };

    saveUpdatedNickname = (nickName) => {

        const externalAssessmentId = this.state.externalAssessmentId.trim();

        if (externalAssessmentId !== '') {

            this.setState({loading: true});

            let nicknameData = {
                nickName: nickName
            };

            return axios.put(`${process.env.REACT_APP_API_URL}/api/survey/nickname/${externalAssessmentId}`, nicknameData, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
        }

    };

    nickNameAssessment = () => {
        //console.log("passing redemption code: ", props.redemptionCode);
        //props.setRedemptionCode(props.redemptionCode);
        // const text = prompt("Give Your Assessment a nickname (ex. Mary's Assessment)");
        //
        jQuery('#nicknameModal').modal({
            backdrop: 'static'
        });

        jQuery('#nicknameModal').on('shown.bs.modal', function () {
            // jQuery('#nickname').trigger('focus');
            // console.log('focusing');
            document.getElementById('nickname').focus();
            //document.getElementById('nickname').value = props.redemptionCode;
        });

    };


    updateNickname = () => {
        //console.log("passing redemption code: ", props.redemptionCode);
        //props.setRedemptionCode(props.redemptionCode);
        // const text = prompt("Give Your Assessment a nickname (ex. Mary's Assessment)");
        //
        jQuery('#upNickModal').modal({
            backdrop: 'static'
        });

        jQuery('#upNickModal').on('shown.bs.modal', function () {
            // jQuery('#nickname').trigger('focus');
            // console.log('focusing');
            document.getElementById('upnickname').focus();
            //document.getElementById('nickname').value = props.redemptionCode;
        });

    };

    validateRedemptionCode = (e) => {

        e.preventDefault();

        const enteredCode = this.state.enteredRedemptionCode.trim();


        axios.get(`${process.env.REACT_APP_API_URL}/api/survey/${enteredCode}/validate`, {})
            .then((response) => {

                if (response.status >= 200 && response.status < 300) {

                    this.setState({
                        error: false,
                        errorDetails: "",
                        redemptionCode: enteredCode
                    }, () => this.nickNameAssessment());


                }


            })
            .catch(error => {

                this.setState({error: true, errorDetails: "Invalid Redemption Code"});

            });


    };

    render() {

        // let styles = this.state.modalVisible
        //     ? {display: "block"}
        //     : {display: "none"};

        let availableAssessments = this.state.availableAssessments ? this.state.availableAssessments.map((item, idx) => {
            return (

                <AvailableAssessmentItem key={idx} productName={item.productName} sku={item.skuId}
                                         usedByUserEmail={item.usedByUserEmail}
                                         redemptionCode={item.redemptionCode} setRedemptionCode={this.setRedemptionCode}
                                         nickNameAssessment={this.nickNameAssessment}
                />

            )
        }) : null;

        let acitvatedAssessments = [];

        console.log("activatedAssessments", this.state.activatedAssessments);

        if(this.state.activatedAssessments !== null && this.state.activatedAssessments.length > 0) {

            acitvatedAssessments = this.state.activatedAssessments.map((item, idx) => {

                if(item.completePercentage < 100) {

                    return (
                        <ActivatedAssessmentItem key={idx} assessment={item}
                                                 redemptionCode={item.redemptionCode}
                                                 setCurrentNickname={this.setCurrentNickname}
                                                 updateThisNickname={this.updateNickname}
                        />
                    )
                } else {
                    return null;
                }
            });

            if(acitvatedAssessments.length == 1 && acitvatedAssessments[0] === null) {
                acitvatedAssessments = [];
            }
        }



        console.log("AA", acitvatedAssessments);

        let finishedAssessments = this.state.finishedAssessments.map((item, idx) => {

                return (
                    <FinishedAssessmentItem key={idx} assessment={item}
                                             redemptionCode={item.redemptionCode}
                                             setCurrentNickname={this.setCurrentNickname}
                                             updateThisNickname={this.updateNickname}
                                             handleCheckboxChange={this.handleCheckboxChange}
                    />
                )
        });


        return (

            <>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4" style={{fontSize: '2.3em'}}>Assessments</h5>
                </div>

                <NavButton className="btn btn-outline-success mb-2" link="/pricing">
                    <i className="fal fa-cart-plus"></i> Purchase More Assessments</NavButton>

                {availableAssessments.length > 0 ?

                    <div className="card mt-4">
                        <div className="card-header">
                            <i className="fal fa-tachometer-alt-fastest"></i> Available Assessments
                        </div>
                        <div className="card-body scroll">
                            {/*{availableAssessments}*/}

                            <table className="table table-fixed">
                                <tbody>
                                {availableAssessments}
                                </tbody>
                            </table>

                        </div>
                    </div> : ''
                }

                <div className='text-muted mt-2'>
                    * In order to link assessments, you must share an assessment with another person/account.
                </div>

                {acitvatedAssessments.length > 0 ?

                <div className="card mt-4">
                    <div className="card-header">
                        <i className="fal fa-tasks"></i> In Progress Assessments ({acitvatedAssessments.length})
                    </div>
                    <div className="card-body scroll">
                        <div className="container">

                            <table className="table table-fixed">
                                <tbody>
                                <tr className="text-center">
                                    <th></th>
                                    <th>Assessment</th>
                                    <th>Progress</th>
                                    <th>Action</th>
                                </tr>
                                {acitvatedAssessments}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div> : ''}

                <div className="card mt-4">
                    <div className="card-header font-weight-bold">
                        <i className="fal fa-list-alt"></i> Finished Assessments
                    </div>
                    <div className="card-body scroll">
                        <div className="container">

                            <table className="table table-fixed">
                                <tbody>
                                <tr className="text-center">
                                    <th>Rename</th>
                                    <th>Assessment</th>
                                    <th>Completed</th>
                                    <th>Action</th>
                                </tr>
                                {finishedAssessments}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>



                <div className="card mt-4">
                    <div className="card-header">
                        <i className="fal fa-barcode-read"/> Enter Redemption Code to Begin a New Assessment
                    </div>
                    <div className="card-body">
                        {this.state.error && <AlertError>{this.state.errorDetails}</AlertError>}
                        <form className="form-inline" onSubmit={this.validateRedemptionCode}>
                            <div className="form-group mb-2 mr-2">
                                Redemption Code
                            </div>
                            <div className="form-group mx-sm-3 mb-2">
                                <label htmlFor="redemptionCode" className="sr-only">Password</label>
                                <input type="text" className="form-control" id="redemptionCode"
                                       placeholder="Redemption Code"
                                       onChange={this.handleRedemptionCode} value={this.state.enteredRedemptionCode}
                                />
                            </div>
                            <button type="submit" className="btn btn-outline-success mb-2"><i
                                className="fal fa-hourglass-start"/> Begin Assessment
                            </button>
                        </form>
                    </div>
                </div>

                <NicknameModal redemptionCode={this.state.redemptionCode} beginAssessment={this.beginAssessment}/>
                <UpdateNicknameModal redemptionCode={this.state.redemptionCode}
                                     currentNickName={this.state.currentNickName}
                                     externalAssessmentId={this.state.externalAssessmentId}
                                     saveUpdatedNickname={this.saveUpdatedNickname}
                                     reloadActivated={this.loadActivatedAssessments}/>

            </>

        )
    }

}

export default MyAssessment;