import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class KindnessAndFlexibility extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {

        let kindnessData = [[this.props.kind.primarySelf[0], this.props.kind.partnerPrimary[0], this.props.kind.primaryPartner[0], this.props.kind.partnerSelf[0]],
            [100 - this.props.kind.primarySelf[0], 100 - this.props.kind.partnerPrimary[0], 100 - this.props.kind.primaryPartner[0], 100 - this.props.kind.partnerSelf[0]]];

        let kindnessColors = [this.props.kind.primarySelf[1], this.props.kind.partnerPrimary[1], this.props.kind.primaryPartner[1], this.props.kind.partnerSelf[1]];

        let flexibilityData = [[this.props.flex.primarySelf[0], this.props.flex.partnerPrimary[0], this.props.flex.primaryPartner[0], this.props.flex.partnerSelf[0]],
            [100 - this.props.flex.primarySelf[0], 100 - this.props.flex.partnerPrimary[0], 100 - this.props.flex.primaryPartner[0], 100 - this.props.flex.partnerSelf[0]]];

        let flexibilityColors = [this.props.flex.primarySelf[1], this.props.flex.partnerPrimary[1], this.props.flex.primaryPartner[1], this.props.flex.partnerSelf[1]];

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <i className="fal fa-hands-heart fa-2x" style={{color: "#c3b2b5"}}></i>
                        </div>

                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            KINDNESS & FLEXIBILITY
                        </div>
                    </div>
                </div>
                {/*<div className="text-muted text-right" style={{fontSize: ".8em"}}>{this.props.nickname} {this.props.username}</div>*/}

                <GraphLegend/>

                {/*<table className="table table-borderless table-sm col-6" style={{fontSize: ".8em"}}>*/}
                {/*    <tr>*/}
                {/*        <th className="text-right">YOU:</th>*/}
                {/*        <td>{this.props.nickname} {this.props.username}</td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th className="text-right">YOUR PARTNER:</th>*/}
                {/*        <td>{this.props.partner} {this.props.partnerUsername}</td>*/}
                {/*    </tr>*/}
                {/*</table>*/}


                {this.props.windowSize.windowWidth > 710 &&

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td align="center"><DonutChart elId='kind1' percent={this.props.kindAndFlex.primarySelf[0]}
                                                           color={this.props.kindAndFlex.primarySelf[1]}/>
                            </td>
                            <td className="border-right" align="center"><DonutChart elId='kind2'
                                                                                    percent={this.props.kindAndFlex.partnerPrimary[0]}
                                                                                    color={this.props.kindAndFlex.partnerPrimary[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='kind3'
                                                           percent={this.props.kindAndFlex.primaryPartner[0]}
                                                           color={this.props.kindAndFlex.primaryPartner[1]}/></td>
                            <td align="center"><DonutChart elId='kind4'
                                                           percent={this.props.kindAndFlex.partnerSelf[0] !== '' ? this.props.kindAndFlex.partnerSelf[0] : '0'}
                                                           color={this.props.kindAndFlex.partnerSelf[1]}/></td>

                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF
                            </td>
                            <td className="border-right" align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU
                            </td>
                            <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                            <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                }

                {this.props.windowSize.windowWidth <= 710 &&
                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <td align="center"><DonutChart elId='kind1' percent={this.props.kindAndFlex.primarySelf[0]}
                                                       color={this.props.kindAndFlex.primarySelf[1]}/>
                        </td>
                        <td align="center"><DonutChart elId='kind2' percent={this.props.kindAndFlex.partnerPrimary[0]}
                                                       color={this.props.kindAndFlex.partnerPrimary[1]}/>
                        </td>
                    </tr>
                    <tr className="border-bottom" style={{fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                        <td align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                    </tr>
                    <tr>
                        <td align="center"><DonutChart elId='kind3' percent={this.props.kindAndFlex.primaryPartner[0]}
                                                       color={this.props.kindAndFlex.primaryPartner[1]}/></td>
                        {/*<td align="center"><DonutChart elId='kind4' percent='0' color='#eee' /></td>*/}
                        <td align="center"><DonutChart elId='kind4'
                                                       percent={this.props.kindAndFlex.partnerSelf[0] !== '' ? this.props.kindAndFlex.partnerSelf[0] : '0'}
                                                       color={this.props.kindAndFlex.partnerSelf[1]}/></td>
                    </tr>

                    <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                        <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                    </tr>
                    </tbody>
                </table>
                }


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    The <strong>Kindness scale</strong> measures your underlying personality and how you relate to other
                    people.
                    Are you calm in your interactions? Do you treat other people with consideration and in a friendly
                    manner? The <strong>Flexibility scale</strong> measures how adaptable you and your partner are to
                    challenges in life.
                    Can you go with the flow? Are to suggestions? Both of these factors are key underlying personality
                    traits to healthy relationships.
                </div>

                <div className='mt-3 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            In research with couples, the higher the levels of positive family background, the higher
                            the levels of emotional readiness and kindness/flexibility.
                        </div>
                    </div>
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>KINDNESS</b>
                </div>

                <div className="chart-container" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='kindness' data={kindnessData} colors={kindnessColors}
                                        displayTicks={true}/>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>FLEXIBILITY</b>
                </div>

                <div className="chart-container" style={{width: '100%', borderBottom: '1px black solid'}}>
                    <HorizontalBarChart barChartId='flexibility' data={flexibilityData} colors={flexibilityColors}
                                        displayTicks={true}/>
                </div>

                <StrengthLegendDetail id="kaf-strength">
                    People who score in this area of this scale see themselves and/or their partners as being kind,
                    considerate, adaptable, and easy-going.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="kaf-needs-impr">
                    People who have scores in this area see themselves and/or their partners as being kind, considerate,
                    adaptable and easy-going some of the time.
                    You may want to evaluate where you stand as a couple to help increase marital satisfaction.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="kaf-challenge">
                    People who have kindness and flexibility scores in this area are more likely to be in relationships
                    that are less satisfying.
                    People rating themselves and/or their partners in the challenge area on this scale may want to
                    evaluate their ways of interacting to see how they can
                    increase the levels of kindness, flexibility, and consideration in their relationships.
                </ChallengeLegendDetail>


                <button className="btn btn-outline-dark no-print" type="button" data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false" aria-controls="collapseExample">
                    Show Section Questions
                </button>

                <div className="collapse" id="collapseExample">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-hands-heart"></i> Kindness and Flexibility
                            </h5>
                        </div>
                        <div className="card-body">
                            <table className="table ">
                                <thead>
                                <tr>
                                    <th scope="col" className="align-middle">How much do these words or phrases describe
                                        you?
                                    </th>
                                    <th scope="col" className="align-middle">How much do these words or phrases describe
                                        YOUR PARTNER?
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">
                                        Considerate
                                    </td>
                                    <td className="align-middle">
                                        Considerate
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Loving
                                    </td>
                                    <td className="align-middle">
                                        Loving
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Kind
                                    </td>
                                    <td className="align-middle">
                                        Kind
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Friendly
                                    </td>
                                    <td className="align-middle">
                                        Friendly
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Open-Minded
                                    </td>
                                    <td className="align-middle">
                                        Open-Minded
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Flexible
                                    </td>
                                    <td className="align-middle">
                                        Flexible
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Easy-Going
                                    </td>
                                    <td className="align-middle">
                                        Easy-Going
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        Adaptable
                                    </td>
                                    <td className="align-middle">
                                        Adaptable
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">
                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>Are you satisfied with your scores on the Kindness scale? Are you satisfied with your
                                scores on the flexibility scale?
                            </li>
                            <li>Do you want to change how flexible and/or kind you are?</li>
                            <li>What triggers yourself or your partner to act unkindly? How can you change those
                                reactions? How can you avoid those triggers?
                            </li>
                            <li>Does your partner want to change how kind and/or flexible they are?</li>
                            <li>What does changing your kindness/flexibility mean in your relationship?</li>
                            <li>Will being more kind and/or flexible make your relationship stronger and happier?</li>
                        </ul>
                    </div>
                </div>

            </>

        )

    }

}

export default withWindowSizeListener(KindnessAndFlexibility);