import React, {Component} from 'react';
import {createRiskTable} from '../reportUtils';

class RiskBehaviorChecklist extends Component {

    render() {


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-ballot-check fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            RISK BEHAVIORS CHECKLIST
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    While previous sections have focused on how experiences or mental health issues may generally influence your relationships moving forward,
                    there are also several other factors that research has suggested may elevate your risk of unhealthy relationships related to your current behaviors.
                    These often related to risk-taking behaviors that not only influence your general health but also influence your ability to interaction with
                    other or form healthy relationships. The following is a list of behaviors that research has found to be related to challenges in forming successful relationships.
                    You may have very few risk factors, or you may have a lot.  The key to understand with this checklist is that a check marking a factor as a risk does not mean
                    you will fail in relationships.  Rather, a pile up of risks suggests your relationships may require more effort in some areas than others.
                    Hover over any of the risk factors to learn more about why that factor elevates your risk.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>CHECKLIST</b>
                </div>

                <table className="table table-striped table-bordered col-sm-8 mt-4" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Challenge</th>
                        <th scope="col">Strength</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Drug use</th>
                        {createRiskTable(this.props.drugs.primarySelf[0])}
                    </tr>
                    <tr>
                        <th scope="row">Alcohol use</th>
                        {createRiskTable(this.props.alcohol.primarySelf[0])}
                    </tr>
                    <tr>
                        <th scope="row">Pornography use</th>
                        {createRiskTable(this.props.porn.primarySelf[0])}
                    </tr>
                    <tr>
                        <th scope="row">Sexual risk behaviors</th>
                        {createRiskTable(this.props.sexRisk.primarySelf[0])}
                    </tr>
                    <tr>
                        <th scope="row">Gambling</th>
                        {createRiskTable(this.props.gambling.primarySelf[0])}
                    </tr>
                    <tr>
                        <th scope="row">Exercise</th>
                        {createRiskTable(this.props.exercise.primarySelf[0])}
                    </tr>
                    <tr>
                        <th scope="row">Criminal activity</th>
                        {createRiskTable(this.props.crime.primarySelf[0])}
                    </tr>
                    </tbody>
                </table>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>DRUG USE</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Research has shown that drug abuse is associated with an increased risk of conflict and instability in relationships.  It is also a risk factor for abusive relationships.
                    Many drugs interfere with our ability to think clearly and make sound decisions.  Those with a history of drug abuse are more prone to health issues which can also
                    negatively impact relationships.  Addictive behaviors related to drugs can also lead to problems around finances, lying, and even intimacy within a relationship.
                    Those with active addictions to drugs are generally not able to form and maintain healthy romantic relationships.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>ALCOHOL USE</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    While occasional drinking has not been shown to create increased risk in relationships, excessive or habitual drinking has been shown to place individuals at
                    risk for abuse in relationships, conflict, poor health, and poor communication.  Those with an active addiction to alcohol are generally not able to form and
                    maintain healthy romantic relationships.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-4">
                    <b>PORNOGRAPHY USE</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Excessive viewing of pornography has been associated with a number of negative relationship outcomes.  This includes sexual dysfunction,
                    increased rates of sexual coercion, and infidelity.  It has also been associated with mental health outcomes associated with poorer relationship
                    quality such as higher risk of depression and higher conflict.  Pornography use is also associated with diminished self-esteem for both the viewer
                    and the viewer’s romantic partners.  In addition, pornography viewers may develop a distorted view of reality which can create a greater
                    tendency towards physical abuse of a romantic partner. If pornography is a persistent behavior in your life, consider how it may be
                    impact how you interact with other and how you think about the role of sexual intimacy.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}
                     className="mt-4">
                    <b>SEXUAL RISK BEHAVIORS</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Sexual risk behaviors include hooking up, unprotected sex, and having sex while drunk.  These behaviors tend to be associated with a casual mindset toward sex, separating
                    it from relationships, and are associated with relationship instability, higher rates of infidelity, and decreased dedication to relationships.  They are also associated
                    with a higher risk of sexually transmitted diseases and infections which can be passed on to future romantic partners.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}
                     className="mt-4">
                    <b>GAMBLING</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Excessive gambling can have a negative impact on relationships in two ways.  First, while the occasional trip to the casino can be a fun
                    way to bond and spend time together, gambling has the potential to develop into a compulsive behavior.
                    Gambling relies on a concept called “intermittent rewards”, meaning that you get a reward at random intervals while you are playing,
                    encouraging you to keep playing and spending more money.  When this behavior become compulsive, it often leads to lying, deception, and isolation.
                    All these factors can undermine healthy relationships.  Excessive gambling can also lead to a loss of financial resources, something
                    research suggests is very important for new and growing families.  If you gamble regularly, consider first if this behavior may be interfering
                    with other aspects of your life. If so, you may wish to seek some outside help in changing your patterns before you enter a dating relationship.
                    Even if the behavior is not causing problems, consider how your gambling behavior may limit or cause conflict in any future relationships you
                    have and what you can do now to prevent those issues from becoming major barriers to a healthy relationship.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}
                     className="mt-4">
                    <b>EXERCISE</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Research suggests that health problems are one of the most common stressors the undermine healthy romantic relationships.
                    Healthier people tend to have longer and happier relationships because they often avoid chronic health conditions that can create a
                    burden on relationships.  In addition, people who exercise regularly tend to be happier and less stressed than people who don’t.
                    Not only can regular exercise help you avoid some of the common stressors that plague relationships, it can also give you an activity
                    you can regularly do with your relationship partners to foster time together and communication.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}
                     className="mt-4">
                    <b>CRIMINAL ACTIVITY</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-5 text-justify'>
                    Clearly, engaging in criminal activity is a hinderance to healthy and successful romantic relationships.
                    Any time we engage in behavior that is outside of the law or societal norms, we risk our own health and freedom.
                    Not only can engaging in criminal activity put your future loved ones in danger, but often criminal activity is hidden from romantic partners.
                    This undermines trust and healthy communication in relationships.  When it comes to criminal activities, healthy partners simply avoid them.
                </div>


            </>

        )

    }

}

export default RiskBehaviorChecklist;