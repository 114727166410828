import React, {Component} from 'react'
import Chart from "chart.js";

class HorizontalBarChart extends Component {

  state = {
    backGroundColor: ["rgba(238, 238, 238, 0.2)", "rgba(238, 238, 238, 0.2)", "rgba(238, 238, 238, 0.2)", "rgba(238, 238, 238, 0.2)"],
    borderColor: ["rgb(238, 238, 238)", "rgb(238, 238, 238)", "rgb(238, 238, 238)", "rgb(238, 238, 238)"],
    labels: ["HOW YOU VIEW YOURSELF", "HOW YOUR PARTNER VIEWS YOU", "HOW YOU VIEW YOUR PARTNER", "HOW YOUR PARTNER VIEWS THEMSELVES"]
  };

  componentDidMount() {

    if (!Array.isArray(this.props.labels) || !this.props.labels.length) {

      let defaultHeight = 160;
      this.drawBarChart(this.state.labels, this.state.borderColor, this.state.backGroundColor, defaultHeight);

    } else {
      this.drawBarChart(this.props.labels, this.props.borderColor, this.props.backGroundColor, this.props.height);
    }

  }

  drawBarChart = (labels, borderColor, backGroundColor, height) => {

    let ctx = document.getElementById(this.props.barChartId);
    ctx.height = height;

    // let barChart =

    new Chart(ctx, {
      type: "horizontalBar",
      data: {
        labels: labels,
        datasets: [{
          data: this.props.data[0],
          fill: false,
          backgroundColor: this.props.colors,
          borderColor: this.props.colors,
          borderWidth: 1
        },
          {
            data: this.props.data[1],
            fill: false,
            backgroundColor: backGroundColor,
            borderColor: borderColor,
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false,
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              display: this.props.displayTicks
            },
            gridLines: {
              display: false,
              drawBorder: false,
            }
          }]
        }
      }
    });

  };

  render() {

    return (

        <>

          <canvas id={this.props.barChartId}></canvas>

        </>

    )

  }
}

export default HorizontalBarChart;