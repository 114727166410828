import React from 'react';

const improveLegendDetail = (props) => {


  return (

      <div className="bd-callout bd-callout-warning text-justify" style={{borderLeftColor: "#28b7b2"}}>
        <h5 id={props.id} style={{color: "#28b7b2"}}>Needs Improvement</h5>
        <div style={{height: '4px', width: '200px', backgroundColor: '#28b7b2'}} className='mb-2'></div>
        <p>
          {props.children}
        </p>
      </div>

  )};

export default improveLegendDetail;