import React, {Component} from 'react';
import Order from "./Order";
import axios from '../axios/axios-custom.js'
import OrdersSkeleton from "./OrdersSkeleton";
import NavButton from "../layout/navbar/navigationItems/NavButton";

class Orders extends Component {

  _isMounted = false;

  state = {
    orders: [],
    error: '',
    loading: true,
  };

  componentDidMount() {

    this._isMounted = true;

    this.getOrders();

  }

  componentWillUnmount() {
    this._isMounted = false;
  };


  getOrders = (ev) => {

      let url = `${process.env.REACT_APP_API_URL}/api/orders`;

      axios.get(url,
      //     {
      //   headers: {
      //     Authorization: 'Bearer ' + localStorage.getItem('token')
      //   }
      // }

      )
      .then(response => {

        if (this._isMounted) {
          this.setState({orders: response.data, loading: false});
        }

      })
      .catch(error => {

        // Error 😨
        if (error && error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          // console.log(error.response.data);
          // console.log(error);
          this.setState({error: error.response.data.detail});

        } else if (error.request) {
          // console.log(error);

          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
        } else {
          // Something happened in setting up the request and triggered an Error
          // console.log('Error', error.message);
          // console.log(error);
        }

        // console.log(error);

      });

  };

  render() {

    // const carItems = props.cart.cartContents.map((item, idx) => {
    //
    //   return <CartWidgetItem key={idx} productName={item.productName} qty={item.qty} price={item.price} sku={item.sku} />;
    //
    // });

    const orders = this.state.orders.length > 0 ? this.state.orders.map((item, idx) => {

      return <div key={idx}><Order key={item.userOrderId} orderId={item.userOrderId}  {...item} /><div className="mb-5"></div></div>;

    }) :
        <div className="card">
          <div className="card-body">
            You do not have any orders yet.
            <NavButton className="btn btn-outline-primary btn-sm ml-3" link="/pricing"><i className="fal fa-cash-register"></i> Purchase an assessment</NavButton>
          </div>
        </div>;
    let errorDiv = <div className="alert alert-danger text-center" role="alert">
      <i className="fal fa-exclamation-triangle"></i> {this.state.error}
    </div>;

    return (

        <>

          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Your Orders</h5>
          </div>

          {this.state.error && errorDiv}


          {this.state.loading &&
          <React.Fragment>
            <OrdersSkeleton/>
            <OrdersSkeleton/>
          </React.Fragment>
          }

          {orders}


        </>

    );
  }

}

export default Orders;