import React, {Component} from 'react';
import Spinner from "../Spinner/Spinner";
import axios from "../axios/axios-custom";

class UserDetails extends Component {

    state = {
        errorDetails: '',
        userEmail: '',
        userDetail: {
            user: null,
            userAssessments: []
        },
        relate: true,
        ready: false,
        assessment: 'relate',
        asmtCount: '',
        generatedCodes: [],
        relateUser: {

        }
    }

    handleToggle = (e) => {

        if (e.target.value.trim() === 'relate') {
            this.setState({assessment: 'relate', relate: true, ready: false});
        } else {
            this.setState({assessment: 'ready', relate: false, ready: true});
        }

    };

    handleUserInput = (event) => {

        this.setState({userEmail: event.target.value});
    };

    handleCountInput = (event) => {

        if(this.isNumeric(event.target.value)) {
            console.log(event.target.value)
            this.setState({asmtCount: event.target.value});
        }


    };

    isNumeric = (num) => {
        return !isNaN(num);
    };

    handleSubmitAddCodes = async (event) => {

        event.preventDefault();

        this.setState({errorDetails: ''});

        this.setState({showSpinner: true});

        const addCodeData = {

            email: this.state.userEmail.trim(),
            assessmentType: this.state.assessment.trim(),
            qty: this.state.asmtCount.trim()
        };

        let url = `${process.env.REACT_APP_API_URL}/api/admin/redemption-codes/account`;

        try {
            let response = await axios.post(url, addCodeData, {withCredentials: true});

            //this.setState({showSpinner: false, userDetail: response.data});
            this.setState({showSpinner: false, generatedCodes: response.data, asmtCount: '', relate: true, ready: false, assessment: 'relate'});

            //window.location.reload(false);

        } catch (error) {

            this.setState({showSpinner: false});

            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                this.setState({errorDetails: error.response.data.detail});

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                //console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                //console.log('Error', error.message);
            }


        }
    };

    handleSubmit = async (event) => {

        event.preventDefault();

        this.setState({errorDetails: '', generatedCodes: [], showSpinner: true});

        if(this.state.userEmail.length > 0) {

            const codeData = {

                userEmail: this.state.userEmail
            };

            let url = `${process.env.REACT_APP_API_URL}/api/admin/user-details`;

            try {
                let response = await axios.post(url, codeData, {withCredentials: true});

                this.setState({showSpinner: false, userDetail: response.data});

                //window.location.reload(false);

            } catch (error) {

                this.setState({showSpinner: false});

                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail});

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }


            }

        } else {
            this.setState({errorDetails: "Number of Assessments must be greater than zero"});
        }

    };

    getGeneratedCodes = () => {

        if(this.state.generatedCodes.length > 0) {


            const codes = this.state.generatedCodes.map((item, idx) => {

                console.log(item);

                return <tr key={idx}><td>{item.redemptionCode} - {item.assessmentId === 1 ? 'RELATE' : 'READY'}</td></tr>;

            });

            return codes;


        }

    };

    convertToPro = async () => {

        const accountInfo = {

            userEmail: this.state.userEmail
        };

        let url = `${process.env.REACT_APP_API_URL}/api/admin/convert-to-pro`;

        try {
            let response = await axios.post(url, accountInfo, {withCredentials: true});

            this.setState({showSpinner: false, userDetail: response.data});

            // this.setState(prevState => ({
            //     showSpinner: false, userDetail: {
            //         ...prevState.userAssessments,
            //         user: response.data
            //     }
            // }));


            //window.location.reload(false);

        } catch (error) {

            this.setState({showSpinner: false});

            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                this.setState({errorDetails: error.response.data.detail});

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                //console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                //console.log('Error', error.message);
            }


        }

    };

    getUserInformation = () => {

        if(this.state.userDetail.user != null) {

            const userInfo =
                <td>
                    <div><b>User: </b> {this.state.userDetail.user.username}</div>
                    <div><b>User Id: </b> {this.state.userDetail.user.userId}</div>
                    <div><b>Name: </b> {this.state.userDetail.user.firstName} {this.state.userDetail.user.lastName}</div>
                    <div><b>Active: </b> {this.state.userDetail.user.active ? "True" : "False"}</div>
                    <div><b>Created: </b> {this.state.userDetail.user.createDate}</div>

                    {this.state.userDetail.user.role === 'ROLE_COUNSELOR' ?
                        <div style={{backgroundColor: 'darkred', color: 'white'}}>
                            <b>Role: </b> {this.state.userDetail.user.role}
                            {/*<button className="btn btn-sm btn-success ml-3" link="/user/details"><i*/}
                            {/*    className="fal fa-exclamation" /> Convert to User Account</button>*/}
                        </div> :
                        <div>
                            <b>Role: </b> {this.state.userDetail.user.role}
                            {this.state.userDetail.user.role === 'ROLE_USER' ?
                                <button className="btn btn-sm btn-outline-danger ml-3" onClick={this.convertToPro} ><i
                                    className="fal fa-exclamation" /> Convert to Pro Account</button> : ''}
                        </div>
                    }


                </td>;

            return userInfo;


        }

    };

    getUserAssessments = () => {

        if(this.state.userDetail.user != null) {

            return this.state.userDetail.userAssessments.map((item, idx) => {

                return <tr key={idx}>
                    <td>
                        {item.redemptionCode} - {item.assessmentId === 1 ? 'RELATE' : 'READY'}<br/>
                        <b>survey Id: </b> {item.externalSurveyId}<br/>
                        <b>Started: </b> {item.startedAt}<br/>
                        <b>Completed: </b> {item.completedAt}<br/>
                        <b>Complete Percentage: </b> {item.completePercentage} %<br/>
                        <b>Nickname: </b> {item.nickname}<br/>
                    </td>

                </tr>;

            });


        }

    };


    render() {

        let errorDiv = <div className="alert alert-danger text-center" role="alert">
            <i className="fal fa-exclamation-triangle"/> {this.state.errorDetails}
        </div>;

        let addCodes = (
            <div>
                <form className="form-signin"  onSubmit={this.handleSubmitAddCodes}>

                <div className="mb-2">
                    <div className="mb-3">Assessment Type</div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="relate" className="custom-control-input" value="relate"
                               onChange={(v) => this.handleToggle(v)} checked={this.state.relate}/>
                        <label className="custom-control-label" htmlFor="relate">Relate
                        </label>
                    </div>

                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="ready" className="custom-control-input" value="ready"
                               onChange={(v) => this.handleToggle(v)} checked={!this.state.relate}/>
                        <label className="custom-control-label" htmlFor="ready">Ready</label>
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="asmtCount">Number of Assessments</label>
                    <input type="text" className='form-control'
                           id="asmtCount" name="asmtCount" value={this.state.asmtCount}
                           onChange={(event) => this.handleCountInput(event)}

                    />

                </div>
                    <button className="btn btn-outline-info btn-block" type="submit"><i className="fal fa-barcode" /> Create Assessment Codes</button>
                    {this.getGeneratedCodes()}
                </form>



            </div>
        );

        let generatedCodes = <div className="col-md-6 mx-auto">
            <table className="table table-bordered table-hover">
                <tbody>
                <tr>
                    <th>User Information</th>
                </tr>
                <tr>{this.getUserInformation()}</tr>
                <tr>
                    <th>Assessments</th>
                </tr>
                {this.getUserAssessments()}
                </tbody>
            </table></div>;

        let userForm = (

            <>
                {this.state.errorDetails && errorDiv}
                {!this.state.showSpinner && <form className="form-signin"  onSubmit={this.handleSubmit}>
                    <div className="col-md-12 order-md-1">

                        <div className="mb-3">
                            <label htmlFor="userEmail">User email</label>
                            <input type="text" className="form-control"
                                   id="userEmail" name="userEmail" value={this.state.userEmail}
                                   onChange={(event) => this.handleUserInput(event)}
                            />
                        </div>

                    </div>
                    <hr className="mb-4"/>
                    <button className="btn btn-outline-info btn-block" type="submit"><i className="fal fa-search"></i> Find User</button>
                </form> }

                {this.state.showSpinner && <Spinner />}




            </>

        );

        return (
            <div>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>User Details</h5>
                </div>

                {userForm}
                {this.state.userDetail.user != null && addCodes}
                {this.state.userDetail.user != null && generatedCodes}

            </div>


        );
    }
}

export default UserDetails;