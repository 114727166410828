import React from "react";
import styles from './BulkOrder.module.css'
import ProStripe from './ProStripeProvider'
import {CardElement, Elements, injectStripe} from 'react-stripe-elements';
import {createOptions} from "../Stripe/createOptions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import CardForm from "../Stripe/CardForm";
import BulkOrderForm from "./BulkOrderForm";
import ProCheckout from "./ProCheckout";

class BulkOrder extends React.Component {





    render() {


        return (

            <>
                <ProStripe />
            </>

        );
    }
}

export default BulkOrder;
