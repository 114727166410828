import React from 'react';
import NavButton from "../layout/navbar/navigationItems/NavButton";
import {NavLink} from "react-router-dom";

const activatedAssessmentItem = (props) => {


  const updateNickname = () => {

    props.setCurrentNickname(props.assessment.nickname, props.assessment.surveyId);

    props.updateThisNickname();

  };

  let progressBarClass = 'progress-bar progress-bar-striped ';

  // let actionButton =  <button type="button" className="btn btn-sm btn-outline-success">
  //   <i className="fas fa-arrow-right"></i> Continue
  // </button>;

  let assessmentUrl = `/survey/${props.assessment.surveyId}`;
  let actionButton =   <NavButton className="btn btn-sm btn-outline-success" link={assessmentUrl}>
    <i className="fal fa-arrow-right" /> Continue</NavButton>;

  if(props.assessment.completePercentage <= 25) {

    progressBarClass += 'bg-danger';

  } else if (props.assessment.completePercentage >= 75 && props.assessment.completePercentage !== 100) {

    progressBarClass += 'bg-info';

  } else if (props.assessment.completePercentage === 100) {

    let completedAssessmentUrl = `/report/${props.assessment.surveyId}`;

    if(props.assessment.surveyName === 'READY') {
      completedAssessmentUrl = `/report-ready/${props.assessment.surveyId}`;
    }

    progressBarClass = 'progress-bar bg-success ';

    actionButton = <NavButton className="btn btn-sm btn-outline-success" link={completedAssessmentUrl}><i className="fal fa-chart-bar"></i> My Results</NavButton>;
  }

  let linkAssessmentLink = '';

  if(props.assessment.surveyName === 'RELATE') {

    let url = `/link-assessments/${props.assessment.surveyId}`;

    if(props.assessment.connectedSurveyId === null) {

      linkAssessmentLink = <span className="text-primary text-nowrap" style={{fontSize: '.8em'}}>
        <NavLink to={url} exact={true} title={'Link ' + props.assessment.redemptionCode}><i className="fal fa-link"/> Link Assessment</NavLink>
    </span>;

    } else {

      linkAssessmentLink = <span className="text-success text-nowrap" style={{fontSize: '.8em'}}>
              <i className="fal fa-check"/> <NavLink to={url} exact={true} title={'Link ' + props.assessment.redemptionCode}>Assessment Linked</NavLink>
    </span>;

    }

  }



  return (

      <>
        <tr className=' table-middle'>
          <td className="w-auto">
            <i className="fal fa-edit pointer-cursor" onClick={() => updateNickname()}></i>
          </td>
          <td className="w-auto">
              <span title={props.assessment.surveyName + ' ' + props.assessment.redemptionCode}>
                {props.assessment.nickname}
              </span><br/>
            {props.assessment.completePercentage === 100 && linkAssessmentLink}
          </td>
          <td className="w-75" style={{verticalAlign: "middle"}}>
            <div className="progress">
              <div className={progressBarClass} role="progressbar"
                   style={{width: props.assessment.completePercentage + "%"}} aria-valuenow={props.assessment.completePercentage} aria-valuemin="0" aria-valuemax="100">
                {props.assessment.completePercentage} %
              </div>
            </div>
          </td>
          <td className="w-25 text-center table-middle">{actionButton}</td>
        </tr>
      </>

  )};

export default activatedAssessmentItem;