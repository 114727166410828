import React, {Component} from 'react';
import SkeletonLine from "../layout/SkeletonLine";

class OrdersSkeleton extends Component {

  render() {

    return (

        <div className='mb-4 mt-2'>
          <div className="" style={{fontSize: '.8em', color: '#646464'}}>
            <div className="row">
              <div className="col-4">
                <SkeletonLine />
              </div>
              <div className="col-4">
                <SkeletonLine />
              </div>
              <div className="col-4 text-right"><SkeletonLine />
              </div>
            </div>
          </div>
          <div className="card-body">
            <div  className="row d-table">
              <div className="col-2">
                <SkeletonLine />
              </div>
              <div className="col-10 h-100  d-table-cell align-middle">
                <h5><SkeletonLine /></h5>
                <SkeletonLine />
              </div>
            </div>
          </div>
          <div className="">
            <SkeletonLine />
            <hr/>
          </div>
        </div>

    );
  }

}

export default OrdersSkeleton;