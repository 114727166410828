import React, {Component} from 'react';

class Numeric extends Component {

  state = {

    rowClass: '',
    selected: [],
    value: 'select',
    inputValue: '',
    helpText: 'Only whole numeric values are allowed (including 0)',
    helpClassName: 'form-text text-muted',

  };

  handleTyped = (questionId, event) => {

    // alert(questionId + " " + event.target.value);

    if(event.target.value >= 0 && event.target.value.toString().indexOf(".") === -1) {

      this.setState({value: event.target.value, selected: this.state.selected.concat(questionId)});

      this.props.testMethod(questionId, event.target.value);

    }

  };

  componentDidMount() {
   // this.setState({inputValue: this.props.questions.selectedQuestionResponse});
  }

  handleChange = (e) => {
    // if(e.target.value > 20) {
    //   this.setState({inputValue: 20, helpText: 'Only values between 0 and 20 are valid', helpClassName: 'form-text text-danger'});
    // } else {
    //   this.setState({inputValue: e.target.value});
    // }

    if(e.target.value >= 0 && e.target.value.toString().indexOf(".") === -1) {

      // this.setState({inputValue: e.target.value}, () => {console.log(this.state.inputValue)});

      this.setState({inputValue: e.target.value});
    }
    // else {
    //   this.setState({helpText: 'Please enter a whole positive number', helpClassName: 'form-text text-danger'});
    // }

  };

  handleFocus = (event) => event.target.select();

  render() {

    return (

        this.props.questions.map((question, i) => {

              return (

                  <div className="card mb-5" key={question.questionId}>
                    <div className="card-header"><h5>{question.questionText}</h5></div>
                    <div className="card-body">
                        <div className="form-group">
                          <table className="table table-borderless mt-3">
                            <tbody>
                            <tr className={
                              this.state.selected.includes(question.questionId) || question.selectedQuestionResponse !== 0
                                  ? "empty"
                                  : ""
                            }
                            >
                              <td>
                                {/*<label htmlFor={"questionLine" + question.questionId}></label>*/}
                                <div className="row">
                                  <div className="col-sm-3">
                                <input className="form-control" type='number' min="0" max="100" step="1" pattern="\d*" width='30px'
                                       id={"questionLine" + question.questionId}
                                       name={"responseChoice" + question.questionId}
                                       value={this.state.inputValue === '' ? question.selectedQuestionResponse : this.state.inputValue}
                                      // value={this.state.inputValue || ''}
                                       onChange={this.handleChange}
                                       onFocus={this.handleFocus}
                                       onBlur={(e) => this.handleTyped(question.questionId, e)}/>
                                <small id="emailHelp" className={this.state.helpClassName}>{this.state.helpText}</small>
                                  </div>
                                  <div className="col-sm">
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                        </div>



                    </div>
                  </div>

              )

            }
        )

    )
  }

}

export default Numeric;