import React from 'react';

const cartWidgetDiscount = (props) => {

  return (


        <li className="list-group-item d-flex justify-content-between bg-light">
          <div className="text-success">
            <h6 className="my-0">Promo code</h6>
            <small>{props.coupon}</small>
          </div>
          <span className="text-success">-${(props.originalPrice - props.total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
        </li>

  )};

export default cartWidgetDiscount;