import React, {Component} from 'react';
import {connect} from "react-redux";
import LoggedInNavBar from '../layout/navbar/LoggedInNavBar';
import LoggedOutNavBar from '../layout/navbar/LoggedOutNavBar';

class Header extends Component {

    loadData = () => {

        this.setState({loadedItem: this.props.match.params.id});

    };


    render() {

        let navigation = <LoggedOutNavBar authenticated={this.props.authenticated}/>;

        if (this.props.authenticated) {

            // navigation = <NavBar authenticated={this.props.authenticated} />;
            navigation = <LoggedInNavBar authenticated={this.props.authenticated}/>;

        }

        return (


            <header className='no-print'>

                {navigation}

            </header>


        )
    }

}

const mapStateToProps = (reduxState) => {
    return {
        authenticated: reduxState.oldReducer.token !== null,
    };
};

export default connect(mapStateToProps)(Header);