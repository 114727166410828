import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from '../../constants/Colors'

class ReadyTraits extends Component {

    componentDidMount() {
    }

    render() {

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-hand-holding-seedling fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            EMOTIONAL READINESS
                        </div>
                    </div>
                </div>

                <div className="mt-2 mb-5 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    Personality refers to our tendencies towards certain styles of being or interacting, such as whether
                    we are more quiet and shy or more outgoing, whether we are more likely to be happy and positive or
                    pessimistic. Our personality is an important factor in how we interact with others in romantic
                    settings, including how we react to relationship events, our preferences in romantic partners, and
                    our ability to attract others. Being able to identify our personality can help us understand the
                    unique challenges we may face in relationships. Rather than being strengths or weaknesses, however,
                    not all personality traits are necessarily good or bad. For some traits research suggests that
                    similarity with a partner is more important than how we score individually, but if we are low in a
                    particular trait it may also be helpful to find someone high in that trait who can complement us. In
                    this section, we measure three different categories of personality characteristics which influence
                    our relationships in different ways: emotional readiness, warmth, and conscientiousness.
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='gglm' percent={this.props.emotionReady.primarySelf[0]}
                                    color={this.props.emotionReady.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1">
                        {this.props.emotionReady.primarySelf[1] === Color.CHALLENGE &&
                        <ChallengeLegendDetail id="ggqa">
                            You tend to be emotionally reactive. In other words, you tend to let your emotions rule you
                            rather than controlling your emotions and channeling them into positive coping. This can be
                            dangerous for relationships as negative emotions tend to drag down those close to you. Such
                            a rollercoaster can become distressful for romantic partners and is not likely to encourage
                            long-lasting love. Gaining control of your emotional response is most likely to occur with
                            outside guidance in adjusting your expectations about what you can control and adopting more
                            positive coping skills. The following scales represent the subdimensions of emotional
                            readiness.
                        </ChallengeLegendDetail>
                        }

                        {this.props.emotionReady.primarySelf[1] === Color.IMPROVE &&
                        <ImproveLegendDetail id="gian">
                            In some ways you are emotionally stable, but you are lacking in ways that may hurt your
                            relationships during major challenges. You probably recognize that you cannot always be in
                            control of things, but that does not stop you from trying. When you feel a loss of control
                            you are more likely to overreact to stress. Pay attention to the techniques you use to stay
                            calm during minor issues and practice those techniques so they become more instinctual when
                            major stress occurs.
                        </ImproveLegendDetail>
                        }

                        {this.props.emotionReady.primarySelf[1] === Color.STRENGTH &&
                        <StrengthLegendDetail id="gkzc">
                            You are emotionally ready to handle the stresses that come to any relationship. You are able
                            to remain calm and optimistic, which are great assets when challenges and obstacles come. In
                            general, you are able to remain positive and hopeful for your relationships. You think
                            clearly and focus on what you can do to diminish stress.
                        </StrengthLegendDetail>
                        }
                    </div>
                </div>

                <div className="mt-2 mb-5 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    This section assesses your overall emotional tendencies in relationships, such as how you react to
                    difficulties that arise in your life. Emotional reactivity, or neuroticism, is generally considered
                    harmful to relationships because it reflects difficulty controlling emotions and strong reactions to
                    emotionally charged events. Neuroticism also means you put emotional meaning into events that do not
                    require an emotional response. A lack of emotional control can lead to taking things personally,
                    irrational responses to challenges, and a tendency to misinterpret the behavior of others. On
                    emotional readiness it has been found that being with someone high in this trait is better for
                    relationships, no matter how you score.
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>CALMNESS</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='gmhd' percent={this.props.calm.primarySelf[0]}
                                    color={this.props.calm.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Calmness represents the tendency to keep your anxious emotions out of problem-solving. This is a
                        helpful trait to have as it allows for clearer thinking and problem-solving in handling stress.
                        When we are not calm, anxious, we may tend to try to focus on expressing an emotion rather than
                        solving a problem. A high calmness score indicates you have good control over your emotions and
                        are able to separate emotions from problems.

                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>MATURITY</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='gpuj' percent={this.props.maturity.primarySelf[0]}
                                    color={this.props.maturity.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Maturity refers to your ability to control your emotions such as anger and frustration rather
                        than allowing them to control you. When we maintain control of ourselves, our actions are more
                        appropriate and effective in solving problems. Maturity is primarily shown in actions that avoid
                        harm to others and relationships. A high score in this section means you are able to act in ways
                        considered to be acceptable for your age and understanding.

                    </div>
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>HAPPINESS</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='grkn' percent={this.props.happiness.primarySelf[0]}
                                    color={this.props.happiness.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Happiness is important in helping us maintain a positive perspective on life when trials come.
                        When we are happy, we tend to have more hope and motivation to solve the problems we are facing.
                        Happy people also take a positive approach to problems, focusing on how they can grow from the
                        experience or event. A high score in happiness suggests you are a “glass-half-full” person and
                        find good even during stressful times.

                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>SELF-ESTEEM</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='gsxw' percent={this.props.selfEsteem.primarySelf[0]}
                                    color={this.props.selfEsteem.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Self-esteem refers to our sense of acceptance of ourselves. Self-esteem can help us focus on
                        what we can control and what we cannot control, thus allowing us to feel more confident in our
                        problem solving and relationship skills. A high score in this section indicates you believe you
                        have something to offer in solving problems.

                    </div>
                </div>


            </>

        )

    }

}

export default ReadyTraits;