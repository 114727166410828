import React, {Component} from 'react';
import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';
import Radial from "./Radial";

class MaritalPermanence extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-telescope fa-2x" style={{color: "#c3b2b5"}}/></div>
                        <div>
                            MARRIAGE OUTLOOK
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    Though few people marry just to divorce sometime down the road, we do not all agree on what "permanence" means in marriage.
                    The words "as long as you both shall live" are even being taken out of many marriage ceremonies to be replaced with the words
                    "as long as you both shall love".  Which words do you agree most with and what does that mean for your future marriage?
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="pt-4">
                    <b>MARITAL PERMANENCE: <span className='text-black-70'>{this.props.permamence.primarySelf[0] < 50 && 'Low'}
                        {this.props.permamence.primarySelf[0] >= 50 && 'High'}</span></b>
                </div>

                {this.props.windowSize.windowWidth >= 710 &&

                <div className="d-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill align-items-center text-center gauge">Low
                    </div>
                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.permamence.primarySelf[0]} cId='brmv'/>
                    </div>
                    <div className="p-2 flex-fill gauge">High
                    </div>
                </div>
                }

                {this.props.windowSize.windowWidth < 710 &&

                <div className="d-flex justify-content-around align-items-center">

                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.permamence.primarySelf[0]} cId='brmv' />
                    </div>

                </div>
                }

                <div style={{borderBottom: '2px #4374a3 solid', marginTop: '-8em', zIndex: 9999}} />

                <div className="d-flex justify-content-around align-items-start text-justify">
                    <div className={'mt-2 p-2 flex-even ' + (this.props.permamence.primarySelf[0] < 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">Low</div>
                            }
                            <p>
                                Low: If you scored low in marital permanence you tend to focus more on your individual happiness.  If something does not make you happy,
                                you believe you should get out of that situation.  Recognizing that divorce is sometimes necessary can be important and help you get out of a
                                dangerous marriage if needed without hesitation.  But research suggests that making divorce an option from the start may lead to divorce more
                                than actual unhappiness, and it rarely solves the real issues that make marriages fail.  If we just jump ship rather than figuring out what
                                is causing our unhappiness, we will carry with us the uncertainty of relationships, as well as paying costs that we may regret later.
                            </p>
                        </div>
                    </div>
                    <div className={'mt-2 p-2 flex-even ' + (this.props.permamence.primarySelf[0] > 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">High</div>
                            }
                            <p>
                                High: If you scored high in marital permanence it means you believe that marriage is for life and even if you are unhappy you should stick it out.
                                You believe that marriage is more than just something that is meant to make us happy, but something that is meant to help us
                                grow and experience lasting connection with another.  Such a belief contributes to strong stability in marriage as it defines divorce as a
                                last resort rather than a solution to bumps along the way.  Even in dating, such a belief can be helpful as it promotes efforts in maintaining
                                positive relationship patterns.
                            </p>
                        </div>
                    </div>
                </div>

                <div style={{borderBottom: '2px #4374a3 solid'}} className='mb-5'/>

            </>

        )

    }

}

export default withWindowSizeListener(MaritalPermanence);