import React, {useEffect, useState} from 'react';

function CartItem(props) {

  const [qty, setQty] = useState(props.qty);

  useEffect(() => {
    setQty(props.qty);
  }, [props.qty]);

  const add = () => {

    let newQty = qty + 1;

    setQty(newQty);
    props.changed(newQty);

  };

  const subtract = () => {

    if(qty > 0) {
      let newQty = qty - 1;

      //setQty(newQty);

      props.changed(newQty);
    } else if (qty === 0) {
      props.changed(0);
    }

  };

  return (

      <>


        <div className="d-flex justify-content-around align-items-center">
          <div className="text-center align-self-center flex-fill">
            <img className="img-responsive" src={`/images/${props.sku.toLowerCase()}.jpg`} width="120" alt={props.sku} />
          </div>
          <div className="text-center align-self-center flex-fill" style={{minWidth: '100px'}}>
            <h4 className="product-name"><strong>{props.name === 'Relate Couple' ? 'Rrelate x 2' : props.name}</strong></h4>
            {/*<h4>*/}
            {/*  <small>{props.name} ({props.sku})</small>*/}
            {/*</h4>*/}

            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="flex-fill">
                <div className='text-danger hand' onClick={subtract}><i className="fal fa-minus-circle fa-2x"></i></div>
              </div>
              <div className="flex-fill text-black-50"><h4>{qty}</h4></div>
              <div className="flex-fill">
                <div className='text-success hand' onClick={add}><i className="fal fa-plus-circle fa-2x"></i></div>
              </div>
            </div>

          </div>
          <div className="text-center flex-fill align-self-center">

            <label><strong>${Number.parseFloat( props.price ).toFixed( 2 )} <span className="text-muted">x</span> {props.value} </strong></label>
            <br/>
            Line Total: ${( props.price *  props.value).toFixed( 2 )}
            {/*<input type="number" step="1" max="99" min="0"  title="Qty" className="form-control p-1" size="4" value={props.value} onChange={props.changed} />*/}
          </div>
          <div className="text-center flex-fill align-self-center">
            <button type="button" className="btn btn-outline-danger btn-sm cmt-3"  onClick={props.removeItem}>
              <i className="fal fa-trash"></i>
            </button>
          </div>
        </div>


        {/*<div className="row">*/}
        {/*  <div className="col-sm-2">*/}
        {/*    <img className="img-responsive" src={`/images/${props.sku.toLowerCase()}.jpg`} width="120" alt={props.sku} />*/}
        {/*  </div>*/}
        {/*  <div className="col-sm-5">*/}
        {/*    <h4 className="product-name"><strong>{props.name} </strong></h4>*/}
        {/*    <h4>*/}
        {/*      <small>{props.name} ({props.sku})</small>*/}
        {/*    </h4>*/}
        {/*  </div>*/}
        {/*  <div className="col-sm-2">*/}
        {/*    <div className="text-md-right" style={{paddingTop: '10px'}}>*/}
        {/*      <h6><strong>{Number.parseFloat( props.price ).toFixed( 2 )} <span className="text-muted">x</span></strong></h6>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col-sm-2">*/}
        {/*    <div className="text-md-right" style={{paddingTop: '5px'}}>*/}
        {/*      <input type="number" step="1" max="99" min="0"  title="Qty" className="form-control" size="4"*/}
        {/*             value={props.value}*/}
        {/*             onChange={props.changed}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col-sm-1">*/}
        {/*    <div className="text-md-right">*/}
        {/*      <button type="button" className="btn btn-outline-danger btn-sm" style={{marginTop: '5px'}} onClick={props.removeItem}>*/}
        {/*        <i className="fal fa-trash"></i>*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}


        <hr/>


      </>

  )};

export default CartItem;
