import React from 'react';

const graphLegend = (props) => {


  return (
        <div style={{fontFamily: 'Nunito', fontSize: '0.6em'}}>
          <table>
            <tbody>
            <tr>
              <td align='right'>Strength</td>
              <td>
                <div style={{ width: '80px', backgroundColor: '#4375a4', border: '4px solid #4375a4'}}>
                </div>
              </td>
            </tr>
            <tr>
              <td align='right'>Needs Improvement</td>
              <td>
                <div style={{width: '50px', backgroundColor: '#28b7b2', border: '4px solid #28b7b2'}}>
                </div>
              </td>
            </tr>
            <tr>
              <td align='right'>Challenge</td>
              <td>
                <div style={{width: '20px', backgroundColor: '#dc2229', border: '4px solid #dc2229'}}>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

  )};

export default graphLegend;