import React, {Component} from "react";
import { connect } from 'react-redux';
import * as actionCreators from "../store/action/checkOut";
import NavButton from "./navbar/navigationItems/NavButton";
import Spinner from "../Spinner/Spinner";

class AddToCart extends Component {

    state = {
        totalQty: this.props.cartQty,
        showCheckOut: this.props.cartQty > 0,
        readyQty: 0,
        readyTouched: false,
        relateQty: 0,
        relateTouched: false,
        relateCoupleQty: 0,
        relateCouplTouched: false,
        products: [
            {
                sku: "MC-GTV-227",
                price: 25,
                name: "Ready",
                image: 'ready.png',
                descriptions: [
                    "1 Ready Assessment",
                    "Assesses relationship readiness"
                ]
            },
            {
                sku: "MC-OTN-802",
                price: 25,
                name: "Relate",
                image: 'relate.png',
                descriptions: [
                    "1 Relate Assessment",
                    "Assesses Couple's Relationship.",
                    "Includes 1 assessments."
                ]
            },
            {
                sku: "MC-OTN-141",
                price: 40,
                name: "Couples Package",
                image: 'relate.png',
                descriptions: [
                    "Includes 2 assessments.",
                    "Assesses Couple's Relationship.  ",
                    "Includes 2 assessments."
                ]
            }
        ]
    };

    componentDidMount() {

        this.props.cartContents.forEach(item => {

            if(item.sku === 'MC-GTV-227') {
                this.setState({readyQty: item.qty, readyTouched: (item.qty > 0)});
            }
            if(item.sku === 'MC-OTN-802') {
                this.setState({relateQty: item.qty, relateTouched: (item.qty > 0)});
            }
            if(item.sku === 'MC-OTN-141') {
                this.setState({relateCoupleQty: item.qty, relateCoupleTouched: (item.qty > 0)});
            }
        });

        if(this.props.match.params.type === 'ready') {
            this.addToCart('MC-GTV-227', 1);
        } else if (this.props.match.params.type === 'relate') {
            this.addToCart('MC-OTN-802', 1);
        } else {
            this.addToCart('MC-OTN-141', 1);
        }

        this.props.history.push('/customer/email');

    }

    addToCart = (sku, qty) => {

        if(qty === 1) {

            const products = [...this.state.products];

            const product = products.filter(product => product.sku === sku);

            const cart = {
                productName: product[0].name,
                qty: qty,
                price: product[0].price,
                sku: product[0].sku,
            };

            const inCart = this.props.cartContents.some(item => item.sku === sku);

            if (!inCart && qty > 0) {
                this.props.onAddToCart(cart);
            }
        }

    };

    render() {
        return (

            <>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4" style={{fontSize: '2.3em'}}>Processing...</h5>
                </div>
                <Spinner />



            </>
        )
    }
}

const mapReduxStateToProps = (reduxState) => {
    return {
        cartContents: reduxState.checkOut.cart.cartContents,
        cartQty: reduxState.checkOut.cart.totalQty,
        isAuthenticated: reduxState.oldReducer.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddToCart: (cart) => dispatch(actionCreators.addToCart(cart)),
        onUpdateCart: (cart) => dispatch(actionCreators.updateCart(cart)),
        onRemoveFromCart: (sku) => dispatch(actionCreators.removeFromCart(sku)),
    };
};

export default connect(mapReduxStateToProps, mapDispatchToProps) (AddToCart);
