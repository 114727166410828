import React from "react";
import axios from "../axios/axios-custom";
import LinkSkeleton from "./LinkSkeleton";
import Spinner from "../Spinner/Spinner";
import AlertError from "../Error/AlertError";

class EmailLink extends React.Component {

    state = {
        assessmentId: '',
        linkCode: '',
        linkResult: {
            originalAssessmentId: '',
            linked: false,
            activatedAssessments: []
        },
        errorDetails: '',
        error: false,
        checked: '',
        loading: true,
        showSpinner: false,
    };

    componentDidMount() {

        const linkCode = this.props.match.params.linkCode;

        console.log(linkCode);

        this.setState({linkCode: linkCode});

        this.attemptToLinkAssessments(linkCode);

    }

    processLinkResults = () => {

        console.log(this.state.linkResult);

        if (this.state.linkResult.linked === true) {
            // redirect to the link page
            this.props.history.push(`/assessments`);
        } else if (this.state.linkResult.linked === false && this.state.linkResult.activatedAssessments.length === 0) {

            this.setState({
                error: true,
                errorDetails: 'You do not have any completed assessments to link. Please, complete your assessment before trying to link results.',
                loading: false
            });

        } else if (this.state.linkResult.linked === false && this.state.linkResult.activatedAssessments.length > 0) {

            this.setState({error: false, errorDetails: '', loading: false});

        } else {
            this.setState({error: true, errorDetails: 'Unexpected error has occurred.', loading: false});
        }
    };


    attemptToLinkAssessments = (linkCode) => {


        axios.get(`${process.env.REACT_APP_API_URL}/api/assessment/partner-link/${linkCode}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {

                this.setState({
                    linkResult: response.data,
                }, () => this.processLinkResults());

            })
            .catch(error => {

                this.setState({loading: false, showSpinner: false});

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({error: true, errorDetails: error.response.data.detail, loading: false, showSpinner: false});
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                    this.setState({loading: false, showSpinner: false});
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

            });

    };


    linkTwoAssessments = (assessmentIdToLink) => {

        this.setState({showSpinner: true});

        const primaryAssessmentId = this.state.linkResult.originalAssessmentId;


        const linkInAccountData = {

            assessmentId1: primaryAssessmentId,
            assessmentId2: assessmentIdToLink,

        };
        let url = `${process.env.REACT_APP_API_URL}/api/assessment/link/in-account`;

        axios.post(url, linkInAccountData, {})
            .then(response => {

                this.props.history.push(`/assessments`);

                // this.setState({
                //     linkInfo: response.data,
                //     loading: false,
                //     showSpinner: false
                // });

            })
            .catch(error => {

                this.setState({loading: false, showSpinner: false});

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({error: true, errorDetails: error.response.data.detail, loading: false, showSpinner: false});
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                    this.setState({loading: false, showSpinner: false});
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }
                //console.log(error);

            });


    };

    handleLinkSelectClick = (id) => {

        if (id === this.state.checked) {
            this.setState({checked: ''});
        } else {
            this.setState({checked: id});
        }
    };


    render() {

        let options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };

        let assessmentsToLink = this.state.linkResult.activatedAssessments.map((assessment, idx) => {


            return (

                <tr className=' table-middle text-center'>
                    <td className="w-auto">
                        <button
                            className={this.state.checked === assessment.surveyId ? 'btn btn-sm btn-success btn-block text-left' : 'btn btn-sm btn-outline-success btn-block text-left'}
                            onClick={(id) => this.handleLinkSelectClick(assessment.surveyId)}>
                            {this.state.checked === assessment.surveyId ? <><i
                                className="fal fa-check-square fa-lg"/> <span className="ml-2">{assessment.nickname}</span></> : <><i
                                className="fal fa-square fa-lg"/> <span className="ml-2">{assessment.nickname}</span></>}
                        </button>
                    </td>
                    <td className="w-auto">
              <span title={assessment.surveyName + ' ' + assessment.redemptionCode}>
                {new Date(assessment.completedAt).toLocaleString('en-us', options)}
                  {/*{new Date(assessment.completedAt).toLocaleString('en-us', options)}*/}
              </span><br/>
                    </td>
                    <td className="w-25 text-center table-middle">
                        {this.state.checked === assessment.surveyId ?
                            <button className='btn btn-sm btn-dark'
                                    onClick={(id) => this.linkTwoAssessments(assessment.surveyId)}>
                                Link
                            </button>
                            : ''}

                    </td>
                </tr>

            )
        });


        return <>
            {this.state.error && <AlertError>{this.state.errorDetails}</AlertError>}

            {this.state.loading &&
                <LinkSkeleton/>
            }

            {this.state.showSpinner && <Spinner />}

            {this.state.linkResult.activatedAssessments.length > 0 && !this.state.showSpinner &&

            <div className="card mt-4">
                <div className="card-header" style={{fontSize: '.9em', color: '#646464'}}>
                    Choose the assessment results you would like to link to your partner's from the list below
                </div>
                <div className="card-body scroll">
                    <div className="container">

                        <table className="table table-fixed">
                            <tbody>
                            <tr className="text-center">
                                <th>Select</th>
                                <th>Completed</th>
                                <th>Action</th>
                            </tr>
                            {assessmentsToLink}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            }

        </>;


    }
}

export default EmailLink;
