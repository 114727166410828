import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;


const requestHandler = (request) => {
    // Modify request here
   // request.headers['X-CodePen'] = 'https://codepen.io/teroauralinna/full/vPvKWe';

  let token = localStorage.getItem('token');

  if(token !== null || token !== '') {
    request.headers['Authorization'] = 'Bearer ' + token;
  }

  return request
};

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
);

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {


  if (error.response && error.response.status === 401) {
    window.location = '/logout';
  }

  return Promise.reject(error);

});

export default axiosInstance;