import React, {Component} from 'react';

class DropDown extends Component {

  state = {

    rowClass: 'form-control',
    selected: [],
    value: '',

  };

  handleSelect = (questionId, event) => {



    if(event.target.value.length > 0) {

      this.setState({value: event.target.value, selected: this.state.selected.concat(questionId)});

      // this.setState({
      //   // rowClass: "selected-response",
      //   selected: this.state.selected.concat(questionId)
      // });

      this.props.testMethod(questionId, event.target.value);

    }
  };

  render() {

    return (

        this.props.questions.map((question, i) => {

         // let valueToShow = '';

          return (

                  <div className="card mb-5" key={question.questionId}>
                    <div className="card-header">
                      <h5 className="card-title">{question.questionText}</h5>
                    </div>

                    <div className="card-body">

                      <table className="table table-borderless mt-3">
                        <tbody>
                        <tr className={
                          this.state.selected.includes(question.questionId) || question.selectedQuestionResponse !== 0
                              ? "empty"
                              : ""
                        }
                        >
                          <td>
                            {/*<label htmlFor={"questionLine" + question.questionId}></label>*/}
                            <div className="row">
                              <div className="col-sm">
                                <select className="form-control"
                                        id={"questionLine" + question.questionId}
                                        name={"responseChoice" + question.questionId}
                                        onChange={(e) => this.handleSelect(question.questionId,
                                            e)} value={question.selectedQuestionResponse !== 0 && this.state.value === ''
                                    ? question.selectedQuestionResponse : this.state.value}>
                                  {question.selectedQuestionResponse === 0 &&
                                  <>
                                  <option value="">
                                    Select a Response
                                  </option>
                                  </>
                                  }

                                  {

                                    question.responseChoices.map(choice => {
                                      return (
                                          <option value={choice.responseChoiceId}
                                                  key={choice.responseChoiceId}>
                                            {choice.labelText}
                                          </option>
                                      )
                                    })
                                  }
                                  <optgroup label=""></optgroup>
                                </select>
                              </div>
                              <div className="col-sm">
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>





                    </div>
                  </div>

              )

            }
        )

    )
  }

}

export default DropDown;