import React, {useEffect, useRef, useState} from 'react';
import axios from "../axios/axios-custom";
import {withRouter} from "react-router";
import AlertError from "../Error/AlertError";

function Email(props) {

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorDetail, setErrorDetail] = useState("You need to accept terms of service and enter a name for your assessment.");

  const emailRef = useRef(null);

  useEffect(() => {
      emailRef.current.focus();
    if(localStorage.getItem('tempUserId') !== null) {
      localStorage.removeItem('tempUserId');
    }

  });

  const checkEmail = (e) => {

    e.preventDefault();

    if(validateEmail(email.trim())) {

      const emailData = {
        email: email.trim(),
      };


      let url = `${process.env.REACT_APP_API_URL}/api/users/email/validate`;

      axios.post(url, emailData)
      .then(response => {

        if (response.status >= 200 && response.status < 300) {

          props.history.push({
            pathname:"/auth",
            state:{
              from: { pathname: "/payment" },
              email: emailData.email
            }
          });
        }


      })
      .catch(error => {

        // Error 😨
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          props.history.push({
            pathname:"/payment",
            state:{
              email: emailData.email
            }
          });
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          //console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          //console.log('Error', error.message);
        }
        //console.log(error);

      });

    } else {
      setError(true);
      setErrorDetail("Please enter a valid email address");
    }









    // if(termsAccepted && nickName.length > 0) {
    //
    //   setError(false);
    //
    //   props.beginAssessment(termsAccepted, nickName.trim())
    //   .then((response) => {
    //
    //     if (response.status >= 200 && response.status < 300) {
    //
    //       jQuery('#nicknameModal').modal('hide');
    //
    //       props.history.push(`/survey5/${response.data.surveyId}`);
    //
    //     }
    //
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     setError(true);
    //     setErrorDetail(error.response.data.detail);
    //   });
    //
    //
    //
    //
    //   // let termsData = {
    //   //   termsAccepted: termsAccepted,
    //   //   nickName: nickName
    //   // };
    //   //
    //   // axios.post(`${process.env.REACT_APP_API_URL}/api/survey/${props.redemptionCode}`, termsData, {
    //   //   headers: {
    //   //     Authorization: 'Bearer ' + localStorage.getItem('token')
    //   //   }
    //   // })
    //   // .then((response) => {
    //   //
    //   //   if (response.status >= 200 && response.status < 300) {
    //   //
    //   //     jQuery('#nicknameModal').modal('hide');
    //   //
    //   //     props.history.push(`/survey5/${response.data.surveyId}`);
    //   //
    //   //   }
    //   //
    //   // })
    //   // .catch(error => {
    //   //   console.log(error);
    //   //   setError(true);
    //   //   setErrorDetail(error.response.data.detail);
    //   // });
    //
    // } else {
    //   setError(true);
    // }
  };

  const validateEmail = (email) => {

    let result = true;

    if (email.indexOf("@") <= 0) {
      result = false;
    }
    return result;

  };


  return (

      <>
        <div className="pricing-header mx-auto text-center">
          <h5 className="display-4" style={{fontSize: '2.3em'}}>CREATE AN ACCOUNT</h5>
          <p className="lead">
            Please enter a valid email address to create your account or login.
          </p>
        </div>

        {error && <AlertError>{errorDetail}</AlertError>}

        <form className="form-signin"  onSubmit={checkEmail}>
          <div className="col-md-12 order-md-1">
            <div className="mb-3">
              <label htmlFor="email">Email</label>
              <input type="email" ref={emailRef}
                     className="form-control"
                     id="email" name="email"
                     onChange={e => setEmail(e.target.value)}
                  //  onBlur={(event) => this.validateElementInput(event)}
              />
              <div className="invalid-feedback">
                Please enter a valid email address.
              </div>
            </div>
          </div>
          <hr className="mb-4"/>
          <button className="btn btn-success btn-block" type="submit">Continue</button>
        </form>

                {/*<form onSubmit={saveNickname}>*/}
                {/*  <div className="form-group">*/}
                {/*    <label htmlFor="inputPassword5">Enter the nickname (25 characters max) <small>(Required)</small></label>*/}
                {/*    <input type="text" id="nickname" value={nickName}  onChange={e => setNickName(e.target.value)}*/}
                {/*           maxLength='25' className="form-control" aria-describedby="nickNameHelpBlock" />*/}
                {/*    <small id="nickNameHelpBlock" className="form-text text-muted">*/}
                {/*      Give Your Assessment a nickname (ex. Mary's or John's).  This will help you identify this*/}
                {/*      specific assessment in the future.*/}
                {/*    </small>*/}
                {/*  </div>*/}
                {/*  <div className="custom-control custom-checkbox">*/}
                {/*    <input type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} className="custom-control-input" id="terms"/>*/}
                {/*    <label className="custom-control-label" htmlFor="terms">Check here to indicate that you have read and agree to the</label>*/}
                {/*    <div>*/}
                {/*      <a href=''>Terms of Service</a>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</form>*/}

      </>

  )

}

export default withRouter(Email);