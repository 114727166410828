import React, {Component} from 'react';
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class Cover extends Component {

    showComponent = (componentToShow) => {

        if( this.props.onClick != null) {
            this.props.onClick(componentToShow);
        }

    };

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {

        return (

            <>

                <div className='text-center p-5' style={{backgroundColor: '#dedfdd'}}>
                        <div className="p-2">
                            <div style={{
                                fontWeight: 'bold',
                                fontFamily: 'Nunito',
                                fontSize: '1.2em',
                                color: '#4374a3'
                            }} className="pt-4 mb-4">
                                <b>Your READY assessment has been analyzed and compiled into this multi-page report.</b>
                            </div>

                            <div className="d-sm-flex mb-5">
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('futureRisks')}>
                                    <i className="fal fa-thermometer-three-quarters fa-5x" style={{color: 'white'}}/>
                                    <div>Future Relationship Risk</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('famBackgroundChecklist')}>
                                    <i className="fal fa-tasks fa-5x" style={{color: 'white'}}/>
                                    <div>Family Background Checklist</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('readyFamilyBackground')}>
                                    <i className="fal fa-home-heart fa-5x" style={{color: 'white'}}/>
                                    <div>Family Background</div>
                                </div>
                                <div className='p-2 flex-even pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('relationshipInfluence')}>
                                    <i className="fal fa-signal-stream fa-5x" style={{color: 'white'}}/>
                                    <div>Relationship Influence</div>
                                </div>
                            </div>

                            <div className="d-sm-flex mb-5">
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('myths')}>
                                    <i className="fal fa-transporter-empty fa-5x" style={{color: 'white'}}/>
                                    <div>Dating Myths</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('mentalHealth')}>
                                    <i className="fal fa-head-side-brain fa-5x" style={{color: 'white'}}/>
                                    <div>Mental Health</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('riskBehaviorsChecklist')}>
                                    <i className="fal fa-ballot-check fa-5x" style={{color: 'white'}}/>
                                    <div>Risk Behaviors Checklist</div>
                                </div>
                                <div className='p-2 flex-even pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('relationshipOutlook')}>
                                    <i className="fal fa-rings-wedding fa-5x" style={{color: 'white'}}/>
                                    <div>Relationship Outlook</div>
                                </div>
                            </div>

                            <div className="d-sm-flex mb-5">
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('marriageAdvantage')}>
                                    <i className="fal fa-chart-line fa-5x" style={{color: 'white'}}/>
                                    <div>Marriage Advantage</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('timingOfMarriage')}>
                                    <i className="fal fa-clock fa-5x" style={{color: 'white'}}/>
                                    <div>Timing Of Marriage</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('marriageOptimism')}>
                                    <i className="fal fa-house-day fa-5x" style={{color: 'white'}}/>
                                    <div>Marriage Optimism</div>
                                </div>
                                <div className='p-2 flex-even pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('maritalSalience')}>
                                    <i className="fal fa-house-night fa-5x" style={{color: 'white'}}/>
                                    <div>Marital Salience</div>
                                </div>
                            </div>

                            <div className="d-sm-flex mb-5">
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('maritalPermanence')}>
                                    <i className="fal fa-telescope fa-5x" style={{color: 'white'}}/>
                                    <div>Marital Permanence</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('readyAttachment')}>
                                    <i className="fal fa-leaf-heart fa-5x" style={{color: 'white'}}/>
                                    <div>Attachment</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('readyCommunication')}>
                                    <div className="fa-stack fa-3x" style={{color: 'white'}}>
                                        <i className="fal fa-comment fa-stack-2x"/>
                                        <i className="fal fa-heart fa-stack-1x"/>
                                    </div>
                                    <div>Effective Communication</div>
                                </div>
                                <div className='p-2 flex-even pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('readyConflictResolve')}>
                                    <i className="fal fa-handshake fa-5x" style={{color: 'white'}}/>
                                    <div>Conflict Resolution</div>
                                </div>

                            </div>

                            <div className="d-sm-flex mb-5">
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('readyAggression')}>
                                    <i className="fal fa-exclamation-circle fa-5x" style={{color: 'white'}}/>
                                    <div>Relational Aggression</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('personalTraits')}>
                                    <i className="fal fa-hand-holding-seedling fa-5x" style={{color: 'white'}}/>
                                    <div>Trait - Emotional Readiness</div>
                                </div>
                                <div className='p-2 flex-even mr-md-5 pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('warmth')}>
                                    <i className="fal fa-scarf fa-5x" style={{color: 'white'}}/>
                                    <div>Trait - Warmth</div>
                                </div>
                                <div className='p-2 flex-even pt-md-4 sum-border mb-2 pointer-cursor cover'
                                     onClick={() => this.showComponent('conscience')}>
                                    <i className="fal fa-book-heart fa-5x" style={{color: 'white'}}/>
                                    <div>Trait - Conscientiousness</div>
                                </div>
                            </div>

                        </div>

                </div>

                {/*HOW TO READ THIS REPORT*/}
                <div className="page-break"/>


                <hr className='mt-5'/>


                <div className="d-sm-flex">
                    <div className="p-2 flex-30">
                        <div style={{
                            fontWeight: 'bold',
                            fontFamily: 'Nunito',
                            fontSize: '2em',
                            color: '#4374a3'
                        }} className="pb-4">
                            <b>HOW TO READ THIS REPORT</b>
                        </div>
                    </div>
                    <div className="p-2 flex-70  text-justify pl-5 pr-5" style={{borderLeft: '1px solid #eee'}}>
                        <div>
                            This report presents a summary profile of you, your
                            partner, and your relationship in areas that
                            researchers at the RELATE Institute have found to
                            be most important in influencing relationship
                            quality. We encourage you to discuss together
                            what the results mean for each of you personally
                            and for your relationship.
                        </div>

                        <div className="p-2 flex-30">
                            <div style={{
                                fontWeight: 'bold',
                                fontFamily: 'Nunito',
                                fontSize: '1.3em',
                                color: '#4374a3'
                            }} className="pb-2 mt-4">
                                <b>HOW TO READ THIS REPORT'S GRAPHS</b>
                            </div>
                        </div>

                        <h4>Calculation of Strength and Challenge Zones</h4>
                        <div className="pb-3">Charts in the <b>READY</b> report are divided into 3 zones:</div>

                        <div style={{fontFamily: 'Nunito', fontSize: '1.2em'}}>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <div style={{
                                            width: '100px',
                                            backgroundColor: '#4375a4',
                                            border: '7px solid #4375a4'
                                        }}>
                                        </div>
                                    </td>
                                    <td align='left' className='pl-4'>Strength</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div style={{
                                            width: '100px',
                                            backgroundColor: '#28b7b2',
                                            border: '7px solid #28b7b2'
                                        }}>
                                        </div>
                                    </td>
                                    <td align='left' className='pl-4'>Needs Improvement</td>

                                </tr>
                                <tr>
                                    <td>
                                        <div style={{
                                            width: '100px',
                                            backgroundColor: '#dc2229',
                                            border: '7px solid #dc2229'
                                        }}>
                                        </div>
                                    </td>
                                    <td align='left' className='pl-4'>Challenge</td>

                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-3 mb-3">The cut-offs between zones were calculated by comparing
                            scores with partners’ reports of relationship satisfaction via a
                            large, national sample of couples in the United States.
                        </div>
                        <div className="mb-3">
                            The cut-off point for the <b style={{color: '#4375a4', fontSize: '1.2em'}}>BLUE ZONE</b> is the level where 90%
                            or more people reporting that score were satisfied with their
                            relationship.
                        </div>
                        <div className="mb-3">
                            The <b style={{color: '#28b7b2', fontSize: '1.2em'}}>GREEN ZONE</b> is the level where 70% to 89% typically
                            report being highly satisfied.
                        </div>
                        <div className="mb-3">
                            The <b style={{color: '#dc2229', fontSize: '1.2em'}}>RED ZONE</b> indicates the level where less than 70% of
                            individuals reported being satisfied with their relationships.
                        </div>

                        <div className="p-2 flex-30">
                            <div style={{
                                fontWeight: 'bold',
                                fontFamily: 'Nunito',
                                fontSize: '1.2em',
                                color: '#4374a3'
                            }} className="pb-2 mt-4">
                                <b>GUIDELINES FOR INTERPRETING YOUR <span className='text-dark'>READY</span> REPORT</b>
                            </div>
                        </div>


                        <div className="mt-3 mb-3">
                            Keep in mind that <b>READY</b> is not designed to predict the
                            success of your future relationships, nor to diagnose possible
                            personal or relationship difficulties.
                        </div>
                        <div className="mb-3">
                            Use the <b>READY</b> report to focus discussion with your partner
                            on developing your strengths and overcoming your
                            challenges.
                        </div>
                        <div className="mb-3">
                            The accuracy of each graph will depend on the level of
                            honesty and insight you had when you responded to the <b>READY</b> questionnaire, as well as when you now read this
                            report.
                        </div>
                        <div className="mb-3">
                            The <b>READY</b> Report contains sensitive information. If serious
                            problems related to any of these issues are hampering your
                            ability to maintain a satisfying relationship you may want to
                            consider seeking assistance in resolving these problems.
                        </div>

                        <div className="mb-3">
                            If a scale or chart in the Summary Profile does not seem
                            correct, look up the specific questions and answers for that
                            scale online under that sections table, located bottom right
                            page of that sections report.
                        </div>

                    </div>
                </div>

                <div className="page-break"/>

            </>

        )

    }

}

export default withWindowSizeListener(Cover);