import React from 'react';
import {StripeProvider} from 'react-stripe-elements';
import ProCheckout from "./ProCheckout";

const proStripe = ( props ) => {
    return (
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <ProCheckout />
        </StripeProvider>
    );

};

export default proStripe;