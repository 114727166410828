import React, {Component} from 'react';
import {Redirect, Route} from "react-router";

class PaymentRoute extends Component {


  render() {
    const { component: Component, ...props } = this.props;

    return (
        <Route
            {...props}
            render={props => (
                (localStorage.getItem('cart') !== null) ?
                    <Component {...props} /> :
                    <Redirect to='/pricing' />
            )}
        />
    )
  }
}

export default PaymentRoute;