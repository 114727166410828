import React from 'react';

const receiptItem = (props) => {

  return (

      <>

        <div className="row">
          <div className="col-sm-2">
            {/*<img className="img-responsive" src="http://placehold.it/120x80" alt="prewiew" width="120" height="80"/>*/}
              <img className="img-responsive" src={`/images/${props.sku.toLowerCase()}.jpg`} width="120" alt={props.sku} />
          </div>
          <div className="col-sm-10">
            <h4 className="product-name"><strong>{props.productName} Assessment</strong></h4>
            <h4>
              <small>Redemption code: {props.redemptionCode}</small>
            </h4>
          </div>
        </div>
        <hr/>


      </>

  )};

export default receiptItem;