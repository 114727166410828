import React, {Component} from 'react';
import Radial from "./Radial";
import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';

class MarriageOptimism extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-house-day fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            MARRIAGE OUTLOOK
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    Though many of us want to marry, we often approach it fearfully.  We have all witnessed at least one failed marriage
                    and this can make us wonder if successful, happy marriage is even possible.  Yet some of us still remain hopeful of
                    finding lasting love, especially if it remains one of our most desired life goals.  This section assesses how hopeful
                    you are for a future of lasting marriage bliss.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="pt-4">
                    <b>MARRIAGE OPTIMISM: <span className='text-black-70'>{this.props.optimism.primarySelf[0] < 50 && 'Low'}
                        {this.props.optimism.primarySelf[0] >= 50 && 'High'}</span></b>
                </div>

                {this.props.windowSize.windowWidth >= 710 &&
                <div className="d-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill align-items-center text-center gauge">Low
                    </div>
                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.optimism.primarySelf[0]} cId='byek'/>
                    </div>
                    <div className="p-2 flex-fill gauge">High
                    </div>
                </div>
                }

                {this.props.windowSize.windowWidth < 710 &&

                <div className="d-flex justify-content-around align-items-center">

                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.optimism.primarySelf[0]} cId='byek'/>
                    </div>

                </div>
                }

                <div style={{borderBottom: '2px #4374a3 solid', marginTop: '-8em', zIndex: 9999}} />

                <div className="d-flex justify-content-around align-items-start">
                    <div className={'mt-2 p-2 flex-even ' + (this.props.optimism.primarySelf[0] < 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">Low</div>
                            }
                            <p>
                                Low: A low score in this scale indicates you are afraid of commitment and failing marriage.
                                With high divorce rates and a lack of commitment in general, who can blame you?  To you, committed relationships are seen as fragile.
                                However, research has found that fear of something happening often leads to that very thing happening.  In other words, if
                                you treat relationships and future marriage as fragile you will make them fragile through a lack of clear goals and hopes for
                                the relationship, holding back on commitment, and short-term thinking.  This applies in dating too.  If you approach a potential
                                relationship as something fragile it will likely be less satisfying.  Look for reasons to form or stay in relationships rather than reasons to leave.
                            </p>
                        </div>
                    </div>
                    <div className={'mt-2 p-2 flex-even ' + (this.props.optimism.primarySelf[0] >= 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">High</div>
                            }
                            <p>
                                High: A high score in this scale indicates you remain optimistic about your future marriage.
                                You are not afraid to marry and are confident it will make you happy.  This can be helpful as being optimistic for long-lasting
                                relationships encourages greater investment in the relationship which in turn increases feelings of commitment and stability itself.
                                In other words, being optimistic of the future leads to the very behaviors that are more likely to make marriage work.
                            </p>
                        </div>
                    </div>
                </div>

                <div style={{borderBottom: '2px #4374a3 solid'}} className='mb-5'/>

            </>

        )

    }

}

export default withWindowSizeListener(MarriageOptimism);