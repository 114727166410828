import React, {Component} from 'react'
//import axios from "axios";
import axios from "../axios/axios-custom";
import KindnessAndFlexibility from "./relate/KindnessAndFlexibility";
import EmotionalReadiness from "./relate/EmotionalReadiness";
import RelationshipQuality from "./relate/RelationshipQuality";
import FamilyBackground from "./relate/FamilyBackground";
import RelationshipEffort from "./relate/RelationshipEffort";
import EffectiveCommunication from "./relate/EffectiveCommunication";
import ConflictResolution from "./relate/ConflictResolution";
import SexualSatisfaction from "./relate/SexualSatisfaction";
import RelationalAggression from "./relate/RelationalAggression";
import Attachment from "./relate/Attachment";
import Spinner from '../Spinner/Spinner';
import Error from '../Error/Error';
import RelateReportMenu from "./RelateReportMenu";
import NavButton from "../layout/navbar/navigationItems/NavButton";
import {NavLink} from "react-router-dom";
import ReportNavigation from "./ReportNavigation";
import Cover from "./relate/Cover";
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class Report extends Component {

    state = {
        reportInfo: {},
        error: '',
        errorDetail: '',
        assessmentId: '',
        errorStatus: 0,
        componentToShow: 'kindnessAndFlexibility',
        selfNickname: '',
        partnerNickname: '',
        username: '',
        partnerUsername: '',
        sections: {
            cover: 'cover',
            kindness: 'kindnessAndFlexibility',
            emotion: 'emotionalReadiness',
            family: 'familyBackground',
            quality: 'relationshipQuality',
            effort: 'relationshipEffort',
            communication: 'effectiveCommunication',
            conflict: 'conflictResolution',
            satisfaction: 'sexualSatisfaction',
            aggression: 'relationalAggression',
            attachment: 'attachment',
        },

        reverseSections: {
            cover: 'cover',
            kindnessAndFlexibility: 'kindness',
            emotionalReadiness: 'emotion',
            familyBackground: 'family',
            relationshipQuality: 'quality',
            relationshipEffort: 'effort',
            effectiveCommunication: 'communication',
            conflictResolution: 'conflict',
            sexualSatisfaction: 'satisfaction',
            relationalAggression: 'aggression',
            attachment: 'attachment',
        },

        readable: {
            cover: 'Report Summary',
            kindnessAndFlexibility: 'Kindness & Flexibility',
            emotionalReadiness: 'Emotional Readiness',
            familyBackground: 'Family Background',
            relationshipQuality: 'Relationship Quality',
            relationshipEffort: 'Relationship Effort',
            effectiveCommunication: 'Effective Communication',
            conflictResolution: 'Conflict Resolution',
            sexualSatisfaction: 'Sexual Satisfaction',
            relationalAggression: 'Relational Aggression',
            attachment: 'Attachment',
        },

        componentKeys: [],
        componentsLength: 0,


    };

    componentDidMount() {

        WindowSizeListener.DEBOUNCE_TIME = 1000;

        const assessmentId = this.props.match.params.assessmentId;

        let section = this.props.match.params.section;

        if (typeof section === 'undefined') {

            section = 'cover';

        }

        if (this.state.sections[section] !== null && this.state.sections[section] !== '') {
            section = this.state.sections[section];
        } else {
            section = 'cover';
        }

        this.setState({
            assessmentId: assessmentId,
            componentToShow: section,
            componentKeys: this.getComponentKeys(),
            componentsLength: this.getComponentLength()
        });

        this.loadAssessmentResult(assessmentId);
        this.getNicknames(assessmentId);


        // From ComponentWillMOunt
        // Not sure why I had it there to begin with
        this.unlisten = this.props.history.listen((location, action) => {

            if (!location.pathname.includes('print')) {

                let section = this.props.match.params.section;

                if (typeof section === 'undefined') {

                    section = 'cover';

                }

                if (this.state.sections[section] !== null && this.state.sections[section] !== '') {
                    section = this.state.sections[section];
                } else {
                    section = 'cover';
                }

                this.setState({componentToShow: section});

            }
        });

    }

    getNicknames = (assessmentId) => {

        let url = `${process.env.REACT_APP_API_URL}/api/assessment/${assessmentId}/nickname`;

        axios.get(url)
            .then((response) => {

                this.setState({
                    selfNickname: response.data.nickname, partnerNickname: response.data.partnerNickname,
                    username: response.data.username, partnerUsername: response.data.partnerUsername
                });

            })
            .catch(error => {
                //console.log(error);

                if (error.response.data.status === 422) {
                    this.setState({
                        error: error,
                        errorDetail: error.response.data.detail,
                        errorStatus: error.response.data.status
                    });
                } else {
                    this.setState({error: error});
                }

            });

    };

    // componentWillMount() {
    //
    //
    //   this.unlisten = this.props.history.listen((location, action) => {
    //
    //     if(!location.pathname.includes('print')) {
    //
    //       let section = this.props.match.params.section;
    //
    //       if(typeof section === 'undefined') {
    //
    //         section = 'cover';
    //
    //       }
    //
    //       if(this.state.sections[section] !== null && this.state.sections[section] !== '') {
    //         section = this.state.sections[section];
    //       } else {
    //         section = 'cover';
    //       }
    //
    //       this.setState({componentToShow: section});
    //
    //     }
    //   });
    // }

    componentWillUnmount() {
        this.unlisten();
    }

    loadAssessmentResult = (assessmentId) => {

        let url = `${process.env.REACT_APP_API_URL}/api/report/${assessmentId}/sect/1`;

        axios.get(url)
            .then((response) => {

                let respObject = response.data.reduce((obj, item) => {
                    obj[item.subsection] = item.resultValues;
                    return obj;
                }, {});

                console.log(respObject);

                this.setState({reportInfo: respObject});

            })
            .catch(error => {

                if (error.response.data.status === 422 || error.response.data.status === 412) {
                    this.setState({
                        error: error,
                        errorDetail: error.response.data.detail,
                        errorStatus: error.response.data.status
                    });
                } else {
                    this.setState({error: error});
                }

            });
    };

    showComponent = (currentComponentToShow) => {

        let newUrl = "/report/" + this.state.assessmentId + '/' + this.state.reverseSections[currentComponentToShow];

        this.props.history.push(newUrl);
        window.scrollTo(0, 0);
        this.setState({componentToShow: currentComponentToShow});


    };


    getComponentKeys = () => {
        return Object.keys(this.state.reverseSections);
    };

    getComponentLength = () => {
        return Object.keys(this.state.reverseSections).length;
    };

    getCurrentIndex = () => {
        return this.getComponentKeys().indexOf(this.state.componentToShow)
    };


    render() {

        let cover = '';

        let kindnessAndFlexibility = '';
        let emotionalReadiness = '';
        let familyBackground = '';
        let relationshipQuality = '';
        let relationshipEffort = '';
        let effectiveCommunication = '';
        let conflictResolution = '';
        let sexualSatisfaction = '';
        let relationalAggression = '';
        let attachment = '';

        if (this.state.reportInfo.kindAndFlex) {

            cover = <><Cover onClick={this.showComponent} nickname={this.state.selfNickname}
                             partner={this.state.partnerNickname} username={this.state.username}/>
                <hr className='vhr'/>
            </>;

            kindnessAndFlexibility = <><KindnessAndFlexibility
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                kindAndFlex={this.state.reportInfo.kindAndFlex} kind={this.state.reportInfo.kind}
                flex={this.state.reportInfo.kindAndFlex}/>
                <hr className='vhr'/>
            </>;

            emotionalReadiness = <><EmotionalReadiness
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                emotionReady={this.state.reportInfo.emotionReady}
                maturity={this.state.reportInfo.maturity}
                calm={this.state.reportInfo.calm}
                selfEsteem={this.state.reportInfo.selfEsteem}
                happiness={this.state.reportInfo.happiness}/>
                <hr className='vhr'/>
            </>;

            familyBackground = <><FamilyBackground
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                famBackground={this.state.reportInfo.famBackground}
                famQuality={this.state.reportInfo.famQuality}
                parentMarriage={this.state.reportInfo.parentMarriage}
                famInfluence={this.state.reportInfo.famInfluence}/>
                <hr className='vhr'/>
            </>;

            relationshipQuality = <><RelationshipQuality
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                satisfaction={this.state.reportInfo.satisfaction}
                stability={this.state.reportInfo.stability}
                probArea={this.state.reportInfo.probArea}
                overallRelSatisfaction={this.state.reportInfo.overallRelSatisfaction}/>
                <hr className='vhr'/>
            </>;


            relationshipEffort = <><RelationshipEffort
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                relEffort={this.state.reportInfo.relEffort}/>
                <hr className='vhr'/>
            </>;

            effectiveCommunication = <><EffectiveCommunication
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                overallEffComm={this.state.reportInfo.overallEffComm}
                empathy={this.state.reportInfo.empathy}
                clearSending={this.state.reportInfo.clearSending}/>
                <hr className='vhr'/>
            </>;

            conflictResolution = <><ConflictResolution
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                overallConflictResol={this.state.reportInfo.overallConflictResol}
                noncritical={this.state.reportInfo.noncritical}
                notOverwhelmed={this.state.reportInfo.notOverwhelmed}
                respect={this.state.reportInfo.respect}/>
                <hr className='vhr'/>
            </>;

            sexualSatisfaction = <><SexualSatisfaction
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                sexSatisfaction={this.state.reportInfo.sexSatisfaction}/>
                <hr className='vhr'/>
            </>;

            relationalAggression = <><RelationalAggression
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                relAggression={this.state.reportInfo.relAggression}/>
                <hr className='vhr'/>
            </>;

            attachment = <><Attachment
                nickname={this.state.selfNickname} partner={this.state.partnerNickname} username={this.state.username}
                partnerUsername={this.state.partnerUsername}
                attachment={this.state.reportInfo.attachment}/>
                <hr className='vhr'/>
            </>;
        }


        return (

            <>

                {!this.state.reportInfo.kindAndFlex && !this.state.error && <Spinner/>}

                {
                    this.state.error && this.state.errorDetail.length === 0 &&
                    <Error heading='Assessment Results'>
                        There was a problem displaying your assessment results. An unexpected error has occurred.
                        If you believe you reached this page in error, please try again later or contact support.
                    </Error>
                }

                {
                    this.state.error && this.state.errorDetail.length > 0 &&
                    <Error heading='Assessment Results'>
                        {this.state.errorDetail}
                        {this.state.errorStatus === 422 &&
                        <div>
                            Complete your assessment <NavLink className='alert-link'
                                                              to={`/survey/${this.props.match.params.assessmentId}`}>Here</NavLink>
                        </div>
                        }
                    </Error>
                }


                {
                    this.state.reportInfo.kindAndFlex &&
                    <>
                        <RelateReportMenu onClick={this.showComponent}/>
                        {this.props.windowSize.windowWidth > 710 &&
                        <NavButton className="btn btn-outline-success ml-4"
                                   link={`/report/action/print/${this.state.assessmentId}`}>
                            <i className="fal fa-print"></i> Print Report
                        </NavButton>
                        }

                        <ReportNavigation onClick={this.showComponent}
                                          next={this.state.componentKeys[this.getCurrentIndex() + 1]}
                                          nextValue={this.state.readable[this.state.componentKeys[this.getCurrentIndex() + 1]]}
                                          prev={this.state.componentKeys[this.getCurrentIndex() - 1]}
                                          prevValue={this.state.readable[this.state.componentKeys[this.getCurrentIndex() - 1]]}
                                          current={this.getCurrentIndex()}
                                          componentsLength={this.state.componentsLength}

                        />

                        <hr/>

                    </>

                }


                {this.state.componentToShow === 'cover' && cover}
                {this.state.componentToShow === 'kindnessAndFlexibility' && kindnessAndFlexibility}
                {this.state.componentToShow === 'emotionalReadiness' && emotionalReadiness}
                {this.state.componentToShow === 'familyBackground' && familyBackground}
                {this.state.componentToShow === 'relationshipQuality' && relationshipQuality}
                {this.state.componentToShow === 'relationshipEffort' && relationshipEffort}
                {this.state.componentToShow === 'effectiveCommunication' && effectiveCommunication}
                {this.state.componentToShow === 'conflictResolution' && conflictResolution}
                {this.state.componentToShow === 'sexualSatisfaction' && sexualSatisfaction}
                {this.state.componentToShow === 'relationalAggression' && relationalAggression}
                {this.state.componentToShow === 'attachment' && attachment}


                {this.state.reportInfo.kindAndFlex && <RelateReportMenu onClick={this.showComponent}/>}

                <ReportNavigation onClick={this.showComponent}
                                  next={this.state.componentKeys[this.getCurrentIndex() + 1]}
                                  nextValue={this.state.readable[this.state.componentKeys[this.getCurrentIndex() + 1]]}
                                  prev={this.state.componentKeys[this.getCurrentIndex() - 1]}
                                  prevValue={this.state.readable[this.state.componentKeys[this.getCurrentIndex() - 1]]}
                                  current={this.getCurrentIndex()}
                                  componentsLength={this.state.componentsLength}

                />
                <div className='mb-2'></div>

            </>

        )


    }
}

export default withWindowSizeListener(Report);