import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import NoAnswer from "../relate/NoAnswer";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from "../../constants/Colors";

class ReadyFamilyBackground extends Component {

    render() {

        let motherScore = Number(this.props.mother.primarySelf[0]);
        let fatherScore = Number(this.props.father.primarySelf[0]);
        let divisor = (motherScore === 0 || fatherScore === 0) ? 1 : 2;

        let relParents = Math.round((motherScore + fatherScore) / divisor);

        let relParentColor = Color.CHALLENGE;

        if (relParents >= 33.33 && relParents < 66.66) {
            relParentColor = Color.IMPROVE;
        } else if (relParents >= 66.66) {
            relParentColor = Color.STRENGTH;
        } else if (relParents === 0) {
            relParentColor = Color.NOT_APPLICABLE;
        }

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-home-heart fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            FAMILY BACKGROUND
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill align-self-center">
                        <div className='pl'>
                        <DonutChart elId='duqs' percent={this.props.famBackground.primarySelf[0]}
                                    color={this.props.famBackground.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            The way we experience relationships in our families
                            as children can shape our ability to relate to others as
                            adults. The above chart should give you a general idea
                            about the level of risk you might bring to a relationship
                            based on your family experiences. Click on the individual
                            sections below to help you identify some of your specific
                            risk areas.
                        </p>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='mt-4 text-justify'>
                    The scales in the following graphs show a comparison of your perceptions of your family background
                    experiences.
                    For all of the scales in this section, higher scores indicate that your experiences with your
                    families (e.g., relationships with parents, etc.)
                    are generally better than those who have low scores.
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>FAMILY QUALITY</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='dvcv' percent={this.props.famQuality.primarySelf[0]}
                                    color={this.props.famQuality.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            This section measures the overall quality of your family life growing up. This score
                            reflects how happy, secure, and fulfilled you felt growing up in your family. The experience
                            you had in your family is likely to influence how important your original family is to you
                            as well as color how you view your future family and relationships. If you experienced
                            high-quality family life you will also be more likely to have successful relationships. When
                            people have happy and positive experiences with their family growing up, they are more
                            likely to bring a positive attitude into any relationship they have. If family experiences
                            were negative, people may bring anxiety, fear, and/or anger into new relationships they
                            form.
                        </p>
                    </div>
                </div>

                {this.props.famQuality.primarySelf[1] === Color.STRENGTH &&

                <StrengthLegendDetail id="dvhx">
                    The quality of life you experienced in your family has prepared you well for relationships. The
                    patterns of interaction in your family were mostly positive, so learn from them and utilize those
                    positive patterns in your new relationships.
                </StrengthLegendDetail>
                }

                {this.props.famQuality.primarySelf[1] === Color.IMPROVE &&

                <ImproveLegendDetail id="dxhc">
                    The experience you had in your family relationships was probably inconsistent, with some good and
                    bad patterns. This inconsistency could continue in your own relationships if you do not take some
                    time to learn which family patterns were and were not helpful to how you feel about relationships.
                    Which patterns from your family give you hope for successful relationships and which patterns arouse
                    feelings of anxiety?
                </ImproveLegendDetail>
                }

                {this.props.famQuality.primarySelf[1] === Color.CHALLENGE &&

                <ChallengeLegendDetail id="efmt">
                    Scoring in this section indicates the quality of your family life growing up is a source of anxiety
                    for you. Try recalling a relationship you have had with someone you trust, and compare that
                    relationship to your family patterns. This could help you recognize why family patterns were not
                    helpful and help you avoid making some of the same mistakes in your future relationships.
                </ChallengeLegendDetail>
                }

                <div className="page-break"/>

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>PARENT'S MARRIAGE</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='ehxf' percent={this.props.parentMarriage.primarySelf[0]}
                                    color={this.props.parentMarriage.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            This section shows how the quality of your parents’ marriage affects how you relate to
                            others. Research has found that we learn how to be in romantic relationships partly by how
                            we see others experience romantic relationships. Since our parents’ relationship is often
                            the first romantic relationship we see, it is no surprise that the quality of our parents’
                            relationships predicts the quality of our relationships as adults.
                        </p>
                    </div>
                </div>

                {this.props.parentMarriage.primarySelf[1] === Color.NOT_APPLICABLE &&

                <NoAnswer id="ejbe"/>

                }

                {this.props.parentMarriage.primarySelf[1] === Color.STRENGTH &&

                <StrengthLegendDetail id="ejpu">
                    Your parents’ relationship is a good example of a successful relationship. They had effective
                    relationship patterns and were able to develop good relationship skills. You do not need to do
                    everything exactly the same as they did, but try to think about what you saw them do and learn why
                    it was so effective.
                </StrengthLegendDetail>
                }

                {this.props.parentMarriage.primarySelf[1] === Color.IMPROVE &&

                <ImproveLegendDetail id="ekgr">
                    You witnessed good and bad in your parents’ relationship. Maybe they went through some rough
                    patches, or maybe they had some ineffective patterns. Think about ways to replace ineffective
                    practices with effective patterns to strengthen your relationships. Try to get some insight into
                    what you may do that mimics unhealthy patterns your parents may have, as this may help you
                    understand why they are ineffective.
                </ImproveLegendDetail>
                }

                {this.props.parentMarriage.primarySelf[1] === Color.CHALLENGE &&

                <ChallengeLegendDetail id="eqjj">
                    Your parents' marriage may be a source of confusion for you in relationships. They did not set a
                    great example for your own relationships. However, having other role models in our relationships can
                    give us examples of positive patterns. Try to think about other couples you have come across. Could
                    any of them serve as more positive role models for relationships? Also, consider doing some reading
                    of your own and seeking professional counsel to learn more effective relationship patterns.
                </ChallengeLegendDetail>
                }


                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>RELATIONSHIP WITH PARENTS</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='euui' percent={relParents} color={relParentColor}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 mb-1 text-justify" style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            This section assesses the quality of your relationship with each of your parents. Just as we
                            learn how to relate to other adults by watching our parents’ relationship with each other,
                            we often learn how to form secure relationships with others by our personal relationship
                            with each of our parents. Research has found that the connections we form with our parents,
                            whether good or bad, influence our ability to connect with others in secure ways. The
                            relationship with our parents teaches us if relationships are safe, secure, and rewarding or
                            if relationships are filled with anxiety, hurt, and mistrust. These charts will help you
                            determine what kind of influence your relationship with your parents may have on your future
                            relationships.
                        </p>
                    </div>
                </div>

                {relParentColor === Color.NOT_APPLICABLE &&

                <NoAnswer id="evdj"/>

                }

                {relParentColor === Color.CHALLENGE &&
                <ChallengeLegendDetail id="ezlv">
                    Your relationship with your parents is probably strained and rocky even now. You might have felt
                    a lot of disapproval from your parents, or felt like your desires were ignored. Or you might
                    have felt like your parents ignored you completely and there was almost no relationship. Either
                    way, you might feel that relationships are more trouble than they are worth. Try thinking about
                    other relationships you have had, romantic or non-romantic, such as a teacher or a friend. How
                    do you feel about those relationships? Also, consider putting some focused energy into repairing
                    or building a relationship with your parents. Creating a healthier relationship with them may
                    provide benefits to eventual romantic relationships.
                </ChallengeLegendDetail>
                }

                {relParentColor === Color.IMPROVE &&
                <ImproveLegendDetail id="fcll">
                    If you scored in this section, you probably experienced some inconsistency in how you were
                    parented. Sometimes you felt safe, wanted, and loved, but other times you felt judged and love
                    was conditional on obedience or doing what your parents wanted. You may find yourself feeling
                    the same in your adult relationships. Perhaps you want to feel safe, but often worry about
                    putting too much trust in another. Or maybe you fear making mistakes and losing your
                    relationship. While you may not be able to change these feelings overnight, try to make sure
                    that you are consistent in your relationships with others and you may find it easier to trust
                    others.
                </ImproveLegendDetail>
                }

                {relParentColor === Color.STRENGTH &&
                <StrengthLegendDetail id="fcrm">
                    Your relationship with your parents has taught you how to open up and feel secure trusting those
                    close to you. Because you have such a strong relationship with your parents, however, you should
                    be cautious to not let them interfere in your new relationships. While your parents may be your
                    primary relationship now, make the switch when you do form a new relationship.
                </StrengthLegendDetail>
                }

                <div className="page-break"/>
                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>FAMILY STRESSORS</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='fcuv' percent={this.props.famStress.primarySelf[0]}
                                    color={this.props.famStress.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 mb-1 text-justify" style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            This section evaluates the general amount of stress experienced in your immediate family
                            growing up. While other sections of this report will focus on specific family relationships,
                            we also know that the general level of stress that was present in your family growing up
                            will impact your own relationships. By stress, we are referring to major events or struggles
                            your family dealt with regularly. Some of these common stressors are financial strains and
                            joblessness, or family members with physical disabilities. While it is not helpful to blame
                            these stressors for relationship challenges, they shape how you relate to others and view
                            relationships overall. How you dealt with these challenges as a family is also an important
                            influence in how you react to challenges in relationships as an adult. Like other sections
                            in this part of the report, family stress is another area where your own views and attitudes
                            toward relationships may be filtered through your experiences as a family.
                        </p>
                    </div>
                </div>

                {this.props.famStress.primarySelf[1] === Color.STRENGTH &&

                <StrengthLegendDetail id="flne">
                    Your family life was fairly calm. Any stress your family did experience was typically handled well
                    and did not have lasting negative effects. Because of this, you have probably developed healthy
                    coping skills when you experience stress in your own life and relationships. Keep developing those
                    skills and when stress does come to your relationships, you will be able to handle it effectively.
                </StrengthLegendDetail>
                }

                {this.props.famStress.primarySelf[1] === Color.IMPROVE &&

                <ImproveLegendDetail id="fnbk">
                    Though all families experience stress, not all families handle stress the same way. Your family was
                    probably eventually able to cope with the stress, but it was not always healthy coping. Perhaps
                    there were certain situations or issues that were not handled in a healthy way. Try to identify
                    these issues and think about how they have affected you. Pay attention to how you react to stress.
                    What is helpful? Do you feel like some of your coping strategies might be unhealthy? A few
                    unresolved issues from your family may continue to cause anxiety and stress in your life moving
                    forward.
                </ImproveLegendDetail>
                }

                {this.props.famStress.primarySelf[1] === Color.CHALLENGE &&

                <ChallengeLegendDetail id="fqru">
                    Your family experienced at least one major stressful event if not many, and how your family coped
                    with that stress may have done harm more often than good. You may find that you do not deal well
                    with stress now. Try discussing with others how they handle stress and try to adopt some healthier
                    coping skills. Consider doing gather additional resources that may help you handle stress in your
                    life. Professional counseling may also be an option if you find that stress and anxiety are
                    interfering with your day-to-day life.
                </ChallengeLegendDetail>
                }

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>FAMILY INFLUENCE</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='fryr' percent={this.props.famInfluence.primarySelf[0]}
                                    color={this.props.famInfluence.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            This section measures how strongly you think your family experience influences your
                            relationships now. While previous sections rely heavily on the assumption that previous
                            family relationships impact your relationships in the future, it is also important to see if
                            you think there is an impact. Sometimes it does not matter if your family background was
                            good or bad as much as how you allow your experience to affect you. Research has found that
                            people who are able to work through bad experiences from their childhood are often able to
                            have successful relationships just as much as someone with a strong family background. While
                            you may not be able to change the results of the previous family sections, use this section
                            as a guide on how you might be able to overcome some of the potential challenges you face or
                            if you are still holding on to negative experiences from your family growing up.
                        </p>
                    </div>
                </div>

                {this.props.famInfluence.primarySelf[1] === Color.STRENGTH &&

                <StrengthLegendDetail id="fxjp">
                    If you scored in this section then you either feel your family life was positive overall, or you
                    have already worked through many of the struggles from your family life and they do not affect you
                    as strongly as they once did. Whether because of the past or despite the past, you feel confident in
                    relationships and see them as a source of good for your life.
                </StrengthLegendDetail>
                }

                {this.props.famInfluence.primarySelf[1] === Color.IMPROVE &&

                <ImproveLegendDetail id="fyrq">
                    If you scored in this section you may feel unsure about relationships because of the experiences you
                    had in your family. Your perceptions about relationships may be skewed towards insecurity and
                    anxiety, but you also have moments of hope. You have probably also begun the process of working
                    through some of the negative experiences from your past and are beginning to feel more confident.
                    Keep working and hoping. Focus on your specific weaknesses and you are not far from feeling safe and
                    secure in future relationships.
                </ImproveLegendDetail>
                }

                {this.props.famInfluence.primarySelf[1] === Color.CHALLENGE &&

                <ChallengeLegendDetail id="gdfj">
                    A score in this section indicates that you struggle with understanding what you experienced in your
                    family. You probably focus a lot on the negative feelings, which make you feel like a victim of
                    broken trust. Try to get out of this mindset, and think more about what you can control and learn
                    ways to adopt healthier patterns so you do not carry on those same harmful practices into future
                    relationships.
                </ChallengeLegendDetail>
                }

            </>

        )

    }

}

export default ReadyFamilyBackground;