import React from "react";
import NavButton from "../layout/navbar/navigationItems/NavButton";
import {NavLink} from "react-router-dom";

class FinishedAssessmentItem extends React.Component {

    state = {
        link: false,
        error: false,
        errorDetails: "",
        spinner: false,
        checkedItems: new Map(),
        checked: false,
    };

    componentDidMount() {
    }

    handleClick = (id) => {
        this.setState({checked: !this.state.checked});
    };


    handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({link: !this.state.link});

        // const item = e.target.name;
        // const isChecked = e.target.checked;
        // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));

    };

    nickNameAssessment = () => {
        this.props.setRedemptionCode(this.props.redemptionCode);

        this.props.nickNameAssessment();

    };

    updateNickname = () => {

        this.props.setCurrentNickname(this.props.assessment.nickname, this.props.assessment.surveyId);

        this.props.updateThisNickname();

    };

    render() {

        //console.log(this.props);

        let dateAsString = '2020-01-14T16:40:31';
        let completedDate = new Date(this.props.assessment.completedAt);
        let options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };
        //https://stackabuse.com/how-to-format-dates-in-javascript/

        let parts = dateAsString.split('-');
       // console.log(parts);
        //console.log(completedDate.toDateString());
        //console.log(completedDate.toLocaleString('en-us', options));


        let progressBarClass = 'progress-bar progress-bar-striped ';
        let assessmentUrl = `/survey/${this.props.assessment.surveyId}`;

        let completedAssessmentUrl = `/report/${this.props.assessment.surveyId}`;

        if (this.props.assessment.surveyName === 'READY') {
            completedAssessmentUrl = `/report-ready/${this.props.assessment.surveyId}`;
        }

        let actionButton = <NavButton className="btn btn-sm btn-outline-success" link={completedAssessmentUrl}><i
            className="fal fa-chart-bar"/> My Results</NavButton>;

        let linkAssessmentLink = '';

        if (this.props.assessment.surveyName === 'RELATE') {

            let url = `/link-assessments/${this.props.assessment.surveyId}`;

            if (this.props.assessment.connectedSurveyId === null) {

                linkAssessmentLink = <span className="text-primary text-nowrap" style={{fontSize: '.8em'}}>
                                          <NavLink to={url} exact={true}
                                                   title={'Link ' + this.props.assessment.redemptionCode}><i
                                              className="fal fa-link"/> Link Assessment</NavLink>
                                       </span>;

            } else {

                linkAssessmentLink = <span className="text-success text-nowrap" style={{fontSize: '.8em'}}>
                                         <i className="fal fa-check"/> <NavLink to={url} exact={true}
                                                                                title={'Link ' + this.props.assessment.redemptionCode}>Assessment Linked</NavLink></span>;

            }

        }


        return (
            <>
                <tr className='text-center'>
                    <td className="w-auto">
                        <i className="fal fa-edit pointer-cursor" onClick={() => this.updateNickname()}/>
                    </td>
                    <td className="w-auto">
              <span title={this.props.assessment.surveyName + ' ' + this.props.assessment.redemptionCode}>
                {this.props.assessment.nickname}
              </span><br/>
                        {this.props.assessment.completePercentage === 100 && linkAssessmentLink}
                    </td>
                    <td style={{verticalAlign: "middle"}}>
                        {completedDate.toLocaleString('en-us', options)}

                    </td>
                    <td className="w-25 text-center">{actionButton}</td>
                </tr>
            </>
        );
    }
}

export default FinishedAssessmentItem;
