import React from 'react';
import {Link} from "react-router-dom";

const loggedOutFooter = ( props ) => (

    <footer className="pt-4 my-md-5 pt-md-5 border-top no-print">
      <div className="row">
        <div className="col-12 col-md">
          <img className="mb-2" src="/images/relate-sm.png" alt="Relate" width="32" height="32"/>
          <small className="d-block mb-3 text-muted">&copy; 2019</small>
        </div>
        <div className="col-6 col-md">
          <h5>Features</h5>
          <ul className="list-unstyled text-small">
            <li><a className="text-muted"  target='_blank' href="https://relatefoundation.com/singles/" rel="noopener noreferrer" title="Singles" >Singles</a></li>
            <li><a className="text-muted"  target='_blank' href="https://relatefoundation.com/couples/" rel="noopener noreferrer" title="Couples">Couples</a></li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5><i className="fal fa-book"/> Resources</h5>
          <ul className="list-unstyled text-small" style={{lineHeight: '190%'}}>
            <li><a className="text-muted"  target='_blank' href="https://relatefoundation.com/" rel="noopener noreferrer" title="Relate Blog">Relate Blog</a></li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>About</h5>
          <ul className="list-unstyled text-small">
            {/*<li><a className="text-muted" href="#">Team</a></li>*/}
            <li><a className="text-muted" target='_blank' href="/privacy.html" title="Privacy">Privacy</a></li>
            <li><a className="text-muted" target='_blank'  href="/terms.html" title="Terms">Terms</a></li>
          </ul>
        </div>
      </div>
    </footer>


);

export default loggedOutFooter;