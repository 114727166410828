import React from 'react';
import { Route } from 'react-router';
import {Redirect, Switch} from "react-router-dom";
import MyAssessment from "./Survey/MyAssessment";
import FocusForm from "./color/FocusForm";
import Survey from "./Survey/Survey";
import Logout from "./Auth/Logout";
import Pricing from "./layout/Pricing";
import CheckOut from "./Checkout/Cart";
import UpdatePassword from "./layout/users/UpdatePassword";
import PaymentRoute from "./Stripe/PaymentRoute";
import Stripe from "./Stripe/Stripe";
import PurchaseConfirmation from "./Stripe/PurchaseConfirmation";
import Report from "./Report/Report";
import Cover from "./Report/relate/Cover";
import ShareCode from "./Survey/ShareCode";
import ReadyCover from "./Report/ready/ReadyCover";
import PrintReport from "./Report/PrintReport";
import ReadyReport from "./Report/ReadyReport";
import ReadyPrint from "./Report/ReadyPrint";
import Orders from "./Orders/Orders";
import LinkAssessments from "./Survey/LinkAssessments";
import Auth from "./Auth/Auth";
import EmailLink from "./Survey/EmailLink";
import ProfessionalReview from "./Survey/ProfessionalReview";
import StrongerMarriage from "./layout/StrongerMarriage";

const userRoutes = () => (
    <Switch>
        {/*<Route path="/" exact render={() => <h1>Home</h1>}/>*/}
        <Route path="/" exact component={MyAssessment}/>
        {/*{this.props.userData.role === 'ROLE_DEVELOPER' &&  <Route path="/test/:id" exact component={Details}/> }*/}
        {/*{this.props.userData.role === 'ROLE_DEVELOPER' && <Route path="/test" exact component={Test}/>}*/}

        <Route path="/focus" exact component={FocusForm}/>
        <Route path="/survey/:assessmentId" exact component={Survey}/>
        <Route path="/logout" exact component={Logout}/>
        <Route path="/pricing" exact component={Pricing}/>
        <Route path="/cart" exact component={CheckOut}/>
        <Route path="/users/account" exact component={UpdatePassword}/>
        <Route path="/professional/review/:assessmentId" exact component={ProfessionalReview}/>
        <Route path="/stronger-marriage/:assessment" exact component={StrongerMarriage}/>


        {/*<Route path="/payment" exact component={Stripe}/>*/}
        <PaymentRoute path="/payment" exact component={Stripe}/>
        <Route path="/receipt/:orderId/:userId" exact component={PurchaseConfirmation}/>
        <Route path="/assessments" exact component={MyAssessment}/>
        <Route path="/report/:assessmentId" exact component={Report}/>
        <Route path="/report/:assessmentId/:section" exact component={Report}/>
        <Route path="/report/:assessmentId/relate/cover" exact component={Cover}/>
        <Route path="/assessment/code/share" exact component={ShareCode}/>
        <Route path="/report-ready/:assessmentId/ready/cover" exact component={ReadyCover}/>
        <Route path="/report/action/print/:assessmentId" exact component={PrintReport}/>
        <Route path="/report-ready/:assessmentId" exact component={ReadyReport}/>
        <Route path="/report-ready/:assessmentId/:section" exact component={ReadyReport}/>
        <Route path="/report-ready/action/print/:assessmentId" exact component={ReadyPrint}/>
        <Route path="/orders" exact component={Orders}/>
        <Route path="/link-assessments/:assessmentId" exact component={LinkAssessments}/>
        <Route path="/link-assessments/partner/:linkCode" exact component={EmailLink}/>
        <Route path="/auth" exact component={Auth}/>


        <Redirect to="/"/>
        {/*<Route component={NoMatch} />*/}
    </Switch>
);

export default userRoutes;