import React, {Component} from 'react'
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from '../../constants/Colors'

class ReadyAgression extends Component {

    componentDidMount() {
    }

    render() {

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-exclamation-circle fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            RELATIONAL AGGRESSION
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='aafz' percent={this.props.relAggression.primarySelf[0]}
                                    color={this.props.relAggression.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1">
                        {this.props.relAggression.primarySelf[1] === Color.CHALLENGE &&
                        <ChallengeLegendDetail id="aapz">
                            A score in the red indicates the frequent use of relationally aggressive tactics. You are
                            skilled at using relationships as a means to control others. You may have friends with whom
                            you share everything, including negative aspects about others. When you are in conflict with
                            others, you are especially prone to use personal attacks to get people on your side. This is
                            extremely harmful to relationships as it destroys trust and effective problem resolution.
                            The most common outcome of using relationships as a means of control is to lose the
                            relationship entirely. It is likely that you will need therapy to uncover the origins of
                            your aggression and the models you are using who were aggressive to you. While people can
                            clearly become less aggressive, it is usually not easy and requires considerable effort and
                            guidance from others.
                        </ChallengeLegendDetail>
                        }

                        {this.props.relAggression.primarySelf[1] === Color.IMPROVE &&
                        <ImproveLegendDetail id="afaf">
                            You may catch yourself spreading rumors or gossip about others at times. You may not have
                            the intent to harm others, but you also have trouble recognizing the effects of your
                            actions. You may also have a tendency to turn personal information against someone if they
                            do something you do not like. Such behavior discourages openness in relationships and can
                            create significant emotional barriers. When you begin to feel hurt by another’s actions,
                            talk to that person first to discuss your feelings. Then focus on the positive results of
                            your resolution if you do share it with others.
                        </ImproveLegendDetail>
                        }

                        {this.props.relAggression.primarySelf[1] === Color.STRENGTH &&
                        <StrengthLegendDetail id="ahnx">
                            A score in this section indicates that you do not often use relational aggression as a
                            method of control with others. Instead of turning outward in relationships, you probably
                            tend to turn towards close others when something is troubling you. When sharing information
                            about others, you are careful to focus on positive things. This is important for
                            relationships and is a sign that you can be trusted with intimate details about your
                            partners.
                        </StrengthLegendDetail>
                        }
                    </div>
                </div>

                <div className="mt-2 mb-5 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    Aggression is defined as “behavior that is made with the intent to harm or cause pain.” Aggression
                    is often used as an attempt to gain or maintain control over something or someone. Outside of
                    relationships, we may use such tactics as rumors or name-calling to harm the relationships of
                    others. In our own relationships, we sometimes use our relationship status as a tool to control our
                    partners. Both of these tools are called relational aggression, and have been found to be associated
                    with negative relationship outcomes. This section measures your tendency to use relationally
                    aggressive tactics with others, either to control your own relationships or the relationships of
                    others.
                </div>


            </>

        )

    }

}

export default ReadyAgression;