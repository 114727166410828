import React, { Component } from 'react'
import axios from "../axios/axios-custom";
import KindnessAndFlexibility from "./relate/KindnessAndFlexibility";
import EmotionalReadiness from "./relate/EmotionalReadiness";
import RelationshipQuality from "./relate/RelationshipQuality";
import FamilyBackground from "./relate/FamilyBackground";
import RelationshipEffort from "./relate/RelationshipEffort";
import EffectiveCommunication from "./relate/EffectiveCommunication";
import ConflictResolution from "./relate/ConflictResolution";
import SexualSatisfaction from "./relate/SexualSatisfaction";
import RelationalAggression from "./relate/RelationalAggression";
import Attachment from "./relate/Attachment";
import ReportDivider from './ReportDivider';
import Spinner from '../Spinner/Spinner';
import Error from "../Error/Error";
import Cover from "./relate/Cover";

class PrintReport extends Component {

  state = {
    error: '',
    reportInfo: {},
    assessmentId: '',
  };

  componentDidMount() {

    const assessmentId = this.props.match.params.assessmentId;

    this.setState({assessmentId: assessmentId});

    this.loadAssessmentResult(assessmentId);
  }



  loadAssessmentResult = (assessmentId) =>  {

    let url = `${process.env.REACT_APP_API_URL}/api/report/${assessmentId}/sect/1`;

    axios.get(url)
    .then( (response) => {

      //let respObject = this.arrayToObject(response.data);

      let respObject = response.data.reduce((obj, item) => {
        obj[item.subsection] = item.resultValues;
        return obj;
      }, {});

      this.setState({reportInfo: respObject}, () => setTimeout(window.print, 1000));



    })
    .catch(error => {
      // console.log(error);
      this.setState({error: error});
    });


  };

  render() {

    let cover = '';
    let kindnessAndFlexibility = '';
    let emotionalReadiness = '';
    let familyBackground = '';
    let relationshipQuality = '';
    let relationshipEffort = '';
    let effectiveCommunication = '';
    let conflictResolution = '';
    let sexualSatisfaction = '';
    let relationalAggression = '';
    let attachment = '';

    if(this.state.reportInfo.kindAndFlex) {

      cover = <><Cover /> <ReportDivider /></>;

      kindnessAndFlexibility = <><KindnessAndFlexibility kindAndFlex={this.state.reportInfo.kindAndFlex} kind={this.state.reportInfo.kind}
                                                         flex={this.state.reportInfo.kindAndFlex} /><ReportDivider /></>;

      emotionalReadiness = <><EmotionalReadiness emotionReady={this.state.reportInfo.emotionReady} maturity={this.state.reportInfo.maturity} calm={this.state.reportInfo.calm}
                                                selfEsteem={this.state.reportInfo.selfEsteem} happiness={this.state.reportInfo.happiness}/><ReportDivider /></>;

      familyBackground = <><FamilyBackground famBackground={this.state.reportInfo.famBackground}
                                             famQuality={this.state.reportInfo.famQuality}
                                             parentMarriage={this.state.reportInfo.parentMarriage}
                                             famInfluence={this.state.reportInfo.famInfluence} /> <ReportDivider /></>;

      relationshipQuality = <><RelationshipQuality satisfaction={this.state.reportInfo.satisfaction} stability={this.state.reportInfo.stability}
                                                   probArea={this.state.reportInfo.probArea}
                                                   overallRelSatisfaction={this.state.reportInfo.overallRelSatisfaction}/>
        <ReportDivider /></>;


      relationshipEffort = <><RelationshipEffort relEffort={this.state.reportInfo.relEffort} />
        <ReportDivider /></>;

      effectiveCommunication = <><EffectiveCommunication overallEffComm={this.state.reportInfo.overallEffComm} empathy={this.state.reportInfo.empathy}
                                                         clearSending={this.state.reportInfo.clearSending} /><ReportDivider /></>;

      conflictResolution = <><ConflictResolution overallConflictResol={this.state.reportInfo.overallConflictResol} noncritical={this.state.reportInfo.noncritical}
                                                 notOverwhelmed={this.state.reportInfo.notOverwhelmed}
                                                 respect={this.state.reportInfo.respect}/><ReportDivider /></>;

      sexualSatisfaction = <><SexualSatisfaction sexSatisfaction={this.state.reportInfo.sexSatisfaction} /><ReportDivider /></>;

      relationalAggression = <><RelationalAggression relAggression={this.state.reportInfo.relAggression} /><ReportDivider /></>;

      attachment = <><Attachment attachment={this.state.reportInfo.attachment}  /><hr className='vhr no-print' /></>;
    }



    return (



        <>

          {!this.state.reportInfo.kindAndFlex && !this.state.error && <Spinner />}

          {
            this.state.error &&
            <Error heading='Assessment Results'>
              There was a problem displaying your assessment results. An unexpected error has occurred.
              If you believe you reached this page in error, please try again later or contact support.
            </Error>
          }


          {cover}
          {kindnessAndFlexibility}
          {emotionalReadiness}
          {familyBackground}
          {relationshipQuality}
          {relationshipEffort}
          {effectiveCommunication}
          {conflictResolution}
          {sexualSatisfaction}
          {relationalAggression}
          {attachment}
        </>



    )


  }
}

export default PrintReport;