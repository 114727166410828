import React, {Component} from 'react'
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from "../../constants/Colors";

class MentalHealth extends Component {

    componentDidMount() {
    }

    render() {


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-head-side-brain fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            MENTAL HEALTH
                        </div>
                    </div>
                </div>

                <div className="mt-3 pb-4 pl-4 pr-4 pt-3 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    <p>
                        Our mental health plays a major role in our relationship success. How we think about and
                        experience our emotions is a driving force in our
                        reactions to negative events, conflict, or tension in our relationships. It also influences our
                        level and quality of interaction with our partners.
                        The greatest threat to relationships, however, is not the presence of mental health problems but
                        rather allowing them to go unrecognized. Below are two of
                        the most common mental health problems, depression and anxiety, which are frequently found to be
                        factors in the well-being of our relationships.
                    </p>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>DEPRESSION</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill align-items-center text-center">
                        <div className='pl'>
                            <DonutChart elId='bzui' percent={this.props.depression.primarySelf[0]}
                                        color={this.props.depression.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            This section assesses your experience with depression. While having some periods of
                            depression is not uncommon for many,
                            serious and chronic depression has consistently been found to inhibit the success of
                            romantic relationships. It is difficult to
                            feel relationships are going well if you are struggling with feelings of inadequacy and
                            hopelessness. If your score falls below
                            the clinical depression cut-off point, we recommend you seek professional counseling.
                        </p>
                    </div>
                </div>

                {this.props.depression.primarySelf[1] === Color.STRENGTH &&

                <StrengthLegendDetail id="iren">
                    If you score in this section it means that depression is not a likely source of relationship
                    difficulty
                    for you. You are generally positive about yourself and your life and you tend to be optimistic. This
                    can serve you well in relationships as you are better able to maintain hope when conflict or other
                    obstacles arise in your relationships.
                </StrengthLegendDetail>
                }

                {this.props.depression.primarySelf[1] === Color.IMPROVE &&

                <ImproveLegendDetail id="iwew">
                    If you score in the white you experience depressive symptoms more frequently than the majority of
                    people. This means you may experience a day or two of depressive episodes, or experience a few of
                    the symptoms of depression. It is important for you to understand how you react differently to
                    stress when you are having a bad day versus a good day. Then help your romantic partners understand
                    your unique struggles as well so they can have a better perspective of your different reactions.
                </ImproveLegendDetail>
                }

                {this.props.depression.primarySelf[1] === Color.CHALLENGE &&

                <ChallengeLegendDetail id="iwxq">
                    If you scored in this section, it is an indication that you experience depressive symptoms enough to
                    be
                    considered clinically depressed. This is a major issue for relationships and should be addressed
                    now. You may not always be depressed, but when you are it may last for days or weeks at a time and
                    severely impacts your ability to function, personally or relationally. We recommend you seek
                    professional therapy at this level and spend some time talking to a therapist about how your
                    depression impacts relationships specifically.
                </ChallengeLegendDetail>
                }

                <div className="mt-3 pb-4  pt-3 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    <h4>Depression and Relationship Development</h4>
                    <p>
                        Depression rates have been increasing steadily for the last 30 years, meaning more and more
                        individuals have experienced either a severe depressive episode or on-going depressive symptoms.
                        Depression is often separated from simple feelings of sadness or fear (which are common for
                        anyone) by the severity of negative feelings, frequency of feelings, and impact of the feelings
                        on your life. Once depressive feelings start to feel more intense, start to occur on a regular
                        basis (regardless of what is happening in your life), or start to interfere with your work,
                        school, or social life, you may be struggling with depression. The first and most important
                        thing to know about depression is that there are many effective and wonderful resources out
                        there to help. If you think you might be struggling with depression, get help! Find a local
                        counselor or talk to a friend/mentor. If your feelings of depression are intense or you have
                        thought about suicide, tell someone or call an anonymous and free hotline like the National
                        Suicide Prevention Hotline at 1-800-273-8255.
                    </p>
                    <p>
                        How does depression influence relationships? Depression and depressive symptoms can interfere
                        with healthy relationship development in several ways. First, feelings of depression can hurt
                        your ability to seek out and find potential dating partners. When depression gets in the way of
                        living your daily life or engaging in normal behavior, therapists call that functional
                        impairment. That means you may struggle doing the normal day-to-day things most people do,
                        including going to parties, interacting with work colleagues, or attending other social events
                        where you may encounter potential dating partners. Research also shows that depression can
                        interfere with the development of healthy relationships once you are in a serious relationship.
                        Those struggling with depression tend to have lower quality and less stable relationships. Why?
                        Often because those struggling at the individual level with depression find it difficult to put
                        enough energy into their relationships. They may feel insecure or overcome with negative
                        feelings about themselves or their relationship. Little conflicts may feel insurmountable or
                        negative interactions with their partner may feel like they are never ending. These feelings
                        make those with depression less willing or able to engage with their romantic partner to
                        overcome obstacles or problems. This creates a negative cycle where those with depression
                        struggle to engage with their partner, which makes romantic partners less likely to engage back.
                        This can feed into negative feelings or insecurity for the partner with depression. The
                        bottom-line is this, any level of serious depression can be a major obstacle to forming and
                        maintaining a healthy long-term relationship. If you are suffering from depression, seek out
                        help and resources around you to put yourself in a better place to begin and sustain a healthy
                        relationship.
                    </p>
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>ANXIETY</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill align-items-center text-center">
                        <div className='pl'>
                            <DonutChart elId='cbfh' percent={this.props.anxiety.primarySelf[0]}
                                        color={this.props.anxiety.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            This section assesses your experience with generalized anxiety. While experiencing moments
                            of panic or fear is very normal for
                            anyone, constant and overwhelming fears and anxiety can prevent us from forming fulfilling
                            relationships. If we are always
                            worrying about what we might lose or losing what we already have, it is difficult to invest
                            in relationships. Constant anxiety
                            in our lives also prevents us from doing the day-to-day tasks in life that allow us to
                            function not only in a relationship,
                            but simply as a person. If your score falls below the cut-off point for general anxiety
                            disorder, we recommend you
                            seek professional counseling.
                        </p>
                    </div>
                </div>

                {this.props.anxiety.primarySelf[1] === Color.STRENGTH &&

                <StrengthLegendDetail id="jaqn">
                    Scoring in this section is a good indication that anxiety does not significantly affect your life.
                    Even when you do have moments of anxiety, you quickly move through them and are able to keep them
                    from impacting your relationships. You feel fairly secure in what you have, and recognize that loss
                    is part of life, but you tend to focus on enjoying what you have in the moment.
                </StrengthLegendDetail>
                }

                {this.props.anxiety.primarySelf[1] === Color.IMPROVE &&

                <ImproveLegendDetail id="jbux">
                    If you scored in this section then you may often show patterns of anxiety in your life, but they do
                    not
                    severely limit your ability to function. You might often be hesitant to invest in something new,
                    like a relationship, because you fear losing it. The key to working through your moments of anxiety
                    is to recognize your triggers. Certain events in relationships are more likely to set off moments of
                    fear than other events, and recognizing these triggers can help you either avoid them or work
                    through them when they do occur. Help future partners recognize these triggers as well so they can
                    respond more appropriately in the moment.
                </ImproveLegendDetail>
                }

                {this.props.anxiety.primarySelf[1] === Color.CHALLENGE &&

                <ChallengeLegendDetail id="jcrq">
                    A score in this section is an indication that you experience anxiety at a clinical level. Your
                    anxiety
                    may severely limit your functioning, especially in relationships. Your fears may drive your
                    decisions and may be holding you back from new experiences that could enhance your life. You might
                    find it difficult to engage fully in relationships when you do have them, or difficult to actively
                    look for potential relationships when you do not have them. We recommend you seek professional
                    counseling to help you better understand how to get more out of your relationships.
                </ChallengeLegendDetail>
                }

                <div className="mt-3 pb-4  pt-3 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    <h4>Anxiety and Relationships</h4>
                    <p>
                        Everyone has stress and anxiety in their life. Feeling stressed out is a normal part of our
                        everyday experiences at home, work, or with friends. Anxiety comes when we encounter change in
                        our lives. Human beings enjoy and seek out stability in their lives. When that stability is
                        disrupted and something changes (or we think something might change), we feel anxiety. Anxiety
                        may be normal but there are two main ways that anxiety may interfere with your ability to form
                        and maintain healthy relationships. The first has to do with how you mange your anxiety. All
                        couples have conflict, and it is really how you manage that conflict that matters, avoiding any
                        form of anxiety in your life is likely impossible. Avoiding anxiety is not the goal, learning to
                        manage anxiety is. When individuals have poor management of their stress, it tends to influence
                        their perceptions of the world and their relationships. The more anxiety we feel and the poorer
                        we manage it; the more negative our perceptions become. Research shows these negative
                        perceptions tied to anxiety can have a negative influence on future relationships. When you
                        start to assume the worst about yourself, your partner, or your relationship, it starts to
                        influence your behavior. You may feel less engaged with your romantic partner or simply feel
                        more anger or resentment toward them. Regardless of the specific effect, this heightened anxiety
                        can interfere with being the best version of yourself in relationships.
                    </p>
                    <p>
                        The other main way high anxiety can influence relationships is when anxiety becomes so high, it
                        starts to interfere with more than just our perceptions. Clinical anxiety rates are on the rise
                        as more and more people struggle with irrational feelings or high levels of stress in their
                        lives. When anxiety becomes this high (often over a sustained period of time), several
                        roadblocks to relationship development can be created. Research shows that individuals with high
                        anxiety often end up in two camps. Some tend to retreat from social interactions, withdrawing
                        from others, including those they are dating or in a committed relationship with. This
                        disengagement from relationships can undermine trust and commitment and make it more likely that
                        future romantic partners will likewise disengage from the relationship as well. On the other
                        hand, some individuals with high anxiety go to the other extreme, seeking constant validation
                        and attention from their dating partners. These partners come off as “clingy” and “needy”, often
                        making their romantic partners retreat but for different reasons. In both cases, high anxiety
                        can cause future relationship partners to distance themselves in the relationship, making
                        connection and intimacy harder to achieve.
                    </p>
                    <p>
                        For all of these reasons, it is important to learn how to handle minor and major anxieties in
                        your life. While everyone may feel anxiety about dating specifically or life generally, how you
                        learn to manage and handle these anxieties are important aspects of how you will develop healthy
                        relationships with eventual dating partners.
                    </p>
                </div>

            </>

        )

    }

}

export default MentalHealth;