import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";
import GraphLegend from "./GraphLegend";
import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';

class EmotionalReadiness extends Component {

  componentDidMount() {
    WindowSizeListener.DEBOUNCE_TIME = 1000;
  }

  render() {

    //BarGraphvalues
    let calmnessData = [[this.props.calm.primarySelf[0], this.props.calm.partnerPrimary[0], this.props.calm.primaryPartner[0], this.props.calm.partnerSelf[0]],
      [100 - this.props.calm.primarySelf[0], 100 - this.props.calm.partnerPrimary[0], 100 - this.props.calm.primaryPartner[0], 100 - this.props.calm.partnerSelf[0]]];
    let calmnessColors = [this.props.calm.primarySelf[1], this.props.calm.partnerPrimary[1], this.props.calm.primaryPartner[1], this.props.calm.partnerSelf[1]];

    let maturityData = [[this.props.maturity.primarySelf[0], this.props.maturity.partnerPrimary[0], this.props.maturity.primaryPartner[0], this.props.maturity.partnerSelf[0]],
      [100 - this.props.maturity.primarySelf[0], 100 - this.props.maturity.partnerPrimary[0], 100 - this.props.maturity.primaryPartner[0], 100 - this.props.maturity.partnerSelf[0]]];
    let maturityColors = [this.props.maturity.primarySelf[1], this.props.maturity.partnerPrimary[1], this.props.maturity.primaryPartner[1], this.props.maturity.partnerSelf[1]];

    let selfEsteemData = [[this.props.selfEsteem.primarySelf[0], this.props.selfEsteem.partnerPrimary[0], this.props.selfEsteem.primaryPartner[0], this.props.selfEsteem.partnerSelf[0]],
      [100 - this.props.selfEsteem.primarySelf[0], 100 - this.props.selfEsteem.partnerPrimary[0], 100 - this.props.selfEsteem.primaryPartner[0], 100 - this.props.selfEsteem.partnerSelf[0]]];
    let selfEsteemColors = [this.props.selfEsteem.primarySelf[1], this.props.selfEsteem.partnerPrimary[1], this.props.selfEsteem.primaryPartner[1], this.props.selfEsteem.partnerSelf[1]];


    let happinessData = [[this.props.happiness.primarySelf[0], this.props.happiness.partnerPrimary[0], this.props.happiness.primaryPartner[0], this.props.happiness.partnerSelf[0]],
      [100 - this.props.happiness.primarySelf[0], 100 - this.props.happiness.partnerPrimary[0], 100 - this.props.happiness.primaryPartner[0], 100 - this.props.happiness.partnerSelf[0]]];
    let happinessColors = [this.props.happiness.primarySelf[1], this.props.happiness.partnerPrimary[1], this.props.happiness.primaryPartner[1], this.props.happiness.partnerSelf[1]];


    return (

        <>
          <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
            <div className="d-flex justify-content-between align-items-center">
              <div><i className="fal fa-hand-holding-seedling fa-2x" style={{color: "#c3b2b5"}}></i></div>
                <div className="text-right">
                    <div className="text-muted text-right" style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                EMOTIONAL READINESS
              </div>
            </div>
          </div>

          <GraphLegend />

          {this.props.windowSize.windowWidth > 710 &&

          <div className="table-responsive">
            <table className="table table-borderless">
              <tbody>
              <tr>
                <td align="center"><DonutChart elId='emotion1' percent={this.props.emotionReady.primarySelf[0]} color={this.props.emotionReady.primarySelf[1]} />
                </td>
                <td className="border-right" align="center"><DonutChart elId='emotion2' percent={this.props.emotionReady.partnerPrimary[0]} color={this.props.emotionReady.partnerPrimary[1]} />
                </td>
                <td align="center"><DonutChart elId='emotion3' percent={this.props.emotionReady.primaryPartner[0]} color={this.props.emotionReady.primaryPartner[1]} /></td>
                <td align="center"><DonutChart elId='emotion4' percent={this.props.emotionReady.partnerSelf[0]} color={this.props.emotionReady.partnerSelf[1]} /></td>
              </tr>
              <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                <td className="border-right" align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                <td align="center">HOW <b>YOU</b> VIEW <br />YOUR PARTNER</td>
                <td align="center">HOW <b>YOUR PARTNER</b> <br />VIEWS THEMSELVES</td>
                {/*NO PARTNER <br/>ANSWER RECORDED*/}
              </tr>
              </tbody>
            </table>
          </div>

          }

          {this.props.windowSize.windowWidth <= 710 &&
          <table className="table table-borderless">
            <tbody>
            <tr>
              <td align="center"><DonutChart elId='emotion1' percent={this.props.emotionReady.primarySelf[0]} color={this.props.emotionReady.primarySelf[1]} />
              </td>
              <td align="center"><DonutChart elId='emotion2' percent={this.props.emotionReady.partnerPrimary[0]} color={this.props.emotionReady.partnerPrimary[1]} />
              </td>
           </tr>
            <tr className="border-bottom" style={{fontFamily: 'Nunito', fontSize: '0.8em'}}>
              <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
              <td align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>

            </tr>

            <tr>
              <td align="center"><DonutChart elId='emotion3' percent={this.props.emotionReady.primaryPartner[0]} color={this.props.emotionReady.primaryPartner[1]} /></td>
              <td align="center"><DonutChart elId='emotion4' percent={this.props.emotionReady.partnerSelf[0]} color={this.props.emotionReady.partnerSelf[1]} /></td>

            </tr>
            <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
              <td align="center">HOW <b>YOU</b> VIEW <br />YOUR PARTNER</td>
              <td align="center">HOW <b>YOUR PARTNER</b> <br />VIEWS THEMSELVES</td>
            </tr>

            </tbody>
          </table>
          }

          <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
            The <b>Emotional Readiness</b> scale is a measure of your own and your partner's levels of maturity, calmness,
            self-esteem, and happiness. This scale taps into several underlying individual factors related to emotions that
            are linked to healthy and positive relationships. When things go wrong, how do you react? How do you feel in
            your daily life?
          </div>

          <div className='mt-3 pb-4 pl-4 pr-4 pt-3' style={{ backgroundColor: '#d9e3ed',
            borderRadius: '20px'}}>
            <div className='row'>
              <div className='col-3 border-right border-dark'>
                Relate Institute<br/><b>Findings</b>
              </div>
              <div className='col-9'>
                  Depression, anxiety, low self-esteem, and immature/impulsive behaviors are negatively related to one's own and partner's relationship quality.
              </div>
            </div>
          </div>


          {this.props.windowSize.windowWidth > 710 &&
          <>
            <div className="table-responsive">
              <table className="table table-borderless" style={{borderBottom: '1px black solid'}}>
                <tbody>
                <tr>
                  <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>CALMNESS</td>
                  <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>HAPPINESS</td>
                </tr>
                <tr>
                  <td className='col-lg-7'>
                    <div className="chart-container" style={{width: '100%'}}>
                      <HorizontalBarChart barChartId='calmness' data={calmnessData} colors={calmnessColors} displayTicks={true}/>
                    </div>
                  </td>
                  <td className='col-lg-5'>
                    <div style={{width: '100%'}}>
                      <HorizontalBarChart barChartId='calmness2' data={happinessData} colors={happinessColors} displayTicks={false}/>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive">
              <table className="table table-borderless" style={{borderBottom: '1px black solid'}}>
                <tbody>
                <tr>
                  <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>MATURITY</td>
                  <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>SELF ESTEEM</td>
                </tr>
                <tr>
                  <td className='col-lg-7'>
                    <div className="chart-container" style={{width: '100%'}}>
                      <HorizontalBarChart barChartId='maturity' data={maturityData} colors={maturityColors} displayTicks={true}/>
                    </div>
                  </td>
                  <td className='col-lg-5'>
                    <div style={{width: '100%'}}>
                      <HorizontalBarChart barChartId='self-esteem' data={selfEsteemData} colors={selfEsteemColors} displayTicks={false}/>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </>
          }

          {this.props.windowSize.windowWidth <= 710 &&
          <>
            <table className="table table-borderless">
              <tbody>
              <tr>
                <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>CALMNESS</td>
              </tr>
              <tr>
                <td>
                  <div className="chart-container" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='calmness' data={calmnessData} colors={calmnessColors} displayTicks={true}/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <table className="table table-borderless">
              <tbody>
              <tr>
                <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>HAPPINESS</td>
              </tr>

              <tr>
                <td>
                  <div className="chart-container" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='calmness2' data={happinessData} colors={happinessColors} displayTicks={true}/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <table className="table table-borderless">
              <tbody>
              <tr>
                <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>MATURITY</td>
              </tr>
              <tr>
                <td>
                  <div className="chart-container" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='maturity' data={maturityData} colors={maturityColors} displayTicks={true}/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <table className="table table-borderless" style={{borderBottom: '1px black solid'}}>
              <tbody>
              <tr>
                <td align='center' style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}}>SELF ESTEEM</td>
              </tr>
              <tr>
                <td>
                  <div style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='self-esteem' data={selfEsteemData} colors={selfEsteemColors} displayTicks={true}/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </>
          }



          <StrengthLegendDetail id="emr-strength">
            People who score in this area see themselves and/or their partners as being calm, mature, high in self-esteem and low on depression.
          </StrengthLegendDetail>

          <ImproveLegendDetail id="emr-needs-impr">
            People scoring in this section do not consider themselves/their partners as either emotional strong or emotionally weak.
            They may be calm/mature in some situations, have mild depression, fluctuating self-esteem levels, or have slight anxiety.
            Some counseling may be beneficial to help produce emotional stability but it is not critical at this point.
          </ImproveLegendDetail>

          <ChallengeLegendDetail id="emr-challenge">
            People scoring in the challenge area on this scale may have seriously high levels of anxiety, depression and other emotional
            problems and may benefit from visiting with a professional therapist.
          </ChallengeLegendDetail>

            <button className="btn btn-outline-dark no-print" type="button" data-toggle="collapse" data-target="#emotion"
                    aria-expanded="false" aria-controls="emotion">
                Show Section Questions
            </button>


            <div className="collapse" id="emotion">
                <div className="mt-3"/>
                <div className="card">
                    <div>
                        <h5 className="card-header"><i className="fal fa-hand-holding-seedling" /> Emotional Readiness</h5>
                    </div>
                    <div className="card-body">
                        <table className="table ">
                            <thead>
                            <tr>
                                <th scope="col" className="align-middle">How much do these words or phrases describe you?</th>
                                <th scope="col" className="align-middle">How much do these words or phrases describe YOUR PARTNER?</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="align-middle">
                                    Fight with others/lose temper
                                </td>
                                <td className="align-middle">
                                    Fight with others/lose temper
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Act immature
                                </td>
                                <td className="align-middle">
                                    Act immature
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Easily irritated or mad
                                </td>
                                <td className="align-middle">
                                    Easily irritated or mad
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Fearful
                                </td>
                                <td className="align-middle">
                                    Fearful
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Tense
                                </td>
                                <td className="align-middle">
                                    Tense
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Nervous
                                </td>
                                <td className="align-middle">
                                    Nervous
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Worrier
                                </td>
                                <td className="align-middle">
                                    Worrier
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Depressed
                                </td>
                                <td className="align-middle">
                                    Depressed
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Sad and blue
                                </td>
                                <td className="align-middle">
                                    Sad and blue
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    Feel hopeless
                                </td>
                                <td className="align-middle">
                                    Feel hopeless
                                </td>
                            </tr>
                            <tr>
                                <th scope="col" className="align-middle">How do you feel about yourself?</th>
                                <th scope="col" className="align-middle">How does YOUR PARTNER feel about herself or himself?</th>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    I take a positive attitude toward myself
                                </td>
                                <td className="align-middle">
                                    My partner takes a positive attitude toward himself/herself
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    I think I am no good at all
                                </td>
                                <td className="align-middle">
                                    My partner thinks he/she is no good at all
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    I feel I am a person of worth
                                </td>
                                <td className="align-middle">
                                    My partner feels he/she is a person of worth
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    I am inclined to think I am a failure
                                </td>
                                <td className="align-middle">
                                    My partner is inclined to think he/she is a failure
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

          <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
            borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin:'0 auto'}}>

            <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
              <div className="col">

              </div>
              <div className="col">
                <div className="float-right pb-2">
                  Discussion Questions
                </div>
              </div>
            </div>
            <div>
              <ul className='list-line-height'>
                <li>Are you satisfied with the current rating of yourself on this scale? If not, what can you do to improve it?</li>
                <li>How are your answers similar or different from one another? Why were there differences or why did you both agree?</li>
                <li>Does your partner want you to improve the emotional readiness in your relationship? <br/>If so, discuss why and how this can be facilitated.</li>
                <li>How will your relationship change if you improved the emotional readiness in your relationship?</li>
              </ul>
            </div>
          </div>

        </>

    )

  }

}

export default withWindowSizeListener(EmotionalReadiness);