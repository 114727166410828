import React, {Component} from 'react';
import Thermometer from "./Thermometer";
import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';

class FutureRelRisk extends Component {

    componentDidMount() {

        WindowSizeListener.DEBOUNCE_TIME = 1000;

        // let gaugeElement = document.getElementById('therm');
        // console.log(gaugeElement);
        //
        // gaugeElement.setAttribute('data-color-numbers', 'red');

        // Guage.update();
        // var gauge = Gauge({ renderTo: 'canvas-id' });
        // gauge.draw();

    }


    render() {

        let value = 0;
        let color = '#eee';
        let paragraph = '';
        let therm = null;

        if (this.props.risk && this.props.risk.primarySelf) {

            value = this.props.risk.primarySelf[0];


            if (value >= 0 && value <= 10) {

                color = '#249AA7';
                paragraph = <div className=' text-justify'>
                    <div><b>Risk: Very Low</b></div>
                    Whatever risks you bring into your future relationships are not likely to be major sources of
                    problems.
                    However, do not ignore those areas of risk as they can become larger issues if you do not pay
                    attention to how they are influencing you.</div>;
            } else if (value >= 11 && value <= 20) {

                color = '#B8E1F2';
                paragraph = <div className=' text-justify'>
                    <div><b>Risk: Low</b></div>
                    There are elements of your past or present behaviors that are slightly elevating your risk of
                    unhealthy relationship outcomes. ' +
                    'You should be aware of how your background is creating a risk for your relationships. Your chances
                    of having successful ' +
                    'relationships are still good but could be in jeopardy if you don’t work on your unique challenges.
                </div>;
            } else if (value >= 21 && value <= 30) {

                color = '#F8C830';
                paragraph = <div  className=' text-justify'>
                    <div><b>Risk: Moderate</b></div>
                    If you fall into this category, you have a slightly high risk of unhealthy relationships
                    in the future based on your personal background. Too many potential partners, the amount of risk you
                    bring to relationships may
                    be a red flag.
                    You might find it difficult to have successful relationships without working through some of the
                    issues from your past first.
                    Working on these issues should be a priority if you desire to have a healthy relationship moving
                    forward.</div>;
            } else {

                color = '#F1594A';
                paragraph = <div className=' text-justify'>
                    <div><b>Risk: High</b></div>
                    Right now is probably not a good time for you to be in a relationship.
                    Before looking for someone else to be with, spend some time learning more about why your past brings
                    so much risk and
                    understand how your past is holding you back. It may be helpful to seek professional counseling in
                    order to improve your chances
                    of a successful relationship. Right now, spend the time to work on yourself, and then successful
                    relationships may become part
                    of your future.</div>;
            }

            therm = <Thermometer value={value} color={color} cId='bqya' title='Future Risks'/>;


        }

        return (

            <>
                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-thermometer-three-quarters fa-2x" style={{color: "#c3b2b5"}}></i>
                        </div>
                        <div>
                            FUTURE RELATIONSHIP RISK
                        </div>
                    </div>
                </div>

                <div className="mt-2 text-justify">
                    <b>One of the things we know from decades of research on relationships is that what you are doing
                        today, even if you’ve never been in a relationship, impacts your future relationship
                        success.</b> Everything in your life so far has molded you into the person you are today and has
                    both increased
                    and decreased your chance of a successful relationship. This section will highlight some of the
                    risks you are likely to bring into a relationship based on your personal background. By risks, we
                    mean anything from your past that research has found to increase the probability of greater
                    difficulty in relationships. Don’t worry if you have a few risks, everyone does. Having an elevated
                    risk does not mean you should never have a relationship. What it does mean is you may want to think
                    about ways to either reduce your risk or at least be aware of some potential problems that may arise
                    in the future.
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.2em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>What Your Chance of Success Means for You</b>
                </div>

                <div className="d-sm-flex justify-content-around align-items-start mt-3">
                    <div className="p-2 flex-fill text-center">
                        {therm}
                    </div>
                    <div className="p-2 flex-fill"></div>
                    <div className="p-2">
                        <div>{paragraph}</div>
                        <div className='pt-3'>
                            While it may not be possible to erase the past, being able to identify the risks you have is
                            the first
                            step to keep them from ruining a happy relationship. After you identify your specific areas
                            of risk in
                            this section, continue on to “Personal Relationship Strengths & Weaknesses” to find out more
                            about
                            how your risks may affect your relationship skills and how you can have a successful future
                            despite
                            your past.
                        </div>
                    </div>
                </div>
            </>

        )

    }

}

export default withWindowSizeListener(FutureRelRisk);