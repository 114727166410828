import React, {useState} from 'react';
import {withRouter} from "react-router";
import jQuery from "jquery";

function NicknameModal(props) {

    const [nickName, setNickName] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState(false);
    const [errorDetail, setErrorDetail] = useState("You need to accept terms of service and enter a name for your assessment.");

    const saveNickname = (e) => {

        e.preventDefault();

        if (termsAccepted && nickName.length > 0) {

            setError(false);

            props.beginAssessment(termsAccepted, nickName.trim())
                .then((response) => {

                    if (response.status >= 200 && response.status < 300) {

                        jQuery('#nicknameModal').modal('hide');

                        props.history.push(`/survey/${response.data.surveyId}`);

                    }

                })
                .catch(error => {
                    //console.log(error);
                    setError(true);
                    setErrorDetail(error.response.data.detail);
                });


            // let termsData = {
            //   termsAccepted: termsAccepted,
            //   nickName: nickName
            // };
            //
            // axios.post(`${process.env.REACT_APP_API_URL}/api/survey/${props.redemptionCode}`, termsData, {
            //   headers: {
            //     Authorization: 'Bearer ' + localStorage.getItem('token')
            //   }
            // })
            // .then((response) => {
            //
            //   if (response.status >= 200 && response.status < 300) {
            //
            //     jQuery('#nicknameModal').modal('hide');
            //
            //     props.history.push(`/survey/${response.data.surveyId}`);
            //
            //   }
            //
            // })
            // .catch(error => {
            //   console.log(error);
            //   setError(true);
            //   setErrorDetail(error.response.data.detail);
            // });

        } else {
            setError(true);
        }
    };

    const cleanUp = () => {

        setNickName("");
        setTermsAccepted(false);
        setError(false);

    };

    let errorDiv = <div className="alert alert-danger text-center" role="alert">
        <i className="fal fa-exclamation-triangle"></i> {errorDetail}
    </div>;


    return (

        <>
            <div className="modal fade in" id="nicknameModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="nicknameModalTitle"><i
                                className="fal fa-exclamation-circle"></i> Name This Assessment</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={cleanUp}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {error && errorDiv}
                            <form onSubmit={saveNickname}>
                                <div className="form-group">
                                    {/*<label htmlFor="inputPassword5">Enter the nickname (25 characters max) <small>(Required)</small></label>*/}
                                    <input type="text" id="nickname" value={nickName}
                                           onChange={e => setNickName(e.target.value)}
                                           maxLength='25' className="form-control" aria-describedby="nickNameHelpBlock"
                                           placeholder="Enter the nickname (ex. Mary's or John's)"/>
                                    <small id="nickNameHelpBlock" className="form-text text-muted">
                                        This is the most comprehensive, research-validated assessment. There are over
                                        200 questions and will require at least 25 minutes. Your honesty will provide
                                        best results. The assessment can be started and resumed at a later time.
                                    </small>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" checked={termsAccepted}
                                           onChange={() => setTermsAccepted(!termsAccepted)}
                                           className="custom-control-input" id="terms"/>
                                    <label className="custom-control-label" htmlFor="terms">Check here to indicate that
                                        you have read and agree to the</label>
                                    <div>
                                        <a href='/terms.html' target='_blank'>Terms of Service</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={cleanUp}>Close
                            </button>
                            <button type="submit" className="btn btn-primary" onClick={saveNickname}
                                    disabled={!termsAccepted || nickName.trim().length === 0}>Save and Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default withRouter(NicknameModal);