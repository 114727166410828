import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";

class FamilyBackground extends Component {

    state = {
        backGroundColor: ["rgba(238, 238, 238, 0.2)", "rgba(238, 238, 238, 0.2)"],
        borderColor: ["rgb(238, 238, 238)", "rgb(238, 238, 238)"],
        labels: ["YOUR RATING", "YOUR PARTNER'S RATING"],
    };


    componentDidMount() {
    }

    render() {

        let famQualityData = [[this.props.famQuality.primarySelf[0], this.props.famQuality.partnerSelf[0]],
            [100 - this.props.famQuality.primarySelf[0], 100 - this.props.famQuality.partnerSelf[0]]];
        let famQualityColors = [this.props.famQuality.primarySelf[1], this.props.famQuality.partnerSelf[1]];

        let parentMarriageData = [[this.props.parentMarriage.primarySelf[0], this.props.parentMarriage.partnerSelf[0]],
            [100 - this.props.parentMarriage.primarySelf[0], 100 - this.props.parentMarriage.partnerSelf[0]]];
        let parentMarriageColors = [this.props.parentMarriage.primarySelf[1], this.props.parentMarriage.partnerSelf[1]];

        let famInfluenceData = [[this.props.famInfluence.primarySelf[0], this.props.famInfluence.partnerSelf[0]],
            [100 - this.props.famInfluence.primarySelf[0], 100 - this.props.famInfluence.partnerSelf[0]]];
        let famInfluenceColors = [this.props.famInfluence.primarySelf[1], this.props.famInfluence.partnerSelf[1]];


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-home-heart fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            FAMILY BACKGROUND
                        </div>
                    </div>
                </div>

                <GraphLegend/>


                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td className="border-right" align="center"><DonutChart elId='famBackg1'
                                                                                    percent={this.props.famBackground.primarySelf[0]}
                                                                                    color={this.props.famBackground.primarySelf[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='famBackg2'
                                                           percent={this.props.famBackground.partnerSelf[0]}
                                                           color={this.props.famBackground.partnerSelf[1]}/></td>
                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td className="border-right" align="center"><b>YOUR</b> RATING</td>
                            <td align="center"><b>YOUR PARTNER'S</b> RATING</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    The <b>Family Background</b> scale is a measure of your perceptions of your family background and
                    how the
                    experiences you had in your family of origin have influenced your perspective on other relationships
                    in your
                    life. For example, if a person had an upbringing with low conflict and available parents then
                    usually that person
                    will also view friendships, romantic partners, and other relationships outside the family of origin
                    in a positive
                    way.
                </div>

                <div className='mt-3 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            In research with couples, the higher the levels of positive family background, the higher
                            the levels of emotional readiness and kindness/flexibility.
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='mt-4 text-justify'>
                    The scales in the following graphs show a comparison of your perceptions of your family background
                    experiences.
                    For all of the scales in this section, higher scores indicate that your experiences with your
                    families (e.g., relationships with parents, etc.)
                    are generally better than those who have low scores.
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>FAMILY QUALITY</b>
                </div>

                <div className="chart-container" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='famQual' data={famQualityData} colors={famQualityColors}
                                        displayTicks={true}
                                        labels={this.state.labels} backGroundColor={this.state.backGroundColor}
                                        borderColor={this.state.borderColor}
                                        height='90'/>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>PARENT'S MARRIAGE</b>
                </div>

                <div className="chart-container" style={{width: '100%', borderBottom: '1px black solid'}}>
                    <HorizontalBarChart barChartId='pMarriage' data={parentMarriageData} colors={parentMarriageColors}
                                        displayTicks={true}
                                        labels={this.state.labels} backGroundColor={this.state.backGroundColor}
                                        borderColor={this.state.borderColor}
                                        height='90'/>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>FAMILY INFLUENCE</b>
                </div>

                <div className="chart-container" style={{width: '100%', borderBottom: '1px black solid'}}>
                    <HorizontalBarChart barChartId='famInfl' data={famInfluenceData} colors={famInfluenceColors}
                                        displayTicks={true}
                                        labels={this.state.labels} backGroundColor={this.state.backGroundColor}
                                        borderColor={this.state.borderColor}
                                        height='90'/>
                </div>


                <StrengthLegendDetail id="emr-strength">
                    People who score in this area of the scale see their families as emotionally healthy,
                    their parents' marriages as strong, and their current relationships with parents are healthy and
                    encouraging.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="emr-needs-impr">
                    People scoring in this section, all things considered, tend to view their childhood years and their
                    relationships with their parents in a good way.
                    There were moments growing up that caused some anxiety and instability but those were paralleled by
                    other experiences that promoted feelings of
                    security and happiness. Overall there are not many unresolved negative experiences from their
                    childhood that affect their emotional
                    readiness in other relationships, although it may benefit a couple to discuss some of these less
                    than ideal experiences.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="emr-challenge">
                    People who have family background scores in the red area are more likely to be in relationships that
                    are less satisfying.
                </ChallengeLegendDetail>


                <button className="btn btn-outline-dark no-print" type="button" data-toggle="collapse"
                        data-target="#family"
                        aria-expanded="false" aria-controls="emotion">
                    Show Section Questions
                </button>


                <div className="collapse" id="family">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-home-heart"/> Family Background</h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col" className="align-middle">How much do you agree with the following
                                        statements about your family, based on your years growing up?
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">
                                        My mother was happy in her marriage
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        I would like my marriage to be like my parents' marriage
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        My father was happy in his marriage
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle">
                                        How much do you agree with the following statements about your family, based on
                                        your years growing up?

                                    </th>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        From what I experienced in my family, I think family relationships are safe,
                                        secure, rewarding, worth being in, and a source of comfort
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        From what I experienced in my family, I think family relationships are
                                        confusing, unfair, anxiety-provoking, inconsistent, and unpredictable
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        We had a loving atmosphere in our family
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        All things considered, my childhood years were happy
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle">
                                        How much do you agree with the following statements about your family, based on
                                        your years growing up?
                                    </th>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        There are matters from my family experience that I'm still having trouble
                                        dealing with or coming to terms with
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        There are matters from my family experience that negatively affect my ability to
                                        form close relationships
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">
                                        I feel at peace about anything negative that happened to me in the family in
                                        which I grew up
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col-2">
                        </div>
                        <div className="col-10">
                            <div className="float-right pb-2">
                                Interpretation Guidelines and Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>
                                Discuss each family background scale and how you think it has affected you as an adult.
                                How may this factor be affecting you or your
                                relationship as a couple now? For example, if you rate your family's processes as
                                largely negative (e.g., having an unloving atmosphere),
                                how might this affect your view of marriage and family life and your current
                                relationship?
                            </li>
                            <li>
                                For bar graphs that are very low, think back on how you answered these questions to
                                better determine why the score is so low.
                                For scales with very low scores, set goals on how to improve your family relationships,
                                if possible. For example, if you rated your
                                father-child relationship as poor, what can you do to improve it now?
                            </li>
                            <li>
                                Discuss the stressors that occurred to your family while growing up. Discuss how these
                                stressors and your family's type of reaction
                                (e.g. denial, quick resolution of the crisis, etc.) affected you as a child and affect
                                you now as an adult. How have these stressors
                                affected your attitudes about marriage and family life? Are you more or less confident
                                that you can handle future life stressors as an
                                individual or a couple? Why?
                            </li>
                        </ul>
                    </div>
                </div>

            </>

        )

    }

}

export default FamilyBackground;