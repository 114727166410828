import React, {Component} from 'react';

class RelateReportMenu extends Component {


  showComponent = (componentToShow) => {

    this.props.onClick(componentToShow);

  };

  componentDidMount() {
  }

  render() {


    return (

          <div className="btn-group">
            <button type="button" className="btn btn-outline-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fal fa-chart-bar"/> Report Sections
            </button>
            <div className="dropdown-menu">
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('cover')}>
                <i className="fal fa-info-square text-muted mr-1"/> Report Summary
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('kindnessAndFlexibility')}>
                <i className="fal fa-hands-heart text-muted mr-1"/> Kindness & Flexibility
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('emotionalReadiness')}>
                <i className="fal fa-hand-holding-seedling text-muted mr-1"/> Emotional Readiness
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('familyBackground')}>
                <i className="fal fa-home-heart text-muted mr-1"/> Family Background
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('relationshipQuality')}>
                <i className="fal fa-medal text-muted mr-1"/> Relationship Quality
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('relationshipEffort')}>
                <i className="fal fa-hand-holding-heart text-muted mr-1"/> Relationship Effort
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('effectiveCommunication')}>
                <div className="fa-stack text-muted mr-1" style={{fontSize: '.7rem'}}>
                  <i className="fal fa-comment fa-stack-2x"/>
                  <i className="fal fa-heart fa-stack-1x"/>
                </div>
                Effective Communication
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('conflictResolution')}>
                <i className="fal fa-handshake text-muted mr-1"/> Conflict Resolution
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('sexualSatisfaction')}>
                <i className="fal fa-hand-heart text-muted mr-1"/> Sexual Satisfaction
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('relationalAggression')}>
                <i className="fal fa-exclamation-circle text-muted mr-1"/> Relational Aggression
              </div>
              <div className="dropdown-item pointer-cursor" onClick={() => this.showComponent('attachment')}>
                <i className="fal fa-leaf-heart text-muted mr-1"/>
                Attachment
              </div>
            </div>
          </div>

    )

  }

}

export default RelateReportMenu;