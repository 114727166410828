
import React from 'react';

const challengeLegendDetail = (props) => {


  return (

      <div className="bd-callout bd-callout-warning text-justify" style={{borderLeftColor: "#dc2229"}}>
        <h5 id={props.id} style={{color: "#dc2229"}}>Challenge</h5>
        <div style={{height: '4px', width: '100px', backgroundColor: '#dc2229'}} className='mb-2'></div>

        <p>
          {props.children}
        </p>
      </div>

  )};

export default challengeLegendDetail;