import React from "react";

export function createRiskTable (value) {
        return <>
            <td className={value === 'true' ? 'table-danger text-center' : ''}>
                {value === 'true' ? <i className="fal fa-check"></i> : ''}
            </td>
            <td className={value === 'false' ? 'table-success text-center' : ''}>
                {value === 'false' ? <i className="fal fa-check"></i> : ''}
            </td>
        </>;
}


export function createRiskTableNumeric (result, challengeNumber) {
    let valueAsNumber = Number(result);
    return <>
        <td className={valueAsNumber > challengeNumber ? 'table-danger text-center' : ''}>
            {valueAsNumber > challengeNumber ? <i className="fal fa-check"></i> : ''}
        </td>
        <td className={valueAsNumber <= challengeNumber ? 'table-success text-center' : ''}>
            {valueAsNumber <= challengeNumber ? <i className="fal fa-check"></i> : ''}
        </td>
    </>;
}