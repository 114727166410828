import React, {Component} from 'react';
import jQuery from "jquery";
import axios from "../axios/axios-custom";

class ProfessionalReview extends Component {

    state = {
        interested: false
    };

    componentDidMount() {


        jQuery('#exampleModalCenter').modal({
            backdrop: 'static'
        });

    }

    interested = async () => {

        const assessmentId = this.props.match.params.assessmentId;

        let url = `${process.env.REACT_APP_API_URL}/api/report/professional-review/${assessmentId}`;

        try {
            await axios.get(url, {withCredentials: true});

            this.setState({interested: true});

            // jQuery('#exampleModalCenter').modal('hide');
            // this.props.history.push(`/report/${this.props.match.params.assessmentId}`);

        } catch (error) {

            this.setState({showSpinner: false});

            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                this.setState({errorDetails: error.response.data.detail});

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                //console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                //console.log('Error', error.message);
            }


        }



    };

    notInterested = () => {

        jQuery('#exampleModalCenter').modal('hide');
        this.props.history.push(`/report/${this.props.match.params.assessmentId}`);

    };

    render() {
        return (
            <div>

                {/*Button trigger modal*/}
                {/*<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">*/}
                {/*    Launch demo modal*/}
                {/*</button>*/}

                {/*Modal*/}

                <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" style={{display: "block", paddingRight: "17px"}}
                     aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content"  style={{backgroundColor: "#4575a3", color: "white"}}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle" style={{width: "100%"}}>
                                    <div className="d-flex justify-content-around text-justify">
                                        <div className="p-2"><img src={`/images/report.svg`}  height='50' alt="Report" /></div>
                                        <div className="p-2"><img src={`/images/therapist.svg`}  height='50' alt="Therapist" /></div>
                                        <div className="p-2"><img src={`/images/video.svg`}  height='50' alt="Video" /></div>
                                    </div>
                                </h5>
                            </div>
                            <div className="modal-body text-center" style={{fontSize: "1.2em"}}>
                                {!this.state.interested &&
                                    <>
                                        <div className="mb-5">
                                            We offer free report evaluations from licensed therapists. A professional will send a 5-10 minute video offering feedback
                                            and advice to help you understand your scores, and improve in certain areas if needed.
                                        </div>

                                        <div>
                                            <h4>Would you like a FREE report review?</h4>
                                        </div>
                                    </>
                                }

                                {this.state.interested &&
                                <>
                                    <div>
                                        <h4>We will have a member of our team be in touch with you shortly</h4>
                                    </div>
                                </>
                                }

                            </div>
                            <div className="modal-footer">
                                <div className="d-flex justify-content-around text-justify"  style={{width: "100%"}}>
                                    {!this.state.interested &&
                                        <>
                                            <div className="p-2">
                                                <button type="button" className="btn" style={{backgroundColor: "white", color: "#4575a3"}} onClick={this.interested}>Yes, Please</button>
                                            </div>
                                            <div className="p-2">
                                                <button type="button" className="btn" onClick={this.notInterested} style={{backgroundColor: "white", color: "#4575a3"}}>
                                                    No, Thanks
                                                </button>
                                            </div>
                                        </>
                                    }

                                    {this.state.interested &&
                                    <>
                                        <div className="p-2">
                                            <button type="button" className="btn" onClick={this.notInterested} style={{backgroundColor: "white", color: "#4575a3"}}>
                                                Continue to my report
                                            </button>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">*/}
                {/*    <div class="modal-dialog">*/}
                {/*        <div class="modal-content">*/}
                {/*            <div class="modal-header">*/}
                {/*                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                {/*                <button type="button" class="close" data-dismiss="modal" aria-label="Close">*/}
                {/*                    <span aria-hidden="true">&times;</span>*/}
                {/*                </button>*/}
                {/*            </div>*/}
                {/*            <div class="modal-body">*/}
                {/*                ...*/}
                {/*            </div>*/}
                {/*            <div class="modal-footer">*/}
                {/*                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>*/}
                {/*                <button type="button" class="btn btn-primary">Save changes</button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        );
    }
}

export default ProfessionalReview;