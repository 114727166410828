import React, {Component} from 'react';
import axios from "../axios/axios-custom";
import Spinner from '../Spinner/Spinner';
import Error from '../Error/Error';
import {NavLink} from "react-router-dom";
import FutureRelRisk from "./ready/FutureRelRisk";
import ReadyFamilyBackground from "./ready/ReadyFamilyBackground";
import FamBackgroundChecklist from "./ready/FamBackgroundChecklist";
import RelationshipInfluence from "./ready/RelationshipInfluence";
import Myths from "./ready/Myths";
import MentalHealth from "./ready/MentalHealth";
import RiskBehaviorChecklist from "./ready/RiskBehaviorChecklist";
import RelationshipOutlook from "./ready/RelationshipOutlook";
import MarriageAdvantage from "./ready/MarriageAdvantage";
import TimingOfMarriage from "./ready/TimingOfMarriage";
import MarriageOptimism from "./ready/MarriageOptimism";
import MaritalSalience from "./ready/MaritalSalience";
import MaritalPermanence from "./ready/MaritalPermanence";
import ReadyAttachment from "./ready/ReadyAttachment";
import ReadyCommunication from "./ready/ReadyCommunication";
import ReadyAgression from "./ready/ReadyAgression";
import ReadyTraits from "./ready/ReadyTraits";
import ReadyConflictResolve from "./ready/ReadyConflictResolve";
import Warmth from "./ready/Warmth";
import Conscientiousness from "./ready/Conscientiousness";
import ReportDivider from "./ReportDivider";
import Cover from "./ready/ReadyCover";

class ReadyPrint extends Component {

    state = {
        reportInfo: {},
        error: '',
        errorDetail: '',
        assessmentId: '',
        errorStatus: 0,
        componentToShow: 'futureRisks',
        sections: {
            futRelRisk: 'futureRisks',
            zmftqmddagv: 'famBackgroundChecklist',
            sIHVzZXJuYW1lP: 'readyFamilyBackground',
            aWwuY29tLCB: 'relationshipInfluence',
            GrcCuJBqo: 'myths',
            nkLVnlszme: 'mentalHealth',
            A1elYC2Qt6Ick: 'riskBehaviorsChecklist',
            s5dW1haWxA: 'relationshipOutlook',
            jul7ipaylr: 'marriageAdvantage',
            inxtt3joym: 'timingOfMarriage',
            zmeqbhkp1l: 'marriageOptimism',
            tglje4y7dl: 'maritalSalience',
            dlub2nt7t6: 'maritalPermanence',
            g9a063kd6s: 'readyAttachment',
            ui66zlox2g: 'readyCommunication',
            fgfpnc8043: 'readyConflictResolve',
            u1flhrywx0: 'readyAggression',
            zhww8tr289: 'personalTraits',
            xeb45u6h82: 'warmth',
            io5q6x1ijs: 'conscience'
        },

        reverseSections: {
            futureRisks: 'futRelRisk',
            famBackgroundChecklist: 'zmftqmddagv',
            readyFamilyBackground: 'sIHVzZXJuYW1lP',
            relationshipInfluence: 'aWwuY29tLCB',
            myths: 'GrcCuJBqo',
            mentalHealth: 'nkLVnlszme',
            riskBehaviorsChecklist: 'A1elYC2Qt6Ick',
            relationshipOutlook: 's5dW1haWxA',
            marriageAdvantage: 'jul7ipaylr',
            timingOfMarriage: 'inxtt3joym',
            marriageOptimism: 'zmeqbhkp1l',
            maritalSalience: 'tglje4y7dl',
            maritalPermanence: 'dlub2nt7t6',
            readyAttachment: 'g9a063kd6s',
            readyCommunication: 'ui66zlox2g',
            readyConflictResolve: 'fgfpnc8043',
            readyAggression: 'u1flhrywx0',
            personalTraits: 'zhww8tr289',
            warmth: 'xeb45u6h82',
            conscience: 'io5q6x1ijs'
        },

        readable: {
            futureRisks: 'Future Relationship Risk',
            famBackgroundChecklist: 'Family Background Checklist',
            readyFamilyBackground: 'Family Background',
            relationshipInfluence: 'Relationship Influence',
            myths: 'Dating Myths',
            mentalHealth: 'Mental Health',
            riskBehaviorsChecklist: 'Risk Behaviors Checklist',
            relationshipOutlook: 'Relationship Outlook',
            marriageAdvantage: 'Marriage Advantage',
            timingOfMarriage: 'Timing Of Marriage',
            marriageOptimism: 'Marriage Optimism',
            maritalSalience: 'Marital Salience',
            maritalPermanence: 'Marital Permanence',
            readyAttachment: 'Attachment',
            readyCommunication: 'Effective Communication',
            readyConflictResolve: 'Conflict Resolution',
            readyAggression: 'Relational Aggression',
            personalTraits: 'Trait - Emotional Readiness',
            warmth: 'Trait - Warmth',
            conscience: 'Trait - Conscientiousness'
        },

        componentKeys: [],
        componentsLength: 0,
    };

    componentDidMount() {

        const assessmentId = this.props.match.params.assessmentId;

        let section = this.props.match.params.section;

        if (typeof section === 'undefined') {

            section = 'futRelRisk';

        }

        if (this.state.sections[section] !== null && this.state.sections[section] !== '') {
            section = this.state.sections[section];
        } else {
            section = 'futRelRisk';
        }

        this.setState({
            assessmentId: assessmentId, componentToShow: section,
            componentKeys: this.getComponentKeys(), componentsLength: this.getComponentLength()
        });

        this.fetchReportInfo(assessmentId);

        this.unlisten = this.props.history.listen((location, action) => {

            if (!location.pathname.includes('print')) {

                let section = this.props.match.params.section;

                if (typeof section === 'undefined') {

                    section = 'futRelRisk';

                }

                if (this.state.sections[section] !== null && this.state.sections[section] !== '') {
                    section = this.state.sections[section];
                } else {
                    section = 'futRelRisk';
                }

                this.setState({componentToShow: section});

            }
        });
    }

    // componentWillMount() {
    //
    //
    //     this.unlisten = this.props.history.listen((location, action) => {
    //
    //         if (!location.pathname.includes('print')) {
    //
    //             let section = this.props.match.params.section;
    //
    //             if (typeof section === 'undefined') {
    //
    //                 section = 'futRelRisk';
    //
    //             }
    //
    //             if (this.state.sections[section] !== null && this.state.sections[section] !== '') {
    //                 section = this.state.sections[section];
    //             } else {
    //                 section = 'futRelRisk';
    //             }
    //
    //             this.setState({componentToShow: section});
    //
    //         }
    //     });
    // }

    componentWillUnmount() {
        this.unlisten();
    }

    fetchReportInfo = (assessmentId) => {
        let url = `${process.env.REACT_APP_API_URL}/api/report/prefetch/${assessmentId}`;

        axios.get(url)
            .then((response) => {

                if (response.data === 'ready') {

                    this.loadAssessmentResult(assessmentId);

                } else {

                    this.setState({
                        error: 'There was a problem loading your report',
                        errorDetail: 'There was a problem loading your report',
                        errorStatus: '400'
                    });

                }

            })
            .catch(error => {
               // console.log(error);

                if (error.response.data.status === 422) {
                    this.setState({
                        error: error,
                        errorDetail: error.response.data.detail,
                        errorStatus: error.response.data.status
                    });
                } else {
                    this.setState({error: error});
                }

            });

    };

    loadAssessmentResult = (assessmentId) => {


        let url = `${process.env.REACT_APP_API_URL}/api/report/${assessmentId}/sect/1`;

        axios.get(url)
            .then((response) => {

                let respObject = response.data.reduce((obj, item) => {
                    obj[item.subsection] = item.resultValues;
                    return obj;
                }, {});

               // this.setState({reportInfo: respObject});
                this.setState({reportInfo: respObject}, () => setTimeout(window.print, 1000));

            })
            .catch(error => {

                if (error.response.data.status === 422) {
                    this.setState({
                        error: error,
                        errorDetail: error.response.data.detail,
                        errorStatus: error.response.data.status
                    });
                } else {
                    this.setState({error: error});
                }

            });
    };

    showComponent = (currentComponentToShow) => {

        let newUrl = "/report-ready/" + this.state.assessmentId + '/' + this.state.reverseSections[currentComponentToShow];


        this.props.history.push(newUrl);
        window.scrollTo(0, 0);
        this.setState({componentToShow: currentComponentToShow});


    };

    getComponentKeys = () => {
        return Object.keys(this.state.reverseSections);
    };

    getComponentLength = () => {
        return Object.keys(this.state.reverseSections).length;
    };

    getCurrentIndex = () => {
        return this.getComponentKeys().indexOf(this.state.componentToShow)
    };

    render() {

        let cover = '';
        let futRelRisk = '';
        let readyFamilyBackGround = '';
        let famBackgroundChecklist = '';
        let relInfluence = '';
        let myths = '';
        let mentalHealth = '';
        let riskBehaviors = '';
        let relOutlook = '';
        let marriageAdvantage = '';
        let timingOfMarriage = '';
        let marriageOptimism = '';
        let maritalSalience = '';
        let maritalPermanence = '';
        let readyAttachment = '';
        let readyCommunication = '';
        let readyConflictResolve = '';
        let readyAggression = '';
        let personalTraits = '';
        let warmth = '';
        let conscience = '';


        if (this.state.reportInfo.futRelRisk) {

            cover = <><Cover /> <ReportDivider /></>;

            futRelRisk = <><FutureRelRisk risk={this.state.reportInfo.futRelRisk}/><ReportDivider /></>;

            readyFamilyBackGround = <><ReadyFamilyBackground
                mother={this.state.reportInfo.mother}
                father={this.state.reportInfo.father}
                famStress={this.state.reportInfo.famStress}
                famBackground={this.state.reportInfo.famBackground}
                famQuality={this.state.reportInfo.famQuality}
                parentMarriage={this.state.reportInfo.parentMarriage}
                famInfluence={this.state.reportInfo.famInfluence}/>
                <ReportDivider />
            </>;

            famBackgroundChecklist = <><FamBackgroundChecklist
                sexAbuse={this.state.reportInfo.sexAbuse}
                divorcedParents={this.state.reportInfo.divorcedParents}
                unmarriedParents={this.state.reportInfo.unmarriedParents}
                physAbuse={this.state.reportInfo.physAbuse}/>
                <ReportDivider />
            </>;

            relInfluence = <><RelationshipInfluence
                relInfluence={this.state.reportInfo.relInfluence}
                livTogether={this.state.reportInfo.livTogether}
                pastRelation={this.state.reportInfo.pastRelation}
                numPartners={this.state.reportInfo.numPartners}
            />
                <ReportDivider />
            </>;

            myths = <><Myths myth1={this.state.reportInfo.myth1}
                             myth2={this.state.reportInfo.myth2}
                             myth3={this.state.reportInfo.myth3}
                             myth4={this.state.reportInfo.myth4}
                             myth5={this.state.reportInfo.myth5}
                             myth6={this.state.reportInfo.myth6}/>
                <ReportDivider />
            </>;

            mentalHealth = <><MentalHealth depression={this.state.reportInfo.depression}
                                           anxiety={this.state.reportInfo.anxiety}/>
                <ReportDivider />
            </>;

            riskBehaviors = <><RiskBehaviorChecklist
                drugs={this.state.reportInfo.drugs}
                alcohol={this.state.reportInfo.alcohol}
                porn={this.state.reportInfo.porn}
                sexRisk={this.state.reportInfo.sexRisk}
                gambling={this.state.reportInfo.gambling}
                exercise={this.state.reportInfo.exercise}
                crime={this.state.reportInfo.crime}/>
                <ReportDivider />
            </>;

            relOutlook = <><RelationshipOutlook romanticism={this.state.reportInfo.romanticism}
                                                searchLove={this.state.reportInfo.searchLove}/>
                <ReportDivider />
            </>;
            marriageAdvantage = <><MarriageAdvantage advantage={this.state.reportInfo.advantage}
                                                     marTiming={this.state.reportInfo.marTiming}
            />
                <ReportDivider />
            </>;

            timingOfMarriage = <><TimingOfMarriage marTiming={this.state.reportInfo.marTiming}
                                                   idealAge={this.state.reportInfo.idealAge}/>
                <ReportDivider />
            </>;

            marriageOptimism = <><MarriageOptimism optimism={this.state.reportInfo.optimism}/>
                <ReportDivider />
            </>;

            maritalSalience = <><MaritalSalience salience={this.state.reportInfo.salience}/>
                <ReportDivider />
            </>;
            maritalPermanence = <><MaritalPermanence permamence={this.state.reportInfo.permamence}/>
                <ReportDivider />
            </>;

            readyAttachment = <><ReadyAttachment attachAnxiety={this.state.reportInfo.attachAnxiety}
                                                 avoidance={this.state.reportInfo.avoidance}/>
                <ReportDivider />
            </>;

            readyCommunication = <><ReadyCommunication overallEffComm={this.state.reportInfo.overallEffComm}
                                                       empathy={this.state.reportInfo.empathy}
                                                       love={this.state.reportInfo.love}
                                                       clearSending={this.state.reportInfo.clearSending}
                                                       sooth={this.state.reportInfo.sooth}/>
                <ReportDivider />
            </>;

            readyConflictResolve = <><ReadyConflictResolve
                overallConflictResol={this.state.reportInfo.overallConflictResol}
                noncritical={this.state.reportInfo.noncritical}
                respect={this.state.reportInfo.respect}
                notOverwhelmed={this.state.reportInfo.notOverwhelmed}/>
                <ReportDivider />
            </>;

            readyAggression = <><ReadyAgression relAggression={this.state.reportInfo.relAggression}/>
                <ReportDivider />
            </>;

            personalTraits = <><ReadyTraits calm={this.state.reportInfo.calm} maturity={this.state.reportInfo.maturity}
                                            happiness={this.state.reportInfo.happiness}
                                            selfEsteem={this.state.reportInfo.selfEsteem}
                                            emotionReady={this.state.reportInfo.emotionReady}/>
                <ReportDivider />
            </>;

            warmth = <><Warmth kind={this.state.reportInfo.kind} sociability={this.state.reportInfo.sociability}/>
                <ReportDivider />
            </>;

            conscience = <><Conscientiousness flex={this.state.reportInfo.flex}
                                              organization={this.state.reportInfo.organization}/>
                <ReportDivider />
            </>;


        }


        return (

            <>

                {(!this.state.reportInfo.kindAndFlex && !this.state.reportInfo.futRelRisk) && !this.state.error &&
                <Spinner/>}

                {
                    this.state.error && this.state.errorDetail.length === 0 &&
                    <Error heading='Assessment Results'>
                        There was a problem displaying your assessment results. An unexpected error has occurred.
                        If you believe you reached this page in error, please try again later or contact support.
                    </Error>
                }

                {
                    this.state.error && this.state.errorDetail.length > 0 &&
                    <Error heading='Assessment Results'>
                        {this.state.errorDetail}
                        {this.state.errorStatus === 422 &&
                        <div>
                            Complete your assessment <NavLink className='alert-link'
                                                              to={`/survey/${this.props.match.params.assessmentId}`}>Here</NavLink>
                        </div>
                        }
                    </Error>
                }


                {cover}
                {futRelRisk}
                {readyFamilyBackGround}
                {famBackgroundChecklist}
                {relInfluence}
                {myths}
                {mentalHealth}
                {riskBehaviors}
                {relOutlook}
                {marriageAdvantage}
                {timingOfMarriage}
                {marriageOptimism}

                {maritalSalience}
                {maritalPermanence}
                {readyAttachment}

                {readyCommunication}
                {readyConflictResolve}
                {readyAggression}
                {personalTraits}
                {warmth}
                {conscience}

            </>

        )


    }
}

export default ReadyPrint;