import React, {Component} from "react";
import DropDown from "./DropDown";
import Numeric from "./Numeric";
//import axios from 'axios';
import axios from "../axios/axios-custom";
import Spinner from '../Spinner/Spinner';
import {withRouter} from 'react-router-dom';
import ResponsiveMatrixGrid from "./ResponsiveMatrixGrid";
import jQuery from 'jquery';
import RelateProgress from "./RelateProgress";
import ReadyProgress from "./ReadyProgress";

class Survey extends Component {

  state = {

    survey: null,
    responses: [],
    surveySectionId: 0,
    sectionDisplayName: '',
    sectionDescription: '',
    surveyId: 0,
    responseQuestions: {},
    errorMessage: '',
    previous: null,
    next: null,
    assessmentId: '',
    error: '',
    showSpinner: false,
  };

  componentDidMount() {

    window.addEventListener("resize", this.resize);

    const assessmentId = this.props.match.params.assessmentId;

    this.setState({assessmentId: assessmentId});

   this.loadAssessmentSection(assessmentId, 0);


  };


  loadAssessmentSection = (assessmentId, surveyId) => {

    this.setState({showSpinner: true});

    window.scrollTo(0, 0);

    let url = `${process.env.REACT_APP_API_URL}/api/survey/${assessmentId}/start`;

    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    .then( (response) => {
      if(response.data.surveySections === null) {

        if(surveyId === 1) {

           this.props.history.push(`/report/${assessmentId}`);
          //this.props.history.push(`/professional/review/${assessmentId}`);


        } else {

          this.props.history.push(`/report-ready/${assessmentId}`);

        }



      } else {

        this.setState({survey: response.data,
          surveySectionId: response.data.surveySectionId,
          sectionDisplayName: response.data.displayName,
          sectionDescription: response.data.description,
          surveyId: response.data.surveyId ,
          responseQuestions: response.data.questions,
          previous: response.data.previous,
          next: response.data.next,
          required: response.data.required,
        });

      }
      //console.log(response);

    })
        .catch(error => {

          this.setState({showSpinner: false});

          // Error 😨
          if (error && error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data);
            //console.log(error);
            this.setState({error: error.response.data.detail});

          } else if (error.request) {
            //console.log(error);

            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
          } else {
            // Something happened in setting up the request and triggered an Error
           // console.log('Error', error.message);
           // console.log(error);
          }

         // console.log(error);

        });

  };

  testMethod = (questionId, responseChoiceId) => {

    let questions =  {...this.state.responseQuestions};

    questions[questionId] = responseChoiceId;

    this.setState({
      // rowClass: "selected-response",
      // responses: this.state.responses.concat(responseChoiceId)

      responseQuestions: questions,
      // items.splice(foundIndex, 1, item)
    });

    // let foundIndex = this.state.responses.findIndex(element => element === responseChoiceId);
    //
    // console.log(foundIndex);
    //
    // if(foundIndex > -1) {
    //
    //   this.setState({
    //     // rowClass: "selected-response",
    //     // responses: this.state.responses.concat(responseChoiceId)
    //
    //     responses: this.state.responses.splice(foundIndex, 1, responseChoiceId),
    //     // items.splice(foundIndex, 1, item)
    //   });
    //
    // } else {
    //
    //   this.setState({
    //     // rowClass: "selected-response",
    //     responses: this.state.responses.concat(responseChoiceId),
    //   });
    //
    // }

  };


  previous = (sectionId) => {


    // this.setState({survey: null,
    //   surveySectionId: 0,
    //   sectionDisplayName: '',
    //   sectionDescription: '',
    //   surveyId: 0 ,
    //   responseQuestions:{},
    //   responses: [],
    // });


    this.setState({survey: null,
      surveySectionId: 0,
      sectionDisplayName: '',
      sectionDescription: '',
      surveyId: 0 ,
      responseQuestions: {},
      responses: [],
      previous: 0,
      next: 0,
      required: false,
    });

    window.scrollTo(0, 0);

    let url = `${process.env.REACT_APP_API_URL}/api/survey/${this.state.assessmentId}/section/${sectionId}`;

    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    .then( (response) => {

      this.setState({survey: response.data,
        surveySectionId: response.data.surveySectionId,
        sectionDisplayName: response.data.displayName,
        sectionDescription: response.data.description,
        surveyId: response.data.surveyId ,
        responseQuestions: response.data.questions,
        previous: response.data.previous,
        next: response.data.next,
        required: response.data.required,
      });

    })
    .catch(error => {
      //console.log(error);
    });

  };



  save = () => {

    let canSubmit = true;
    let unAnsweredQuestions = [];
    let surveyId = this.state.surveyId;

    if (this.state.required) {

      for (let response in this.state.responseQuestions) {
        if (this.state.responseQuestions.hasOwnProperty(response)) {
          if (this.state.responseQuestions[response] === null ||
              this.state.responseQuestions[response] === 0 || this.state.responseQuestions[response].length === 0) {
            canSubmit = false;
            //window.scrollTo(0, 0);
            let itemId = document.getElementsByName("responseChoice" + response)[0].id;
            unAnsweredQuestions.push(itemId);
          }
        }
      }

    }

    if(canSubmit) {

      let surveySection = {

        responses: this.state.responseQuestions,
        surveySectionId: this.state.surveySectionId,

      };
      let userId = localStorage.getItem('userId');

      let url = `${process.env.REACT_APP_API_URL}/api/survey/${this.state.assessmentId}/user/${userId}`;

      axios.post(url, surveySection, {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })
      .then( (response) => {

        // this.setState({survey: null,
        //   surveySectionId: 0,
        //   sectionDisplayName: '',
        //   sectionDescription: '',
        //   surveyId: 0 ,
        //   responseQuestions:{},
        //   responses: [],
        // });


        this.setState({survey: null,
          surveySectionId: 0,
          sectionDisplayName: '',
          sectionDescription: '',
          surveyId: 0 ,
          responseQuestions: {},
          responses: [],
          previous: 0,
          next: 0,
          required: false,
        });


        this.loadAssessmentSection(this.state.assessmentId, surveyId);
        // this.survey = response.data;

        //console.log(response);

      })
      .catch(error => {
        //console.log(error);
      });

    } else {

      this.setState({errorMessage: 'Please respond to all of the questions before continuing!'});

      //this.openModal();
      // Could put const $ = window.$; at the top
      jQuery('#exampleModalCenter').modal({
        backdrop: 'static'
      });
      // window.$('#exampleModalCenter').modal({
      //   backdrop: 'static'
      // });

      unAnsweredQuestions.forEach(item => {
        let closestTr = document.getElementById(item).closest('tr');
        if(closestTr != null) {
          closestTr.className = 'survey-no-answer';
        }

      });

      if(unAnsweredQuestions.length > 0) {
        document.getElementById(unAnsweredQuestions[0]).scrollIntoView();
      }
    }

  };


  // function showObject(obj) {
  //   var result = "";
  //   for (var p in obj) {
  //     if( obj.hasOwnProperty(p) ) {
  //       result += p + " , " + obj[p] + "\n";
  //     }
  //   }
  //   return result;
  // }


  // Reference: https://stackoverflow.com/questions/47402365/how-to-have-nested-loops-with-map-in-jsx
  render() {

    let previousButton = null;
    let nextButton = null;
    let completeButton = null;

    if(this.state.survey) {
      previousButton  = <button type="button" className="btn btn-lg btn-outline-primary" tabIndex='-1' onClick={() => this.previous(this.state.previous)}>Previous</button>;
      nextButton = <button type="button" className="btn btn-lg btn-outline-primary" onClick={this.save}>Next</button>;
      completeButton = <button type="button" className="btn btn-lg btn-outline-danger" onClick={this.save}>Complete Survey</button>;
    }

    let errorDiv = <div className="alert alert-danger text-center" role="alert">
      <i className="fal fa-exclamation-triangle"></i> {this.state.error}
    </div>;

    return (

        <>

          {this.state.error && errorDiv}


          {this.state.survey != null && this.state.survey.surveyId === 1 &&
          <RelateProgress sections={this.state.survey.surveySections} currentSectionId={this.state.surveySectionId} />}

          {this.state.survey != null && this.state.survey.surveyId === 2 &&
          <ReadyProgress sections={this.state.survey.surveySections} currentSectionId={this.state.surveySectionId} />}



          {/*<h1>{'Page: ' + this.state.survey.page + ' of ' sections={this.state.survey.surveySections}  */}
          {/*+ this.state.survey.totalPages}</h1>*/}
          {/*<h3>{'Section: ' + this.state.survey.section + ' of '*/}
          {/*+ this.state.survey.totalSections}</h3>*/}


          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.0em'}}>{this.state.sectionDisplayName}{this.state.sectionDisplayName ? ':' : ''} {this.state.sectionDescription}</h5>
            {/*<strong className='text-black-50'>{this.state.sectionDisplayName} {this.state.sectionDescription} </strong>*/}
          </div>

          {/*<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">*/}
          {/*  <h1 className="display-4">Assessment</h1>*/}
          {/*  <p className="lead">*/}
          {/*    displayName: {this.state.sectionDisplayName}  description: {this.state.sectionDescription}*/}
          {/*    Survey ID: {this.state.surveyId} Survey Section ID: {this.state.surveySectionId}*/}
          {/*    LENGTH: {Object.keys(this.state.responseQuestions).length}<br/>*/}
          {/*    REQUIRED: {this.state.required}*/}
          {/*    {this.state.required ? 'TRUE' : 'FALSE'}*/}
          {/*  </p>*/}
          {/*</div>*/}

          <hr/>


          { this.state.survey ?

            this.state.survey.questionGroups.map((questionGroup, i) => {

            if (questionGroup.questionType === 'grid') {
                return (


                    <div  key={i}>
                      <ResponsiveMatrixGrid  {...questionGroup} key={i} testMethod={this.testMethod} />
                    </div>


                )


            } else if (questionGroup.questionType === 'dropdown') {
              return (
                  <div key={i}>
                    <h4 className='mb-3'>{questionGroup.questionGroupItems.displayText}</h4>
                  <DropDown {...questionGroup} testMethod={this.testMethod} />
                  </div>



              )
            } else if (questionGroup.questionType === 'numeric') {
              return (

                  <div key={i}>
                    <Numeric  {...questionGroup} testMethod={this.testMethod} />
                  </div>
              )
            }

              return "";

          })

              : this.state.showSpinner && <Spinner />

          }

          <div className='row'>
            <div className='col-6'>{this.state.previous !== null ? previousButton : null}</div>
            <div className='col-6 text-right'>{this.state.next !== null ? nextButton : completeButton}</div>
          </div>



          {/* Modal */}
          <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle"><i className="fal fa-exclamation-circle"></i> Incomplete Survey Section</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" style={{color: 'red'}}>
                  {this.state.errorMessage}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

        </>
    );
  }

}

export default withRouter(Survey);