import React, { Component } from 'react'
import Chart from "chart.js";

class DonutChart extends Component {

  componentDidMount() {
    this.drawDonutChart();
  }


  //https://stackoverflow.com/questions/37384092/chart-js-doughnut-with-rounded-edges-and-text-centered
  drawDonutChart = () => {
    // round corners
    Chart.pluginService.register({
      afterUpdate: function (chart) {
        if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
          let arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundedCornersFor];
          arc.round = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius: (chart.outerRadius + chart.innerRadius) / 2,
            thickness: (chart.outerRadius - chart.innerRadius) / 2 - 1,
            backgroundColor: arc._model.backgroundColor
          }
        }
      },

      afterDraw: function (chart) {
        if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
          var ctx = chart.chart.ctx;
          //ctx.height = 120;
          var arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundedCornersFor];
          var startAngle = Math.PI / 2 - arc._view.startAngle;
          var endAngle = Math.PI / 2 - arc._view.endAngle;
          //ctx.height = 100;

          ctx.save();
          ctx.translate(arc.round.x, arc.round.y);
         // console.log(arc.round.startAngle)
          ctx.fillStyle = arc.round.backgroundColor;
          ctx.beginPath();
          ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
          ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        }
      },
    });

    // write text plugin
    Chart.pluginService.register({
      afterUpdate: function (chart) {
        if (chart.config.options.elements.center) {
          var helpers = Chart.helpers;
          var centerConfig = chart.config.options.elements.center;
          var globalConfig = Chart.defaults.global;
          var ctx = chart.chart.ctx;

          var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
          var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);

          if (centerConfig.fontSize)
            var fontSize = centerConfig.fontSize;
          // figure out the best font size, if one is not specified
          else {
            ctx.save();
            fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
            var maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
            var maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

            do {
              ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
              var textWidth = ctx.measureText(maxText).width;

              // check if it fits, is within configured limits and that we are not simply toggling back and forth
              if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize)
                fontSize += 1;
              else {
                // reverse last step
                fontSize -= 1;
                break;
              }
            } while (true)
            ctx.restore();
          }

          // save properties
          chart.center = {
            font: helpers.fontString(fontSize, fontStyle, fontFamily),
            fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
          };
        }
      },
      afterDraw: function (chart) {
        if (chart.center) {
          var centerConfig = chart.config.options.elements.center;
          var ctx = chart.chart.ctx;


          ctx.save();
          ctx.font = chart.center.font;
          ctx.fillStyle = chart.center.fillStyle;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.fillText(centerConfig.text, centerX, centerY);

          // // Circle around the graph
          var radius = 65;

          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);

          ctx.lineWidth = 7;
          ctx.strokeStyle = '#eee';
          ctx.stroke();

          ctx.restore();
        }
      },
    });

    // Chart JS in React
    // https://blog.bitsrc.io/customizing-chart-js-in-react-2199fa81530a
    var config = {
      type: 'doughnut',
      data: {
        datasets: [
          {

            data: [this.props.percent, 100 - this.props.percent],
            backgroundColor: [
              this.props.color,
              "#eee"
            ],
            hoverBackgroundColor: [
              this.props.color,
              "#eee"
            ],
            hoverBorderColor: [
              "#fff",
              "#fff"
            ],
            borderColor:  [
              "#fff",
              "#fff"
            ]
          }]
      },
      options: {
        layout: {
          padding: {
            // Any unspecified dimensions are assumed to be 0
            top: 15,
            bottom: 15,
          }
        },
        maintainAspectRatio: false,
        responsive: false,
        legend: {
          display: false
        },
        cutoutPercentage: 70,
        tooltips: {
          filter: function(item, data) {
            let label = data.labels[item.index];
            if (label) return item;
          },
          enabled: false,
        },
        elements: {
          arc: {
            roundedCornersFor: 0
          },
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: this.props.percent + '%',
            fontColor: '#000',
            fontFamily: "'Nunito', 'Helvetica', 'Arial', sans-serif",
            fontStyle: '600',
            //fontWeight: '600',
            fontSize: 18,
            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 256,
          }
        }
      }
    };


    let ctx = document.getElementById(this.props.elId).getContext("2d");
    //var myChart = new Chart(ctx, config);
    new Chart(ctx, config);

  };


  render() {

    return (

        <>

          <canvas id={this.props.elId} width="150" height="150"/>

        </>

    )


  }
}

export default DonutChart;