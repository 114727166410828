import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";

class SexualSatisfaction extends Component {


    componentDidMount() {
    }

    render() {


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-hand-heart fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            SEXUAL SATISFACTION
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td align="center"><DonutChart elId='sat1'
                                                           percent={this.props.sexSatisfaction.primarySelf[0]}
                                                           color={this.props.sexSatisfaction.primarySelf[1]}/>
                            </td>
                            <td className="border-right" align="center">
                            </td>
                            <td align="center"></td>
                            {/*<td align="center"><DonutChart elId='kind4' percent='0' color='#eee' /></td>*/}
                            <td align="center"><DonutChart elId='sat4'
                                                           percent={this.props.sexSatisfaction.partnerSelf[0] !== '' ? this.props.sexSatisfaction.partnerSelf[0] : '0'}
                                                           color={this.props.sexSatisfaction.partnerSelf[1]}/></td>

                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                            <td className="border-right" align="center"></td>
                            <td align="center"></td>
                            <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                            {/*NO PARTNER <br/>ANSWER RECORDED*/}
                        </tr>
                        </tbody>
                    </table>
                </div>


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    The <b>Sexual Satisfaction</b> scale measures how satisfied both you and your partner are with the
                    depth, frequency,
                    fidelity, and various other elements of physical intimacy and intercourse in the relationship.
                    Physical intimacy is
                    an important part of any relationship that will help in building and sustaining a healthy
                    relationship. Although
                    you and your partner’s intimacy levels will vary depending on life stage and circumstance, attending
                    to the
                    sexualy intimacy in any relationship is an important element of relationship success.
                </div>

                <StrengthLegendDetail id="sat-strength">
                    People who score in the Strength area are very satisfied with the level, depth, frequency, and
                    boundaries of physical and sexual intimacy within the relationship.
                    They feel that their needs have been met and often feel emotionally and physically close with their
                    partners.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="sat-needs-impr">
                    People who score in the Needs Improvement area of the graph feel somewhat satisfied but believe that
                    some change in physical intimacy or sexual
                    intimacy is needed. Some of this dissatisfaction may be with the depth, frequency, fidelity, or
                    other elements of physical intimacy or
                    intercourse in the relationship. It would be a good idea to look at some of the discussion questions
                    and reevaluate some of the important
                    factors of intimacy in your partnership.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="sat-challenge">
                    Those that score in the Challenge area on the scale feel unsatisfied or uncomfortable with the level
                    of physical or sexual intimacy within the
                    relationship. To achieve satisfaction and the continuance of a positive relationship, it is
                    necessary for you and your partner to discuss,
                    reach a level of agreement, and make changes in your levels of intimacy. Please review and discuss
                    the questions found in this section.
                </ChallengeLegendDetail>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            Research has consistently found that engaging in satisfying sexual intimacy is a vital part
                            of healthy relationship development and maintenance. Although the actual frequency of sexual
                            intercourse may fluctuate or even decrease over time in most relationships, research has
                            shown that healthy couples are able to find meaningful and satisfying ways to be physically
                            intimate with each other on a regular basis.
                        </div>
                    </div>
                </div>

                <button className="btn btn-outline-dark no-print mt-3" type="button" data-toggle="collapse"
                        data-target="#ssat"
                        aria-expanded="false" aria-controls="emotion">
                    Show Section Questions
                </button>

                <div className="collapse" id="ssat">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-hand-heart"/> Sexual Satisfaction</h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th className="align-middle">Please answer the following questions about YOURSELF
                                    </th>
                                </tr>
                                <tr>
                                    <td className="align-middle">Are you dissatisfied with the amount of variety in your
                                        sex life with your partner?
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Do you find the sexual relationship with your partner
                                        satisfactory?
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Do you feel there is a lack of love and affection in
                                        your sexual relationship?
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Are you satisfied with the amount of time you and your
                                        partner spend on foreplay?
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Do you have sexual intercourse as often as you would
                                        like?
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Do you feel dissatisfied with the amount of time your
                                        partner spends on intercourse itself?
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>Are you satisfied with the current rating of yourself on this scale? If not, what can
                                you do to improve it?
                            </li>
                            <li>How are your answers similar or different from one another? Why were there differences
                                or why did you both agree?
                            </li>
                            <li>Does your partner want you to improve the sexuality in your relationship? If so, discuss
                                why and how this can be facilitated.
                            </li>
                            <li>How will your relationship change if you improved the sexuality in your relationship?
                            </li>
                        </ul>
                    </div>
                </div>

            </>

        )

    }

}

export default SexualSatisfaction;