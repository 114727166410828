import React from "react";
import axios from "../../axios/axios-custom";
import {NavLink, withRouter} from "react-router-dom";
import NavButton from "../../layout/navbar/navigationItems/NavButton";

class Dashboard extends React.Component {

    initialState = {
        counts: [],
        relate: {
            qty: 0,
            assessmentId: 0,
            productName: ''
        },
        ready: {
            qty: 0,
            assessmentId: 0,
            productName: ''
        },
        showSpinner: false,
        finishedRelate: [],
        finishedReady: [],
        inProgress: [],
        showRelateSpinner: false,
        showReadySpinner: false,
        showInProgress: false,
        disabled: false,
    };

    state = {
        ...this.initialState
    };

    componentDidMount() {

        this.getAvailableAssessmentCounts();
        this.getFinishedRelateAssessments('relate');
        this.getFinishedRelateAssessments('ready');
        this.getInProgressAssessments();
    }


    getFinishedRelateAssessments = (assessment) => {

        this.setState({showRelateSpinner: true, showReadySpinner: true, finishedRelate: [], finishedReady: [],});

        axios.get(`${process.env.REACT_APP_API_URL}/api/pro/assessments/${assessment}/completed`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {



                if(response.data !== null && response.data.length > 0) {

                    if(assessment == 'relate') {
                        this.setState({
                            finishedRelate: response.data,
                            showRelateSpinner: false
                        });
                    } else {
                        this.setState({
                            finishedReady: response.data,
                            showReadySpinner: false
                        });
                    }
                }

                this.setState({
                    showRelateSpinner: false
                });

                this.setState({
                    showReadySpinner: false
                });

            })
            .catch(error => {

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail, showSpinner: false, showRelateSpinner: false, showReadySpinner: false});

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

                this.setState({
                    error: true,
                    showSpinner: false,
                    showRelateSpinner: false, showReadySpinner: false
                });

            });

    };


    getInProgressAssessments = () => {

        this.setState({showInProgress: true});

        axios.get(`${process.env.REACT_APP_API_URL}/api/pro/assessments/in-progress`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {

                if(response.data !== null && response.data.length > 0) {

                    this.setState({
                        inProgress: response.data
                    });
                }



                this.setState({showInProgress: false, showRelateSpinner: false});

            })
            .catch(error => {

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail, showInProgress: false});

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

                this.setState({
                    error: true,
                    showInProgress: false
                });

            });

    };


    getAvailableAssessmentCounts = () => {

        this.setState({showSpinner: true});

        axios.get(`${process.env.REACT_APP_API_URL}/api/pro/code-counts`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {

                if(response.data !== null && response.data.length > 0) {

                    let relateInfo = response.data.filter(item => {return item.productName === "Relate"})[0];

                    let readyInfo = response.data.filter(item => {return item.productName === "Ready"})[0];

                    this.setState({
                        relate: relateInfo === undefined ? this.state.relate : relateInfo,
                        ready: readyInfo === undefined ? this.state.ready : readyInfo,
                    });

                }

                this.setState({showSpinner: false});

            })
            .catch(error => {

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail, showSpinner: false});

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

                this.setState({
                    error: true,
                    showSpinner: false
                });

            });

    };

    resetState = () => {

        this.setState({...this.initialState});

    };

     updateVisibility = async (surveyId) => {

       this.setState({disabled: true});

       await axios.post(`${process.env.REACT_APP_API_URL}/api/pro/assessments/toggle-visiblity/${surveyId}`, {
             headers: {
                 Authorization: 'Bearer ' + localStorage.getItem('token')
             }
         });
         this.setState({disabled: false});

         this.getFinishedRelateAssessments('relate');
         this.getFinishedRelateAssessments('ready');
         this.getInProgressAssessments();
    };

    archiveAssessment = async (surveyId) => {

        this.setState({disabled: true});

        await axios.post(`${process.env.REACT_APP_API_URL}/api/pro/assessments/archive/${surveyId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
        this.setState({disabled: false});

        this.getFinishedRelateAssessments('relate');
        this.getFinishedRelateAssessments('ready');

    };


    showFinishedRelateAssessments = () => {

        return this.state.finishedRelate.map((item, idx) => {

            return <tr key={item.extSurveyId}>
                <td scope="row" className="align-middle">
                    <div className="custom-control custom-checkbox">
                    <input type="checkbox" checked={item.hidden}
                           onChange={() => this.updateVisibility(item.extSurveyId)}
                           disabled={this.state.disabled}
                           className="custom-control-input" id={"vsh" + idx}/>
                        <label className="custom-control-label" htmlFor={"vsh" + idx}></label>
                    </div>
                </td>
                <td scope="row" className="align-middle">{item.firstName} {item.lastName} </td>
                <td scope="row" className="align-middle">
                    {item.nickname}<br/>
                    <NavButton className="btn btn-outline-success btn-sm" link={`/report/${item.extSurveyId}`}>
                        VIEW RESULTS
                    </NavButton>
                </td>
                <td className="align-middle">{item.connectedNickname} <br/>
                {item.connectedCompletedAt !== null ?
                <NavButton className="btn btn-outline-success btn-sm" link={`/report/${item.connectedExtSurveyId}`}>
                    VIEW RESULTS
                </NavButton> : (item.connectedExtSurveyId === null ? '' : <button className="btn btn-sm btn-outline-danger" disabled='disabled'>INCOMPLETE</button>) }
                </td>
                <td scope="row" className="align-middle">
                    {item.hidden ? <i title="Unhide assessment to archive" class="fal fa-eye"></i> :
                        <button type="button" title="Archive" className="btn btn-sm btn-outline-danger"
                                onClick={() => this.archiveAssessment(item.extSurveyId)} disabled={this.state.disabled}>
                            Archive
                        </button>
                    }
                </td>
            </tr>;
        });
    };

    showFinishedReadyAssessments = () => {

        return this.state.finishedReady.map((item, idx) => {

            return <tr key={item.extSurveyId}>
                <td scope="row" className="align-middle">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" checked={item.hidden}
                               onChange={() => this.updateVisibility(item.extSurveyId)}
                               disabled={this.state.disabled}
                               className="custom-control-input" id={"vrh" + idx}/>
                        <label className="custom-control-label" htmlFor={"vrh" + idx}></label>
                    </div>
                </td>
                <td scope="row" className="align-middle">{item.firstName} {item.lastName}</td>
                <td scope="row" className="align-middle">
                    {item.nickname}<br/>

                </td>
                <td className="align-middle">
                    <NavButton className="btn btn-outline-success btn-sm" link={`/report-ready/${item.extSurveyId}`}>
                        VIEW RESULTS
                    </NavButton>
                </td>
                <td scope="row" className="align-middle">
                    {item.hidden ? <i title="Unhide assessment to archive" class="fal fa-eye"></i> :
                        <button type="button" title="Archive" className="btn btn-sm btn-outline-danger"
                                onClick={() => this.archiveAssessment(item.extSurveyId)} disabled={this.state.disabled}>
                            Archive
                        </button>

                    // <div className="custom-control custom-checkbox">
                    //     <input type="checkbox"
                    //            onChange={() => this.archiveAssessment(item.extSurveyId)}
                    //            disabled={this.state.disabled}
                    //            className="custom-control-input" id={"arcr" + idx}/>
                    //     <label className="custom-control-label" htmlFor={"arcr" + idx}></label>
                    // </div>
                    }
                </td>
            </tr>;
        });
    };

    showInProgressAssessments = () => {

        return this.state.inProgress.map((item, idx) => {

            return <tr key={item.extSurveyId}>
                <td scope="row" className="align-middle">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" checked={item.hidden}
                               onChange={() => this.updateVisibility(item.extSurveyId)}
                               disabled={this.state.disabled}
                               className="custom-control-input" id={"vph" + idx}/>
                        <label className="custom-control-label" htmlFor={"vph" + idx}></label>
                    </div>
                </td>
                <td scope="row" className="align-middle">{item.redemptionCode}</td>
                <td scope="row" className="align-middle">{item.firstName} {item.lastName}
                    <div style={{fontSize: ".8em"}} className='font-weight-bold text-muted'>{item.username}</div>
                </td>
                <td scope="row" className="align-middle">{item.nickname}</td>
                <td className="align-middle">
                    {item.assessmentId === 1 ? 'RELATE' : 'READY'}
                </td>
                <td>{item.completePercentage}  %</td>
            </tr>;
        });
    };

    render() {

        let finishedRelateAssessments = this.showFinishedRelateAssessments();
        let finishedReadyAssessments = this.showFinishedReadyAssessments();
        let inProgressAssessments = this.showInProgressAssessments();



        const {elementFontSize} = this.state;
        const spinner = <tr><td><i className="fas fa-spinner fa-spin"></i></td></tr>;
        const spinner2 = <i className="fas fa-spinner fa-spin"></i>;

        let relateClientAccess = <NavButton className="btn btn-block btn-outline-primary"
                   link={`/pro/relate-access`} disabled={this.state.relate.qty === 0}>
            SEND CLIENT ACCESS
        </NavButton>;

        if(this.state.relate.qty === 0) {
            relateClientAccess = <button type="button" title="Purchase more assessments in order to share codes with your clients"
                                         className="btn btn-block btn-outline-primary" disabled={this.state.relate.qty === 0} >
                SEND CLIENT ACCESS
                </button>
        }

        let readyClientAccess = <NavButton className="btn btn-block btn-outline-success"
                                            link={`/pro/ready-access`} disabled={this.state.ready.qty === 0}>
            SEND CLIENT ACCESS
        </NavButton>;

        if(this.state.ready.qty === 0) {
            readyClientAccess = <button type="button" title="Purchase more assessments in order to share codes with your clients"
                                         className="btn btn-block btn-outline-success" disabled={this.state.ready.qty === 0}>
                SEND CLIENT ACCESS
            </button>
        }




        return (

            <>

                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Dashboard</h5>
                </div>

                <div className="mb-4">
                    <NavButton className="btn  btn-outline-success" link="/pro/bulk-orders"><i className="fal fa-cart-plus"/> Purchase More Assessments</NavButton>
                </div>

                <div className="card-deck">
                    <div className="card">
                        <div className="card-header text-primary"><b><i className="fal fa-user-friends"/> RELATE ASSESSMENTS</b></div>
                        <div className="card-body">
                            <h5 className="card-title text-primary font-weight-bold text-center" style={{fontSize: "4em"}}>
                                {this.state.showSpinner ? spinner2 : this.state.relate.qty}
                                {this.state.relate.qty === 0 ?
                                    <><br/><NavButton className="btn btn-sm  btn-outline-primary" link="/pro/bulk-orders"><i className="fal fa-cart-plus"/> Purchase More Assessments</NavButton></> : ''}
                            </h5>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className='col'>
                                    {/*<NavButton className="btn btn-block btn-outline-primary"*/}
                                    {/*           link={`/pro/relate-access`}>*/}
                                    {/*    SEND CLIENT ACCESS*/}
                                    {/*</NavButton>*/}
                                    {relateClientAccess}
                                </div>
                                <div className='col'>
                                    <NavButton className="btn btn-block btn-outline-primary"
                                               link={`/pro/codes`}>
                                        VIEW CODES
                                    </NavButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header text-success"><b><i className="fal fa-user"/> READY ASSESSMENTS</b></div>
                        <div className="card-body">
                            <h5 className="card-title text-success font-weight-bold text-center" style={{fontSize: "4em"}}>
                                {this.state.showSpinner ? spinner2 : this.state.ready.qty}
                                {this.state.ready.qty === 0 ?
                                    <><br/><NavButton className="btn btn-sm  btn-outline-success" link="/pro/bulk-orders"><i className="fal fa-cart-plus"/> Purchase More Assessments</NavButton></> : ''}

                            </h5>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className='col'>{readyClientAccess}</div>
                                <div className='col'>
                                    <NavButton className="btn btn-block btn-outline-success"
                                               link={`/pro/codes`}>
                                        VIEW CODES
                                    </NavButton>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="mt-5 mb-5"/>

                <div className="card">
                    <div>
                        <h5 className="card-header"><i className="fal fa-user-friends"/> FINISHED RELATE ASSESSMENTS</h5>
                    </div>
                    <div className="card-body scroll">
                        <table className="table table-hover text-center">
                            <thead>
                            <tr>
                                <th scope="col" className="align-middle">Hide Results</th>
                                <th scope="col" className="align-middle">Client</th>
                                <th scope="col" className="align-middle">Client Results</th>
                                <th scope="col" className="align-middle">Partner Results</th>
                                <th scope="col" className="align-middle">Archive</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.showRelateSpinner ? spinner : finishedRelateAssessments}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-5 mb-5"/>

                <div className="card">
                    <div>
                        <h5 className="card-header"><i className="fal fa-user"/> FINISHED READY ASSESSMENTS</h5>
                    </div>
                    <div className="card-body scroll">
                        <table className="table table-hover text-center">
                            <thead>
                            <tr>
                                <th scope="col" className="align-middle">Hide Results</th>
                                <th scope="col" className="align-middle">Client</th>
                                <th scope="col" className="align-middle">Assessment Nickname</th>
                                <th scope="col" className="align-middle">Results</th>
                                <th scope="col" className="align-middle">Archive</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.showReadySpinner ? spinner : finishedReadyAssessments}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mb-3"/>

                <NavButton className="text-right" link="/pro/archived"><i className="fal fa-archive" /> View Archived Assessments</NavButton>

                <div className="mt-5 mb-5"/>

                <div className="card">
                    <div>
                        <h5 className="card-header"><i className="fal fa-percent"></i> IN PROGRESS ASSESSMENTS</h5>
                    </div>
                    <div className="card-body scroll">
                        <table className="table table-hover text-center">
                            <thead>
                            <tr>
                                <th scope="col" className="align-middle">Hide Results</th>
                                <th scope="col" className="align-middle">Code</th>
                                <th scope="col" className="align-middle">Client</th>
                                <th scope="col" className="align-middle">Assessment Nickname</th>
                                <th scope="col" className="align-middle">Type</th>
                                <th scope="col" className="align-middle">Complete Percentage</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.showInProgress ? spinner : inProgressAssessments}
                            </tbody>
                        </table>
                    </div>
                </div>


            </>

        );
    }
}

export default withRouter(Dashboard);
