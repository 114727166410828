import React, {Component} from 'react';

class SkeletonLine extends Component {

  render() {

    return (

        <div className='skeleton'>
          &nbsp;
        </div>

    );
  }

}

export default SkeletonLine;