import React, { Component } from 'react'

class FocusForm extends Component {


  //textInput = React.createRef();


  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef();
  }



  focus = () => {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.textInput.current.focus();
  }


  render() {

    // tell React that we want to associate the <input> ref
    // with the `textInput` that we created in the constructor

    return (
        <div>
          <input type="text" ref={this.textInput} />
          <input type="button" value="Set Focus" onClick={this.focus}
          />
        </div>
    )
  }
}

export default FocusForm;