import * as actionTypes from './actions';
import { updateObject, extractUserData} from '../layout/utility';

// const currentCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {    coupon: '',
//   totalCost: 0,
//   totalQty: 0,
//   cartContents: []};

// let totalInCart = currentCart.cartContents.reduce(function(prev, cur) {
//   return prev + cur.qty;
// }, 0);




const initialState = {
  counter: 0,
  results: [],
  token: localStorage.getItem('token'),
  refreshToken: '',
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  userData: extractUserData(),
  cart: {
    coupon: '',
    discount: 0,
    totalCost: 0,
    totalQty: 0,
    cartContents: [],
  },
};



////////////////// AUTH ////////////////////////////////////////////////////////
const authStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true, redirectToReferrer: false,  refreshToken: '' } );
};

const authSuccess = (state, action) => {
  return updateObject( state, {
    token: action.idToken,
    userId: action.userId,
    refreshToken: action.refreshToken,
    error: null,
    loading: false,
    redirectToReferrer: true,
    userData: action.userData,
  } );
};

const authContinue = (state, action) => {
  return {
    ...state,
  };
};

const authFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    token: null,
    userId: null,
    refreshToken: null,
    loading: false,
    redirectToReferrer: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, { token: null, userId: null, redirectToReferrer: false, refreshToken: null });
};

// const setAuthRedirectPath = (state, action) => {
//   return updateObject(state, { authRedirectPath: action.path })
// };




////////////////////////////////////////////////////////////////////////////////



const reducer = (state = initialState, action) => {

//  const currentCart = [...state.cart.cartContents];

  switch (action.type) {
    // case 'INCREMENT':
    //   const newState = Object.assign({}, state);
    //   newState.counter = state.counter + 1;
    //   return newState;
    // case 'DECREMENT':
    //   return {
    //     ...state,
    //     counter: state.counter - 1
    //   };
    // case 'ADD':
    //   return {
    //     ...state,
    //     counter: state.counter + action.val,
    //   };
    // case 'SUBTRACT':
    //   return {
    //     ...state,
    //     counter: state.counter - action.val,
    //   };
    // case actionTypes.STORE_RESULT:
    //   return {
    //     ...state,
    //     results: state.results.concat({id: new Date(), value: action.result})
    //     // results: state.results.concat({id: new Date(), value: state.counter})
    //   };
    // case 'DELETE_RESULT':
    //   // const id = 2;
    //   // const newArray = [...state.results];
    //   // newArray.splice(id, 1)
    //   const updatedArray = state.results.filter(result => result.id !== action.resultElId);
    //   return {
    //     ...state,
    //     results: updatedArray
    //   };
/////////////////////////// AUTH ///////////////////////////////////////////////
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTH_REFRESH: return authContinue(state, action);
      //case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
////////////////////////////////////// CART /////////////////////////////////////////////////


/////////////////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }



};

////////////////////////////////////// CART /////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////////////


export default reducer;