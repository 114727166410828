import React, {Component} from 'react';
//import axios from "axios";
import axios from "../axios/axios-custom";
import {checkValidity, updateObject} from "../layout/utility";
import {NavLink} from "react-router-dom";
import Spinner from "../Spinner/Spinner";

class LinkAssessments extends Component {

    originalForm = {
        email: {
            value: '',
            valid: true,
            touched: false,
            validation: {
                required: true,
                minLength: 5,
                maxLength: 30,
                isEmail: true,
            },
        },
        linkCode: {
            value: '',
            valid: true,
            touched: false,
            validation: {
                required: true,
                minLength: 6,
                maxLength: 30
            },
        },
    };

    state = {

        activatedAssessments: [],
        showUnlinkForm: false,
        unlink: '',
        assessmentId: '',
        linkEmailConfirmation: '',
        loading: false,
        linkInfo: {
            assessmentId: '',
            linkCode: '',
            redemptionCode: '',
            nickname: '',
            linkInitiated: false,
            assessmentLinked: false,
            partnerAssessmetNickname: '',
        },

        emailForm: {
            email: {
                value: '',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 30,
                    isEmail: true,
                },
            },
            linkCode: {
                value: '',
                valid: true,
                touched: false,
                validation: {
                    required: true,
                    minLength: 6,
                    maxLength: 30
                },
            },
        },

        invalidClass: 'form-control is-invalid',
        validClass: 'form-control',
        checked: '',


        error: false,
    };

    componentDidMount() {

        const assessmentId = this.props.match.params.assessmentId;

        this.setState({assessmentId: assessmentId});

        this.loadLinkInformation(assessmentId);

        this.loadActivatedUnlinkedAssessments(assessmentId);
    }

    handleLinkSelectClick = (id) => {

        if (id === this.state.checked) {
            this.setState({checked: ''});
        } else {
            this.setState({checked: id});
        }
    };

    linkTwoAssessments = (assessmentIdToLink) => {

        const primaryAssessmentId = this.props.match.params.assessmentId;


        const linkInAccountData = {

            assessmentId1: primaryAssessmentId,
            assessmentId2: assessmentIdToLink,

        };
        let url = `${process.env.REACT_APP_API_URL}/api/assessment/link/in-account`;

        axios.post(url, linkInAccountData, {})
            .then(response => {

                this.setState({
                    linkInfo: response.data,
                    loading: false,
                });

            })
            .catch(error => {

                this.setState({loading: false});

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail, loading: false});
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                    this.setState({loading: false});
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }
                //console.log(error);

            });


    };


    loadActivatedUnlinkedAssessments = (assessmentId) => {

        window.scrollTo(0, 0);

        axios.get('/api/survey/activated/unlinked')
            .then((response) => {

                console.log("RESPOINSE", response.data);

                const availabletoToLink = response.data.filter(assessment => assessment.surveyId !== assessmentId);
                console.log("FILTERERD", availabletoToLink);

                this.setState({
                    activatedAssessments: availabletoToLink,
                });

            })
            .catch(error => {
            });

    };

    copyText = () => {

        let element = document.getElementById("currentLinkCode");

        // for Internet Explorer
        if (document.body.createTextRange) {
            let range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
            document.execCommand("Copy");

        } else if (window.getSelection) {

            // other browsers
            let selection = window.getSelection();
            let range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("Copy");
        }

    };

    loadLinkInformation = (assessmentId) => {

        axios.get(`${process.env.REACT_APP_API_URL}/api/assessment/link/${assessmentId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {

                this.setState({
                    linkInfo: response.data,
                });

            })
            .catch(error => {

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail});

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

                this.setState({
                    error: true,
                });

            });

    };

    handleUnlink = (event) => {

        const value = event.target.value;

        this.setState({unlink: value.toUpperCase()});

    };

    showUnlinkForm = () => {
        this.setState({showUnlinkForm: true});
    };

    hideUnlinkForm = () => {
        this.setState({showUnlinkForm: false});
    };

    handleUnlinkSubmit = (event) => {

        const assessmentId = this.props.match.params.assessmentId;

        event.preventDefault();

        //const unlinkData = {};
        let url = `${process.env.REACT_APP_API_URL}/api/assessment/unlink/${assessmentId}`;

        axios.delete(url, null)
            .then(response => {

                let updatedLinkInfo = {...this.state.linkInfo};
                updatedLinkInfo.linkInitiated = false;
                updatedLinkInfo.assessmentLinked = false;
                updatedLinkInfo.linkCode = response.data.linkCode;

                this.setState({linkInfo: updatedLinkInfo, showUnlinkForm: false, emailForm: this.originalForm});

            })
            .catch(error => {

                //console.log(error);

            });


    };

    handleEmailInput = (event) => {
        //event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        // this.setState({[name]: value});

        const updatedEmailForm = {
            ...this.state.emailForm
        };

        const updatedFormElement = {
            ...updatedEmailForm[name]
        };

        updatedFormElement.value = value;

        updatedFormElement.valid = true;

        // if(value.length > 5) {
        //   updatedFormElement.valid = true;
        // }

        //updatedFormElement.valid = this.validateForm(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedEmailForm[name] = updatedFormElement;
        this.setState({emailForm: updatedEmailForm});

    };

    validateElementInput = (event) => {

        event.preventDefault();

        if (event.target.value.length > 0) {

            const inputIdentifier = event.target.name;

            if (!this.state.emailForm[inputIdentifier].validation) {

                return true;

            }

            const updatedFormElement = updateObject(this.state.emailForm[inputIdentifier], {
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.emailForm[inputIdentifier].validation),
                touched: true
            });

            const updatedEmailForm = updateObject(this.state.emailForm, {
                [inputIdentifier]: updatedFormElement
            });

            let formIsValid = true;
            for (let inputIdentifier in updatedEmailForm) {
                formIsValid = updatedEmailForm[inputIdentifier].valid && formIsValid;
            }

            this.setState({emailForm: updatedEmailForm, formIsValid: formIsValid});

        }

    };

    linkAssessments = (ev) => {

        this.setState({loading: true});

        ev.preventDefault();

        if (this.state.emailForm.linkCode.valid) {

            const linkData = {

                externalAssessmentResultId: this.state.linkInfo.assessmentId,
                linkCode: this.state.emailForm.linkCode.value,

            };
            let url = `${process.env.REACT_APP_API_URL}/api/assessment/link`;

            axios.post(url, linkData, {
                // headers: {
                //   Authorization: 'Bearer ' + localStorage.getItem('token')
                // }
            })
                .then(response => {

                    this.setState({
                        linkInfo: response.data,
                        loading: false,
                    });

                })
                .catch(error => {

                    this.setState({loading: false});

                    // Error 😨
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        this.setState({errorDetails: error.response.data.detail, loading: false});
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        //console.log(error.request);
                        this.setState({loading: false});
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        //console.log('Error', error.message);
                    }
                    //console.log(error);

                });


        }

    };


    emailLinkInvite = (ev) => {

        ev.preventDefault();
        //ev.stopPropagation();

        if (this.state.emailForm.email.valid) {

            const emailData = {

                externalAssessmentResultId: this.state.linkInfo.assessmentId,
                emailAddress: this.state.emailForm.email.value

            };

            let url = `${process.env.REACT_APP_API_URL}/api/link/email/send`;

            let confirmation =
                <div>
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        Link invitation email successfully sent to <span
                        className="alert-link">{emailData.emailAddress}</span>!
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>;

            axios.post(url, emailData, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
                .then(response => {


                    this.setState({linkEmailConfirmation: confirmation, emailForm: this.originalForm});

                })
                .catch(error => {

                    // Error 😨
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        this.setState({errorDetails: error.response.data.detail});

                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        // console.log(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        // console.log('Error', error.message);
                    }
                    // console.log(error);

                });

        } else {

        }

    };

    render() {

        let displayInfo = '';

        let errorDiv = <div className="alert alert-danger text-center" role="alert">
            <i className="fal fa-exclamation-triangle"></i> {this.state.errorDetails}
        </div>;

        let options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };


        let assessmentsToLink = this.state.activatedAssessments.map((assessment, idx) => {


            return (

                <tr className=' table-middle text-center'>
                    <td className="w-auto">
                        <button
                            className={this.state.checked === assessment.surveyId ? 'btn btn-sm btn-success btn-block text-left' : 'btn btn-sm btn-outline-success btn-block text-left'}
                            onClick={(id) => this.handleLinkSelectClick(assessment.surveyId)}>
                            {this.state.checked === assessment.surveyId ? <><i className="fal fa-check-square fa-lg"/>
                                <span className="ml-2">{assessment.nickname}</span></> : <><i
                                className="fal fa-square fa-lg"/> <span
                                className="ml-2">{assessment.nickname}</span></>}
                        </button>
                    </td>
                    <td className="w-auto">
              <span title={assessment.surveyName + ' ' + assessment.redemptionCode}>
                {new Date(assessment.completedAt).toLocaleString('en-us', options)}
                  {/*{new Date(assessment.completedAt).toLocaleString('en-us', options)}*/}
              </span><br/>
                    </td>
                    <td className="w-25 text-center table-middle">
                        {this.state.checked === assessment.surveyId ?
                            <button className='btn btn-sm btn-dark'
                                    onClick={(id) => this.linkTwoAssessments(assessment.surveyId)}>
                                Link
                            </button>
                            : ''}

                    </td>
                </tr>

            )
        });


        if (this.state.error) {

            displayInfo = <div className="pricing-header mx-auto text-center">
                <h5 className="display-4  mb-4" style={{fontSize: '2.3em'}}>Link Your Assessment Results</h5>
                <div className="alert alert-danger" role="alert">
                    There was a problem linking your assessments at this time. There could be several reasons. The
                    assessments are already linked or
                    an unexpected error has occurred. If you believe you reached this page in error, please try again
                    later or contact support.
                    <div>
                        <a href='/assessments' className="alert-link"><i className="fal fa-arrow-left"></i> Back to My
                            Assessments Page</a>
                    </div>
                </div>
            </div>;
        }

        // eslint-disable-next-line
        let emailConfirmation = '';
        let linkAssessmentContent = '';

        if (this.state.linkInfo.linkInitiated && !this.state.linkInfo.assessmentLinked) {

            linkAssessmentContent = <>
                <div className="alert alert-info" role="alert">
                    <p>
                        You have successfully initiated the linking of your results.
                        You partner will now have to add your code <b>{this.state.linkInfo.linkCode}</b> to their own
                        Link Assessment Page.
                    </p>
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-danger" onClick={this.showUnlinkForm}>
                        <i className="fal fa-unlink"></i> Unlink These Results
                    </button>
                </div>
            </>
            ;
        }

        if (this.state.linkInfo.assessmentLinked) {

            linkAssessmentContent = <>
                <div>
                    <p>
                        You have successfully linked your assessment results
                        (<b>{this.state.linkInfo.nickname}</b>)
                        to your partner's (<b>{this.state.linkInfo.partnerAssessmetNickname}</b>).<br/><br/>
                        You can compare these results side by side <NavLink className='alert-link' exact
                                                                            to={`/report/${this.state.linkInfo.assessmentId}`}>Here</NavLink> if
                        both of your assessments are complete.
                    </p>
                </div>
                <div>
                    <button type="button" className="btn btn-sm btn-outline-danger" onClick={this.showUnlinkForm}>
                        <i className="fal fa-unlink"></i> Unlink These Results
                    </button>
                </div>
            </>
            ;
        }

        if (!this.state.linkInfo.linkInitiated && !this.state.linkInfo.assessmentLinked) {

            linkAssessmentContent = <form onSubmit={this.linkAssessments}>
                <div className="form-group ">
                    <label htmlFor="linkCode" className="form-text">
                        Enter <b>YOUR PARTNER'S</b> link code here
                    </label>
                    <input type="text"
                           className={this.state.emailForm.linkCode.valid ? this.state.validClass : this.state.invalidClass}
                           id="linkCode" name="linkCode" value={this.state.emailForm.linkCode.value}
                           placeholder="Partner's Link Code"
                           onChange={(event) => this.handleEmailInput(event)}
                           onBlur={(event) => this.validateElementInput(event)}
                    />
                    <div className="invalid-feedback">
                        Please check your link codes.
                    </div>
                    <small id="emailHelp" className="form-text text-muted">
                        If you do not have the code, ask your partner to share it with you.
                    </small>
                </div>
                <button type="submit" className="btn btn-sm btn-outline-primary"><i className="fal fa-link"></i> Link
                    Assessment
                </button>
            </form>;
        }

        if (this.state.linkEmailConfirmation.length > 0) {

            emailConfirmation = this.state.linkEmailConfirmation;

        }

        if (!this.state.error && this.state.linkInfo.assessmentId.length > 0) {

            displayInfo = <>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4  mb-4" style={{fontSize: '2.3em'}}>Link Your Assessment Results</h5>
                </div>
                {/*border-left-color: #f0ad4e*/}
                <div className="bd-callout bd-callout-warning" style={{borderLeftColor: "#f0ad4e"}}>
                    <h5 id="conveying-meaning-to-assistive-technologies">How Linking Assessments Works</h5>

                    <p>
                        You can link two assessments together to compare the results side by side. This gives you and
                        your partner a convenient view of
                        your results and creates a great opportunity for further discussion. The assessment results can
                        only be linked and viewed after each of you completed your
                        assessment.
                    </p>
                </div>


                <h3><span
                    className='text-muted'><small>This Assessment's Nickname:</small></span> {this.state.linkInfo.nickname}
                </h3>

                {this.state.errorDetails && errorDiv}


                {/*{this.state.showUnlinkForm &&*/}
                {/*<div className="card mt-3">*/}
                {/*  <div className="card-body">*/}
                {/*    <h5 className="card-title">Unlink your results</h5>*/}
                {/*    <div className="card-text">*/}
                {/*      <div className="alert alert-danger" role="alert">*/}
                {/*        Please, be aware that by un-linking your results you will no longer be able to see*/}
                {/*        the other person's assessment and they will not be able to see yours.  If you would like to relink*/}
                {/*        your results, you will need to use your partner's new link code.*/}
                {/*      </div>*/}

                {/*      <form>*/}
                {/*        <div className="form-group">*/}
                {/*          <label htmlFor="nickname">Type <b>UNLINK</b> below to remove the linked results and click Submit {this.state.unlink}</label>*/}
                {/*          <input type="text" id="unlink" value={this.state.unlink} style={{textTransform: "uppercase"}}*/}
                {/*                 onChange={this.handleUnlink} maxLength='6' className="form-control col-7" />*/}
                {/*          <div className='mt-3'>*/}
                {/*          <button type="button" className="btn btn-outline-danger" onClick={this.handleUnlinkSubmit} disabled={this.state.unlink !== 'UNLINK'}>*/}
                {/*            <i className="fal fa-arrow-right"></i> Submit</button>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </form>*/}

                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>}*/}


                <hr/>

                {/*<div className="text-center" style={{maxWidth: "25rem"}}>*/}
                {/*  <div className="card bg-light mb-4 mt-4">*/}
                {/*    <div className="card-header"><i className="fal fa-link"></i> MY LINK CODE FOR <b title={this.state.linkInfo.redemptionCode}>({this.state.linkInfo.nickname})</b></div>*/}
                {/*    <div className="card-body">*/}
                {/*      <h1 className="card-title pb-2" style={{border: "1px #646464 solid"}}>{this.state.linkInfo.linkCode}</h1>*/}
                {/*      <p className="card-text">*/}
                {/*        Share this code with your partner in order to link your assessments.*/}
                {/*      </p>*/}
                {/*      <hr/>*/}

                {/*      {this.state.linkEmailConfirmation}*/}


                {/*      <form onSubmit={this.emailLinkInvite}>*/}
                {/*        <div className="form-group text-left">*/}
                {/*          <label htmlFor="email" className="form-text">Email</label>*/}
                {/*          <input type="email" className={this.state.emailForm.email.valid ? this.state.validClass : this.state.invalidClass}*/}
                {/*                 id="email" name="email" value={this.state.emailForm.email.value} placeholder="Partner's email"*/}
                {/*                 onChange={(event) => this.handleEmailInput(event)}*/}
                {/*                 onBlur={(event) => this.validateElementInput(event)}*/}
                {/*          />*/}
                {/*          <div className="invalid-feedback">*/}
                {/*            Please enter a valid email address.*/}
                {/*          </div>*/}


                {/*          <small id="emailHelp" className="form-text text-muted">*/}
                {/*            If you do not have the code, ask your partner to share it with you.*/}
                {/*          </small>*/}
                {/*        </div>*/}
                {/*        <button type="submit" className="btn btn-outline-primary"><i className="fal fa-share-alt"></i> Share your Link code</button>*/}
                {/*      </form>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="text-center" style={{maxWidth: "25rem"}}>
                </div>


                <div className='clearfix'/>

                {this.state.linkInfo.assessmentLinked &&
                <div className="card">
                    <div className="card-header" style={{fontSize: '.9em', color: '#646464'}}>
                        Your assessment <b
                        title={this.state.linkInfo.redemptionCode}>({this.state.linkInfo.nickname})</b> is linked
                        to your partner's.
                    </div>
                    <div className="card-body">
                        {this.state.loading && <Spinner/>}
                        {!this.state.loading && linkAssessmentContent}
                    </div>
                </div>
                }


                {!this.state.linkInfo.assessmentLinked &&
                <>
                    <div className="card mt-4">
                        <div className="card-header" style={{fontSize: '.9em', color: '#646464'}}>
                            Link your assessment results <b
                            title={this.state.linkInfo.redemptionCode}>({this.state.linkInfo.nickname})</b> to your
                            partner's
                            by selecting partner's assessment below.
                        </div>
                        <div className="card-body scroll">
                            <div className="container">

                                <table className="table table-fixed">
                                    <tbody>
                                    <tr className="text-center">
                                        <th>Select</th>
                                        <th>Completed</th>
                                        <th>Action</th>
                                    </tr>
                                    {assessmentsToLink}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="text-center mt-4 font-weight-bold">OR</div>

                    <div className="card mt-4">
                        <div className="card-header"><i className="fal fa-link"></i> Link Your Partner's results by email
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                {this.state.linkEmailConfirmation}

                                <div className='text-muted'>* Your partner would have to login to his or her account in order to complete the linking process</div>

                                <form onSubmit={this.emailLinkInvite}>
                                    <div className="form-group text-left">
                                        {/*<h5 className="card-title">Your Link Code: <span*/}
                                        {/*    id='currentLinkCode'>{this.state.linkInfo.linkCode}</span>*/}
                                        {/*    <button type='button' className='btn btn-sm btn-outline-primary ml-2'*/}
                                        {/*            onClick={this.copyText}><i className="fal fa-copy"></i></button>*/}
                                        {/*</h5>*/}

                                        <label htmlFor="email" className="form-text">Partner's email</label>
                                        <input type="email"
                                               className={this.state.emailForm.email.valid ? this.state.validClass : this.state.invalidClass}
                                               id="email" name="email" value={this.state.emailForm.email.value}
                                               placeholder="Partner's email"
                                               onChange={(event) => this.handleEmailInput(event)}
                                               onBlur={(event) => this.validateElementInput(event)}
                                        />
                                        <div className="invalid-feedback">
                                            Please enter a valid email address.
                                        </div>


                                        <small id="emailHelp" className="form-text text-muted">
                                            If you do not have the code, ask your partner to share it with you.
                                        </small>
                                    </div>
                                    <button type="submit" className="btn btn-sm btn-outline-primary"><i
                                        className="fal fa-envelope-open-text" /> Send Email
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </>

                }


                <div className="d-flex align-items-top flex-custom-column">
                    {/*<div className="card mb-3 mt-3" style={{maxWidth: '27rem'}}>*/}
                    {/*  <div className="card-header"><i className="fal fa-link"></i> MY LINK CODE FOR <b title={this.state.linkInfo.redemptionCode}>({this.state.linkInfo.nickname})</b></div>*/}
                    {/*  <div className="card-body">*/}
                    {/*    <h5 className="card-title">Your Link Code</h5>*/}
                    {/*    <h2 id='currentLinkCode' className="card-title text-center pb-2" style={{border: "1px #646464 solid"}}>{this.state.linkInfo.linkCode}</h2>*/}
                    {/*    <small className='form-text text-muted mt-n2'>*/}
                    {/*      Share this code with your partner in order to link your assessments.*/}
                    {/*    </small>*/}
                    {/*    <button type='button' className='btn btn-sm btn-outline-primary mt-3'*/}
                    {/*            onClick={this.copyText}><i className="fal fa-copy"></i> Copy to Clipboard</button>*/}

                    {/*  </div>*/}
                    {/*</div>*/}


                    {/*<div className="card mb-3 mt-3" style={{maxWidth: '27rem'}}>*/}
                    {/*  <div className="card-header" style={{fontSize: '.9em', color: '#646464'}}>*/}
                    {/*    Link your assessment results <b title={this.state.linkInfo.redemptionCode}>({this.state.linkInfo.nickname})</b> to your partner's*/}
                    {/*    by entering the partner's link code below.*/}
                    {/*  </div>*/}
                    {/*  <div className="card-body">*/}
                    {/*    {this.state.loading && <Spinner />}*/}
                    {/*    {!this.state.loading && linkAssessmentContent}*/}
                    {/*  </div>*/}
                    {/*</div>*/}


                    {/*{!this.state.linkInfo.assessmentLinked &&  <div className="card mb-3 mt-3 ml-5" style={{maxWidth: '25rem'}}>*/}
                    {/*  <div className="card-header"><i className="fal fa-link"></i> Email your link CODE to your partner</div>*/}
                    {/*  <div className="card-body">*/}
                    {/*    <div className="card-text">*/}
                    {/*      {this.state.linkEmailConfirmation}*/}

                    {/*      <form onSubmit={this.emailLinkInvite}>*/}
                    {/*        <div className="form-group text-left">*/}
                    {/*          <h5 className="card-title">Your Link Code: <span id='currentLinkCode'>{this.state.linkInfo.linkCode}</span>*/}
                    {/*            <button type='button' className='btn btn-sm btn-outline-primary ml-2' onClick={this.copyText}><i className="fal fa-copy"></i></button></h5>*/}

                    {/*          <label htmlFor="email" className="form-text">Partner's email</label>*/}
                    {/*          <input type="email" className={this.state.emailForm.email.valid ? this.state.validClass : this.state.invalidClass}*/}
                    {/*                 id="email" name="email" value={this.state.emailForm.email.value} placeholder="Partner's email"*/}
                    {/*                 onChange={(event) => this.handleEmailInput(event)}*/}
                    {/*                 onBlur={(event) => this.validateElementInput(event)}*/}
                    {/*          />*/}
                    {/*          <div className="invalid-feedback">*/}
                    {/*            Please enter a valid email address.*/}
                    {/*          </div>*/}


                    {/*          <small id="emailHelp" className="form-text text-muted">*/}
                    {/*            If you do not have the code, ask your partner to share it with you.*/}
                    {/*          </small>*/}
                    {/*        </div>*/}
                    {/*        <button type="submit" className="btn btn-sm btn-outline-primary"><i className="fal fa-share-alt"></i> Share your Link code</button>*/}

                    {/*      </form>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}


                    {/*</div>}*/}
                </div>

                {this.state.showUnlinkForm &&
                <div className="card mt-3 col-6">
                    <div className="card-body">
                        <h5 className="card-title">Unlink your results</h5>
                        <div className="card-text">
                            <div className="alert alert-danger" role="alert">
                                Please, be aware that by un-linking your results you will no longer be able to see
                                the other person's assessment and they will not be able to see yours. If you would like
                                to relink
                                your results, you will need to use your partner's new link code.
                            </div>

                            <form>
                                <div className="form-group">
                                    <label htmlFor="nickname">Type <b>UNLINK</b> below to remove the linked results and
                                        click Submit</label>
                                    <input type="text" id="unlink" value={this.state.unlink}
                                           style={{textTransform: "uppercase"}}
                                           onChange={this.handleUnlink} maxLength='6' className="form-control"/>
                                    <div className='mt-3'>
                                        <button type="button" className="btn btn-outline-success"
                                                onClick={this.hideUnlinkForm}>
                                            <i className="fal fa-arrow-right"></i> Cancel
                                        </button>
                                        <button type="button" className="btn btn-outline-danger ml-3"
                                                onClick={this.handleUnlinkSubmit}
                                                disabled={this.state.unlink !== 'UNLINK'}>
                                            <i className="fal fa-arrow-right"></i> Submit
                                        </button>

                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>}


            </>;

        }


        return (

            <>
                {displayInfo}
            </>

        )
    }

}

export default LinkAssessments;