import React, {Component} from "react";
import {Elements} from 'react-stripe-elements';
import BulkOrderForm from "./BulkOrderForm";

class ProCheckout extends Component {

    state = {
        elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
    };


    _isMounted = false;

    componentDidMount() {

        this._isMounted = true;

        window.addEventListener('resize', () => {
            if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
                this.setState({elementFontSize: '14px'});
            } else if (
                window.innerWidth >= 450 &&
                this.state.elementFontSize !== '18px'
            ) {
                this.setState({elementFontSize: '18px'});
            }
        });

    }

    componentWillUnmount() {
         this._isMounted = false;
         window.removeEventListener("resize", this.resize);
        //this.source.cancel("Operation canceled by the user.");
    };
    resize = () => {
      if (this._isMounted) {
        this.setState({screenSize: window.innerWidth});
      }
    };


    render() {
        const {elementFontSize} = this.state;
        return (
            <div className="Checkout">
                <Elements>
                    <BulkOrderForm fontSize={elementFontSize} />
                </Elements>
            </div>
        );
    }
}

export default ProCheckout;