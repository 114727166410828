
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const LOAD_CART = 'LOAD_CART';
export const SET_TOTAL_QTY = 'SET_TOTAL_QTY';
export const APPLY_DISCOUNT = 'APPLY_DISCOUNT';
export const REMOVE_COUPON = 'REMOVE_COUPON';


export const loadCart = () => {

  return dispatch => {
    const cartJson = localStorage.getItem('cart');


    if (cartJson) {
      const cart = JSON.parse(cartJson);

      dispatch(loadCartFromStorage(cart));

    }
  };

};


////////////////////////////////////////////////////////////////////////////////


// Should we save the cart values to the DB or just keep in localStorgae?
// export const addItemsToCart = ( cart ) => {
//
//   return (dispatch) => {
//     let url = `${process.env.REACT_APP_API_URL}/api/cart`;
//
//
//   }
// };

export const loadCartFromStorage = ( cart ) => {

  return {
    type: LOAD_CART,
    cart: cart
  };
};


export const addToCart = ( cart ) => {

  return {
    type: ADD_TO_CART,
    cart: cart
  };
};

export const updateCart = ( cart ) => {

  return {
    type: UPDATE_CART,
    cart: cart
  };
};

export const removeFromCart = ( sku ) => {

  return {
    type: REMOVE_FROM_CART,
    sku: sku
  };
};

export const setTotalQty = ( qty ) => {

  return {
    type: SET_TOTAL_QTY,
    qty: qty
  };
};


// export const validateCoupon = ( coupon ) => {
//
//   return (dispatch) => {
//
//     setTimeout( () => {
//       // const oldCounter = getState().ctr.counter;
//       // console.log(oldCounter);
//       const discount = .25;
//       dispatch(applyDiscount(coupon, discount));
//     }, 2000 );
//   }
// };


// export const validateCoupon = ( coupon ) => {
//
//   return (dispatch) => {
//
//     dispatch({type: 'START_LOAD'});
//
//     let url = `${process.env.REACT_APP_API_URL}/sanity`;
//
//     axios.get(url)
//     .then(response => {
//
//     setTimeout( () => {
//       const discount = .16;
//       dispatch(applyDiscount(coupon, discount));
//     }, 2000 );
//
//
//     })
//     .catch(error => {
//
//       setTimeout( () => {
//         const discount = .25;
//         dispatch(applyDiscount(coupon, discount));
//       }, 2000 );
//     });
//
//   }
// };

export const applyDiscount = ( coupon, percent, amount) => {
  return {
    type: APPLY_DISCOUNT,
    coupon: coupon,
    percent: percent,
    amount: amount,
    discount: percent > 0 ? percent : amount,
  };
};

export const removeCoupon = () => {
  return {
    type: REMOVE_COUPON,
  };
};

export const emptyCart = () => {
  return {
    type: EMPTY_CART,
  };
};



