import React, {Component} from 'react';
import {checkValidity, updateObject} from '../layout/utility';
import * as actions from '../store/actions';
import {connect} from "react-redux";
import Spinner from '../Spinner/Spinner';
import {Redirect} from "react-router";
import {Link, withRouter} from 'react-router-dom';

class Auth extends Component {

  state = {
    type: 'password',
    controls: {
      email: {
        value: this.props.location.state !== null && typeof this.props.location.state !== 'undefined' && this.props.location.state.email !== null ? this.props.location.state.email : '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 5,
          maxLength: 30,
          isEmail: true,
        },
      },
      password: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 8,
          maxLength: 30,
        },
      },
    },
    formIsValid: false,
    invalidClass: 'form-control is-invalid',
    validClass: 'form-control',
    errorDetails: '',
    showSpinner: false,
  };


  componentDidMount(){

    if(!this.props.isAuthenticated) {
      this.nameInput.focus();
    }
  }

  togglePwd = () => this.setState(({type}) => ({
    type: type === 'text' ? 'password' : 'text'
  }));

  inputChangedHandler = ( event, controlName ) => {
    const updatedControls = updateObject( this.state.controls, {
      [controlName]: updateObject( this.state.controls[controlName], {
        value: event.target.value,
        valid: checkValidity( event.target.value, this.state.controls[controlName].validation ),
        touched: true
      } )
    } );
    this.setState( { controls: updatedControls } );
  };

  handleSubmit = ( event ) => {
    event.preventDefault();

    if(this.state.controls.password.value.trim().length >= 5 &&
        this.state.controls.password.value.trim().length >= 8) {

      this.setState({showSpinner: true});
      this.props.onAuth( this.state.controls.email.value, this.state.controls.password.value);
    }

  };


  handleUserInput = (event) => {

    const name = event.target.name;
    const value = event.target.value;

    const updatedControls = {
      ...this.state.controls
    };

    const updatedFormElement = {
      ...updatedControls[name]
    };

    updatedFormElement.value = value;

    updatedFormElement.valid = true;

    updatedFormElement.touched = true;
    updatedControls[name] = updatedFormElement;
    this.setState({controls: updatedControls});

  };

  render() {

    let {from} = this.props.location.state || { from: { pathname: "/" } };

    if (this.props.redirectToReferrer && from.pathname !== '/logout') {
      return (
          <Redirect to={from} />
      )
    }

    if (this.props.isAuthenticated) {

      return (
          <Redirect to="/"/>
      )
    }

    let form =  <form className="form-signin"  onSubmit={this.handleSubmit}>
      <div className="col-md-12 order-md-1">
        <div className="mb-3">
          <label htmlFor="email">Email</label>
          <input type="email" className={this.state.controls.email.valid ? this.state.validClass : this.state.invalidClass}
                 ref={(input) => { this.nameInput = input; }}
                 id="email" name="email" value={this.state.controls.email.value}
                 onChange={(event) => this.handleUserInput(event)}
              //  onBlur={(event) => this.validateElementInput(event)}
          />
          <div className="invalid-feedback">
            Please enter a valid email address.
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="password">Password {this.state.type === 'text' ?  <i className="fal fa-eye pointer-cursor" onClick={this.togglePwd}/> :
              <i className="fal fa-eye-slash pointer-cursor" onClick={this.togglePwd}/>}   </label>
          <input type={this.state.type} className={this.state.controls.password.valid ? this.state.validClass : this.state.invalidClass}
                 id="password" name="password"
                 value={this.state.controls.password.value}
                 onChange={(event) => this.handleUserInput(event)}
              // onBlur={(event) => this.validateElementInput(event)}
          />

          <div className="invalid-feedback">
            Password must be at least 8 characters long
          </div>
        </div>

      </div>
      <Link to='account/reset'>Forgot Password?</Link>
      <hr className="mb-4"/>
      <button className="btn btn-success btn-block" type="submit">Submit</button>
      <div className='mt-3'>New User? <Link to='signup'>Create Your Account Here!</Link></div>
    </form>;

    if ( this.props.loading ) {
      form = <Spinner />
    }

    let errorMessage = null;

    if ( this.props.error ) {

      errorMessage = (
          <div className="alert alert-danger" role="alert">
            There was an error with your E-Mail/Password combination. Please try again.
          </div>
      );
    }

    return (
        <div>

          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Sign In</h5>
            {errorMessage}
          </div>
          {form}

        </div>

    );
  }
}

const mapStateToProps = (reduxState) => {
  return {
    loading: reduxState.oldReducer.loading,
    error: reduxState.oldReducer.error,
    redirectToReferrer: reduxState.oldReducer.redirectToReferrer,
    isAuthenticated: reduxState.oldReducer.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onIncrementCounter: () => dispatch({type: 'INCREMENT'}),
    onAuth: (email, password) => dispatch(actions.auth(email, password)),

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));