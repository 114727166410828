import React, {Component} from 'react';

class Myths extends Component {

    componentDidMount() {
    }

    render() {



        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-transporter-empty fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div className='pl-6'>
                            DATING AND MARRIAGE MYTHS
                        </div>
                    </div>
                </div>

                <div className="mt-3 pb-4 pl-4 pr-4 pt-3 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    <p>
                        When it comes to beliefs about dating and marriage, we may often come across
                        conflicting messages. This can make relationships difficult if we are not sure which
                        beliefs are accurate and which are myths. If we chase after the myths too much we
                        will be more likely to find more obstacles to successful relationships. In this section
                        we have assessed your dating savvy by testing your awareness of what is myth and
                        what is reality.
                    </p>
                    <p><strong>
                        Below are some of the most common myths about dating. How did you
                        score? If you did not do as well as you would like, then take some time to
                        read about where each myth goes wrong.
                    </strong></p>
                </div>

                <div className="pb-2 pl-4 pr-4 pt-3 text-justify"
                     style={{ backgroundColor: '#d9e3ed', borderRadius: '20px', fontFamily: 'Nunito', fontSize: '1em', fontWeight: 'bold', color: '#4374a3'}}>
                    <p>
                        MYTH 1: THE QUALITY OF A COUPLE’S SEX LIFE IS THE BEST WAY TO KNOW IF
                        THAT COUPLE IS COMPATIBLE FOR A RELATIONSHIP.
                    </p>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4'>
                    <strong>Your Answer:</strong> {this.props.myth1.primarySelf[0] === 'true' ? 'True' : 'False'}
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>Reality:</strong> While sexual attraction, and some level of similar preferences, is an important factor in satisfying sexual
                    relationships, this myth rejects many other important aspects of successful relationships.  First, the quality of a couple’s sex
                    life is more in their control than this myth would allow.  Second, respect, affection, and trust in one another are often more
                    important than the quality of a couple’s sex life, and may even contribute a great deal to satisfying sexual experiences.
                    In other words, a good sex life is often a result of a positive relationship, not the other way around.
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>How it affects your relationships:</strong> Those who follow this myth are more likely to initiate sex early in a relationship,
                    or even as a way to begin a relationship.  Research shows, however, that initiating in sex too early usually ends up making
                    relationships less stable and satisfying by undermining other important relationship aspects, such as good communication and commitment.
                </div>

                <div className="pb-2 pl-4 pr-4 pt-3 text-justify mt-4"
                     style={{ backgroundColor: '#d9e3ed', borderRadius: '20px', fontFamily: 'Nunito', fontSize: '1em', fontWeight: 'bold', color: '#4374a3'}}>
                    <p>
                        MYTH 2: WHEN TWO PEOPLE TRULY LOVE EACH OTHER THEY WILL KNOW
                        WHAT EACH OTHER NEED TO BE HAPPY IN THE RELATIONSHIP.
                    </p>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4'>
                    <strong>Your Answer:</strong> {this.props.myth2.primarySelf[0] === 'true' ? 'True' : 'False'}
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>Reality:</strong> When any two people care enough about each other, they are often motivated to learn what
                    their partner needs to be happy and can often provide it without prompting. However, getting to that
                    point requires open, respectful communication. True love does not magically give us knowledge about
                    what the other person needs or wants. This especially applies in communication differences between
                    men and women!
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>How it affects your relationships:</strong> When we try to apply this myth to our relationships, we may find it
                    easy to get upset with a partner when they don’t meet a particular need. Rather than expressing our
                    need, we will assume they are ignoring us or trying to hurt us. Such assumptions can close down good
                    communication even further and create real hurts.
                </div>


                <div className="pb-2 pl-4 pr-4 pt-3 text-justify mt-4"
                     style={{ backgroundColor: '#d9e3ed', borderRadius: '20px', fontFamily: 'Nunito', fontSize: '1em', fontWeight: 'bold', color: '#4374a3'}}>
                    <p>
                        MYTH 3: A GOOD RELATIONSHIP MEANS YOU ARE ALWAYS HAPPY IN THE
                        RELATIONSHIP.
                    </p>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4'>
                    <strong>Your Answer:</strong> {this.props.myth3.primarySelf[0] === 'true' ? 'True' : 'False'}
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>Reality:</strong> Many things can happen in relationships that might make us feel less than happy. Some
                    common events that might reduce happiness are conflict, job loss, or an expensive bill. Many of these
                    things are out of our control. Research suggests that experiencing low points in relationships, and
                    working through them together, actually makes relationships more successful in the long run than
                    experiencing only happy moments. When couples go through hard times together, they often grow
                    closer, find more fulfillment, and are more stable.
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>How it affects your relationships:</strong> Those who give way to this myth will face a lot of disappointment in
                    marriage. When challenges come, it will be more difficult to stay optimistic and actually make an
                    experience of temporary unhappiness become more permanent. Relationships built on such unrealistic
                    expectations will likely end up becoming a source of confusion and depression.
                </div>

                <div className="pb-2 pl-4 pr-4 pt-3 text-justify mt-4"
                     style={{ backgroundColor: '#d9e3ed', borderRadius: '20px', fontFamily: 'Nunito', fontSize: '1em', fontWeight: 'bold', color: '#4374a3'}}>
                    <p>
                        MYTH 4: COUPLES WHO LIVE TOGETHER BEFORE MARRIAGE ARE BETTER OFF
                        THAN COUPLES WHO WAIT UNTIL AFTER MARRIAGE.
                    </p>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>Your Answer:</strong> {this.props.myth4.primarySelf[0] === 'true' ? 'True' : 'False'}
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>Reality:</strong> In a world of high divorce rates, living together before marriage as a way to determine how well
                    it works seems attractive to many. But research consistently finds that living together before marriage is
                    more often associated with weaker, less fulfilling marriages, and is actually associated with an increased
                    risk of divorce. The one exception are those couples which wait to live together until after engagement.
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>How it affects your relationships:</strong> Behind this myth is an inaccurate assumption that living together
                    without marriage is enough like being married that it is a good alternative and test for later marriage. In
                    the end, however, research suggests that living together before marriage undermines investing in a
                    relationship and looking for the good, rather encouraging couples to find reasons to get out of the
                    relationship before they marry and not putting in a full effort to overcome challenges. Many couples who
                    live together also end up married anyways, even if they would not have married otherwise, because once
                    they move in together the cost of getting out of even a bad relationship becomes much higher.
                </div>


                <div className="pb-2 pl-4 pr-4 pt-3 text-justify mt-4"
                     style={{ backgroundColor: '#d9e3ed', borderRadius: '20px', fontFamily: 'Nunito', fontSize: '1em', fontWeight: 'bold', color: '#4374a3'}}>
                    <p>
                        MYTH 5: I SHOULD NOT GET MARRIED UNTIL I FEEL COMPLETELY READY.
                    </p>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4'>
                    <strong>Your Answer:</strong> {this.props.myth5.primarySelf[0] === 'true' ? 'True' : 'False'}
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>Reality:</strong> In many ways, our society is obsessed with the idea of being “ready” for something. In truth,
                    some things we can never really be ready for. Major life changes, like marriage or becoming parents, will
                    change the course of our life so much that we can never truly be prepared. Preparing for marriage by
                    learning good communication skills, working through past trials, and getting to know our partner are all
                    good things we can, and should, do before marriage. But waiting until we feel “completely” ready will
                    lead to a lot of waiting.
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>How it affects your relationships:</strong> This myth leads to feelings of fear before marriage, and inadequacy
                    after marriage. If we put too much emphasis on being ready, then when we experience something we
                    are not ready for we will question ourselves rather than recognize that sometimes life just happens. It will
                    happen! This will lead to decreased confidence in ourselves and our relationships, which will then
                    decrease relationship satisfaction and stability.
                </div>

                <div className="pb-2 pl-4 pr-4 pt-3 text-justify mt-4"
                     style={{ backgroundColor: '#d9e3ed', borderRadius: '20px', fontFamily: 'Nunito', fontSize: '1em', fontWeight: 'bold', color: '#4374a3'}}>
                    <p>
                        MYTH 6: A COUPLE SHOULD WAIT TO GET MARRIED UNTIL THEY KNOW THEIR
                        MARRIAGE WILL LAST.
                    </p>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4'>
                    <strong>Your Answer:</strong> {this.props.myth6.primarySelf[0] === 'true' ? 'True' : 'False'}
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    <strong>Reality:</strong> Fear of the unknown often makes our fears come true. That is especially true of this myth.
                    Research shows that those marriages which last require heavy investment, deep intimacy, and a great
                    deal of effort in overcoming challenges. The bad news is that we have to make those things happen. The
                    good news it means whether our marriage lasts or not is completely up to us. A lasting marriage is
                    best predicted by our own willingness to make it last.
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    <strong>How it affects your relationships:</strong> Most of us want lasting marriage. Few people marry with the
                    expectation that the marriage will end in a year. So it would make sense that we would not want to marry
                    until we know it will last. Those who approach marriage in this way, however, are more likely to hold back
                    and in the end, actually create the reasons why their marriage fails.
                </div>

            </>

        )

    }

}

export default Myths;