import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class Attachment extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {

        return (

            <>
                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-leaf-heart fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            ATTACHMENT
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                {this.props.windowSize.windowWidth > 710 &&

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td align="center"><DonutChart elId='attach1' percent={this.props.attachment.primarySelf[0]}
                                                           color={this.props.attachment.primarySelf[1]}/>
                            </td>
                            <td className="border-right" align="center"><DonutChart elId='attach2'
                                                                                    percent={this.props.attachment.partnerPrimary[0]}
                                                                                    color={this.props.attachment.partnerPrimary[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='attach3'
                                                           percent={this.props.attachment.primaryPartner[0]}
                                                           color={this.props.attachment.primaryPartner[1]}/></td>
                            {/*<td align="center"><DonutChart elId='kind4' percent='0' color='#eee' /></td>*/}
                            <td align="center"><DonutChart elId='attach4'
                                                           percent={this.props.attachment.partnerSelf[0] !== '' ? this.props.attachment.partnerSelf[0] : '0'}
                                                           color={this.props.attachment.partnerSelf[1]}/></td>

                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                            <td className="border-right" align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                            <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                            <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                            {/*NO PARTNER <br/>ANSWER RECORDED*/}
                        </tr>
                        </tbody>
                    </table>
                </div>
                }

                {this.props.windowSize.windowWidth <= 710 &&

                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <td align="center"><DonutChart elId='attach1' percent={this.props.attachment.primarySelf[0]}
                                                       color={this.props.attachment.primarySelf[1]}/>
                        </td>
                        <td align="center">
                            <DonutChart elId='attach2' percent={this.props.attachment.partnerPrimary[0]}
                                        color={this.props.attachment.partnerPrimary[1]}/>
                        </td>
                    </tr>
                    <tr className="border-bottom"
                        style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                        <td align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                    </tr>
                    <tr>
                        <td align="center">
                            <DonutChart elId='attach3' percent={this.props.attachment.primaryPartner[0]}
                                        color={this.props.attachment.primaryPartner[1]}/>
                        </td>
                        <td align="center">
                            <DonutChart elId='attach4'
                                        percent={this.props.attachment.partnerSelf[0] !== '' ? this.props.attachment.partnerSelf[0] : '0'}
                                        color={this.props.attachment.partnerSelf[1]}/>
                        </td>
                    </tr>
                    <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                        <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                    </tr>
                    </tbody>
                </table>

                }


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    <b>Attachment</b> behaviors are specific behaviors that help to facilitate secure attachment in
                    romantic relationships.
                    They measure a partner's level of accessibility, responsiveness, and engagement in a relationship.
                    Accessibility
                    focuses on how easily you can reach out to your partner or get his/her attention. Responsiveness is
                    knowing that when you need reassurance about the relationship, your partner can effectively support
                    and help
                    you. Engagement results when accessibility and responsiveness occur repeatedly over time. It is
                    knowing that
                    your partner is consistently there for you, and allows you to feel safe.
                </div>

                <StrengthLegendDetail id="agr-strength">
                    A score in this area is an indication of confidence in each other and the relationship overall. An
                    individual score in the Strength area suggests you listen
                    well and are able to open up, giving you a sense of fulfilling engagement with your partner. If both
                    partners are in this section, there is a good balance
                    of give and take in the relationship, where both partners can be open and have their turn to share
                    their feelings and then listen to the other.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="agr-needs-impr">
                    A score in this area is an indication of confidence in oneself or a partner, but maybe not both. One
                    partner may listen well but not feel that they can open up,
                    or trusts their partner with their deepest feelings but does not respond to their partner's attempts
                    to share the same. Both partners may struggle to feel close
                    to each other at times, but with a little work and building trust, it is not difficult to feel more
                    engaged and close in the relationship.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="agr-challenge">
                    Those scoring in this area are often lacking in behaviors which build confidence in the
                    relationship. This could represent an underlying fear of closeness or
                    an anxiety about being to open with a romantic partner. A lack of such connecting behavior can
                    sometimes lead to partners being unresponsive or unavailable
                    to each other or may make the one partner feel that they cannot open up and engage in positive
                    communication with the other. Scoring in this section suggests that
                    you need to consider why you resist connecting with your partner and examine ways in which you can
                    both be more open and connected with each other.
                </ChallengeLegendDetail>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            Research has shown that the more partners display behaviors (accessible, responsive,
                            engaged) that lead to attachment, the more satisfied and stable they are in their
                            relationships.
                        </div>
                    </div>
                </div>

                <button className="btn btn-outline-dark no-print mt-3" type="button" data-toggle="collapse"
                        data-target="#att"
                        aria-expanded="false" aria-controls="att">
                    Show Section Questions
                </button>

                <div className="collapse" id="att">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-leaf-heart"></i> Attachment</h5>
                        </div>
                        <div className="card-body">
                            <table className="table ">
                                <thead>
                                <tr>
                                    <th scope="col" className="align-middle">How are YOU when the two of you have a
                                        conflict?
                                    </th>
                                    <th scope="col" className="align-middle">How is YOUR PARTNER when the two of you
                                        have a conflict?
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">I am confident I can reach out to my partner.</td>
                                    <td className="align-middle">
                                        I am confident my partner reaches out to me.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I am rarely available to my partner.</td>
                                    <td className="align-middle">
                                        My partner is rarely available to me.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">It is hard for my partner to get my attention.</td>
                                    <td className="align-middle">
                                        My partner struggles to feel close and engaged in our relationship.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I listen when my partner shares his/her deepest
                                        feelings.
                                    </td>
                                    <td className="align-middle">
                                        My partner listens when I share my deepest feelings.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">It is hard for me to confide in my partner.</td>
                                    <td className="align-middle">
                                        It is hard for my partner to confide in me.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I struggle to feel close and engaged in our
                                        relationship.
                                    </td>
                                    <td className="align-middle">
                                        It is hard for me to get my partner's attention.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>When your partner attempts to open up to you, how do you show you are ready to listen?
                                Do your actions say that you don’t really
                                care or that what your partner is saying is very important to you?
                            </li>
                            <li>How often do you or your partner have to repeat yourself in conversations? Why?</li>
                            <li>Do you feel that you can trust your partner with personal information and deep feelings?
                                How can your partner increase your trust in them?
                            </li>
                            <li>How often do you initiate conversations with your partner to discuss personal and deep
                                concerns? What about your partner? What is holding you back, if anything?
                            </li>
                        </ul>
                    </div>
                </div>

            </>

        )

    }

}

export default withWindowSizeListener(Attachment);