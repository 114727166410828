import React, { Component } from 'react';
import Gauge from 'canvas-gauges';

class Thermometer extends Component {

    state = {
      value: 0,
      color: '#eee',
        cId: '',
        title: ''
    };

    componentDidMount() {

        this.setState({value: this.props.value, color: this.props.color, cId: this.props.cId, title: this.props.title}, () => this.drawLinearGauge());

    }

    drawLinearGauge = () => {

        let gauge = new Gauge.LinearGauge({
            renderTo: this.state.cId,
            width: 200,
            height: 500,
            title: this.state.title,
            minValue: 0,
            startAngle: 90,
            ticksAngle: 180,
            valueBox: false,
            maxValue: 100,
            majorTicks: ['0','10','20','30','40','50','60','70','80','90','100'],
            minorTicks: 2,
            strokeTicks: true,
            colorPlate: "#fff",
            borderShadowWidth: 0,
            borders: false,
            needle: true,
            needleType: "arrow",
            needleWidth: 5,
            needleShadow: true,
            barWidth: 20,
            value: 0,
            colorStrokeTicks: "#9c9c9c",
            colorMinorTicks: "#ccc",
            colorBar: "#eee",
            colorBarProgress: this.state.color,   //"#ABD25E"  //dc2229   28b7b2  4375a4
            highlights: [ {"from": 0, "to": 100, "color": "rgba(255, 255, 255, .2)"} ],
            animationDuration: 1000,
            animationRule: "linear",
            animationTarget: 'plate'
        });

        gauge.value = this.state.value;

       // gauge.update({animation: true});

    };

    render() {


        return (

            <>

                <canvas data-type="linear-gauge" id={this.state.cId} />

            </>

        )


    }
}

export default Thermometer;