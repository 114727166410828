import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class ConflictResolution extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {

        let noncriticalData = [[this.props.noncritical.primarySelf[0], this.props.noncritical.partnerPrimary[0], this.props.noncritical.primaryPartner[0], this.props.noncritical.partnerSelf[0]],
            [100 - this.props.noncritical.primarySelf[0], 100 - this.props.noncritical.partnerPrimary[0], 100 - this.props.noncritical.primaryPartner[0], 100 - this.props.noncritical.partnerSelf[0]]];

        let noncriticalColors = [this.props.noncritical.primarySelf[1], this.props.noncritical.partnerPrimary[1], this.props.noncritical.primaryPartner[1], this.props.noncritical.partnerSelf[1]];

        let notOverwhelmedData = [[this.props.notOverwhelmed.primarySelf[0], this.props.notOverwhelmed.partnerPrimary[0], this.props.notOverwhelmed.primaryPartner[0], this.props.notOverwhelmed.partnerSelf[0]],
            [100 - this.props.notOverwhelmed.primarySelf[0], 100 - this.props.notOverwhelmed.partnerPrimary[0], 100 - this.props.notOverwhelmed.primaryPartner[0], 100 - this.props.notOverwhelmed.partnerSelf[0]]];

        let notOverwhelmedColors = [this.props.notOverwhelmed.primarySelf[1], this.props.notOverwhelmed.partnerPrimary[1], this.props.notOverwhelmed.primaryPartner[1], this.props.notOverwhelmed.partnerSelf[1]];

        let respectData = [[this.props.respect.primarySelf[0], this.props.respect.partnerPrimary[0], this.props.respect.primaryPartner[0], this.props.respect.partnerSelf[0]],
            [100 - this.props.respect.primarySelf[0], 100 - this.props.respect.partnerPrimary[0], 100 - this.props.respect.primaryPartner[0], 100 - this.props.respect.partnerSelf[0]]];

        let respectColors = [this.props.respect.primarySelf[1], this.props.respect.partnerPrimary[1], this.props.respect.primaryPartner[1], this.props.respect.partnerSelf[1]];


        return (

            <>
                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-handshake fa-2x" style={{color: "#c3b2b5"}}/></div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            CONFLICT RESOLUTION
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                {this.props.windowSize.windowWidth > 710 &&

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td align="center"><DonutChart elId='conflict1'
                                                           percent={this.props.overallConflictResol.primarySelf[0]}
                                                           color={this.props.overallConflictResol.primarySelf[1]}/>
                            </td>
                            <td className="border-right" align="center"><DonutChart elId='conflict2'
                                                                                    percent={this.props.overallConflictResol.partnerPrimary[0]}
                                                                                    color={this.props.overallConflictResol.partnerPrimary[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='conflict3'
                                                           percent={this.props.overallConflictResol.primaryPartner[0]}
                                                           color={this.props.overallConflictResol.primaryPartner[1]}/>
                            </td>
                            {/*<td align="center"><DonutChart elId='kind4' percent='0' color='#eee' /></td>*/}
                            <td align="center"><DonutChart elId='conflict4'
                                                           percent={this.props.overallConflictResol.partnerSelf[0] !== '' ? this.props.overallConflictResol.partnerSelf[0] : '0'}
                                                           color={this.props.overallConflictResol.partnerSelf[1]}/></td>

                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                            <td className="border-right" align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                            <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                            <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                            {/*NO PARTNER <br/>ANSWER RECORDED*/}
                        </tr>
                        </tbody>
                    </table>
                </div>
                }

                {this.props.windowSize.windowWidth <= 710 &&

                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <td align="center"><DonutChart elId='conflict1'
                                                       percent={this.props.overallConflictResol.primarySelf[0]}
                                                       color={this.props.overallConflictResol.primarySelf[1]}/>
                        </td>
                        <td align="center"><DonutChart elId='conflict2'
                                                       percent={this.props.overallConflictResol.partnerPrimary[0]}
                                                       color={this.props.overallConflictResol.partnerPrimary[1]}/>
                        </td>

                    </tr>
                    <tr className="border-bottom" style={{fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                        <td align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                    </tr>


                    <tr>
                        <td align="center">
                            <DonutChart elId='conflict3' percent={this.props.overallConflictResol.primaryPartner[0]}
                                        color={this.props.overallConflictResol.primaryPartner[1]}/>
                        </td>
                        <td align="center"><DonutChart elId='conflict4'
                                                       percent={this.props.overallConflictResol.partnerSelf[0] !== '' ? this.props.overallConflictResol.partnerSelf[0] : '0'}
                                                       color={this.props.overallConflictResol.partnerSelf[1]}/></td>

                    </tr>
                    <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                        <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                    </tr>
                    </tbody>
                </table>

                }


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    The <b>Conflict Resolution</b> Scale is a measure of the degree of criticism, defensiveness,
                    contempt, and emotional
                    flooding that people experience during conflict. The areas of criticism and contempt focus on using
                    negative
                    communication to attack our partners during conflict while defensiveness concerns the feelings we
                    have that
                    we are attacked by our partners. Flooding refers to being overwhelmed by our emotions which tends to
                    interfere with healthy conflict resolution.
                </div>

                <StrengthLegendDetail id="cres-strength">
                    People who score in this area see themselves and their partners as rarely using criticism and
                    defensiveness when resolving conflict;
                    hence they are less likely to be emotionally flooded.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="cres-needs-impr">
                    People who score in this area occasionally experience or use criticism and defensiveness when
                    resolving conflict.
                    There may be a degree of contempt toward your partner during moments of conflict. During and after
                    conflict neither you or your partner may consider
                    the relationship very satisfying. These moments are not necessarily indicative of high relationship
                    problems, but professional help may be beneficial to prevent further harm to the relationship.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="cres-challenge">
                    People who have conflict scores in the challenge area are more likely to have a relationship that is
                    not satisfying.
                    High levels of criticism and contempt are indicative of serious relationship problems that are not
                    likely to be resolved without help.
                </ChallengeLegendDetail>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            Higher levels of effective communication and higher scores on the conflict resolution scale
                            have been shown to be associated with higher relationship quality. High scores on the
                            conflict resolution scale are also related to fewer relationship problems, and higher
                            relationship stability.
                        </div>
                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>NON CRITICAL</b>
                </div>

                <div className="chart-container" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='nonCritic' data={noncriticalData} colors={noncriticalColors}
                                        displayTicks={true}/>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>NOT OVERWHELMED</b>
                </div>

                <div className="chart-container" style={{width: '100%', borderBottom: '1px black solid'}}>
                    <HorizontalBarChart barChartId='notOverWhelm' data={notOverwhelmedData}
                                        colors={notOverwhelmedColors} displayTicks={true}/>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>RESPECT</b>
                </div>

                <div className="chart-container" style={{width: '100%', borderBottom: '1px black solid'}}>
                    <HorizontalBarChart barChartId='respect' data={respectData} colors={respectColors}
                                        displayTicks={true}/>
                </div>


                <button className="btn btn-outline-dark no-print mt-3" type="button" data-toggle="collapse"
                        data-target="#cresol"
                        aria-expanded="false" aria-controls="emotion">
                    Show Section Questions
                </button>

                <div className="collapse" id="cresol">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-handshake"/> Conflict Resolution</h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th className="align-middle">How are YOU when the two of you have a conflict?</th>
                                </tr>
                                <tr>
                                    <td className="align-middle">I don't censor my complaints at all. I really let my
                                        partner have it full force.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I use a tactless choice of words when I complain.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">There's no stopping me once I get started
                                        complaining.
                                    </td>
                                </tr>

                                <tr>
                                    <th className="align-middle">How are YOU when the two of you have a conflict?</th>
                                </tr>
                                <tr>
                                    <td className="align-middle">Whenever I have a conflict with my partner, I feel
                                        physically tense and anxious, and I don't think clearly.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I feel physically tired or drained after I have an
                                        argument with my partner.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">Whenever we have a conflict, the feelings I have are
                                        overwhelming.
                                    </td>
                                </tr>

                                <tr>
                                    <th className="align-middle">How are YOU when the two of you have a conflict?</th>
                                </tr>
                                <tr>
                                    <td className="align-middle">I have no respect for my partner when we are discussing
                                        an issue.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">When I get upset I can see glaring faults in my
                                        partner's personality.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">When my partner complains, I feel that I have to "ward
                                        off" these attacks.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I feel unfairly attacked when my partner is being
                                        negative.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>

                            <li>How intimate toward your partner do you feel after an argument?</li>
                            <li>Are disagreements resolved during conflict or are negative feelings still directed at
                                your partner?
                            </li>
                            <li>Do you feel like your feelings and perspectives are heard during conflict or do you
                                remain frustrated and unvalidated?
                            </li>
                            <li>How long does it usually take for things to “cool down” between you and your partner
                                following a disagreement or fight?
                            </li>
                            <li>How often do you walk away during an argument or disagreement to calm down before
                                working toward a solution?
                            </li>
                        </ul>
                    </div>
                </div>

            </>

        )

    }

}

export default withWindowSizeListener(ConflictResolution);