import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from "../../constants/Colors";

class ReadyAttachment extends Component {

    componentDidMount() {
    }

    render() {

        const anxietyData = [[this.props.attachAnxiety.primarySelf[0], this.props.avoidance.primarySelf[0]],
            [100 - this.props.attachAnxiety.primarySelf[0], 100 - this.props.avoidance.primarySelf[0]]];

        const anxietyColors = [this.props.attachAnxiety.primarySelf[1], this.props.avoidance.primarySelf[1]];

        const labels = ['Anxiety', 'Avoidance'];

        const backGroundColor = ["rgba(238, 238, 238, 0.2)", "rgba(238, 238, 238, 0.2)"];
        const borderColor = ["rgb(238, 238, 238)", "rgba(238, 238, 238, 0.2)"];
        const height = 80;


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-leaf-heart fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            ATTACHMENT
                        </div>
                    </div>
                </div>
                <GraphLegend/>

                <div className="chart-container mt-2" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='aqgj' data={anxietyData} colors={anxietyColors}
                                        displayTicks={true} labels={labels}
                                        backGroundColor={backGroundColor} borderColor={borderColor} height={height}/>
                </div>

                <div className="mt-2 pb-4 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    Attachment refers to how we emotionally connect with others. Attachment research suggests that we
                    develop patterns of behavior and perceptions of relationships based on our experiences in past
                    relationships, such as our relationships with parents or previous romantic partners. These
                    experiences give us perceptions about how much trust we place on those around us and how much stress
                    we feel in relationships in general. As adults, our primary attachment figure becomes those with
                    whom we form romantic relationships. There are two main dimensions of attachment which combine to
                    provide insight into how we react to relationship threats, as well as the amount of trust we place
                    in our partner. Based on your answers to our attachment questions, we have given you a score on both
                    dimensions.
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>ATTACHMENT ANXIETY</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='aqdy' percent={this.props.attachAnxiety.primarySelf[0]}
                                    color={this.props.attachAnxiety.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            In any relationship there will be events which threaten the outcomes and stability of the
                            relationship. These events can arise from within the relationship, such as a disagreement,
                            or from external sources, such as family disapproval or time apart. How we react to such
                            threats depends primarily on how secure we are in the relationship and how much we trust our
                            partner. The dimension of anxiety measures your tendency to react with fear versus trust
                            when important relationships are threatened. By understanding your level of anxiety, you can
                            gain insight into the positive or negative nature of your reactions to threats.
                        </p>
                    </div>
                </div>

                {this.props.attachAnxiety.primarySelf[1] === Color.CHALLENGE &&
                <ChallengeLegendDetail id="awky">
                    Scoring in this section means that you are very anxious about your relationships. Your behavior in
                    relationships is often fueled by fear. Instead of trust, you often look for threats and find them,
                    even when they may not exist. You like to keep others close, or in constant contact when apart, and
                    are likely to experience jealousy when a partner pays attention to others. To potential partners,
                    you may come across as controlling and overbearing, which can create real threats to relationships.
                    Overcoming severe anxiety requires a conscious effort to replace negative memories with positive
                    relationship experiences in the present that contradict those memories, and this can be very
                    difficult to do without professional counseling.
                </ChallengeLegendDetail>
                }

                {this.props.attachAnxiety.primarySelf[1] === Color.IMPROVE &&
                <ImproveLegendDetail id="ayex">
                    A score in this section indicates a moderate level of anxiety. In the face of some threats,
                    especially
                    minor ones, you tend to trust relationships to pull through, but when serious threats arise you may
                    be more prone to look for escape routes, in case they are needed. This can then create an even
                    greater threat to relationships. You may limit your commitment to future partners or experience
                    personal anxiety when serious threats to your relationship emerge. Usually, anxiety is triggered by
                    certain events. Learn to recognize these triggers and where they come from, such as something you
                    experienced in the past. This will allow you to take more control over your reactions to those
                    triggers.
                </ImproveLegendDetail>
                }

                {this.props.attachAnxiety.primarySelf[1] === Color.STRENGTH &&
                <StrengthLegendDetail id="baom">
                    In general, you tend to trust relationships and partners. You will be more likely to see threats as
                    opportunities for growth rather than an attack. You turn towards close others in the face of such
                    threats and work together to overcome them. As you do, your trust in others and your relationships
                    increases and you continue to build a sense of security.
                </StrengthLegendDetail>
                }

                <div className="page-break" />
                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>ATTACHMENT AVOIDANCE</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='bavd' percent={this.props.avoidance.primarySelf[0]}
                                    color={this.props.avoidance.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        <p>
                            Relationships require a high degree of trust and openness with each other. We must learn to
                            depend on our partners to meet our relationship needs, as well as learn to contribute
                            ourselves. The dimension of avoidance measures how well we open up and place our trust in
                            others who are close to us. It assesses our ability to bring others into our lives and share
                            our worries and concerns, as well as our triumphs. Avoidance influences both the type and
                            depth of our communication with our partners, as well as our overall investment in our
                            relationships.
                        </p>
                    </div>
                </div>

                {this.props.avoidance.primarySelf[1] === Color.CHALLENGE &&
                <ChallengeLegendDetail id="bevy">
                    A score in this section indicates that your future relationships may often feel superficial at best.
                    You
                    probably share very little with others, and only share that which cannot hurt you. You may believe
                    that the best way to avoid pain is by putting up walls and other defenses. You also may tend to do
                    everything yourself because you see others as unreliable. However, this can become very overwhelming
                    as you bottle up your emotions and exhaust your energy. Consider where these feelings may be coming
                    from and why they exist. Intentionally try to be more open in your relationships and risk sharing
                    your feelings and opinions with others.
                </ChallengeLegendDetail>
                }

                {this.props.avoidance.primarySelf[1] === Color.IMPROVE &&
                <ImproveLegendDetail id="bihi">
                    A score in this section suggests that your level of avoidance keeps you from opening up to others.
                    The information you do share may sometimes be shallow or contains half-truths. You may find it
                    difficult to share more because you know that once you do share something, you no longer control it
                    completely. Once you do form a relationship of trust with a potential partner, you may open up more,
                    but it takes a while to get to that point. This could be difficult for potential partners as they
                    may begin to wonder if you are really committed to a relationship. Consider identifying topics or
                    issues that you prefer to avoid sharing with others and work on being open with future partners
                    about all topics.
                </ImproveLegendDetail>
                }

                {this.props.avoidance.primarySelf[1] === Color.STRENGTH &&
                <StrengthLegendDetail id="bixb">
                    A score in this section indicates a low level of avoidance. In other words, you are open with close
                    others and tend to develop a strong level of trust. You are open to interdependence, meaning you
                    trust others to meet your relationship needs. You are more willing to share deep and personal
                    information, trusting that your partners will respect you enough to keep it close.
                </StrengthLegendDetail>
                }
            </>

        )

    }

}

export default ReadyAttachment;