import React, { Component } from 'react';
import Gauge from 'canvas-gauges';

class Radial extends Component {

    state = {
        value: 0,
        cId: ''
    };

    componentDidMount() {

        this.setState({value: this.props.value, cId: this.props.cId}, () => this.drawRadialGauage());

    }

    drawRadialGauage = () => {

        let gauge = new Gauge.RadialGauge({
            renderTo: this.state.cId,
            width: 300,
            height: 300,
            barWidth: 40,
            colorBar: "rgba(41, 128, 185, .9)",
            colorBarEnd: "#003D1A",
            colorBarProgress: "rgba(255, 255, 255, 0)",
            colorStrokeTicks: "#fff",
            minValue: 0,
            startAngle: 90,
            ticksAngle: 180,
            valueBox: false,
            maxValue: 100,
            //ticksWidth:500,
           // ticksPadding: 100,
            majorTicks: [
                ""
            ],
            minorTicks: 0,
            strokeTicks: true,
            highlights: [
            //     {"from": 0, "to": 50, "color": "#45CF0E"},
            //     {"from": 50, "to": 100, "color": "#D33D1A"}
             ],
            borderShadowWidth: 0,
            borders: false,
            colorNeedle: "#000", //"rgba(50,50,200,.75)",
            colorNeedleEnd: "#ccc",
            needleType: "arrow",
            needleWidth: 4,
            needleShadow: true,
            needleCircleSize: 7,
            needleCircleOuter: true,
            needleCircleInner: true,
            colorNeedleCircleOuter: "rgba(50,50,200,.75)",
            colorNeedleCircleOuterEnd: "#000",
            animationDuration: 1500,
            animationRule: "bounce"
        });

        gauge.value = this.state.value;

    };

    render() {


        return (

            <>

                <canvas id={this.state.cId} style={{position: "relative", zIndex: -999}} />

            </>

        )


    }
}

export default Radial;