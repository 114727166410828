import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import GraphLegend from "./GraphLegend";
import StrengthLegendDetail from "./StrengthLegendDetail";
import ImproveLegendDetail from "./ImproveLegendDetail";
import ChallengeLegendDetail from "./ChallengeLegendDetail";
import WindowSizeListener, {withWindowSizeListener} from 'react-window-size-listener';

class EffectiveCommunication extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {

        let empathyData = [[this.props.empathy.primarySelf[0], this.props.empathy.partnerPrimary[0], this.props.empathy.primaryPartner[0], this.props.empathy.partnerSelf[0]],
            [100 - this.props.empathy.primarySelf[0], 100 - this.props.empathy.partnerPrimary[0], 100 - this.props.empathy.primaryPartner[0], 100 - this.props.empathy.partnerSelf[0]]];

        let empathyColors = [this.props.empathy.primarySelf[1], this.props.empathy.partnerPrimary[1], this.props.empathy.primaryPartner[1], this.props.empathy.partnerSelf[1]];

        let clearSendingData = [[this.props.clearSending.primarySelf[0], this.props.clearSending.partnerPrimary[0], this.props.clearSending.primaryPartner[0], this.props.clearSending.partnerSelf[0]],
            [100 - this.props.clearSending.primarySelf[0], 100 - this.props.clearSending.partnerPrimary[0], 100 - this.props.clearSending.primaryPartner[0], 100 - this.props.clearSending.partnerSelf[0]]];

        let clearSendingColors = [this.props.clearSending.primarySelf[1], this.props.clearSending.partnerPrimary[1], this.props.clearSending.primaryPartner[1], this.props.clearSending.partnerSelf[1]];

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="fa-stack" style={{color: "#c3b2b5"}}>
                            <i className="fal fa-comment fa-stack-2x"></i>
                            <i className="fal fa-heart fa-stack-1x"></i>
                        </div>
                        <div className="text-right">
                            <div className="text-muted text-right"
                                 style={{fontSize: ".5em"}}>{this.props.nickname} {this.props.username}</div>
                            EFFECTIVE COMMUNICATION
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                {this.props.windowSize.windowWidth > 710 &&

                <div className="table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        <tr>
                            <td align="center"><DonutChart elId='comm1'
                                                           percent={this.props.overallEffComm.primarySelf[0]}
                                                           color={this.props.overallEffComm.primarySelf[1]}/>
                            </td>
                            <td className="border-right" align="center"><DonutChart elId='comm2'
                                                                                    percent={this.props.overallEffComm.partnerPrimary[0]}
                                                                                    color={this.props.overallEffComm.partnerPrimary[1]}/>
                            </td>
                            <td align="center"><DonutChart elId='comm3'
                                                           percent={this.props.overallEffComm.primaryPartner[0]}
                                                           color={this.props.overallEffComm.primaryPartner[1]}/></td>
                            {/*<td align="center"><DonutChart elId='kind4' percent='0' color='#eee' /></td>*/}
                            <td align="center"><DonutChart elId='comm4'
                                                           percent={this.props.overallEffComm.partnerSelf[0] !== '' ? this.props.overallEffComm.partnerSelf[0] : '0'}
                                                           color={this.props.overallEffComm.partnerSelf[1]}/></td>

                        </tr>
                        <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                            <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                            <td className="border-right" align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                            <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                            <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                            {/*NO PARTNER <br/>ANSWER RECORDED*/}
                        </tr>
                        </tbody>
                    </table>
                </div>
                }

                {this.props.windowSize.windowWidth <= 710 &&
                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <td align="center"><DonutChart elId='comm1' percent={this.props.overallEffComm.primarySelf[0]}
                                                       color={this.props.overallEffComm.primarySelf[1]}/>
                        </td>
                        <td align="center"><DonutChart elId='comm2'
                                                       percent={this.props.overallEffComm.partnerPrimary[0]}
                                                       color={this.props.overallEffComm.partnerPrimary[1]}/>
                        </td>
                    </tr>
                    <tr className="border-bottom" style={{fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b><br/>VIEW YOURSELF</td>
                        <td align="center">HOW <b>YOUR <br/>PARTNER</b> VIEWS YOU</td>
                    </tr>
                    <tr>
                        <td align="center"><DonutChart elId='comm3'
                                                       percent={this.props.overallEffComm.primaryPartner[0]}
                                                       color={this.props.overallEffComm.primaryPartner[1]}/></td>
                        <td align="center"><DonutChart elId='comm4'
                                                       percent={this.props.overallEffComm.partnerSelf[0] !== '' ? this.props.overallEffComm.partnerSelf[0] : '0'}
                                                       color={this.props.overallEffComm.partnerSelf[1]}/></td>
                    </tr>


                    <tr style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '0.8em'}}>
                        <td align="center">HOW <b>YOU</b> VIEW <br/>YOUR PARTNER</td>
                        <td align="center">HOW <b>YOUR PARTNER</b> <br/>VIEWS THEMSELVES</td>
                    </tr>
                    </tbody>
                </table>
                }


                <div style={{fontFamily: 'Nunito', fontSize: '.9em'}} className='text-justify'>
                    The <b>Effective Communication</b> scale measures you and your partner's levels of empathy,
                    listening, and ability
                    to send clear messages. Effective Communication is a learned skill. By improving in this area
                    couples can expect
                    to experience decreased levels of confusion, conflict, and misunderstanding. Developing effective
                    communication will increase the trust and intimacy in your relationship.
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    backgroundColor: '#d9e3ed',
                    borderRadius: '20px'
                }}>
                    <div className='row'>
                        <div className='col-3 border-right border-dark'>
                            Relate Institute<br/><b>Findings</b>
                        </div>
                        <div className='col-9 text-justify'>
                            Research on effective communication shows that learning good communication skills certainly
                            helps increase our relationship satisfaction. However, just because we know how to
                            communicate well does not mean we will make the effort. Furthermore, research suggests that
                            we are more likely to communicate well if we care about our partner, whether or not we know
                            what effective communication really is.
                        </div>
                    </div>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>EMPATHY</b>
                </div>

                <div className="chart-container" style={{width: '100%'}}>
                    <HorizontalBarChart barChartId='empathy' data={empathyData} colors={empathyColors}
                                        displayTicks={true}/>
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    borderTop: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>CLEAR SENDING</b>
                </div>

                <div className="chart-container" style={{width: '100%', borderBottom: '1px black solid'}}>
                    <HorizontalBarChart barChartId='clearSending' data={clearSendingData} colors={clearSendingColors}
                                        displayTicks={true}/>
                </div>


                <StrengthLegendDetail id="ec-strength">
                    People who score in this area see themselves and/or their partners as being high in empathy,
                    listening, and in sending clear messages.
                </StrengthLegendDetail>

                <ImproveLegendDetail id="ec-needs-impr">
                    People who have scores in this section see themselves and/or their partners as being kind,
                    considerate, adaptable and easy-going some of the time.
                    You may want to evaluate where you stand as a couple to help increase marital satisfaction.
                </ImproveLegendDetail>

                <ChallengeLegendDetail id="ec-challenge">
                    People scoring in this area are decent communicators but have a difficult time implementing these
                    skills in their relationships.
                    A good example of this would be a person who enjoys listening to others but rarely admits personal
                    feelings.
                    People scoring in this area are not terrible communicators, but the areas they lack are affecting
                    their relationship.
                    Learning new ways to listen empathically, effectively communicate emotions, and be attentive to what
                    nonverbal
                    messages are saying are great ways to improve in this area.
                </ChallengeLegendDetail>

                <button className="btn btn-outline-dark no-print" type="button" data-toggle="collapse"
                        data-target="#ecom"
                        aria-expanded="false" aria-controls="emotion">
                    Show Section Questions
                </button>

                <div className="collapse" id="ecom">
                    <div className="mt-3"/>
                    <div className="card">
                        <div>
                            <h5 className="card-header"><i className="fal fa-comment"/> Effective Communication</h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th className="align-middle">How are YOU in your relationship?</th>
                                </tr>
                                <tr>
                                    <td className="align-middle">I understand my partner's feelings.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I am able to listen to my partner in an understanding
                                        way.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">In most matters, I understand what my partner is trying
                                        to say.
                                    </td>
                                </tr>

                                <tr>
                                    <th className="align-middle">How are YOU in your relationship?</th>
                                </tr>
                                <tr>
                                    <td className="align-middle">I sit down with my partner and just talk things over.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I talk over pleasant things that happen during the day
                                        when I am with my partner
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I discuss my personal problems with my partner.</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">When I talk to my partner I can say what I want in a
                                        clear manner.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="align-middle">I struggle to find words to express myself to my
                                        partner.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='mt-5 pb-4 pl-4 pr-4 pt-3' style={{
                    fontFamily: 'Nunito', fontSize: '.9em', backgroundColor: '#ccc',
                    borderTopRightRadius: '25px', borderTopLeftRadius: '25px', width: '95%', margin: '0 auto'
                }}>

                    <div className="row align-items-center" style={{fontFamily: 'Nunito', fontSize: '1.6em'}}>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className="float-right pb-2">
                                Discussion Questions
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='list-line-height'>
                            <li>How often do you take the time to ask how your partner’s day was and truly listen?</li>
                            <li>When you and your partner disagree, do you interrupt or appear judgmental?</li>
                            <li>What are your partner’s nonverbal signals telling you? What about yours to your partner?
                                Body language is often more important than words.
                            </li>
                            <li>What’s more important to you: Being right or finding a compromise?</li>
                            <li>How often in a stressful conversation do you take the time to slow down and take a break
                                so it won’t escalate further?
                            </li>
                        </ul>
                    </div>
                </div>
            </>

        )

    }

}

export default withWindowSizeListener(EffectiveCommunication);