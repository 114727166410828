import React from "react";
import axios from "../axios/axios-custom";
import CartItem from "../Checkout/CartItem";
import NavButton from "../layout/navbar/navigationItems/NavButton";

class Codes extends React.Component {

    state = {
        relateCodes: [],
        readyCodes: [],
    };

    componentDidMount() {
        this.loadRelateCodes();
        this.loadReadyCodes();
    }


    loadRelateCodes = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/pro/code-list/relate`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {

                this.setState({
                    relateCodes: response.data,
                });


            })
            .catch(error => {

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail});

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

                this.setState({
                    error: true,
                });

            });
    };

    loadReadyCodes = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/pro/code-list/ready`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then((response) => {

                this.setState({
                    readyCodes: response.data,
                });

            })
            .catch(error => {

                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    this.setState({errorDetails: error.response.data.detail});

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    //console.log('Error', error.message);
                }

                this.setState({
                    error: true,
                });

            });
    };


    render() {

        let relateCodesInfo = this.state.relateCodes.map((item, idx) => {
            return (

                <tr>
                    <td scope="row" className="align-middle">
                        {item.redemptionCode}
                        {/*<div className="custom-control custom-checkbox">*/}
                        {/*    <input type="checkbox" className="custom-control-input"*/}
                        {/*           id={item.redemptionCode}/>*/}
                        {/*    <label className="custom-control-label font-weight-normal"*/}
                        {/*           style={{fontSize: "1em"}} htmlFor={item.redemptionCode}>*/}
                        {/*        {item.redemptionCode}*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                    </td>
                </tr>
            )
        });

        let readyCodesInfo = this.state.readyCodes.map((item, idx) => {
            return (

                <tr>
                    <td scope="row" className="align-middle">
                        {item.redemptionCode}
                        {/*<div className="custom-control custom-checkbox">*/}
                        {/*    <input type="checkbox" className="custom-control-input"*/}
                        {/*           id={item.redemptionCode}/>*/}
                        {/*    <label className="custom-control-label font-weight-normal"*/}
                        {/*           style={{fontSize: "1em"}} htmlFor={item.redemptionCode}>*/}
                        {/*        {item.redemptionCode}*/}

                        {/*    </label>*/}
                        {/*</div>*/}
                    </td>
                </tr>
            )
        });

        return (
            <>
                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Relate and Ready codes</h5>
                </div>

                <div className="mb-4">
                    <NavButton className="btn  btn-outline-success" link="/pro/dashboard"><i className="fal fa-arrow-alt-left"/> Back To Dashboard</NavButton>
                </div>

                <div className="card">
                    <div>
                        <h5 className="card-header"><i className="fal fa-user-friends"/> Available Relate Codes</h5>
                    </div>
                    <div className="card-body scroll">
                        <table className="table table-borderless col-6">
                            <tbody>
                            {relateCodesInfo}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-3"/>

                <div className="card">
                    <div>
                        <h5 className="card-header"><i className="fal fa-user"/> Available Ready Codes</h5>
                    </div>
                    <div className="card-body scroll">
                        <table className="table table-borderless col-6">
                            <tbody>
                            {readyCodesInfo}
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="mt-3"/>

                {/*DO THIS NEXT TIME*/}

                {/*<button className="btn btn-outline-dark" type="button" data-toggle="collapse" data-target="#collapseExample"*/}
                {/*        aria-expanded="false" aria-controls="collapseExample">*/}
                {/*    Show Used Codes*/}
                {/*</button>*/}

                {/*<div className="collapse" id="collapseExample">*/}
                {/*    <div className="mt-3"/>*/}
                {/*    <div className="card">*/}
                {/*        <div>*/}
                {/*            <h5 className="card-header"><i className="fal fa-check-circle"></i> Used Codes</h5>*/}
                {/*        </div>*/}
                {/*        <div className="card-body">*/}
                {/*            <table className="table text-center">*/}
                {/*                <thead>*/}
                {/*                <tr>*/}
                {/*                    <th scope="col" className="align-middle">Assessment Code</th>*/}
                {/*                    <th scope="col" className="align-middle">Assigned To</th>*/}
                {/*                    <th scope="col" className="align-middle">Used By</th>*/}
                {/*                </tr>*/}
                {/*                </thead>*/}
                {/*                <tbody>*/}
                {/*                <tr>*/}
                {/*                    <td className="align-middle">Jane Doe</td>*/}
                {/*                    <td className="align-middle">*/}
                {/*                        <button type="button" className="btn btn-outline-success">VIEW RESULTS</button>*/}
                {/*                    </td>*/}
                {/*                </tr>*/}
                {/*                <tr>*/}
                {/*                    <td className="align-middle"><i className="fal fa-link"></i></td>*/}
                {/*                    <td className="align-middle">John Doe</td>*/}
                {/*                    <td className="align-middle">*/}
                {/*                        <button type="button" className="btn btn-outline-success">VIEW RESULTS*/}
                {/*                        </button>*/}
                {/*                    </td>*/}
                {/*                </tr>*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}


            </>
        );
    }
}

export default Codes;
