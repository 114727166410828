import { withRouter} from 'react-router-dom';
import React, {Component} from "react";
import {CardElement, injectStripe} from 'react-stripe-elements';
import {createOptions} from './createOptions';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { updateObject, checkValidity, createUUID } from '../layout/utility';
//import axios from 'axios';
import axios from "../axios/axios-custom";
import * as actionCreators from "../store/action/checkOut";
import {connect} from "react-redux";
import CartWidget from '../Checkout/CartWidget';
import Spinner from "../Spinner/Spinner";

class CardForm extends Component {

  state = {

    purchaseForm: {
      firstName: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 1,
          maxLength: 30
        },
      },
      lastName: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 1,
          maxLength: 30
        },
      },
      email: {
        value: this.props.location.state !== null && typeof this.props.location.state !== 'undefined' && this.props.location.state.email !== null ? this.props.location.state.email : '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 5,
          maxLength: 30,
          isEmail: true,
        },
      },

      address: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 5,
        },
      },

      address2: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: false,
        },
      },

      postalCode: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 5,
          maxLength: 30
        },
      },
      country: {
        value: 'United States',
        valid: true,
        touched: false,
        validation: {
          required: true,
        },
      },
      city: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
        },
      },
      region: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
        },
      },
    },
    formIsValid: false,
    cardHelpText: '',
    invalidClass: 'form-control is-invalid',
    validClass: 'form-control',
    country: '',
    region: '',
    usa: true,
    international: false,
    userId: '',
    error: '',
    spinner: false,

  };

  componentDidMount() {
   // jQuery("input[name='cardnumber']").focus();



    if (this.props.isAuthenticated) {

      const userId = localStorage.getItem('userId');

      let url = `${process.env.REACT_APP_API_URL}/api/users/${userId}`;

      axios.get(url, {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }, )
      .then(response => {

        const loadedPurchaseForm = {
          ...this.state.purchaseForm
        };

        loadedPurchaseForm.firstName.value = response.data.firstName;
        loadedPurchaseForm.lastName.value = response.data.lastName;
        loadedPurchaseForm.email.value = response.data.email;

        loadedPurchaseForm.address.value = response.data.line1;
        loadedPurchaseForm.address2.value = response.data.line2;

        loadedPurchaseForm.postalCode.value = response.data.postalCode;
        loadedPurchaseForm.city.value = response.data.city;

        if(response.data.state.length > 0) {

          loadedPurchaseForm.region.value = response.data.state;
          loadedPurchaseForm.country.value = response.data.country;

        }

        this.setState({purchaseForm: loadedPurchaseForm});

      })
      .catch(error => {

        // console.log(error);

      });

    } else {

      if(this.state.purchaseForm.email.value.trim().length === 0) {
        this.props.history.push(`/customer/email`);
      }
    }

    // console.log("cartContents", this.props.cartContents);
    //
    // this.props.cartContents.forEach(item => {
    //
    //   if(item.sku === 'MC-GTV-227') {
    //     this.setState({readyQty: item.qty, readyTouched: (item.qty > 0)});
    //   }
    //   if(item.sku === 'MC-OTN-802') {
    //     this.setState({relateQty: item.qty, relateTouched: (item.qty > 0)});
    //   }
    //   if(item.sku === 'MC-OTN-141') {
    //     this.setState({relateCoupleQty: item.qty, relateCoupleTouched: (item.qty > 0)});
    //   }
    // });

  }


//https://country-regions.github.io/react-country-region-selector/
  //https://www.npmjs.com/package/react-country-region-selector#demo
  //https://learnetto.com/blog/how-to-do-simple-form-validation-in-reactjs
  //https://learnetto.com/blog/how-to-do-simple-form-validation-in-reactjs

  selectCountry = (val, event) => {
    //this.setState({ country: val });

    const updatedFormElement = updateObject(this.state.purchaseForm.country, {
      value: val,
      valid: checkValidity(val, this.state.purchaseForm.country.validation),
      touched: true
    });

    const updatedPurchaseForm = updateObject(this.state.purchaseForm, {
      country: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedPurchaseForm) {
      formIsValid = updatedPurchaseForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({purchaseForm: updatedPurchaseForm, formIsValid: formIsValid});

  };

  selectRegion = (val) => {

    const updatedFormElement = updateObject(this.state.purchaseForm.region, {
      value: val,
      valid: checkValidity(val, this.state.purchaseForm.region.validation),
      touched: true
    });

    const updatedPurchaseForm = updateObject(this.state.purchaseForm, {
      region: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedPurchaseForm) {
      formIsValid = updatedPurchaseForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({purchaseForm: updatedPurchaseForm, formIsValid: formIsValid});

  };

  handleRegionToggle = (e) => {

    const updatedPurchaseForm = {
      ...this.state.purchaseForm
    };

    if(e.target.value.trim() === 'usa') {
      updatedPurchaseForm.country.value = 'United States';
    } else {
      updatedPurchaseForm.country.value = '';
    }



    this.setState({usa: !this.state.usa, purchaseForm: updatedPurchaseForm});


  };

  handleBlur = () => {
  };

  handleChange = (change) => {

    if (change.error) {
      this.setState({cardHelpText: change.error.message});

    } else {
      this.setState({cardHelpText: ''});
    }

  };
  handleClick = () => {
  };
  handleFocus = () => {
  };
  handleReady = () => {
    // {(el) => el.focus()}
  };

  handleSubmit = (ev) => {

    ev.preventDefault();

    this.setState({spinner: true, error: ''});

    const validationArray = [];

    //let updatedFormElement = '';
    let updatedPurchaseForm = '';

     updatedPurchaseForm = updateObject(this.state.purchaseForm, {

    });

        Object.keys(this.state.purchaseForm).forEach((element) => {
      let validResult = checkValidity(this.state.purchaseForm[element].value, this.state.purchaseForm[element].validation);

      validationArray.push(validResult);

      ///////////////////////
      let updatedFormElement = updateObject(this.state.purchaseForm[element], {
        value: this.state.purchaseForm[element].value,
        valid: validResult,
        touched: true
      });

      updatedPurchaseForm[element] = updatedFormElement;


      /////////////////////////

    });

    const isValidForm = validationArray.every((element) => {
      return element;
    });

    if (isValidForm) {

      if(this.props.cart.totalCost > 0) {

        this.props.stripe.createToken().then(
            payload => {

              if (!('error' in payload)) { //  && this.state.formIsValid

                const orderData = {
                  tokenId: payload.token.id,
                  cardId: payload.token.card.id,
                  brand: payload.token.brand,
                  clientIp: payload.token.client_ip,
                  last4: payload.token.card.last4,
                  firstName: this.state.purchaseForm.firstName.value,
                  lastName: this.state.purchaseForm.lastName.value,
                  email: this.state.purchaseForm.email.value,
                  address: this.state.purchaseForm.address.value,
                  address2: this.state.purchaseForm.address2.value,
                  postalCode: this.state.purchaseForm.postalCode.value,
                  country: this.state.purchaseForm.country.value,
                  city: this.state.purchaseForm.city.value,
                  region: this.state.purchaseForm.region.value,
                  cart: this.props.cart,
                };
                let url = `${process.env.REACT_APP_API_URL}/api/payment`;


                axios.post(url, orderData, {withCredentials: true})
                    .then(response => {

                      localStorage.setItem('tempUserId', response.data.userId);

                      this.props.onPaymentApproval();

                      this.props.history.push(`/receipt/${response.data.orderId}/${response.data.userId}`);

                    })
                    .catch(error => {
                      // console.log('ERROR',  error.response.data.detail);
                      // console.log(JSON.stringify(error));
                      //alert('inside error!' + JSON.stringify(error));
                      this.setState({error: error.response.data.detail});
                      this.setState({spinner: false});

                    });

              } else {

                this.setState({spinner: false});

              }

            }).catch(exception => {
          // token creation
          // TODO: Display an error mesage
          // console.log("exception", exception);
          this.setState({spinner: false});
        });

      } else {

        const orderData = {
          tokenId: createUUID(),
          cardId: createUUID(),
          brand: "free",
          clientIp: '1.1.1.1',
          last4: '0000',
          firstName: this.state.purchaseForm.firstName.value,
          lastName: this.state.purchaseForm.lastName.value,
          email: this.state.purchaseForm.email.value,
          address: this.state.purchaseForm.address.value,
          address2: this.state.purchaseForm.address2.value,
          postalCode: this.state.purchaseForm.postalCode.value,
          country: this.state.purchaseForm.country.value,
          city: this.state.purchaseForm.city.value,
          region: this.state.purchaseForm.region.value,
          cart: this.props.cart,
        };

        let url = `${process.env.REACT_APP_API_URL}/api/payment`;


        axios.post(url, orderData, {withCredentials: true})
            .then(response => {

              localStorage.setItem('tempUserId', response.data.userId);

              this.props.onPaymentApproval();

              this.props.history.push(`/receipt/${response.data.orderId}/${response.data.userId}`);

            })
            .catch(error => {
              // console.log('ERROR',  error.response.data.detail);
              // console.log(JSON.stringify(error));
              //alert('inside error!' + JSON.stringify(error));
              this.setState({error: error.response.data.detail});
              this.setState({spinner: false});

            });



      }



    } else {
      window.scrollTo(0, 0);
      this.setState({spinner: false, purchaseForm: updatedPurchaseForm, error: 'Please correct the form errors'});

      // If The Form is invalid
    }
  };

  handleUserInput = (event) => {
    event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;
    // this.setState({[name]: value});

    const updatedPurchaseForm = {
      ...this.state.purchaseForm
    };

    const updatedFormElement = {
      ...updatedPurchaseForm[name]
    };

    updatedFormElement.value = value;

    updatedFormElement.valid = true;

    // if(value.length > 5) {
    //   updatedFormElement.valid = true;
    // }

    //updatedFormElement.valid = this.validateForm(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedPurchaseForm[name] = updatedFormElement;
    this.setState({purchaseForm: updatedPurchaseForm});

  };

  validateInputLength = (input) => {

    let result = true;

    if (input.length < 5) {
      result = false;
    }

    return result;

  };

  validateEmail = (email) => {
    let result = true;

    if (email.length < 7 || email.indexOf("@") <= 0) {
      result = false;
    }

    return result;

  };

  validateElementInput = (event) => {

    event.preventDefault();

    // const name = event.target.name;
    // const value = event.target.value;

    const inputIdentifier = event.target.name;

    if (!this.state.purchaseForm[inputIdentifier].validation) {

      return true;

    }

    const updatedFormElement = updateObject(this.state.purchaseForm[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.purchaseForm[inputIdentifier].validation),
      touched: true
    });

    const updatedPurchaseForm = updateObject(this.state.purchaseForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedPurchaseForm) {
      formIsValid = updatedPurchaseForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({purchaseForm: updatedPurchaseForm, formIsValid: formIsValid});

  };

  render() {

    const isUS = this.state.usa;
    let countryToShow;

    if(isUS) {

      countryToShow = <input type="text" className={this.state.purchaseForm.country.valid ? this.state.validClass : this.state.invalidClass}
                             id="country" name="country" disabled={true}
                             value={this.state.purchaseForm.country.value}
                             onChange={(event) => this.handleUserInput(event)}
      />;

    } else {

      countryToShow = <CountryDropdown className={this.state.purchaseForm.country.valid ? this.state.validClass : this.state.invalidClass} name="country"
                                       value={this.state.purchaseForm.country.value}
                                       onChange={(value, fullEvent) =>
                                           this.selectCountry(value, fullEvent)}
                                       whitelist={["CA", "GB", "AU", "NZ", "EE", "LV", "LT", "DE", "FR", "IT", "ES", "NL", "BE", "SE", "CH", "CZ", "DK", "FI", "NO", "IE"]}
                                       priorityOptions={["CA", "GB", "AU", "NZ", "EE", "LV", "LT", "DE", "FR", "IT", "ES", "NL", "BE", "SE", "CH", "CZ", "DK", "FI", "NO", "IE"]}/>;

    }

    let errorDiv = <div className="alert alert-danger text-center" role="alert">
      <i className="fal fa-exclamation-triangle"/> {this.state.error}
    </div>;

    let cardDetails = <></>;
    if(this.props.cart.totalCost > 0) {
      cardDetails =
          <div className="col-md-12 order-md-1" style={{display: this.state.spinner ? 'none' : 'block'}}>

        <h4 className="mb-3 mt-3"><span className="text-muted">Card details</span></h4>
        <div className="row">
          <div className="col-md-6 mb-3">
          </div>
        </div>
        <div className="mb-3">
          <CardElement className={this.state.cardHelpText.length === 0 ? this.state.validClass  : this.state.invalidClass }
                       onBlur={this.handleBlur}
                       onChange={this.handleChange}
                       onFocus={this.handleFocus}
                       onReady={(el) => el.focus()}  // {this.handleReady}
                       {...createOptions(this.props.fontSize)}
          />
        </div>
        <div className="invalid-feedback" style={{display: "block"}}>
          {this.state.cardHelpText}
        </div>
      </div>
    }

    return (



        <>

          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Payment</h5>
            <p className="lead">
              Please, provide a valid email account. The codes will be emailed to you.
            </p>
          </div>



          {this.state.error && errorDiv}



          <div className="row" style={{borderTop: "1px solid #eee"}}>

            <div className="col-md-4 order-md-2 mb-4 mt-3">
              <CartWidget total={this.props.cartQty} cart={this.props.cart} />
            </div>


            <div className="col-md-8 order-md-1  pb-4">

              {this.state.spinner && <Spinner />}



              <form onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                {/*style={{display: this.state.spinner === false ? 'display:'  'block' : 'none'}}*/}

                {/*{this.props.cart.totalCost !== 0 &*/}
                {cardDetails}


                {!this.state.spinner &&
                <>
                <div className="col-md-11 order-md-1 mt-3">
                  <h4 className="mb-3"><span className="text-muted">Billing address</span></h4>

                  <div className="mb-3">
                    <h6 className="mb-3"><span className="text-muted">Region</span></h6>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="usa" className="custom-control-input" value="usa" onChange={(v) => this.handleRegionToggle(v)} checked={this.state.usa}/>
                      <label className="custom-control-label" htmlFor="usa">United States
                      </label>
                    </div>

                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="international" className="custom-control-input" value="international" onChange={(v) => this.handleRegionToggle(v)} checked={!this.state.usa}/>
                      <label className="custom-control-label" htmlFor="international">International</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="firstName">First name</label>
                      <input type="text" className={this.state.purchaseForm.firstName.valid ? this.state.validClass : this.state.invalidClass}
                             id="firstName" name="firstName" value={this.state.purchaseForm.firstName.value} placeholder=""
                             onChange={(event) => this.handleUserInput(event)}
                             onBlur={(event) => this.validateElementInput(event)}
                      />
                      <div className="invalid-feedback">
                        Valid first name is required.
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="lastName">Last name</label>
                      <input type="text" className={this.state.purchaseForm.lastName.valid ? this.state.validClass : this.state.invalidClass}
                             id="lastName" name="lastName"
                             value={this.state.purchaseForm.lastName.value}
                             placeholder=""
                             onChange={(event) => this.handleUserInput(event)}
                             onBlur={(event) => this.validateElementInput(event)}
                      />
                      <div className="invalid-feedback">
                        Valid last name is required.
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <input type="email" className={this.state.purchaseForm.email.valid ? this.state.validClass : this.state.invalidClass}
                           id="email" name="email" value={this.state.purchaseForm.email.value} placeholder="you@example.com"
                           onChange={(event) => this.handleUserInput(event)}
                           onBlur={(event) => this.validateElementInput(event)}
                    />
                    <small id="emailHelp" className="form-text text-muted">This email is also your login id</small>
                    <div className="invalid-feedback">
                      Please enter a valid email address.
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="address">Address</label>
                    <input type="text" className={this.state.purchaseForm.address.valid ? this.state.validClass : this.state.invalidClass}
                           id="address" placeholder="1234 Main St" name='address'
                           onChange={(event) => this.handleUserInput(event)}
                           value={this.state.purchaseForm.address.value}
                           onBlur={(event) => this.validateElementInput(event)}
                    />
                    <div className="invalid-feedback">
                      Please enter your shipping address.
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="address2">Address 2 <span className="text-muted">(Optional)</span></label>
                    <input type="text" className='form-control'
                           id="address2" placeholder="Apartment or suite" name='address2'
                           onChange={(event) => this.handleUserInput(event)}
                           value={this.state.purchaseForm.address2.value}
                           onBlur={(event) => this.validateElementInput(event)}
                    />
                  </div>


                  <div className="mb-3">
                    <label htmlFor="address">City</label>
                    <input type="text" className={this.state.purchaseForm.city.valid ? this.state.validClass : this.state.invalidClass}
                           id="city" placeholder="" name='city'
                           onChange={(event) => this.handleUserInput(event)}
                           value={this.state.purchaseForm.city.value}
                           onBlur={(event) => this.validateElementInput(event)}
                    />
                    <div className="invalid-feedback">
                      Please enter your city.
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-5 mb-3">
                      <label htmlFor="country">Country</label>

                      {countryToShow}

                      <div className="invalid-feedback">
                        Please select a valid country.
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="state">{this.state.usa ? 'State*' : 'Province*'}</label>

                      <RegionDropdown className={this.state.purchaseForm.region.valid ? this.state.validClass : this.state.invalidClass}
                                      selected={this.state.purchaseForm.region.value}
                                      name="region"
                                      country={this.state.purchaseForm.country.value}
                                      value={this.state.purchaseForm.region.value}
                                      onChange={this.selectRegion}/>

                      <div className="invalid-feedback">
                        Please provide a valid state.
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="zip">{this.state.usa ? 'Zip Code*' : 'Postal Code*'}</label>
                      <input type="text" className={this.state.purchaseForm.postalCode.valid ? this.state.validClass : this.state.invalidClass}
                             id="postalCode"
                             name="postalCode" placeholder=""
                             value={this.state.purchaseForm.postalCode.value}
                             onChange={(event) => this.handleUserInput(event)}
                             onBlur={(event) => this.validateElementInput(event)}
                      />
                      <div className="invalid-feedback">
                        Postal Code required.
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mb-4"/>
                  <button className="btn btn-success btn-lg btn-block" type="submit">Complete purchase</button>

              </> }

              </form>
            </div>
          </div>

        </>
    );
  }
}

const
    mapReduxStateToProps = (reduxState) => {
      return {
        cart: reduxState.checkOut.cart,
        cartQty: reduxState.checkOut.cart.totalQty,
        isAuthenticated: reduxState.oldReducer.token !== null,
      };
    };

const
    mapDispatchToProps = dispatch => {
      return {
        onAddToCart: (cart) => dispatch(actionCreators.addToCart(cart)),
        onUpdateCart: (cart) => dispatch(actionCreators.updateCart(cart)),
        onRemoveFromCart: (sku) => dispatch(actionCreators.removeFromCart(sku)),
        onPaymentApproval: () => dispatch(actionCreators.emptyCart()),
      };
    };

// export default withRouter(injectStripe(CardForm));

export
default

withRouter(connect(mapReduxStateToProps, mapDispatchToProps)(
    injectStripe(CardForm)
));