import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from "../../constants/Colors";
import {createRiskTable, createRiskTableNumeric} from '../reportUtils';

class RelationshipInfluence extends Component {

    componentDidMount() {
    }

    createTable = (value) => {
        return <>
            <td className={value === 'true' ? 'table-danger text-center' : ''}>
                {value === 'true' ? <i className="fal fa-check"></i> : ''}
            </td>
            <td className={value === 'false' ? 'table-success text-center' : ''}>
                {value === 'false' ? <i className="fal fa-check"></i> : ''}
            </td>
        </>;
    };

    createTableNumeric = (result, challengeNumber) => {

        let valueAsNumber = Number(result);
        return <>
            <td className={valueAsNumber > challengeNumber ? 'table-danger text-center' : ''}>
                {valueAsNumber > challengeNumber ? <i className="fal fa-check"></i> : ''}
            </td>
            <td className={valueAsNumber <= challengeNumber ? 'table-success text-center' : ''}>
                {valueAsNumber <= challengeNumber ? <i className="fal fa-check"></i> : ''}
            </td>
        </>;
    };

    render() {

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-signal-stream fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            RELATIONSHIP INFLUENCE
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                        <DonutChart elId='gwkf' percent={this.props.relInfluence.primarySelf[0]}
                                    color={this.props.relInfluence.primarySelf[1]}/>
                    </div>
                    </div>
                    <div className="mt-3 pb-2 pl-4 pr-4 pt-3 text-justify" style={{
                        backgroundColor: '#d9e3ed',
                        borderRadius: '20px',
                        fontFamily: 'Nunito',
                        fontSize: '1em'
                    }}>
                        <p>
                            This section evaluates your feelings about romantic relationships based on your previous
                            dating experiences. Did you experience generally happy relationships? Have you moved on from
                            previous relationships? The more negativity we experienced in past relationships, the more
                            likely we are to be hesitant to commit to new relationships. We do not start with a clean
                            slate after each break-up. Instead, we carry both good and bad experiences with us into
                            future relationships. We often compare potential or current romantic partners to our old
                            partners. If we experienced negative dating relationships in the past, we may also find it
                            hard to feel that relationships are valuable at all. No matter the experiences you have had
                            previously, the key to forming new, successful relationships is working through your past
                            and coming to terms with the negative.
                        </p>
                    </div>
                </div>

                {this.props.relInfluence.primarySelf[1] === Color.STRENGTH &&

                <StrengthLegendDetail id="gyyd">
                    Overall, you have had mostly positive experiences with past relationships and have been able to move
                    on from anything negative. You are more likely to begin any new relationships with hope,
                    understanding that this new relationship is a chance to make up for any past mistakes. You are also
                    less likely to compare new relationships with the past, which will allow you to make this new
                    relationship an experience of its own.
                </StrengthLegendDetail>

                }

                {this.props.relInfluence.primarySelf[1] === Color.IMPROVE &&

                <ImproveLegendDetail id="hcdr">
                    If you scored in the white, your past relationship experiences might begin to haunt any new
                    relationship if you do not work through those experiences more. If you are still holding on to the
                    past, you may find it difficult to trust your new partner and the new relationship. Recognize that
                    each new relationship gives you a new chance to make love work. You may be one of the lucky ones who
                    can actually find closure for your past relationships by trying again.
                </ImproveLegendDetail>

                }

                {this.props.relInfluence.primarySelf[1] === Color.CHALLENGE &&

                <ChallengeLegendDetail id="hfla">
                    If you scored in this section, your past relationships are a major source of anxiety for you.
                    Recognize that relationships are not the cause for anxiety but how the people in a relationship work
                    together. This may mean that instead of playing the victim of bad partners, you should take some
                    time to figure out what you may have contributed to negative events in past relationships. What can
                    you do to change those patterns in your next relationship?
                </ChallengeLegendDetail>

                }

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    The previous section focused on how the quality of your past relationships shape your perceptions of
                    relationships overall. Many of these factors are what we call static or demographic. That means they
                    are factors from your past that we know for one reason or another increases your risk of
                    unsuccessful relationships. They cannot be changed and simply do or do not exist. Keep in mind that
                    having one risk is not as problematic as a pile up of risks. The following list are possible risk
                    factors from previous relationships:
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>CHECKLIST</b>
                </div>

                <table className="table table-striped table-bordered col-sm-8 mt-4"
                       style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Challenge</th>
                        <th scope="col">Strength</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Living with a romantic partner without marriage</th>
                        {/*{this.createTable(this.props.livTogether.primarySelf[0])}*/}
                        {createRiskTable(this.props.livTogether.primarySelf[0])}
                    </tr>
                    <tr>
                        <th scope="row">Number of sexual partners</th>
                        {createRiskTableNumeric(this.props.numPartners.primarySelf[0], 1)}
                    </tr>
                    <tr>
                        <th scope="row">Number of past relationships</th>
                        {createRiskTableNumeric(this.props.pastRelation.primarySelf[0], 1)}
                    </tr>
                    </tbody>
                </table>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>COHABITATION</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4  text-justify'>
                    Research has consistently shown that individuals who live with romantic partners, but are unmarried,
                    are at greater risk for conflict and instability in their relationships. Those that cohabit with
                    romantic partners are also generally less likely to form long-term and happy marriages. Only
                    individuals who are at least engaged before living together have been shown to avoid many of these
                    risks. In addition, the more partners you have lived with, the greater the risk. Research also shows
                    that those that have cohabited in the past may have less commitment to future partners.
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>NUMBER OF SEX PARTNERS</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 text-justify'>
                    Research has emerged showing that individuals with multiple non-marital sexual partners are at
                    greater risk for relationship difficulties including instability, conflict, and lack of commitment
                    once married. In addition, the more sexual partners you have the more difficult it may be to enjoy
                    sex with your current partner. Such research has suggested that having multiple sexual partners can
                    lead to some unhealthy tactics such as rushing intimacy in new relationships, comparing past sexual
                    partners to new partners, and overemphasizing the physical aspects of relationships. If you have had
                    multiple sexual partners in the past, think about if any of these negative traits has or may
                    influence your future relationships.
                </div>

                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="mt-4">
                    <b>NUMBER OF PAST RELATIONSHIPS</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    Research has shown that individuals with high numbers of past romantic relationships are more likely
                    to experience doubts about new relationships and trust issues. Much of this research has begun to
                    focus on the negative effects of break-ups, finding that breaking-up with a long-term dating partner
                    may have some of the same effects as divorce over time. When individuals experience multiple
                    significant dating partnerships, they sometimes may feel less satisfied with their current
                    relationships and are more anxious about the outcome. This can create feelings of insecurity and
                    instability in relationships. If you have found yourself in a series of committed relationships that
                    have broken-up, consider how such experiences may give you a negative perception of relationships
                    moving forward.
                </div>

            </>

        )

    }

}

export default RelationshipInfluence;