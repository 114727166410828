import React from 'react';

const strengthLegendDetail = (props) => {


  return (

      <div className="bd-callout bd-callout-warning text-justify" style={{borderLeftColor: "#4375a4"}}>

        <h5 id={props.id} style={{color: "#4375a4"}}>Strength</h5>
        <div style={{height: '4px', width: '90px', backgroundColor: '#4375a4'}} className='mb-2'></div>

        <p>
          {props.children}
        </p>
      </div>

  )};

export default strengthLegendDetail;