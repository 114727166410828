

import React, {Component} from 'react';

class AlertError extends Component {

  render() {

    return (

        <>

         <div className="alert alert-danger text-center" role="alert">
          <i className="fal fa-exclamation-triangle"></i> {this.props.children}
        </div>

        </>

    );
  }

}

export default AlertError;