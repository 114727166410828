import React, {Component} from 'react';
import {checkValidity, updateObject} from "../layout/utility";
import axios from "axios";
import * as actions from "../store/actions";
import {connect} from "react-redux";
import Spinner from "../Spinner/Spinner";
import {Link} from "react-router-dom";


class SignUp extends Component {

  state = {

    registrationForm: {
      firstName: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 1,
          maxLength: 30
        },
      },
      lastName: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 1,
          maxLength: 30
        },
      },
      email: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 5,
          maxLength: 50,
          isEmail: true,
        },
      },
      password: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 8,
          maxLength: 30,
        },
      },
      passwordConfirmation: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: true,
          minLength: 8,
          maxLength: 30,
        },
      },
    },
    formIsValid: false,
    invalidClass: 'form-control is-invalid',
    validClass: 'form-control',
    errorDetails: '',
    showSpinner: false,
  };

  componentDidMount(){
    // not focusing so it would not give error when sin in link is clicked
    //this.nameInput.focus();
  }


  handleUserInput = (event) => {
    //event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;
    // this.setState({[name]: value});

    const updatedRegistrationForm = {
      ...this.state.registrationForm
    };

    const updatedFormElement = {
      ...updatedRegistrationForm[name]
    };

    updatedFormElement.value = value;

    updatedFormElement.valid = true;

    // if(value.length > 5) {
    //   updatedFormElement.valid = true;
    // }

    //updatedFormElement.valid = this.validateForm(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedRegistrationForm[name] = updatedFormElement;
    this.setState({registrationForm: updatedRegistrationForm});

  };

  validateElementInput = (event) => {

    event.preventDefault();

    // const name = event.target.name;
    // const value = event.target.value;

    const inputIdentifier = event.target.name;

    if (!this.state.registrationForm[inputIdentifier].validation) {

      return true;

    }

    const updatedFormElement = updateObject(this.state.registrationForm[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.registrationForm[inputIdentifier].validation),
      touched: true
    });

    const updatedRegistrationForm = updateObject(this.state.registrationForm, {
      [inputIdentifier]: updatedFormElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedRegistrationForm) {
      formIsValid = updatedRegistrationForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({registrationForm: updatedRegistrationForm, formIsValid: formIsValid});

  };


  handleSubmit = (ev) => {

    ev.preventDefault();

    if(this.state.registrationForm.password.value.trim().length >= 8 &&
        this.state.registrationForm.password.value.trim() === this.state.registrationForm.passwordConfirmation.value.trim()) {

      this.setState({showSpinner: true});


      const registrationData = {
        firstName: this.state.registrationForm.firstName.value.trim(),
        lastName: this.state.registrationForm.lastName.value.trim(),
        email: this.state.registrationForm.email.value.trim(),
        password: this.state.registrationForm.password.value.trim(),
        passwordConfirmation: this.state.registrationForm.passwordConfirmation.value.trim(),
      };


      let url = `${process.env.REACT_APP_API_URL}/api/register`;

      axios.post(url, registrationData)
      .then(response => {
        this.props.postRegistrationAuth( response.data );
        this.setState({showSpinner: false});

      })
      .catch(error => {

        this.setState({showSpinner: false});

        // Error 😨
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          this.setState({errorDetails: error.response.data.detail});

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          //console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          //console.log('Error', error.message);
        }
        //console.log(error);

      });

    } else {

      if(this.state.registrationForm.password.value.trim().length >= 8 &&
          this.state.registrationForm.password.value.trim() !== this.state.registrationForm.passwordConfirmation.value.trim()) {

      const updatedFormElement = updateObject(this.state.registrationForm['passwordConfirmation'], {
        value: this.state.registrationForm.passwordConfirmation.value.trim(),
        valid: false,
        touched: true
      });

      const updatedRegistrationForm = updateObject(this.state.registrationForm, {
        'passwordConfirmation': updatedFormElement
      });

      let formIsValid = false;
      this.setState({registrationForm: updatedRegistrationForm, formIsValid: formIsValid});

    }

    }

  };


  render() {

    let errorDiv = <div className="alert alert-danger text-center" role="alert">
      <i className="fal fa-exclamation-triangle"/> {this.state.errorDetails}
    </div>;


    let signUpForm = (

        <>

          <div className="pricing-header mx-auto text-center">
            <h5 className="display-4" style={{fontSize: '2.3em'}}>Register</h5>
            <p className="lead">
              Register for an account.  It's easy and the registration is free.  No credit card required.
            </p>
          </div>

          {this.state.errorDetails && errorDiv}

          {!this.state.showSpinner && <form className="form-signin"  onSubmit={this.handleSubmit}>
            <div className="col-md-12 order-md-1">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="firstName">First name</label>
                  <input type="text" className={this.state.registrationForm.firstName.valid ? this.state.validClass : this.state.invalidClass}
                         id="firstName" name="firstName"
                         ref={(input) => { this.nameInput = input; }}
                         value={this.state.registrationForm.firstName.value}
                         onChange={(event) => this.handleUserInput(event)}
                         onBlur={(event) => this.validateElementInput(event)}
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Last name</label>
                  <input type="text" className={this.state.registrationForm.lastName.valid ? this.state.validClass : this.state.invalidClass}
                         id="lastName" name="lastName"
                         value={this.state.registrationForm.lastName.value}
                         onChange={(event) => this.handleUserInput(event)}
                         onBlur={(event) => this.validateElementInput(event)}
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email">Email</label>
                <input type="email" className={this.state.registrationForm.email.valid ? this.state.validClass : this.state.invalidClass}
                       id="email" name="email" value={this.state.registrationForm.email.value} placeholder="you@example.com"
                       onChange={(event) => this.handleUserInput(event)}
                       onBlur={(event) => this.validateElementInput(event)}
                />
                <div className="invalid-feedback">
                  Please enter a valid email address.
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="password">Password</label>
                <input type="password" className={this.state.registrationForm.password.valid ? this.state.validClass : this.state.invalidClass}
                       id="password" name="password"
                       value={this.state.registrationForm.password.value}
                       onChange={(event) => this.handleUserInput(event)}
                       onBlur={(event) => this.validateElementInput(event)}/>
                <div className="invalid-feedback">
                  Password must be at least 8 characters long
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input type="password" className={this.state.registrationForm.passwordConfirmation.valid ? this.state.validClass : this.state.invalidClass}
                       id="passwordConfirmation" name="passwordConfirmation"
                       value={this.state.registrationForm.passwordConfirmation.value}
                       onChange={(event) => this.handleUserInput(event)}/>
                <div className="invalid-feedback">
                  Passwords do not match
                </div>
              </div>

            </div>
            <hr className="mb-4"/>
            <button className="btn btn-success btn-block" type="submit">Register</button>
            <div className='mt-4'>Already have an account? <Link to='auth'>Sign In Here</Link></div>
          </form> }

          {this.state.showSpinner && <Spinner />}



        </>

    );

    return signUpForm;
  }
}


const mapDispatchToProps = dispatch => {
  return {
    // onIncrementCounter: () => dispatch({type: 'INCREMENT'}),
    postRegistrationAuth: (response) => dispatch(actions.postRegistrationAuth(response)),

  };
};

export default connect(null, mapDispatchToProps)(SignUp);