import React from 'react';
import CartWidgetItem from './CartWidgetItem';
import CartWidgetTotal from './CartWidgetTotal';
import CartWidgetDiscount from './CartWidgetDiscount';
import CouponWidget from "./CouponWidget";

const cartWidget = (props) => {

  let cartWidgetDiscount = '';

  if(props.cart.coupon.trim() !== '' && props.cart.discount > 0) {
    cartWidgetDiscount = <CartWidgetDiscount coupon={props.cart.coupon} discount={props.cart.discount} total={props.cart.totalCost} originalPrice={props.cart.originalPrice} />;
  }

  const carItems = props.cart.cartContents.map((item, idx) => {

    return <CartWidgetItem key={idx} productName={item.productName} qty={item.qty} price={item.price} sku={item.sku} />;

  });

  return (

      <>


          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Your cart</span>
            <span className="badge badge-secondary badge-pill">{props.total}</span>
          </h4>
          <ul className="list-group mb-3">

            {carItems}
            {cartWidgetDiscount}
            <CartWidgetTotal total={props.cart.totalCost} />
              <CouponWidget />


          </ul>



      </>

  )};

export default cartWidget;