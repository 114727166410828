import React from 'react';

const reportDivider = (props) => {


  return (
      <>
        <hr className='vhr no-print' />

        <hr className='vhr-top no-print' />

        {/*https://davidwalsh.name/css-page-breaks*/}
        <div className="page-break" />
      </>

  )};

export default reportDivider;