import React, {Component} from 'react';
import Radial from "./Radial";
import Thermometer from "./Thermometer";
import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';

class TimingOfMarriage extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {

        // let value = 0;
        // let color = '#eee';
        // let paragraph = '';
        // let therm = null;
        //
        // if(this.props.idealAge && this.props.risk.primarySelf) {
        //
        //     value = this.props.idealAge.primarySelf[0];
        //     color = '#28b7b2';
        //     therm = <Thermometer value={value} color={color} cId='mt1' />;
        // }


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-clock fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            MARRIAGE OUTLOOK
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    While most of us want to marry at some point in our life, some of us want to marry sooner than others.
                    Research reveals that our desired timing of marriage contributes to how we act in romantic relationships.
                    In this section we have assessed two aspects of your ideal timing for marriage: how soon you would like to marry from
                    now and the age you think it is best to marry.  Both have some important implications for your dating experiences and preparation for marriage.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="pt-4">
                    <b>TIMING OF MARRIAGE: <span className='text-black-70'>{this.props.marTiming.primarySelf[0] < 50 && 'Later'}
                        {this.props.marTiming.primarySelf[0] >= 50 && 'Now'}</span></b>
                </div>

                {this.props.windowSize.windowWidth >= 710 &&

                <div className="d-flex justify-content-center align-items-center">
                    <div className="p-2 flex-fill align-items-center text-center gauge">Later
                    </div>
                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.marTiming.primarySelf[0]} cId='hpgb'/>
                    </div>
                    <div className="p-2 flex-fill gauge">Now
                    </div>
                </div>
                }

                {this.props.windowSize.windowWidth < 710 &&

                <div className="d-flex justify-content-around align-items-center">

                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.marTiming.primarySelf[0]} cId='hpgb'/>
                    </div>

                </div>
                }

                <div style={{borderBottom: '2px #4374a3 solid', marginTop: '-8em', zIndex: 9999}} />

                <div className="d-flex justify-content-around align-items-start">
                    <div className={'mt-2 p-2 flex-even ' + (this.props.marTiming.primarySelf[0] < 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">Later</div>
                            }
                            <p>
                                Low: A low score in this scale indicates you are not in any rush to marry, and may even believe that later is better, if you
                                want to marry at all.  You are mostly focused on dating for fun and companionship without the expectation for high levels of
                                commitment or the long-term.
                            </p>
                            <p>
                                Pros: Because you are not looking for high investment in relationships, you are able to
                                remain flexible when you meet new people.  You do not get too emotionally attached to 	any one person which can
                                help in avoiding feelings of jealousy or disappointment.  You 	are also able to focus on having fun and really
                                getting to know someone rather than 	forcing a relationship to happen.
                            </p>

                            <p>
                                Cons: What you want from relationships will be unclear to yourself and potential 	partners.
                                Research shows that being too vague about where we want a relationship to go 	is related to negative relationship patterns.
                                If you are too focused on having fun and not 	putting in at least some degree of serious consideration to the
                                relationships you form, it 	will be more difficult to commit fully when you do feel ready to marry.
                                You may give up 	a relationship that could turn into a very rewarding and lasting marriage if you are too
                                focused on trying to stay single as long as possible.
                            </p>
                        </div>
                    </div>
                    <div className={'mt-2 p-2 flex-even ' + (this.props.marTiming.primarySelf[0] >= 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">Now</div>
                            }
                            <p>
                                High: A high score in this scale indicates you would like to be married soon.  You are looking for a serious
                                relationship and focusing on finding someone you can envision spending the rest of your life with.
                            </p>
                            <p>
                                Pros: When thinking about relationships, you have a clear picture of what you want from
                                the relationship and are able to prepare for getting just that.  Research shows that having
                                a clear goal contributes to higher relationship stability and satisfaction because we are
                                more intentional in our behaviors and activities.  You look for opportunities to make
                                yourself a more attractive relationship and marriage partner, which is helping you prepare 	for married life.
                            </p>
                            <p>
                                Cons: If you come across as too anxious to marry, you can to scare away potential 	partners.
                                While wanting to marry soon is not a bad thing, do not let that desire control 	you and keep you
                                blind to your own limitations as well as those of a potential partner.
                                You may miss important red flags in dating and get into an unhealthy relationship if you 	are so caught
                                up in wanting to get married that you don’t take the time to really get to	know your partner at a deep level.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="page-break"></div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="mt-2">
                    <b>IDEAL AGE AT MARRIAGE</b>
                </div>
                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    <strong>Research shows that our ideal age for marriage is often when we actually marry.</strong> Based on this
                    information, your ideal age provides some information on what your future marriage may look like
                    and how it may affect your future romantic relationships. Overall age at marriage trends suggest
                    there is actually a sweet spot for marriage. We have assessed where your ideal falls in relation to that
                    sweet spot, and what that might mean for you. Why we see a sweet spot for marriage is not very clear
                    in the research, though there are many theories. Keep in mind that this information represents trends
                    only, and does not necessarily mean you will experience all, if any, of the challenges of your ideal age.
                    Use this information instead to evaluate if your ideal age for marriage will really give you the best
                    chance for marriage and relationship success.
                </div>
                <div style={{borderBottom: '2px #4374a3 solid'}} />

                <div className="d-sm-flex justify-content-around align-items-start text-center">
                    <div className="p-2 flex-fill">
                        <Thermometer value={this.props.idealAge.primarySelf[0]} color='#28b7b2' cId='hymb' title='Ideal Age' />
                    </div>
                    <div className="p-2 text-justify">
                        <p>
                            <strong>30+:</strong> Getting married after 30 often comes with elevated risk. While divorce rates from 20 to
                            30 show decreasing rates over time, after 30 the risk of divorce begins to increase again. In
                            addition, satisfaction in marriage is also likely to suffer more after 30. If you answered in this
                            range, research suggests you may be giving credibility to such unrealistic expectations for
                            marriage readiness as being well established in a career, owning a home, and already leading
                            an upper-middle class life. While these are all good goals, research actually suggests that
                            such achievements create challenges in being able to adjust your life to fit the goals and
                            desires of a spouse. Achievement in these goals is also found to be higher among married
                            couples than single individuals. Think about why you believe waiting so long is helpful and
                            seriously consider the possibility that being married will help rather than hinder your efforts.
                        </p>
                        <p>
                            <strong>25 to 30:</strong> This age range generally experiences the least risk of divorce. You will be marrying
                            at a time when you are just beginning a career which may offer good life flexibility, be more
                            financially stable, and just have the benefit of added maturity. All of these are known to
                            contribute to both stability in life in general as well as relationships. But stability may come at
                            the cost of satisfaction. Research suggests that you may struggle with remaining happy with
                            your marriage compared to those who marry younger than 25. If you wait to marry you may
                            find that those with whom you would be happiest will already be married which may lead to
                            settling for a spouse somewhat less well matched. You may also experience physical and
                            sexual declines that could then decrease overall marriage satisfaction.
                        </p>
                        <p>
                            <strong>20 to 25:</strong> Current social trends would have us believe that marrying before 25 is likely to lead
                            to divorce. While this age range does have a higher risk of divorce than those older than 25,
                            this difference is actually very small. And that difference may be worth it. Recent research
                            suggests that those who marry between 22 and 25 years of age tend to be happier in their
                            marriages than those who marry older. If you answered in this range, some of the benefits
                            scholars have identified are ease of adjusting your life to that of a spouse, having
                            opportunities to date a few people without the possible loss of a great marriage partner, and
                            working towards education and career with your marriage in mind. Some of the challenges
                            you face, however, include financial instability and a higher possibility of childbirth earlier in
                            life which brings additional constraints.
                        </p>
                        <p>
                            <strong>Below 20:</strong> Research consistently shows that those who marry younger than 20 have unique,
                            and often severe, challenges in finding marriage success. If you answered in this range,
                            however, it does not mean your future marriage is doomed. Just be aware of these unique
                            challenges including possible social stigma, a loss of educational and work experiences, and
                            a greater possibility of regret for other missed opportunities in dating. Be flexible and willing
                            to wait a little longer as just a couple of extra years may give you a much firmer foundation for
                            marriage.
                        </p>
                    </div>
                </div>

                <div style={{borderBottom: '2px #4374a3 solid'}} className='mb-5'></div>

            </>

        )

    }

}
export default withWindowSizeListener(TimingOfMarriage);