import React, {Component} from 'react';
import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';
import Radial from "./Radial";

class MaritalSalience extends Component {

    componentDidMount() {
        WindowSizeListener.DEBOUNCE_TIME = 1000;
    }

    render() {


        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-house-night fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            MARRIAGE OUTLOOK
                        </div>
                    </div>
                </div>

                <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4 text-justify'>
                    In life we are presented with many different roles from husband or wife, mother or father, career, and our education.
                    While each role is often important to us and we want to be successful in each, research shows that we often have to prioritize our
                    many roles and sometimes make sacrifices in one so we can have greater success in another.  When it comes to marital success, the less
                    marriage becomes a priority, the more couples often struggle to maintain satisfying and stable relationships.  In this section we have
                    assessed how the importance you place on your future marriage compares to other future roles.
                </div>

                <div style={{borderBottom: '1px black solid', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '1.5em', color: '#4374a3'}} className="pt-4">
                    <b>MARITAL SALIENCE: <span className='text-black-70'>{this.props.salience.primarySelf[0] < 50 && 'Low'}
                        {this.props.salience.primarySelf[0] >= 50 && 'High'}</span></b>
                </div>

                {this.props.windowSize.windowWidth >= 710 &&
                <div className="d-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill align-items-center text-center gauge">Low</div>
                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.salience.primarySelf[0]} cId='btir'/>
                    </div>
                    <div className="p-2 flex-fill gauge">High</div>
                </div>
                }

                {this.props.windowSize.windowWidth < 710 &&

                <div className="d-flex justify-content-around align-items-center">

                    <div className="p-2 flex-fill align-items-center text-center">
                        <Radial value={this.props.salience.primarySelf[0]} cId='btir' />
                    </div>

                </div>
                }

                <div style={{borderBottom: '2px #4374a3 solid', marginTop: '-8em', zIndex: 9999}} />

                <div className="d-flex justify-content-around align-items-start text-justify">
                    <div className={'mt-2 p-2 flex-even ' + (this.props.salience.primarySelf[0] < 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">Low</div>
                            }
                            <p>
                                Low: If you scored low on marital salience it means that marriage is not among the top priorities in your life.
                                You feel that finishing an education and establishing yourself in your career are more important.
                            </p>
                            <p>
                                Pros: You will have more options in career and educational pursuits if you are not 	married as it is easier to move alone than with a family.
                                You will also be less likely to 	come out of educational pursuits with high student debt as your income will stretch 	further.
                                Such benefits could be helpful in later relationships as you feel more financially 	stable, which is shown to contribute to increased stability in marriage.
                            </p>
                            <p>
                                Cons: One of the greatest challenges to forming lasting marriage when it is not among 	your top priorities is difficulty remaining
                                flexible in work and education options as 	getting married can require settling for an option other than 	your first choice, or require
                                "starting over" in a new job or career path more consistent with family life.  The more
                                time you spend in activities outside of family life, the more chances there are for strain 	through unequal sharing of household and childcare
                                responsibilities, as well as limited 	opportunities for relationship building.
                            </p>
                        </div>
                    </div>
                    <div className={'mt-2 p-2 flex-even ' + (this.props.salience.primarySelf[0] >= 50 && 'selected-bg')}>
                        <div style={{fontFamily: 'Nunito', fontSize: '1em'}} className='mt-4 mb-4'>
                            {this.props.windowSize.windowWidth < 710 &&
                            <div className="p-2 flex-fill align-items-center text-center gauge">High</div>
                            }
                            <p>
                                High: If you scored high on marital salience it means that marriage is one of the top priorities of your life.  You are more likely to give up a promotion
                                at work, or an extra college course, in order to spend more time on your marriage.  You are also less concerned with being established in your future career or
                                finishing an education before marrying.
                            </p>
                            <p>
                                Pros: Because you value successful marriage more than other pursuits you will have 	better chances to experience happiness and security in your marriage.
                                You have a clear 	vision of where marriage fits in your life goals, which allows for making plans that work 	well with married life.  You are willing and
                                ready to make the sacrifices necessary in 	order to increase your chances of marriage success.
                            </p>
                            <p>
                                Cons: You will be required to make sacrifices in career and educational pursuits leading 	to possible feelings of inadequacy in other areas of life.
                                This can lead to depression or 	anxiety which can negatively impact marriages.  You may also find that some of your
                                expectations about the time you will have to spend with spouse and family are not always 	realistic which can create feelings of confusion and
                                lack of motivation.
                            </p>
                        </div>
                    </div>
                </div>

                <div style={{borderBottom: '2px #4374a3 solid'}} className='mb-5'></div>

            </>

        )

    }

}

export default withWindowSizeListener(MaritalSalience);