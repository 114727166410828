import React, {Component} from 'react';
import DonutChart from "../DonutChart";
import GraphLegend from "../relate/GraphLegend";
import StrengthLegendDetail from "../relate/StrengthLegendDetail";
import ImproveLegendDetail from "../relate/ImproveLegendDetail";
import ChallengeLegendDetail from "../relate/ChallengeLegendDetail"
import * as Color from '../../constants/Colors'

class ReadyConflictResolve extends Component {

    componentDidMount() {
    }

    render() {

        return (

            <>

                <div style={{borderBottom: '2px #4374a3 solid', fontFamily: 'Nunito', fontSize: '1.6em'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div><i className="fal fa-handshake fa-2x" style={{color: "#c3b2b5"}}></i></div>
                        <div>
                            CONFLICT RESOLUTION
                        </div>
                    </div>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='dlbt' percent={this.props.overallConflictResol.primarySelf[0]}
                                    color={this.props.overallConflictResol.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="p-2 flex-fill"></div>
                    <div className="mt-3 pb-4 pl-4 pr-4 pt-3 mb-1">
                        {this.props.overallConflictResol.primarySelf[1] === Color.CHALLENGE &&
                        <ChallengeLegendDetail id="dosc">
                            Conflict is a source of weakness in your relationships. You may often be focused on being
                            right or you may not back down easily. Or, you may never feel right so you tend to shut
                            down. Both are extremes that hurt relationships. Resolving conflict is not about winning or
                            losing, but finding ways to connect and learning about what is important to others.
                            Relationship educators and therapists have developed a number of techniques for more
                            effectively resolving conflicts, and many of them can be found in books or online. The
                            following scales represent the sub-dimensions of conflict that may help you target specific
                            areas you can work on.
                        </ChallengeLegendDetail>
                        }

                        {this.props.overallConflictResol.primarySelf[1] === Color.IMPROVE &&
                        <ImproveLegendDetail id="dpew">
                            You likely have some weaknesses in either communication skills related to conflict or
                            communication motivations that hold you back from successfully resolving conflicts. Minor
                            conflicts are not likely a major problem, but you may find that very sensitive or important
                            issues will be more overwhelming for you. Don’t be afraid to take a break if you find
                            conflict getting out of your control. Give yourself time to think and cool down. Don’t
                            return to the issue until you feel ready.
                        </ImproveLegendDetail>
                        }

                        {this.props.overallConflictResol.primarySelf[1] === Color.STRENGTH &&
                        <StrengthLegendDetail id="dqea">
                            You are able to resolve conflict well and in a way that keeps both you and your partner
                            feeling validated and cared for. This is a great asset for you as conflict will arise in any
                            relationship and you are well prepared to handle it. Both your communication skills and your
                            ability to stay connected with others are strong. But while this is a strength now, remember
                            that without good motivations our skills can quickly become a weapon. Stay focused on
                            connecting.
                        </StrengthLegendDetail>
                        }
                    </div>
                </div>

                <div className="mt-2 text-justify" style={{fontFamily: 'Nunito', fontSize: '1em'}}>
                    Conflict is a normal part of any relationship, especially romantic relationships, and is a specific
                    type of communication that occurs when people disagree. How we handle conflict is one of the
                    determining factors for the success of our relationships. As with any communication, conflict
                    resolution requires good skills as well as motivations for connection. In fact, when it comes to
                    conflict, our desire to maintain connection with our partner may be far more important than our
                    skills. If we do not have the right motivation, it is possible to use our skills to hurt the other
                    person. So for this section, we have chosen to measure aspects which represent not only skills, but
                    reflect the underlying motivations you may have during conflict.
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>NON-CRITICAL</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='dqes' percent={this.props.noncritical.primarySelf[0]}
                                    color={this.props.noncritical.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>

                        Criticism during conflict reflects a tendency to place the blame for some failing on another
                        person. But this usually only makes conflict escalate to dangerous levels. Our motivation when
                        we use criticism is being right and passing off responsibility. Healthy conflict resolution can
                        only occur when we are more objective and focus on resolution rather than complaint. A high
                        score means that you are able to withhold criticisms and don’t allow them to escalate conflict.

                    </div>
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>RESPECT</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='dskw' percent={this.props.respect.primarySelf[0]}
                                    color={this.props.respect.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="p-2 flex-fill"></div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        Respect represents the idea of treating each other equally and accepting different points of
                        view. At the core of respect is acceptance of another for who they are. This means we are not
                        trying to change another person. If we are trying to change another during conflict, we will
                        focus on being right rather than focusing on resolving the issue as a team. A high score on this
                        scale reflects a tendency towards a respectful motivation.
                    </div>
                </div>


                <div style={{
                    borderBottom: '1px black solid',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: '1.5em',
                    color: '#4374a3'
                }} className="pt-4">
                    <b>NOT OVERWHELMED</b>
                </div>

                <GraphLegend/>

                <div className="d-sm-flex justify-content-around align-items-center mb-3">
                    <div className="p-2 flex-fill">
                        <div className='pl'>
                            <DonutChart elId='duom' percent={this.props.notOverwhelmed.primarySelf[0]}
                                    color={this.props.notOverwhelmed.primarySelf[1]}/>
                        </div>
                    </div>
                    <div className="mt-3 pb-3 pl-4 pr-4 pt-3 mb-1 text-justify"
                         style={{backgroundColor: '#d9e3ed', borderRadius: '20px'}}>
                        During conflict, it is especially easy to become overwhelmed by emotions, which then either
                        drives us to rash and hurtful words or withdrawal into silence. Neither is helpful in resolving
                        conflicts, often making it worse. If we allow our emotions to overwhelm us, our motivations
                        during conflict become less about resolving anything and more about trying to escape. This scale
                        assesses how emotions tend to potentially overwhelm you during conflict, and therefore shift
                        your motivations. A high score means you are able to work through emotions quickly and stay in
                        control.
                    </div>
                </div>


            </>

        )

    }

}

export default ReadyConflictResolve;