import React from "react";
import styles from  './BulkOrderForm.module.css';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {createOptionsWithPostal} from "../Stripe/createOptions";
import axios from "../axios/axios-custom";
import {withRouter} from "react-router-dom";
import NavButton from "../layout/navbar/navigationItems/NavButton";
import Spinner from "../Spinner/Spinner";

class BulkOrderForm extends React.Component {

    initialState = {
        relateQty: 0,
        readyQty: 0,
        totalCost: 0,
        totalQty: 0,
        pricePer: 0,
        cardHelpText: '',
        elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
        invalidClass: 'form-control is-invalid',
        validClass: 'form-control',
        showCardElement: false,
        cardComplete: false,
        buttonDisabled: false,
        spinner: false,
        error: '',
    };

    state = {
        ...this.initialState
    };

    resetState = () => {

        this.setState({...this.initialState});

    };

    handleBlur = () => {
    };

    handleChange = (change) => {

        //console.log(change);

        //console.log(change.error);

        this.setState({cardComplete: change.complete});

        if (change.error) {
            this.setState({cardHelpText: change.error.message});

        } else {
            this.setState({cardHelpText: ''});
        }

    };
    handleClick = () => {
    };
    handleFocus = () => {
    };
    handleReady = () => {
        // {(el) => el.focus()}
    };

    handlePaymentSubmit = () => {

        this.setState({buttonDisabled: true, spinner: true, error: ''});


        if(this.state.totalQty > 0) {

            this.props.stripe.createToken().then(
                payload => {

                    if (!('error' in payload)) {

                        const orderData = {
                            tokenId: payload.token.id,
                            cardId: payload.token.card.id,
                            brand: payload.token.brand,
                            clientIp: payload.token.client_ip,
                            last4: payload.token.card.last4,
                            readyQty: this.state.readyQty,
                            relateQty: this.state.relateQty
                        };

                        let url = `${process.env.REACT_APP_API_URL}/api/pro/bulk-order`;


                        axios.post(url, orderData, {withCredentials: true})
                            .then(response => {

                                //console.log(response);

                                this.props.history.push(`/receipt/pro/${response.data.orderId}/${response.data.userId}`);

                            })
                            .catch(error => {
                                // console.log('ERROR',  error.response.data.detail);
                                // console.log(JSON.stringify(error));
                                //alert('inside error!' + JSON.stringify(error));
                                this.setState({error: error.response.data.detail, buttonDisabled: false, spinner: false});

                            });

                    } else {

                        this.setState({buttonDisabled: false, spinner: false});

                    }

                }).catch(exception => {
                // token creation
                // TODO: Display an error mesage
                // console.log("exception", exception);
                this.setState({buttonDisabled: false, spinner: false});
            });

        }

    };

    handleInput = (event) => {
        // console.log(event.target.value);

        let enteredQty = Math.abs(event.target.value);

        let totalQty = 0;
        let price = 0;

        if (event.target.id === "relateQty") {

            totalQty = enteredQty + this.state.readyQty;
            price = this.getPrice(totalQty);

            this.setState({
                relateQty: enteredQty, totalCost: totalQty * price,
                totalQty: totalQty, pricePer: price
            });
        } else {

            totalQty = enteredQty + this.state.relateQty;
            price = this.getPrice(totalQty);

            this.setState({readyQty: enteredQty, totalCost: totalQty * price, totalQty: totalQty, pricePer: price});
        }

    };

    getPrice = (qty) => {

        if (qty <= 9) {
            return 20;
        } else if (qty > 9 && qty <= 20) {
            return 18;
        } else if (qty > 20 && qty <= 50) {
            return 15;
        } else if (qty > 50 && qty <= 99) {
            return 12;
        } else {
            return 10;
        }

    };

    preventNonNumeric = (event) => {
        if (event.which < 48 || event.which > 57) {
            event.preventDefault();
        }
    };

    formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    };


    render() {

        let errorDiv = <div className="alert alert-danger text-center" role="alert">
            <i className="fal fa-exclamation-triangle"/> {this.state.error}
        </div>;

        const {elementFontSize} = this.state;

        let priceTable = <div className="card bg-light">

            <div className="card-body">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Relate Qty</th>
                        <th scope="col">Ready Qty</th>
                        <th scope="col">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.formatNumber(this.state.relateQty)}</td>
                        <td>{this.formatNumber(this.state.readyQty)}</td>
                        <td>{this.formatNumber(this.state.totalQty)} x
                            $ {Number.parseFloat(this.state.pricePer).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td/>
                        <td><b>$ {this.formatNumber(Number.parseFloat(this.state.totalCost).toFixed(2))}</b></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>;

        let cardElement =
            <div className="mb-3" id="bulk">
                <CardElement
                    className={this.state.cardHelpText.length === 0 ? this.state.validClass : this.state.invalidClass}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onReady={(el) => el.focus()}  // {this.handleReady}
                    {...createOptionsWithPostal(this.props.fontSize)}
                />
                <div className="invalid-feedback text-left" style={{display: "block"}}>
                    {this.state.cardHelpText}
                </div>
                <div className="mb-3"/>
                <button type="button" className="btn btn-outline-success btn-block" onClick={this.handlePaymentSubmit} disabled={!this.state.cardComplete || this.state.buttonDisabled}>Complete Purchase</button>
            </div>;

        return (

            <>

                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mb-4" style={{fontSize: '2.3em'}}>Professionals Bulk Order</h5>
                </div>



                <div className="mx-auto  col-10">
                    <div className="mb-4">
                        <NavButton className="btn  btn-outline-success" link="/pro/dashboard"><i className="fal fa-arrow-alt-left"/> Back To Dashboard</NavButton>
                    </div>
                <div className="card">
                    <div className="card-header">
                        ASSESSMENT BULK PRICING
                    </div>
                    <div className="card-body text-justify">
                        <ul className="leaders">
                            <li><span>1 - 9</span>
                                <span>$20.00 each</span></li>
                            <li><span>10 - 20</span>
                                <span>$18.00 each</span></li>
                            <li><span>21 - 50</span>
                                <span>$15.00 each</span></li>
                            <li><span>51 - 99</span>
                                <span>$12.00 each</span></li>
                            <li><span>100 +</span>
                                <span>$10.00 each</span></li>
                        </ul>
                    </div>
                </div>
                </div>

                <div className="pricing-header mx-auto text-center">
                    <h5 className="display-4 mt-4" style={{fontSize: '1.5em'}}>
                        Enter the quantity of each assessment you would like to purchase
                    </h5>
                </div>

                {this.state.spinner && <Spinner />}
                {this.state.error && errorDiv}
                <div className="mx-auto text-center">

                    <form hidden={this.state.spinner} className={`form-signin ${styles.formFont}`} onSubmit={(ev) => ev.preventDefault() }>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="relateQty" className={styles.bulkOrderLabel}>RELATE&trade; Quantity</label>
                                <input type="text" className="form-control form-control-lg" id="relateQty"
                                       name="relateQty" onChange={this.handleInput}
                                       value={this.state.relateQty > 0 ? this.state.relateQty : ''}
                                       onKeyPress={this.preventNonNumeric}
                                       tabIndex="1"
                                />
                                <button type="button" disabled={this.state.totalCost === 0}
                                        className={`btn btn-outline-primary btn-block mb-4 mr-5 mt-3`}
                                        onClick={() => this.setState({showCardElement: true})}  tabIndex="3">PROCEED
                                </button>
                            </div>

                            <div className="col-6">
                                <label htmlFor="readyQty" className={styles.bulkOrderLabel}>READY&trade; Quantity</label>
                                <input type="text" className="form-control form-control-lg" id="readyQty"
                                       name="readyQty" onChange={this.handleInput}
                                       value={this.state.readyQty > 0 ? this.state.readyQty : ''}
                                       onKeyPress={this.preventNonNumeric}
                                       tabIndex="2"
                                />
                                <button type="button" className="btn btn-block btn-outline-danger mb-4 mt-3 pl-4 pr-4"
                                        disabled={this.state.totalCost === 0}
                                        onClick={this.resetState}  tabIndex="4">CLEAR
                                </button>
                            </div>

                        </div>

                        {this.state.showCardElement && cardElement}
                        {this.state.totalCost > 0 && priceTable}


                    </form>


                </div>


            </>

        );
    }
}

export default withRouter(injectStripe(BulkOrderForm));
