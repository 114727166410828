import React from 'react';
import {Link} from 'react-router-dom';
import NavigationItem from "./navigationItems/NavigationItem";
import NavButton from "./navigationItems/NavButton";
import CartDisplay from "./CartDisplay";

const loggedOutNavBar = ( props ) => (

<>



  <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">

    <h5 className="my-0 mr-md-auto font-weight-normal">
      <a href="https://www.relatefoundation.com" className="p-2 text-dark" ><img className="mb-2" src="/images/header-logo.png" alt="" height="40"/></a>
      {/*<Link to='https://www.relatefoundation.com' className="p-2 text-dark" ><img className="mb-2" src="/images/header-logo.png" alt="" height="40"/></Link>*/}
    </h5>
    <nav className="my-2 my-md-0 mr-md-3">
      <NavigationItem link="/" exact ><i className="fal fa-home"></i> Home</NavigationItem>
      <NavigationItem link="/pricing"><i className="fal fa-dollar-sign"></i> Pricing</NavigationItem>
      <NavButton className="btn btn-outline-primary btn-sm mr-4 ml-4" link="/auth"><i className="fal fa-sign-in-alt"></i> Sign in</NavButton>
      <NavButton className="btn btn-outline-success btn-sm" link="/signup"><i className="fal fa-user-plus"></i> Register</NavButton>
    </nav>

    <CartDisplay />

  </div>


</>

);

export default loggedOutNavBar;