import React, {Component} from 'react';

// let images = require.context('../../public/images', true);

class Order extends Component {

  formatDate = (dateAsString) => {

    let parts = dateAsString.split('-');
// Please pay attention to the month (parts[1]); JavaScript counts months from 0:
// January - 0, February - 1, etc.
    let date = new Date(parts[0], parts[1] - 1, parts[2]);

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    }).format(date);
  };

  // https://www.carlrippon.com/formatting-dates-and-numbers-in-react/
  formatCurrency = (currency) => {

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(currency)

  };


  render() {

    let displayInfo = '';
    let receiptButton = this.props.receiptUrl ? <a href={this.props.receiptUrl}
                                  className="btn btn-outline-primary btn-sm" role="button" target="_blank" rel="noopener noreferrer">
      <i className="fal fa-receipt"/> View Receipt</a> : "ZERO DOLLAR ORDER";

    if(this.props.userOrderId) {

      //const orderDetails = [...this.props.orders];


      displayInfo =  <><div className="card">
        <div className="card-header" style={{fontSize: '.8em', color: '#646464'}}>
          <div className="row">
            <div className="col-4">
              <i className="fal fa-money-check"></i> ORDER PLACED<br/>
              {this.formatDate(this.props.orderDate)}
            </div>
            <div className="col-4">TOTAL<br/>
              {/*${Number.parseFloat(this.props.finalCost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}*/}
              {this.formatCurrency(this.props.finalCost)}
            </div>
            <div className="col-4 text-right">
              ORDER # {this.props.orderId}<br/>
              {this.props.cardBrand && <><img src={`/images/${this.props.cardBrand}.png`} height="16" alt="" /> - {this.props.lastFour}</>}
              {/*<img src={images(`./${this.props.cardBrand}.png`)}  height="16" />*/}

            </div>
          </div>
        </div>
        <div className="card-body">

          {this.props.orders.map((item, idx) => {

            return <React.Fragment key={idx}><div  className="row d-table">
              <div className="col-2">
                <img src={`/images/${item.skuId}.jpg`} width="128" alt="Product" />
              </div>
              <div className="col-10 h-100  d-table-cell align-middle">
                <h5>{item.sku} {item.productName} Assessment</h5>
                Code: {item.redemptionCode}
              </div>
            </div><hr/></React.Fragment>;

          })}

        </div>

        <div className="card-footer text-muted">
          {receiptButton}
          {/*<a href={this.props.receiptUrl}*/}
          {/*   className="btn btn-outline-primary btn-sm" role="button" target="_blank">*/}
          {/*  <i className="fal fa-receipt"></i> View Receipt</a>*/}
        </div>
      </div></>;

    }

    // <img src={images(`./${this.props.cardBrand}.jpg`)} />

    //const orderDetails = [...this.props.orderDetails];


    // const a = this.props.orderDetails.map((item, idx) => {
    //
    //   return <div key={idx}>{item.redemptionCode}</div>;
    //
    // });



    return (




        <>

          {displayInfo}


        </>

    );
  }

}

export default Order;