import React, { Component } from "react";
//import WindowSizeListener, { withWindowSizeListener } from 'react-window-size-listener';
import WindowSizeListener, {withWindowSizeListener} from "react-window-size-listener";

class ResponsiveMatrixGrid extends Component {

  state = {
    rowClass: "selected-response",
    selected: [],
    value: "select",
    questions: this.props.questions,
  };

  componentDidMount() {
    WindowSizeListener.DEBOUNCE_TIME = 1000;
  };

  componentWillUnmount() {
  };



  handleChange = (question, responseId) => {
    question.selectedQuestionResponse = responseId;
  };

  handleClick = (questionId, responseId) => {
    //alert('Clicked ' + qustionId + ' ' + responseId);

    // updateObject( this.state, {
    //   rowClass: 'table-success',
    //   selected: this.state.selected.concat(qustionId)
    // });

    this.props.testMethod(questionId, responseId);

    this.setState({
      // rowClass: "selected-response",
      selected: this.state.selected.concat(questionId)
    });
    //alert('Clicked ' + qustionId + ' ' + responseId);
  };

  render() {
    if(this.props.windowSize.windowWidth > 760) {

      return (
          <div className="card mb-5">
            <div className="card-header">
              <h5>{this.props.questionGroupItems.displayText}</h5>
            </div>

            <div className="card-body table-responsive">

              <table className="table table-hover">
                <thead>
                <tr>
                  <th></th>

                  {this.props.questionGroupItems.labels.map(label => (
                      <th  className='text-center' key={label.labelId} style={{borderRight: label.labelText === 'Doesn\'t Apply' ? "1px solid #eee" : "none"}}>{label.labelText}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {this.state.questions.map(question => {
                  if (question.questionType === "grid") {
                    return (
                        <tr
                            key={question.questionId}
                            className={
                              this.state.selected.includes(question.questionId) || question.selectedQuestionResponse !== 0
                                  ? this.state.rowClass
                                  : ""
                            }
                        >
                          <th scope="row" style={{ width: "35%" }}>
                            {question.questionText}{" "}
                          </th>

                          {question.responseChoices.map(choice => {
                            return (
                                <td key={choice.responseChoiceId} style={{textAlign: 'center', verticalAlign: "middle", borderRight: choice.labelText === 'Doesn\'t Apply' ? "1px solid #eee" : "none"}}>
                                  <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        id={"questionLine" + choice.responseChoiceId}
                                        name={"responseChoice" + question.questionId}
                                        className="custom-control-input"
                                        checked={question.selectedQuestionResponse === choice.responseChoiceId}
                                        onChange={() =>
                                            this.handleChange(
                                                question,
                                                choice.responseChoiceId
                                            )}
                                        onClick={() =>
                                            this.handleClick(
                                                question.questionId,
                                                choice.responseChoiceId
                                            )
                                        }
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={
                                          "questionLine" + choice.responseChoiceId
                                        }
                                    >
                                      {/*{question.questionId + ' ' + choice.responseChoiceId}*/}
                                    </label>
                                  </div>
                                </td>
                            );
                          })}
                        </tr>
                    );
                  }
                  return '';
                })}
                </tbody>
              </table>
            </div>
          </div>
      );

    } else {


      return (

          this.props.questions.map((question, i) => {

                return (

                    <React.Fragment key={i}>

                      <h5>{this.props.questionGroupItems.displayText}</h5>



                    <div className="card mb-5" key={question.questionId}>
                      <div className="card-header">
                        <h5 className="card-title">{question.questionText}</h5>
                      </div>

                      <div className="card-body">
                        <table className="table table-borderless">
                          <tbody>
                          <tr className={
                            this.state.selected.includes(question.questionId) || question.selectedQuestionResponse !== 0
                                ? "empty"
                                : ""
                          }
                          >
                            <td>
                          {

                            question.responseChoices.map(choice => {
                              return (

                                  <div className="custom-control custom-radio" style={{minHeight: '2rem', marginRight: '1rem'}} key={choice.responseChoiceId}>


                                    <input
                                        type="radio"
                                        id={"questionLine" + choice.responseChoiceId}
                                        name={"responseChoice" + question.questionId}
                                        className="custom-control-input"
                                        checked={question.selectedQuestionResponse === choice.responseChoiceId}
                                        onChange={() =>
                                            this.handleChange(
                                                question,
                                                choice.responseChoiceId
                                            )}
                                        onClick={() =>
                                            this.handleClick(
                                                question.questionId,
                                                choice.responseChoiceId
                                            )
                                        }
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={
                                          "questionLine" + choice.responseChoiceId
                                        }
                                    >
                                      {/*{question.questionId + ' ' + choice.responseChoiceId}*/}
                                      {choice.labelText}
                                    </label>
                                  </div>
                              )
                            })

                          }



                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                      </React.Fragment>

                );

              }
          )
      );



    }

  }
}

export default withWindowSizeListener(ResponsiveMatrixGrid);
