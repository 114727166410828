import React from 'react';
import CouponWidget from "./CouponWidget";

const cartWidgetTotal = (props) => {

  return (


        <li className="list-group-item d-flex justify-content-between">
          <span>Total (USD)</span>
          <strong>${props.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong>
        </li>

  )};

export default cartWidgetTotal;