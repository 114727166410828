export const createOptions = (fontSize) => {
  return {
    hidePostalCode: true,
    iconStyle: 'default',
    style: {
      base: {
        fontSize: "16px",
        color: '#495057',
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        '::placeholder': {
          color: "#868e96",
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};


export const createOptionsWithPostal = (fontSize) => {
  return {
    hidePostalCode: false,
    iconStyle: 'default',
    style: {
      base: {
        fontSize: fontSize,
        color: '#495057',
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        '::placeholder': {
          color: "#868e96",
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};