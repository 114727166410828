import React, {useEffect, useRef, useState} from 'react';
import axios from "../axios/axios-custom";
import {withRouter} from "react-router";
import AlertError from "../Error/AlertError";
import {Link} from "react-router-dom";
import Spinner from '../Spinner/Spinner';

const ShareCode = (props) => {

    const [email, setEmail] = useState("");
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [errorDetail, setErrorDetail] = useState("There was a problem with sending email.");
    const [showConfirm, setShowConfirm] = useState(false);

    const emailRef = useRef(null);

    //let confirmDiv = <b>dsds</b>;

    useEffect(() => {

        if(props.location.state === undefined
            || props.location.state === null || props.location.state.code.trim() === '') {

            props.history.push({
                pathname: '/assessments'
            })

        }

        //alert(props.location.state.code);
        if(emailRef.current !== null) {
            emailRef.current.focus();
        }

        if(localStorage.getItem('tempUserId') !== null) {
            localStorage.removeItem('tempUserId');
        }

    });

    const checkEmail = (e) => {

        setProcessing(true);

        e.preventDefault();

        if(validateEmail(email.trim())) {

            const emailData = {
                emailAddress: email.trim(),
                redemptionCode: props.location.state.code.trim()
            };


            let url = `${process.env.REACT_APP_API_URL}/api/share/redemption_code`;

            axios.post(url, emailData)
                .then(response => {

                    setProcessing(false);

                    if (response.status >= 200 && response.status < 300) {


                        setShowConfirm(true);

                        console.log();
                    }


                })
                .catch(error => {

                    setError(true);
                    setProcessing(false);


                    // Error 😨
                    if (error.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                    } else if (error.request) {
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        //console.log(error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        //console.log('Error', error.message);
                    }
                    //console.log(error);

                });

        } else {
            setError(true);
            setErrorDetail("Please enter a valid email address");
        }
    };

    const validateEmail = (email) => {

        let result = true;

        if (email.indexOf("@") <= 0) {
            result = false;
        }
        return result;

    };


    return (

        <>
            <div className="pricing-header mx-auto text-center">
                <h5 className="display-4" style={{fontSize: '2.3em'}}>Share Your Assessment Code</h5>
                <p className="lead">
                    Enter the email address of a person to whom you would like to send this redemption code
                    (<b>{props.location.state === undefined ? '' : props.location.state.code }</b>)
                </p>
            </div>

            {error && <AlertError>{errorDetail}</AlertError>}
            {processing && <Spinner />}

            {showConfirm && <div className="alert alert-warning text-center" role="alert">
            The assessment code has been successfully sent<br/>
                Back to <Link to='/assessments'>My Assessments</Link>
            </div>}

            {!processing && <form className="form-signin"  onSubmit={checkEmail}>
                <div className="col-md-12 order-md-1">
                    <div className="mb-3">
                        <label htmlFor="email">Email</label>
                        <input type="email" ref={emailRef}
                               className="form-control"
                               id="email" name="email"
                               onChange={e => setEmail(e.target.value)}
                            //  onBlur={(event) => this.validateElementInput(event)}
                        />
                        <div className="invalid-feedback">
                            Please enter a valid email address.
                        </div>
                    </div>
                </div>
                <hr className="mb-4"/>
                <button className="btn btn-success btn-block" type="submit">Continue</button>
            </form>}

        </>

    )

}

export default withRouter(ShareCode);