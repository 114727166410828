import React, {Component} from 'react';
import './ready-progress.css';
import {withWindowSizeListener} from 'react-window-size-listener';

class ReadyProgress extends Component {

    render() {

        let currentId = 0;
        let percent = 0.0;

        let items = this.props.sections.map((item, idx) => {

            let classNames = '';

            if (parseInt(item.externalSectionId) === this.props.currentSectionId) {

                currentId = item.id;
                classNames = 'active pulse';


                percent = Math.round((idx) / this.props.sections.length * 100);

            }

            if (currentId === 0) {
                classNames = 'completed ';
            }

            return (
                <li key={idx} className={`${classNames} sec-${item.externalSectionId}`} title={item.description} ><small>{item.displayName}</small></li>
            )
        });

        return (

            <>

                {this.props.windowSize.windowWidth >= 650 &&
                <>
                    <div className='progressbar-r-container'>
                        <ul className='progressbar-r'>
                            {items}
                        </ul>
                    </div>
                </>
                }
                <div className="clearfix mb-3"></div>

                {this.props.windowSize.windowWidth < 650 &&
                <>
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped bg-info" role="progressbar" style={{width: `${percent}%`}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100">{percent}%
                        </div>
                    </div>
                    <div className="clearfix mb-3"></div>
                </>
                }


            </>

        )
    }

}

export default withWindowSizeListener(ReadyProgress);